import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import { useAuthStatus } from '@hooks';

const UnauthorizedRoute = () => {
  const isAuthorized = useAuthStatus();

  return isAuthorized ? <Navigate to={ROUTES.root} /> : <Outlet />;
};

export default UnauthorizedRoute;
