interface Props<T> {
  comparator: T;
  toCompare: T[];
  children: React.ReactElement[];
}

const SwitchRender = <T extends string | number>({
  comparator,
  toCompare,
  children,
}: Props<T>) =>
  children[toCompare.findIndex((item) => item === comparator)] ?? null;

export default SwitchRender;
