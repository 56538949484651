import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import usersApi, { UpdateUserData } from './api';

export const getUserThunk = createCommonAsyncThunk<null>(
  'getUser',
  usersApi.getUser
);

export const updateUserThunk = createCommonAsyncThunk<UpdateUserData>(
  'updateUser',
  usersApi.updateUser
);

export const shopifyConnectionStatusThunk = createCommonAsyncThunk<UpdateUserData>(
  'shopifyConnectionStatus',
  usersApi.getShopifyConnectionStatus
);

export default { getUserThunk, updateUserThunk, shopifyConnectionStatusThunk };
