import { ShopifyProduct } from '@devTypes';

import EcommerceLayout1CartItem from './EcommerceLayout1CartItem';

interface Props {
  products: ShopifyProduct[];
  checkoutButtonText: string;
}

const EcommerceLayoutCart = ({ products, checkoutButtonText }: Props) => (
  <div className="flex flex-col gap-7 font-Helvetica">
    <div className="mt-8 flex flex-col gap-5 bg-ecommerce-400 px-4 py-5  rounded-lg">
      <div className="flex flex-col gap-5">
        {products.map((product) => (
          <EcommerceLayout1CartItem
            key={product.id}
            title={product.title}
            options={product.options}
          />
        ))}
      </div>
    </div>
    <div className="w-[278px] flex flex-col gap-4 self-end">
      <div className="flex justify-between font-semibold ">
        <div>Total:</div>
        <div>$40.50</div>
      </div>
      <div className=" EcommerceLayout1CartCheckoutButton text-center py-2.5 cursor-pointer">
        {checkoutButtonText}
      </div>
    </div>
  </div>
);

export default EcommerceLayoutCart;
