import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import grapesjs from 'grapesjs';

import { EditorToolbar, GenerativeAIButton } from '@molecules';
import { EDITOR_SIDEBAR_TABS } from '@constants/common';
import {
  DEFAULT_OUTER_BACKGROUND_COLOR,
  EDITOR_CONTAINERS,
  EditorComponentTypes,
} from '@constants/editor';
import {
  useAutoSave,
  useEditor,
  useForceUpdate,
  useTimeoutAutoSave,
} from '@hooks';
import EditorCanvasHeader from '@organisms/EditorCanvasHeader/EditorCanvasHeader';
import EditorPanel from '@organisms/EditorPanel/EditorPanel';
import { canvasActions } from '@store/features/canvas';
import { editorActions, editorSelectors } from '@store/features/editor';
import { organizationsAsyncThunks } from '@store/features/organizations';
import { shopifyAsyncThunks } from '@store/features/shopify';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { editorConfig } from '@utils/editor';
import { BLOCK_TYPES } from '@utils/editor/jsonBuilder/types';
import {
  getEditorHeight,
  handleEmailTypeChange,
  reduceCarouselBlocksAmount,
  removeAddBlockPlaceholder,
  removeEditorDocumentSelection,
  resizeCanvas,
  resizeHeightAfterRemove,
  setEditorHeight,
} from '@utils/helpers';

import './styles.css';

const EDITOR_TOP_GAP = 110;
const SPACE_BETWEEN_TOOLBAR_AND_EDITOR = 20;

interface EditorProps {
  initiateEditor: Function;
  onEditorChnage: (editor: grapesjs.Editor) => void;
  onGenerativeAISettingsOpen: () => void;
}

const Editor = ({
  initiateEditor,
  onEditorChnage,
  onGenerativeAISettingsOpen,
}: EditorProps) => {
  const [selectedComponent, setSelectedComponent] =
    useState<grapesjs.Component | null>(null);
  const [toolbarPosition, setToolbarPosition] = useState({ x: 0, y: 0 });
  const currentState = useAppSelector(editorSelectors.currentState);
  const canvasWrapperRef = useRef(null);
  const { id } = useParams();
  const editor = useEditor();
  const forceUpdate = useForceUpdate();

  const dispatch = useAppDispatch();
  const autoSave = useAutoSave();
  const autoSaveTimer = useTimeoutAutoSave();

  const handleEditorIframeClick = (e: grapesjs.Editor) => {
    const frame = e.Canvas.getFrameEl();

    frame?.contentWindow?.document.body.addEventListener('click', () =>
      forceUpdate()
    );
  };

  const setupEditorListeners = (e: grapesjs.Editor) => {
    e.on('component:add', (component: grapesjs.Component) => {
      // Ignore component with add-block type
      if (component.get('type') === EditorComponentTypes.AddBlock) {
        return;
      }

      if (component.attributes.type === EditorComponentTypes.Ecommerce) {
        const { height } = component.getAttributes();
        const editorHeight = getEditorHeight(e) + height;

        dispatch(editorActions.setCurrentStateEditorHeight(editorHeight));
      }

      // Allow only one carousel block to be added to canvas
      reduceCarouselBlocksAmount(e, component);

      // Remove add-block placeholder
      removeAddBlockPlaceholder(e);

      resizeCanvas(e);
    });

    e.on('component:remove', () => {
      resizeHeightAfterRemove(e);
    });

    e.on('component:create', (component) => {
      if (component.attributes.type === 'wrapper') {
        const { editorHeight } = component.getAttributes();

        if (editorHeight) {
          dispatch(editorActions.setCurrentStateEditorHeight(editorHeight));
        }
      }
    });

    e.on('component:selected', (selected: grapesjs.Component) => {
      // Deselect sidebar tab
      dispatch(canvasActions.setEditorSelectedTab(null));

      // Set selected component
      setSelectedComponent(selected);

      // Apply gjs-selected for Grapesjs react components
      const classes = selected.getClasses();
      if (!classes.includes('gjs-selected')) {
        selected.addClass('gjs-selected');
      }

      const selectedEl = selected.getEl();
      const gjs = document.getElementById('gjs');

      if (selectedEl) {
        setToolbarPosition({
          x: gjs!.clientWidth + SPACE_BETWEEN_TOOLBAR_AND_EDITOR,
          y: selectedEl.getBoundingClientRect().y,
        });
      }

      resizeCanvas(e);
    });

    e.on('component:deselected', (deselected: grapesjs.Component) => {
      const attributes = deselected.getAttributes();

      resizeHeightAfterRemove(e);

      if (!attributes.settingsChanged) {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: true })
        );
      } else {
        autoSaveTimer.triggerTimeout(() => {
          autoSave();
        }, 0);
      }

      // Remove gjs-selected for Grapesjs react components
      deselected.removeClass('gjs-selected');

      // Deselect selected component
      setSelectedComponent(null);

      const selection = e.Canvas?.getDocument()?.getSelection();

      if (selection) {
        selection.empty();
      }

      removeEditorDocumentSelection(e);

      resizeCanvas(e);
    });

    e.on('canvas:drop', (DataTransfer, model) => {
      if (model.attributes.type === 'text') {
        const data = e.getProjectData();
        const previousState = {
          gjs: currentState.gjs,
          editorHeight: currentState.editorHeight,
          background: currentState.background,
        };
        dispatch(editorActions.addPreviousState(previousState));

        dispatch(editorActions.setCurrentStateGjs(JSON.stringify(data)));
        dispatch(
          editorActions.setCurrentStateEditorHeight(getEditorHeight(editor!))
        );
      } else {
        autoSaveTimer.triggerTimeout(() => {
          autoSave();
        }, 2000);
      }
      resizeCanvas(e);
    });

    e.on('component:drag:end', () => {
      autoSaveTimer.triggerTimeout(() => {
        autoSave();
      });
      resizeCanvas(e);
    });

    e.on('load', () => {
      const ecommerce = e
        .getWrapper()
        ?.components()
        .find((c) => c.get('type') === EditorComponentTypes.Ecommerce);
      const carousel = e
        .getWrapper()
        ?.components()
        .find((c) => c.get('type') === EditorComponentTypes.Carousel);

      ecommerce?.removeClass('gjs-selected');
      carousel?.removeClass('gjs-selected');

      const { editorHeight } = e.getWrapper().getAttributes();
      if (editorHeight) {
        dispatch(editorActions.setCurrentStateEditorHeight(editorHeight));
      }
    });

    e.on('component:update', (component) => {
      if (component.attributes.type === BLOCK_TYPES.ECOMMERCE_BLOCK_TYPE) {
        const { height } = component.attributes.attributes;
        const { clientHeight } = component.getEl();

        if (height && clientHeight !== height) {
          const currentEditorHeight = getEditorHeight(e);

          const newEditorHeight = currentEditorHeight - clientHeight + height;
          // eslint-disable-next-line no-param-reassign
          component.attributes.attributes.height = null;

          dispatch(editorActions.setCurrentStateEditorHeight(newEditorHeight));

          return;
        }
      }
      if (component.attributes.type === 'columns') {
        resizeHeightAfterRemove(e);
      }

      if (component.attributes.type === 'columns') {
        resizeHeightAfterRemove(e);
      }

      resizeCanvas(e);
    });
  };

  useEffect(() => {
    const gjs = document.getElementById('gjs');

    if (gjs) {
      setToolbarPosition({
        x: gjs.clientWidth + SPACE_BETWEEN_TOOLBAR_AND_EDITOR,
        y: toolbarPosition.y,
      });
    }

    dispatch(organizationsAsyncThunks.getMeOrganizationDataThunk(null));
    dispatch(shopifyAsyncThunks.verifyShopifyConnectionThunk(null));
  }, []);

  useEffect(() => {
    if (!editor) {
      const editorCanvasWrapperEl = document.querySelector(
        '.Editor-Canvas-Wrapper'
      ) as HTMLElement;

      editorConfig.listenToEl = [editorCanvasWrapperEl];
      onEditorChnage(grapesjs.init(editorConfig));
    } else {
      initiateEditor(editor);

      editor.Keymaps.removeAll();

      if (Number(id) === currentState.id && currentState.gjs) {
        editor.loadProjectData(JSON.parse(currentState.gjs));
        dispatch(templateAsyncThunks.getTemplateByIdThunk({ id: Number(id) }))
          .unwrap()
          .then((res: any) => {
            if (res?.gjs) {
              editor.loadProjectData(JSON.parse(res.gjs));
              dispatch(
                editorActions.setInitialState({
                  gjs: res.gjs,
                  id: Number(id),
                })
              );
            }
          });
      } else {
        dispatch(editorActions.resetCurrentState());
        dispatch(editorActions.clearNextStates());
        dispatch(editorActions.clearPreviousStates());
        dispatch(editorActions.setCurrentStateId(Number(id)));
        dispatch(templateAsyncThunks.getTemplateByIdThunk({ id: Number(id) }))
          .unwrap()
          .then((res: any) => {
            if (res?.gjs) {
              editor.loadProjectData(JSON.parse(res.gjs));
              dispatch(
                editorActions.setInitialState({
                  gjs: res.gjs,
                  id: Number(id),
                })
              );
            }
          });
      }
    }
  }, [editor]);

  useEffect(() => {
    if (editor) {
      //
      // Possible Improvement:
      //      - Decompose all event handlers to separate functions.
      //      - Implement clean up function for event handlers.
      //
      setupEditorListeners(editor);

      handleEditorIframeClick(editor);
    }
  }, [currentState, editor]);

  useEffect(() => {
    setEditorHeight(currentState.editorHeight);
  }, [currentState.editorHeight]);

  const handleComponentDeselect = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target !== canvasWrapperRef.current) {
      return;
    }

    dispatch(canvasActions.setEditorSelectedTab(EDITOR_SIDEBAR_TABS.addBlock));
    editor?.select(undefined);
  };

  const handleToolbarPositionChange = ({ x, y }: { x: number; y: number }) => {
    setToolbarPosition({ x, y });
  };

  return (
    <div className="Editor-Wrapper">
      <EditorPanel editor={editor} selectedComponent={selectedComponent} />
      <div className="Editor-Canvas-Wrapper">
        <div
          className="Editor-Canvas-container"
          id={EDITOR_CONTAINERS.canvasContainer}
          role="button"
          tabIndex={0}
          onClick={handleComponentDeselect}
          ref={canvasWrapperRef}
          style={{
            backgroundColor: DEFAULT_OUTER_BACKGROUND_COLOR,
            backgroundPosition: 'center top',
          }}
        >
          <div className="Editor-Canvas relative">
            <EditorCanvasHeader onChange={handleEmailTypeChange(editor!)} />
            <div id={EDITOR_CONTAINERS.canvas} />
            <div
              className="absolute transition-all z-20"
              style={{
                top: toolbarPosition.y + EDITOR_TOP_GAP,
                left: toolbarPosition.x,
              }}
            >
              {selectedComponent?.attributes.type ===
                EditorComponentTypes.Text && (
                <GenerativeAIButton // todo: fix button position for columns block text
                  onClick={onGenerativeAISettingsOpen}
                  className="absolute -top-[-36px] -left-[778px]"
                />
              )}
              <EditorToolbar
                autoSave={autoSave}
                position={toolbarPosition}
                onPositionChange={handleToolbarPositionChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editor;
