import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';

import './styles.css';

interface Props {
  title: string;
  icon: SvgFunctionComponent;
  footer: React.ReactNode | React.ReactNode[];
  isActive: boolean;
  onClick?: (e: any) => void;
}

const MiniAppCard = ({ title, icon, footer, isActive, onClick }: Props) => (
  <div
    className={clsx(
      isActive && 'MiniAppCard-active',
      'MiniAppCard',
      'flexbox-column'
    )}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <div className="MiniAppCardTitle flexbox-row flexbox-ai-center flexbox-gap-16px">
      <Icon SVG={icon} width={28} height={28} />
      <Typography variant="subtitle3">{title}</Typography>
    </div>
    <div className="MiniAppCardFooter flexbox-row flexbox-ai-center flexbox-jc-space-between">
      {footer}
    </div>
  </div>
);

export default MiniAppCard;
