import { MouseEventHandler } from 'react';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as LeftIcon } from '@assets/icons/chevron-left.svg';

import './styles.css';

interface Props {
  onGoBackClick: MouseEventHandler;
  className?: string;
}

const ExitButton = ({ onGoBackClick, className }: Props) => (
  <div
    className={clsx('ExitButton', className)}
    role="button"
    tabIndex={0}
    onClick={onGoBackClick}
  >
    <div className="ExitButton-Icon">
      <Icon SVG={LeftIcon} height={20} width={20} />
    </div>
    <div className="ExitButton-Back">
      <Typography variant="subtitle3">Back</Typography>
    </div>
  </div>
);

export default ExitButton;
