import grapesjs from 'grapesjs';

import { generateGrapesjsSpacerComponent } from '@utils/editor/components/spacer';
import { createEditorBlock } from '@utils/helpers';

const icon = `
<svg width="24" height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_10_700)">
    <path d="M4 12H20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 8L12 4L16 8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 16L12 20L8 16" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_10_700">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
</svg>
`;

const label = createEditorBlock('Spacer', icon, 'spacer');
const content = generateGrapesjsSpacerComponent();
export const SpacerBlock: grapesjs.BlockOptions = {
  label,
  content,
  select: true,
};
