import { useEffect, useState } from 'react';

import { Divider } from '@atoms';
import { Selector, SliderWithInput } from '@molecules';
import {
  LOGO_ALIGNMENT_OPTIONS,
  MAX_LOGO_PADDING_IN_PX,
  MAX_LOGO_SIZE_IN_PX,
  MIN_LOGO_PADDING_IN_PX,
  MIN_LOGO_SIZE_IN_PX,
} from '@constants/editor';
import { EditorBlockSettingsComponent, SelectorOptionType } from '@devTypes';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';

const EditorLogoBlockSettingsDesign = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => {
  const [selectedAlignment, setSelectedAlignment] =
    useState<SelectorOptionType>();

  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    if (!selectedComponent) return;

    const attributes = selectedComponent.getAttributes();
    const alignment = LOGO_ALIGNMENT_OPTIONS.find(
      ({ id }) => id === attributes.alignId
    );

    setSize(attributes.size);
    setSelectedAlignment(alignment);
  }, []);

  const handleSizeChange = (newSize: number) => {
    selectedComponent.addAttributes({ size: newSize });

    setSize(newSize);
  };

  const handleAlignmentChange = (option: SelectorOptionType) => {
    const alignId = option.id as Number;
    const { align, paddingTop, paddingBottom, paddingLeft, paddingRight } =
      option.value;

    selectedComponent.addAttributes({
      alignId,
      paddingtop: paddingTop,
      paddingbottom: paddingBottom,
      paddingleft: paddingLeft,
      paddingright: paddingRight,
    });

    selectedComponent.addStyle({
      'text-align': align,
      'padding-top': `${paddingTop}px`,
      'padding-bottom': `${paddingBottom}px`,
      'padding-left': `${paddingLeft}px`,
      'padding-right': `${paddingRight}px`,
    });

    setSelectedAlignment(option);
  };

  return (
    <div className="EditorLogoBlockSettingsDesign">
      <div className="flexbox-column flexbox-gap-24px">
        <Selector
          options={LOGO_ALIGNMENT_OPTIONS}
          value={selectedAlignment}
          onChange={handleAlignmentChange}
        />
        <SliderWithInput
          title="Image size"
          value={size}
          onChange={handleSizeChange}
          min={MIN_LOGO_SIZE_IN_PX}
          max={MAX_LOGO_SIZE_IN_PX}
        />
        <EditorBlockBackgroundSettings
          selectedComponent={selectedComponent}
          titleVariant="regular"
        />
      </div>
      <Divider />
      <EditorBlockPaddingSettings
        selectedComponent={selectedComponent}
        min={MIN_LOGO_PADDING_IN_PX}
        max={MAX_LOGO_PADDING_IN_PX}
      />
    </div>
  );
};

export default EditorLogoBlockSettingsDesign;
