import grapesjs from 'grapesjs';

import { getChildComponentAt } from '@utils/helpers';

export const setImage = (
  component: grapesjs.Component,
  imageUrl: string,
  imageId: number
) => {
  const imageComponent = getChildComponentAt(component, 0);

  imageComponent.addAttributes({ imageUrl, src: imageUrl });

  component.addAttributes({ imageUrl, imageId });
};
