import { useCallback } from 'react';

import { DraggableListItem } from './DraggableListItem';

import './styles.css';

interface Props {
  items: any[];
  onChange: (dragIndex: number, hoverIndex: number) => void;
  onDelete: (productId: string | number) => void;
}

const DraggableList = ({ items, onChange, onDelete }: Props) => {
  const renderCard = useCallback(
    (card: { id: number; title: string }, index: number) => (
      <DraggableListItem
        key={card.id}
        index={index}
        id={card.id}
        title={card.title}
        moveCard={onChange}
        onDelete={onDelete}
      />
    ),
    [onDelete]
  );
  return (
    <div style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
      {items.map((card, i) => renderCard(card, i))}
    </div>
  );
};
export default DraggableList;
