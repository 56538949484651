import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ForgotPasswordView } from '@views';
import { ROUTES } from '@constants/routes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { forgotPasswordThunk } from '@store/features/auth/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { schemas } from '@utils/validation';

const defaultValues = {
  email: '',
};
type FormValues = typeof defaultValues;

const ForgotPasswordPage = () => {
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemas.forgotPasswordSchema),
  });
  const { handleSubmit } = methods;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      navigate(ROUTES.createNewPassword, { state: { token } });
    }
  }, []);

  const forgotPasswordSubmit: SubmitHandler<FormValues> = ({ email }) => {
    dispatch(forgotPasswordThunk({ email }))
      .unwrap()
      .then(() =>
        navigate(ROUTES.forgotPasswordCheckEmail, { state: { email } })
      )
      .catch((err: Error) => toast(err.message));
  };

  return (
    <FormProvider {...methods}>
      <ForgotPasswordView onSubmit={handleSubmit(forgotPasswordSubmit)} />
    </FormProvider>
  );
};

export default ForgotPasswordPage;
