import { useSelector } from 'react-redux';

import { allContactsSelectors } from '@store/features/allcontacts';
import TotalAmountCard from '@templates/AllContactsTemplate/TotalAmountCard';

interface Props {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const ContactsTotalAmounts = ({ selectedTab, setSelectedTab }: Props) => {
  const { metadata: { totalCount = 0, unsubscribedCount = 0 } = {} } =
    useSelector(allContactsSelectors.recipients);

  const spamContacts = useSelector(allContactsSelectors.spamContacts);
  const invalidEmails = useSelector(allContactsSelectors.invalidEmails);

  const bouncedEmails = useSelector(allContactsSelectors.bouncedContacts);

  const onClickAmountCard = (title: string) => {
    switch (title) {
      case 'Spam reports':
        setSelectedTab('spam');
        break;
      case 'Bounce':
        setSelectedTab('bounce');
        break;
      case 'Invalid emails':
        setSelectedTab('invalid');
        break;
      case 'Unsubscribes':
        setSelectedTab('unsubscribes');
        break;
      default:
        setSelectedTab('');
        break;
    }
  };

  return (
    <div className="ContactsTotalAmounts">
      <TotalAmountCard
        title="Total Contacts"
        value={totalCount}
        onClick={onClickAmountCard}
        isSelected={selectedTab === ''}
      />
      <TotalAmountCard
        title="Unsubscribes"
        value={unsubscribedCount}
        onClick={onClickAmountCard}
        isSelected={selectedTab === 'unsubscribes'}
      />
      <TotalAmountCard
        title="Spam reports"
        value={spamContacts?.length || 0}
        onClick={onClickAmountCard}
        isSelected={selectedTab === 'spam'}
      />
      <TotalAmountCard
        title="Bounce"
        value={bouncedEmails?.length || 0}
        onClick={onClickAmountCard}
        isSelected={selectedTab === 'bounce'}
      />
      <TotalAmountCard
        title="Invalid emails"
        value={invalidEmails?.length || 0}
        onClick={onClickAmountCard}
        isSelected={selectedTab === 'invalid'}
      />
    </div>
  );
};

export default ContactsTotalAmounts;
