import { useEffect, useState } from 'react';

import { SliderWithInput } from '@molecules';
import { ReactComponent as PaddingBottomIcon } from '@assets/icons/padding-bottom.svg';
import { ReactComponent as PaddingLeftIcon } from '@assets/icons/padding-left.svg';
import { ReactComponent as PaddingRightIcon } from '@assets/icons/padding-right.svg';
import { ReactComponent as PaddingTopIcon } from '@assets/icons/padding-top.svg';
import {
  EditorBlockSettingsComponent,
  PaddingSide,
  SvgFunctionComponent,
} from '@devTypes';
import {
  getPaddingBySide,
  setIsPaddingConstrain,
  setPaddingBySide,
  setPaddings,
} from '@utils/editor/components/general/controllers';

import Header from './Header';

import './styles.css';

const defaultValues = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

type PaddingOption = {
  id: PaddingSide;
  title: string;
  icon: SvgFunctionComponent;
};

const paddingOptions = [
  {
    id: 'top',
    title: 'Top',
    icon: PaddingTopIcon,
  },
  {
    id: 'bottom',
    title: 'Bottom',
    icon: PaddingBottomIcon,
  },
  {
    id: 'left',
    title: 'Left',
    icon: PaddingLeftIcon,
  },
  {
    id: 'right',
    title: 'Right',
    icon: PaddingRightIcon,
  },
] as Array<PaddingOption>;

interface Props extends EditorBlockSettingsComponent {
  min: number;
  max: number;
}

const EditorBlockPaddingSettings = ({ selectedComponent, min, max }: Props) => {
  const [padding, setPadding] = useState(defaultValues);
  const [isConstrainChecked, setIsConstrainChecked] = useState(false);
  const { paddingtop, paddingbottom, paddingleft, paddingright } =
    selectedComponent.getAttributes();

  useEffect(() => {
    const componentPadding = {
      top: getPaddingBySide(selectedComponent, 'top'),
      bottom: getPaddingBySide(selectedComponent, 'bottom'),
      left: getPaddingBySide(selectedComponent, 'left'),
      right: getPaddingBySide(selectedComponent, 'right'),
    };

    setPadding(componentPadding);
  }, [paddingtop, paddingbottom, paddingleft, paddingright]);

  const handleConstrainCheckChange = () => {
    const isChecked = !isConstrainChecked;

    setIsConstrainChecked(isChecked);
    setIsPaddingConstrain(selectedComponent, isChecked);
  };

  const handlePaddingChange = (side: PaddingSide) => (paddingValue: number) => {
    if (isConstrainChecked) {
      setPadding({
        top: paddingValue,
        bottom: paddingValue,
        left: paddingValue,
        right: paddingValue,
      });

      setPaddings(selectedComponent, paddingValue);
    }

    setPadding((prevState) => ({ ...prevState, [side]: paddingValue }));

    setPaddingBySide(selectedComponent, side, paddingValue);
  };

  return (
    <div className="EditorTextBlockPaddingSettings">
      <Header
        isChecked={isConstrainChecked}
        onChange={handleConstrainCheckChange}
      />
      <div className="PaddingSliders">
        {paddingOptions.map(({ id, icon, title }) => (
          <SliderWithInput
            startIcon={icon}
            title={title}
            key={id}
            onChange={handlePaddingChange(id)}
            value={padding[id]}
            min={min}
            max={max}
          />
        ))}
      </div>
    </div>
  );
};

export default EditorBlockPaddingSettings;
