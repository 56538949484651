import type { RootState } from '../../store';

const campaigns = (state: RootState) => state.campaigns.campaigns;

const campaignObject = (state: RootState) => state.campaigns.campaignObject;

const campaignReport = (state: RootState) => state.campaigns.campaignReport;

const isCampaignReportLoading = (state: RootState) =>
  state.campaigns.campaignReport.isLoading;

export default {
  isCampaignReportLoading,
  campaignReport,
  campaigns,
  campaignObject,
};
