import type { RootState } from '../../store';

const listOfDomains = (state: RootState) => state.domains.listOfDomains;

const availableSenders = (state: RootState) => state.domains.availableSenders;

const validationResultsSelector = (state: RootState) =>
  state.domains.validationResults;

export default {
  listOfDomains,
  availableSenders,
  validationResultsSelector,
};
