import { useState } from 'react';

import { GalleryItemProps } from '@devTypes';
import { swapArrayElements } from '@utils/helpers';

import CarouselPreviewItem from './CarouselPreviewItem';

interface Props {
  images: GalleryItemProps[];
  onChange: (images: GalleryItemProps[]) => void;
}

const CarouselPreview = ({ images, onChange }: Props) => {
  const [idOfHoveredItem, setIdOfHoveredItem] = useState(-1);

  return (
    <div className="CarouselPreview">
      {images.map(({ id, url }, index) => {
        const handleLeftArrowClick = () => {
          onChange(swapArrayElements(images, index, index - 1));
        };

        const handleRightArrowClick = () => {
          onChange(swapArrayElements(images, index, index + 1));
        };

        const handleDelete = () => {
          onChange(images.filter((img) => img.id !== id));
        };

        const hasLeftArrow = index > 0;
        const hasRightArrow = index < images.length - 1;

        return (
          <CarouselPreviewItem
            key={id}
            url={url}
            hasLeftArrow={hasLeftArrow}
            onLeftArrowClick={handleLeftArrowClick}
            hasRightArrow={hasRightArrow}
            onRightArrowClick={handleRightArrowClick}
            onMouseEnter={() => setIdOfHoveredItem(id)}
            onMouseLeave={() => setIdOfHoveredItem(-1)}
            onDelete={handleDelete}
            hovered={id === idOfHoveredItem}
          />
        );
      })}
    </div>
  );
};

export default CarouselPreview;
