export const ROUTES = {
  root: '/',
  unsubscribe: '/unsubscribe',
  signIn: '/sign-in',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  forgotPasswordCheckEmail: '/check-email',
  createNewPassword: '/create-new-password',
  passwordUpdated: '/password-updated',
  dashboard: {
    root: '/dashboard',
    campaigns: '/dashboard/campaigns/',
    templates: '/dashboard/templates',
    allcampaigns: '/dashboard/all-campaigns',
    createCampaign: '/dashboard/create-new-campaign',
    selectTemplate: '/dashboard/create-new-campaign/select-template',
    home: '/dashboard/home',
    allcontacts: '/dashboard/all-contacts',
    integrations: '/dashboard/integrations',
    shopifyAuth: '/dashboard/integrations/shopify-setup',
    campaignReport: '/dashboard/campaigns/:id/report',
    profile: '/profile',
  },
  template: {
    editor: '/editor',
    preview: '/preview',
  },
};
