import { MouseEventHandler } from 'react';

import { SelectOptionType } from '@devTypes';
import SelectMenuItem from '@molecules/Select/SelectMenuItem';

interface Props<T> {
  options: T[];
  onClick: (item: T) => MouseEventHandler;
  selectedValue: T | null;
  selectedCheckMark: boolean;
}

const SelectMenu = <T extends SelectOptionType>({
  options,
  onClick,
  selectedValue,
  selectedCheckMark,
}: Props<T>) => (
  <div className="Select">
    {options.map((item) => {
      const hasEachOptionHandler = item instanceof Object && item.onClick;
      const handleClick = hasEachOptionHandler ? item.onClick : onClick(item);

      return (
        <SelectMenuItem
          key={Math.random()}
          item={item}
          onClick={handleClick!}
          selectedValue={selectedValue}
          selectedCheckMark={selectedCheckMark}
        />
      );
    })}
  </div>
);

export default SelectMenu;
