import { Divider } from '@atoms';
import {
  MAX_TEXT_PADDING_IN_PX,
  MIN_TEXT_PADDING_IN_PX,
} from '@constants/editor';
import { EditorBlockSettingsComponent } from '@devTypes';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorTextBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';

const EditorTextBlockDesignSettings = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => (
  <div className="EditorTextBlockSettings">
    <EditorBlockBackgroundSettings
      selectedComponent={selectedComponent}
      titleVariant="regular"
    />
    <Divider />
    <EditorTextBlockPaddingSettings
      selectedComponent={selectedComponent}
      max={MAX_TEXT_PADDING_IN_PX}
      min={MIN_TEXT_PADDING_IN_PX}
    />
  </div>
);

export default EditorTextBlockDesignSettings;
