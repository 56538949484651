import { persistStore } from 'redux-persist';

import { configureStore } from '@reduxjs/toolkit';

import { persistedReducer } from './persistReducer';

const preloadedState = {};

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export const resetStore = () => persistor.purge();
