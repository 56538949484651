import type { RootState } from '../../store';

const templateObject = (state: RootState) =>
  state.templates.createTemplate.templateObject;

const isUpdateTemplateLoading = (state: RootState) =>
  state.templates.updateTemplate.isLoading;

const updatedTemplate = (state: RootState) => state.templates.updateTemplate;

const listOfTemplates = (state: RootState) => state.templates.listOfTemplates;

const template = (state: RootState) => state.templates.template;

const isTemplateByIdLoading = (state: RootState) =>
  state.templates.template.isLoading;

const presetTemplates = (state: RootState) => state.templates.presetTemplates;

export default {
  isTemplateByIdLoading,
  templateObject,
  isUpdateTemplateLoading,
  updatedTemplate,
  listOfTemplates,
  template,
  presetTemplates,
};
