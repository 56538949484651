import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Card, Loader, Typography } from '@atoms';
import { Domain } from '@devTypes';
import Tabs, { TabItem } from '@molecules/Tabs/Tabs';
import CreateDomainForm from '@organisms/CreateDomainForm/CreateDomainForm';
import MyDomain from '@organisms/MyDomain/MyDomain';
import { domainAsyncThunks, domainSelectors } from '@store/features/domains';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import './styles.css';

const tabs: TabItem[] = [
  {
    index: 0,
    id: 'MyDomains',
    label: 'My Domains',
  },
  {
    index: 1,
    id: 'Setup',
    label: 'Setup',
  },
];

const SenderSettingsTemplate = () => {
  const dispatch = useAppDispatch();
  const [selectedTabFirstDomain, setSelectedTabFirst] = useState<TabItem>(
    tabs[0]
  );
  const [selectedTabSecondDomain, setSelectedTabSecond] = useState<TabItem>(
    tabs[0]
  );

  const { data: listOfDomains, isLoading: isLoadingListOfDomains } =
    useAppSelector(domainSelectors.listOfDomains);

  if (isLoadingListOfDomains) {
    return <Loader />;
  }

  const [listOfDomainsState, setListOfDomains] = useState(listOfDomains);

  const validationResults = useAppSelector(
    domainSelectors.validationResultsSelector
  );

  useEffect(() => {
    if (listOfDomains.length === 0) {
      setListOfDomains([
        {
          id: 0,
          domain: '',
          status: '',
          smtp: '',
          lastUsedAt: '',
          records: [],
          senders: [],
        },
      ]);
    } else {
      setListOfDomains(listOfDomains);
    }
  }, [listOfDomains]);

  const renderSenderSettingsTab = (
    senderSettingsTab: string,
    domain?: Domain,
    index?: number
  ) => {
    let validationResultForDomain;
    if (validationResults) {
      validationResultForDomain = validationResults.find(
        (results) => results.domainId === domain?.id
      )?.validationResults;
    }

    const onBackToMyDomains = () => {
      if (index === 1) {
        setSelectedTabSecond(tabs[0]);
      } else {
        setSelectedTabFirst(tabs[0]);
      }
      dispatch(domainAsyncThunks.getListOfDomainsThunk(null));
    };

    switch (senderSettingsTab) {
      case tabs[0].id:
        return listOfDomainsState.length && domain && domain.domain ? (
          <MyDomain
            domain={domain}
            onViewDnsClick={
              index === 1
                ? () => setSelectedTabSecond(tabs[1])
                : () => setSelectedTabFirst(tabs[1])
            }
          />
        ) : (
          <div
            style={{
              paddingTop: 48,
              paddingBottom: 48,
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Typography variant="subtitle4" style={{ color: '#667085' }}>
              You currently haven’t setup your sender domain yet. Click Add
              sender to get started
            </Typography>
          </div>
        );
      case tabs[1].id:
        return (
          <CreateDomainForm
            domain={domain}
            validationResults={validationResultForDomain}
            onContinue={
              index === 1
                ? () => setSelectedTabSecond(tabs[1])
                : () => setSelectedTabFirst(tabs[1])
            }
            activeTab={
              index === 1 ? selectedTabSecondDomain : selectedTabFirstDomain
            }
            onBackToMyDomains={onBackToMyDomains}
          />
        );
      default:
        return <div />;
    }
  };

  const renderListOfDomains = () => {
    if (listOfDomainsState.length === 0) {
      return (
        <Card className="SendingTemplate-Card">
          <Tabs
            items={tabs}
            selectedTab={selectedTabFirstDomain}
            setSelectedTab={setSelectedTabFirst}
            className="SettingsTabs"
          />
          {renderSenderSettingsTab(selectedTabFirstDomain.id)}
        </Card>
      );
    }
    return listOfDomainsState.map((domain, index) => (
      <Card className="SendingTemplate-Card" key={domain.id}>
        <Tabs
          items={tabs}
          selectedTab={
            index === 1 ? selectedTabSecondDomain : selectedTabFirstDomain
          }
          setSelectedTab={
            index === 1 ? setSelectedTabSecond : setSelectedTabFirst
          }
          className="SettingsTabs"
        />
        {renderSenderSettingsTab(
          index === 1 ? selectedTabSecondDomain.id : selectedTabFirstDomain.id,
          domain,
          index
        )}
      </Card>
    ));
  };

  const onAddNewSender = () => {
    if (listOfDomains.length === 0) {
      setSelectedTabFirst(tabs[1]);
      return;
    }
    if (listOfDomains.length < 2) {
      setSelectedTabFirst(tabs[1]);
      setListOfDomains([
        {
          id: 0,
          domain: '',
          status: '',
          smtp: '',
          lastUsedAt: '',
          records: [],
          senders: [],
        },
        ...listOfDomains,
      ]);
    } else {
      toast.error('You are not allowed to register more than 2 domains.');
    }
  };

  return (
    <div className="SenderSettingsTemplate">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle4">Domain</Typography>
        <Button
          title="Add new sender"
          onClick={onAddNewSender}
          variant="primary"
        />
      </div>

      {renderListOfDomains()}
    </div>
  );
};

export default SenderSettingsTemplate;
