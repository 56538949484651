import { useEffect, useState } from 'react';
import { toColor, useColor } from 'react-color-palette';
import grapesjs from 'grapesjs';

import { Divider } from '@atoms';
import { ColorPickerInput, SliderWithInput } from '@molecules';
import {
  MAX_BUTTON_PADDING_IN_PX,
  MAX_DIVIDER_HEIGHT_IN_PX,
  MIN_BUTTON_PADDING_IN_PX,
  MIN_DIVIDER_HEIGHT_IN_PX,
} from '@constants/editor';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';

import './styles.css';

interface Props {
  dividerContainerComponent: grapesjs.Component;
  dividerComponent: grapesjs.Component;
}

const DEFAULT_BORDER_COLOR = '#00000';

const EditorDividerBlockSettingsDesign = ({
  dividerComponent,
  dividerContainerComponent,
}: Props) => {
  const [borderWidth, setBorderWidth] = useState<number>(1);
  const [borderColor, setBorderColor] = useColor('hex', DEFAULT_BORDER_COLOR);

  useEffect(() => {
    const dividerState = dividerComponent.getAttributes();

    setBorderWidth(dividerState.borderWidth);
    setBorderColor(
      toColor('hex', dividerState.borderColor || DEFAULT_BORDER_COLOR)
    );
  }, [dividerContainerComponent]);

  const handleBorderWidthChange = (newSpace: number) => {
    dividerComponent.addAttributes({ borderWidth: newSpace });
    dividerComponent.addStyle({ 'border-bottom-width': `${newSpace}px` });

    setBorderWidth(newSpace);
  };

  const handleDividerColorChange = (colorValue: string) => {
    dividerComponent.addStyle({
      'border-bottom-color': colorValue,
    });
    dividerComponent.addAttributes({ borderColor: colorValue });
  };

  return (
    <div>
      <div className="EditorDividerBlockSettingsDesignSettings">
        <ColorPickerInput
          color={borderColor}
          setColor={setBorderColor}
          onChange={handleDividerColorChange}
          title="Divider color"
        />
        <SliderWithInput
          title="Stroke thickness"
          value={borderWidth}
          onChange={handleBorderWidthChange}
          min={MIN_DIVIDER_HEIGHT_IN_PX}
          max={MAX_DIVIDER_HEIGHT_IN_PX}
        />
        <EditorBlockBackgroundSettings
          selectedComponent={dividerContainerComponent}
        />
      </div>
      <Divider />
      <EditorBlockPaddingSettings
        selectedComponent={dividerContainerComponent}
        min={MIN_BUTTON_PADDING_IN_PX}
        max={MAX_BUTTON_PADDING_IN_PX}
      />
    </div>
  );
};

export default EditorDividerBlockSettingsDesign;
