import { Card } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';
import PlatformInfo from '@organisms/IntegrationCard/PlatformInfo';

import Footer from './Footer';

import './styles.css';

interface Props {
  title: string;
  description: string;
  icon: SvgFunctionComponent;
  isActivated: boolean;
  onActivateClick?: () => void;
  onDeactivateClick?: () => void;
}

const IntegrationCard = ({
  title,
  description,
  icon,
  isActivated,
  onActivateClick,
  onDeactivateClick,
}: Props) => (
  <Card className="IntegrationCard">
    <PlatformInfo title={title} description={description} icon={icon} />
    <Footer
      isActivated={isActivated}
      onActivateClick={onActivateClick}
      onDeactivateClick={onDeactivateClick}
    />
  </Card>
);

export default IntegrationCard;
