import type { RootState } from '../../store';

const recipients = (state: RootState) => state.contacts.recipients;

const spamContacts = (state: RootState) => state.contacts.spamContacts;

const invalidEmails = (state: RootState) => state.contacts.invalidEmails;

const bouncedContacts = (state: RootState) => state.contacts.bouncedEmails;

export default {
  recipients,
  spamContacts,
  invalidEmails,
  bouncedContacts,
};
