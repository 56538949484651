import clsx from 'clsx';

import { Card, Typography } from '@atoms';

interface Props {
  title: string;
  value: string | number;
  onClick?: (title: string) => void;
  isSelected?: boolean;
}

const TotalAmountCard = ({ title, value, onClick, isSelected }: Props) => (
  <Card
    className={clsx('TotalAmountCard', isSelected && 'selected')}
    onClick={() => onClick && onClick(title)}
  >
    <Typography variant="subtitle3">{value}</Typography>
    <Typography
      variant="subtitle4"
      className="TotalAmountCard-Label"
      // style={clickable ? { textDecoration: 'underline', color: '#0074f1' } : {}}
    >
      {title}
    </Typography>
  </Card>
);
export default TotalAmountCard;
