import { Outlet } from 'react-router-dom';

import { DashboardTemplate } from '@templates';

const DashboardView = () => (
  <DashboardTemplate>
    <Outlet />
  </DashboardTemplate>
);

export default DashboardView;
