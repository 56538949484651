import { ENDPOINTS } from '@constants/api';

import { wrappedAxiosRequest } from '../../api';

export interface UpdateUserData {
  firstName: string;
  lastName: string;
}

const usersApi = {
  async getUser() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.users.me,
    });
  },

  async updateUser(data: UpdateUserData) {
    return wrappedAxiosRequest({
      method: 'patch',
      url: ENDPOINTS.users.me,
      data,
    });
  },

  async getShopifyConnectionStatus() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.shopify.connectionStatus,
    });
  },
};

export default usersApi;
