import { MouseEventHandler } from 'react';
import clsx from 'clsx';

import { ButtonVariant } from '@devTypes';

import './styles.css';

type ButtonSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface Props {
  className?: string;
  title: string;
  variant: ButtonVariant;
  size?: ButtonSize;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  hoverDisabled?: boolean;
  disabledVariant?: 'gray';
}

const Button = ({
  className = '',
  variant,
  title,
  size = 'sm',
  startIcon = null,
  endIcon = null,
  disabled = false,
  onClick,
  hoverDisabled = false,
  disabledVariant,
}: Props) => (
  <button
    type="button"
    className={clsx(
      className,
      'Button',
      `Button-${variant}`,
      `Button-${size}`,
      hoverDisabled && 'Button-hover-disabled',
      variant === 'primary' &&
        'bg-primary-main text-white [&>svg]:stroke-white',
      variant === 'error' && 'bg-error-main text-white [&>svg]:stroke-white',
      disabledVariant === 'gray' && 'disabled:bg-gray-400'
    )}
    onClick={onClick}
    disabled={disabled}
  >
    <div className="Button-content">
      {startIcon}
      {title}
      {endIcon}
    </div>
  </button>
);

export default Button;
