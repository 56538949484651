import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DEFAULT_CAROUSEL_NAVIGATION_SIZE } from '@constants/editor';

interface Props {
  images: any;
  navigationColor: any;
  imageFit: any;
  placeholders: any;
}

const EditorCarouselLayout = ({
  images,
  navigationColor,
  imageFit,
  placeholders,
}: Props) => {
  const styles = `
  .swiper-pagination {
    --swiper-theme-color: ${navigationColor};
  }

  .swiper-button-prev {
    --swiper-theme-color: ${navigationColor};
    --swiper-navigation-size: ${DEFAULT_CAROUSEL_NAVIGATION_SIZE}px;
  }


  .swiper-button-next {
    --swiper-theme-color: ${navigationColor};
    --swiper-navigation-size: ${DEFAULT_CAROUSEL_NAVIGATION_SIZE}px;
  }

  .swiper {
    width: 647px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items:center;
    width: 563px;
  } 

  .image {
    width: 563px;
    height: 360px;
    max-width: 100%;
    max-height: 100%;
    object-fit: ${imageFit};
  }
  `;

  return (
    <div className="swiper-wrapper">
      <style>{styles}</style>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        navigation
        pagination
      >
        {!!images.length &&
          images.map((image: any, index: any) => (
            <SwiperSlide key={image.id}>
              <img
                className="image"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src={image.url}
                alt={image}
              />
            </SwiperSlide>
          ))}
        {!images.length &&
          placeholders.map((image: any, index: any) => (
            <SwiperSlide key={image.id}>
              <img
                className="image"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                src={image.url}
                alt={image}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default EditorCarouselLayout;
