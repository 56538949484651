import { Icon, Loader, Typography } from '@atoms';
import { ReactComponent as ImageIcon } from '@assets/icons/image.svg';

import './styles.css';

interface Props {
  children: React.ReactNode;
  image: File | null;
  isLoading?: boolean;
}

const ImagePreview = ({ image, children, isLoading }: Props) => (
  <div className="ImagePreview">
    <div className="ImagePreviewForm">{children}</div>
    <div className="ImagePreviewImageContainer">
      {isLoading && <Loader />}
      {!isLoading && image && <img src={URL.createObjectURL(image)} alt="" />}
      {!isLoading && !image && (
        <div className="ImagePreviewImageContainer-NoImage">
          <Icon SVG={ImageIcon} width={18} height={18} />
          <Typography variant="subtitle4">No image preview</Typography>
        </div>
      )}
    </div>
  </div>
);

export default ImagePreview;
