import clsx from 'clsx';

import {
  HeadingFontWeights,
  SubtitleFontWeights,
  TypographyVariant,
} from '@devTypes';

import './styles.css';

interface Props<T> {
  variant: T;
  weight?: T extends
    | 'subtitle1'
    | 'subtitle2'
    | 'subtitle3'
    | 'subtitle4'
    | 'subtitle5'
    | 'subtitle6'
    ? SubtitleFontWeights
    : HeadingFontWeights;
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLParagraphElement>;
  style?: any;
}

const Typography = <T extends TypographyVariant>({
  variant,
  weight = 'regular',
  children,
  className,
  onClick,
  style,
}: Props<T>) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <p
    className={clsx(
      `Typography-root Typography-${variant} Typography-${weight}`,
      className
    )}
    onClick={onClick}
    style={style}
  >
    {children}
  </p>
);

export default Typography;
