import { useEffect, useState } from 'react';
import { toColor, useColor } from 'react-color-palette';
import clsx from 'clsx';
import grapesjs from 'grapesjs';

import { Button, Divider } from '@atoms';
import {
  ColorPickerInput,
  EditorBlockSettingContainer,
  Selector,
  SelectWithTitle,
  SliderWithInput,
} from '@molecules';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import {
  DEFAULT_BUTTON_HOVER_BORDER_COLOR,
  DEFAULT_BUTTON_HOVER_FILL_COLOR,
  DEFAULT_BUTTON_HOVER_TEXT_COLOR,
  MAX_BUTTON_BORDER_RADIUS_IN_PX,
  MAX_BUTTON_BORDER_WIDTH_IN_PX,
  MAX_BUTTON_HEIGHT_IN_PX,
  MAX_BUTTON_PADDING_IN_PX,
  MIN_BUTTON_HEIGHT_IN_PX,
  MIN_BUTTON_PADDING_IN_PX,
  TEXT_FONT_FAMILY_OPTIONS,
} from '@constants/editor';
import { Font, Fonts, SelectorOptionType } from '@devTypes';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';
import { buttonComponentController } from '@utils/editor/components/button';
import { formatStyleValue } from '@utils/editor/components/text/controllers/general';

import EditorButtonBlockSettingsHoverEffect from './EditorButtonBlockSettingsHoverEffect';

import './styles.css';

const fontSelectOptions = Object.keys(TEXT_FONT_FAMILY_OPTIONS) as Fonts;

interface Option {
  id: string;
  text: string;
}

const alignmentOptions: Option[] = [
  { id: 'left', text: 'Left' },
  { id: 'center', text: 'Center' },
  { id: 'right', text: 'Right' },
];

const widthOptions: Option[] = [
  { id: 'fit-content', text: 'Fit to text' },
  { id: '100%', text: 'Full width' },
];

interface Props {
  buttonContainerComponent: grapesjs.Component;
  buttonComponent: grapesjs.Component;
  editor: grapesjs.Editor;
}

const EditorButtonBlockSettingsDesign = ({
  editor,
  buttonComponent,
  buttonContainerComponent,
}: Props) => {
  const [alignment, setAlignment] = useState<SelectorOptionType>(
    alignmentOptions[0]
  );
  const [width, setWidth] = useState<SelectorOptionType>(widthOptions[0]);
  const [borderRadius, setBorderRadius] = useState<number>(0);
  const [borderWidth, setBorderWidth] = useState<number>(0);
  const [borderColor, setBorderColor] = useColor('hex', '');
  const [textColor, setTextColor] = useColor('hex', '');
  const [fillColor, setFillColor] = useColor('hex', '');
  const [height, setHeight] = useState<number>(0);
  const [font, setFont] = useState<string>('');
  const [isHoverEffectSettingsOpen, setIsHoverEffectSettingsOpen] =
    useState<boolean>(false);

  const componentId = buttonComponent.getId();

  useEffect(() => {
    const buttonState = buttonComponent.getAttributes();

    const buttonWidth = widthOptions.find(
      (option) => option.id === buttonState.width
    );
    const buttonAlignment = alignmentOptions.find(
      (option) => option.id === buttonState.alignment
    );

    setFont(buttonState.font);
    setBorderWidth(buttonState.borderWidth);
    setBorderRadius(buttonState.borderRadius);
    setBorderColor(toColor('hex', buttonState.borderColor));
    setTextColor(toColor('hex', buttonState.textColor));
    setFillColor(toColor('hex', buttonState.fillColor));
    setWidth(buttonState.width);
    setHeight(buttonState.height);
    setFont(buttonState.font);
    setWidth(buttonWidth!);
    setAlignment(buttonAlignment!);

    const isHoverSettingsApplied =
      buttonState.hoverTextColor ||
      buttonState.hoverFillColor ||
      buttonState.hoverBorderColor;

    setIsHoverEffectSettingsOpen(isHoverSettingsApplied);
  }, [buttonContainerComponent]);

  const handleWidthChange = (option: SelectorOptionType) => {
    buttonComponentController.setWidthByOption(
      buttonComponent,
      option.id as string
    );

    setWidth(option);
  };

  const handleAlignmentChange = (option: SelectorOptionType) => {
    buttonComponentController.setAlignmentBySide(
      buttonComponent,
      option.id as string
    );

    setAlignment(option);
  };

  const handleBorderWidthChange = (newSpace: number) => {
    buttonComponent.addStyle({
      'border-width': `${newSpace}px`,
      'line-height': `${height - newSpace * 2}px`,
    });
    buttonComponent.addAttributes({ borderWidth: newSpace });

    setBorderWidth(newSpace);
  };
  const handleHeightChange = (newSpace: number) => {
    buttonComponent.addStyle({
      height: `${newSpace}px`,
      'line-height': `${newSpace - borderWidth * 2}px`,
    });
    buttonComponent.addAttributes({ height: newSpace });

    const { paddingtop, paddingbottom } =
      buttonContainerComponent.getAttributes();

    buttonContainerComponent.addStyle({
      height: `${newSpace + paddingtop + paddingbottom}px`,
    });
    setHeight(newSpace);
  };

  const handleBorderRadiusChange = (newSpace: number) => {
    buttonComponent.addStyle({ 'border-radius': `${newSpace}px` });
    buttonComponent.addAttributes({ borderRadius: newSpace });

    setBorderRadius(newSpace);
  };

  const handleFontChange = (optionValue: string) => {
    buttonComponent.addStyle({
      'font-family': TEXT_FONT_FAMILY_OPTIONS[optionValue as Font],
    });
    buttonComponent.addAttributes({
      font: TEXT_FONT_FAMILY_OPTIONS[optionValue as Font],
    });

    setFont(optionValue);
  };

  const handleTextColorChange = (colorValue: string) => {
    buttonComponent.addStyle({
      color: `${colorValue}`,
    });
    buttonComponent.addAttributes({ textColor: colorValue });
  };

  const handleFillColorChange = (colorValue: string) => {
    buttonComponent.addStyle({
      'background-color': colorValue,
    });
    buttonComponent.addAttributes({ fillColor: colorValue });
  };

  const handleBorderColorChange = (colorValue: string) => {
    buttonComponent.addStyle({
      'border-color': colorValue,
    });
    buttonComponent.addAttributes({ borderColor: colorValue });
  };

  const handleHoverSettingsOpen = () => {
    setIsHoverEffectSettingsOpen(true);
    buttonComponent.addAttributes({
      'hoverTextColor': DEFAULT_BUTTON_HOVER_TEXT_COLOR,
    });
    buttonComponent.addAttributes({
      'hoverFillColor': DEFAULT_BUTTON_HOVER_FILL_COLOR,
    });
    buttonComponent.addAttributes({
      'hoverBorderColor': DEFAULT_BUTTON_HOVER_BORDER_COLOR,
    });
    editor.Css.setRule(`#${componentId}:hover`, {
      color: DEFAULT_BUTTON_HOVER_TEXT_COLOR,
      'border-color': DEFAULT_BUTTON_HOVER_BORDER_COLOR,
      'background-color': DEFAULT_BUTTON_HOVER_FILL_COLOR,
    });
  };

  const handleHoverSettingsClose = () => {
    setIsHoverEffectSettingsOpen(false);
  };

  return (
    <div>
      <div className="EditorButtonBlockSettingsDesignButtonSettings">
        <EditorBlockBackgroundSettings
          selectedComponent={buttonContainerComponent}
          titleVariant="regular"
        />
        <SelectWithTitle
          title="Font"
          onChange={handleFontChange}
          options={fontSelectOptions}
          value={formatStyleValue(font, ',')}
          className="FontSelect"
        />
        <ColorPickerInput
          color={textColor}
          setColor={setTextColor}
          onChange={handleTextColorChange}
          title="Text color"
        />
        <ColorPickerInput
          color={fillColor}
          setColor={setFillColor}
          onChange={handleFillColorChange}
          title="Fill color"
        />
        <SliderWithInput
          title="Border width"
          value={borderWidth}
          onChange={handleBorderWidthChange}
          max={MAX_BUTTON_BORDER_WIDTH_IN_PX}
        />
        <SliderWithInput
          title="Border roundness"
          value={borderRadius}
          onChange={handleBorderRadiusChange}
          max={MAX_BUTTON_BORDER_RADIUS_IN_PX}
        />
        <ColorPickerInput
          color={borderColor}
          setColor={setBorderColor}
          onChange={handleBorderColorChange}
          title="Border color"
        />
        <SliderWithInput
          title="Height"
          value={height}
          onChange={handleHeightChange}
          min={MIN_BUTTON_HEIGHT_IN_PX}
          max={MAX_BUTTON_HEIGHT_IN_PX}
        />
        <EditorBlockSettingContainer title="Width">
          <Selector
            options={widthOptions}
            value={width}
            onChange={handleWidthChange}
          />
        </EditorBlockSettingContainer>
        <EditorBlockSettingContainer title="Alignment">
          <Selector
            options={alignmentOptions}
            value={alignment}
            onChange={handleAlignmentChange}
          />
        </EditorBlockSettingContainer>
        <div
          className={clsx(
            isHoverEffectSettingsOpen ? 'display-hidden' : 'display-block'
          )}
        >
          <Button
            variant="secondary"
            title="Add hover effect"
            startIcon={<PlusIcon />}
            onClick={handleHoverSettingsOpen}
          />
        </div>
        <div
          className={clsx(
            isHoverEffectSettingsOpen ? 'display-block' : 'display-hidden'
          )}
        >
          <EditorButtonBlockSettingsHoverEffect
            onClose={handleHoverSettingsClose}
            editor={editor}
            buttonComponent={buttonComponent}
          />
        </div>
      </div>
      <Divider />
      <EditorBlockPaddingSettings
        selectedComponent={buttonContainerComponent}
        min={MIN_BUTTON_PADDING_IN_PX}
        max={MAX_BUTTON_PADDING_IN_PX}
      />
    </div>
  );
};

export default EditorButtonBlockSettingsDesign;
