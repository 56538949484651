import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { MenuItemComponent } from '@devTypes';

import './styles.css';

const MenuItem = ({
  title,
  icon,
  isSelected,
  className,
  onClick = () => {},
}: MenuItemComponent) => (
  <div
    className={clsx(
      'MenuItem',
      'MenuItem-primary',
      isSelected && 'MenuItem-selected',
      className
    )}
    role="tab"
    tabIndex={0}
    onClick={onClick}
    onKeyUp={onClick}
  >
    <div className="MenuItem-Icon">
      <Icon SVG={icon} width={24} height={24} />
    </div>
    <Typography variant="subtitle6">{title}</Typography>
  </div>
);

export default MenuItem;
