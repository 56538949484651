import { useState } from 'react';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as ChevronRightIcon } from '@assets/icons/chevron-right.svg';

interface Props {
  title: string;
  titleContent?: string | JSX.Element;
  content?: string | JSX.Element;
  isOpen?: boolean;
  isDisabled?: boolean;
}

const Accordion = ({
  title,
  content,
  titleContent,
  isOpen,
  isDisabled,
}: Props) => {
  const [open, setOpen] = useState(isOpen || false);
  return (
    <div className="flex flex-col gap-4 bg-white p-4 rounded-lg">
      <div
        className="flex justify-between w-full"
        style={{ alignItems: 'center' }}
      >
        {titleContent ? (
          <div className="flex gap-1 w-full">{titleContent}</div>
        ) : (
          <Typography
            className={clsx(open && 'text-primary-500')}
            variant="subtitle3"
            weight="semiBold"
          >
            {title}
          </Typography>
        )}
        {content ? (
          <Icon
            className={clsx(
              'stroke-gray-900',
              open && 'transform rotate-90',
              'cursor-pointer',
              'transition-all'
            )}
            SVG={ChevronRightIcon}
            width={24}
            height={24}
            onClick={() => !isDisabled && setOpen(!open)}
          />
        ) : (
          <div style={{ height: 24, width: 24 }} />
        )}
      </div>
      {open && content && <div className="flex gap-1">{content}</div>}
    </div>
  );
};

export default Accordion;
