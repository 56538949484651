import * as yup from 'yup';
import { number, ref, string } from 'yup';

import { removeSpacesInString } from '@utils/helpers';

const EMAIL_REG_EXP = /^|\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const NAME_REG_EXP = /^[a-z ,.'-]+$/i;
const LINK_URL_REG_EXP = /^(ftp|http|https):\/\/[^ "]+$/;
const URL_REG_EXP = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})([\w.\-/?=&]*)$/i;
const NUMBERS_REG_EXP = /^\d+$/;
const MIN_PHONE_NUMBER_LENGTH = 8;
const MAX_PHONE_NUMBER_LENGTH = 12;

const ACCESS_TOKEN = /([^&]*)/;
const PASSWORD_REGEX =
  /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const AT_LEAST_ONE_LOWERCASE_LETTER = /.*[a-z].*/;
const AT_LEAST_ONE_UPPERCASE_LETTER = /.*[A-Z].*/;
const AT_LEAST_ONE_NUMBER = /.*\d.*/;
const AT_LEAST_ONE_SYMBOL = /.*[#?!@$%^&*-].*/;
const NO_WHITESPACES = /^\S*$/;
const PASSWORD_MIN_LENGTH = 8;
const COMMA_SEPARATED_EMAILS_REGEX =
  /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(, )?)+$/;
export const COMMA_OR_COMMA_WITH_SPACE_REGEX = /[ ,]+/;

export const SHOPIFY_STORE_REGEX = /^[a-zA-Z0-9_-]+$/;

export const EMAIL_IN_USE_ERROR_MESSAGE =
  'This is not a valid email\nThat email is already in our system';
export const NO_VALUE_ERROR_MESSAGE = 'Please enter a value';

const firstNameShape = yup
  .string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .matches(NAME_REG_EXP, {
    excludeEmptyString: true,
    message: 'First name is invalid',
  });

const lastNameShape = yup
  .string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .matches(NAME_REG_EXP, {
    excludeEmptyString: true,
    message: 'Last name is invalid',
  });

export const emailShape = yup
  .string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .email('Email is invalid')
  .matches(EMAIL_REG_EXP, {
    excludeEmptyString: true,
    message: 'Email is invalid',
  });

const phoneNumberShape = yup
  .string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .test((value, context) => {
    if (!value) return context.createError({ message: NO_VALUE_ERROR_MESSAGE });

    const numbers = removeSpacesInString(value);

    if (!numbers.match(NUMBERS_REG_EXP)) {
      return context.createError({ message: 'Phone number is invalid' });
    }

    if (numbers.length < MIN_PHONE_NUMBER_LENGTH) {
      return context.createError({ message: 'Phone number is too short' });
    }

    if (numbers.length > MAX_PHONE_NUMBER_LENGTH) {
      return context.createError({ message: 'Phone number is too long' });
    }

    return true;
  });

const passwordShape = yup
  .string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .test((value, context) => {
    if (!value) return context.createError({ message: 'Invalid password' });

    const messages = [];

    if (!value.match(AT_LEAST_ONE_LOWERCASE_LETTER)) {
      messages.push('One lowercase character');
    }

    if (!value.match(AT_LEAST_ONE_UPPERCASE_LETTER)) {
      messages.push('One uppercase character');
    }

    if (!value.match(AT_LEAST_ONE_NUMBER)) {
      messages.push('One number');
    }

    if (!value.match(AT_LEAST_ONE_SYMBOL)) {
      messages.push('One special character #?!@$%^&*-');
    }

    if (!value.match(NO_WHITESPACES)) {
      messages.push('No spaces');
    }

    if (value.length < PASSWORD_MIN_LENGTH) {
      messages.push('8 characters minimum');
    }

    if (!messages.length) return true;

    return context.createError({ message: messages.join(',') });
  });

const confirmPasswordShape = yup
  .string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .oneOf([ref('password')], 'Passwords do not match');

const shapes = {
  password: string()
    .trim()
    .required(NO_VALUE_ERROR_MESSAGE)
    .min(PASSWORD_MIN_LENGTH, 'Password is too weak')
    .matches(PASSWORD_REGEX, {
      excludeEmptyString: true,
      message:
        'The password must contain at least: 8 characters, 1 Uppercase letter, 1 Lowercase letter, 1 digit, 1 special character #?!@$%^&*- and NO spaces',
    }),
  confirmPassword: string()
    .trim()
    .required(NO_VALUE_ERROR_MESSAGE)
    .min(PASSWORD_MIN_LENGTH, 'Password is too weak')
    .matches(PASSWORD_REGEX, {
      excludeEmptyString: true,
      message:
        'The password must contain at least: 8 characters, 1 Uppercase letter, 1 Lowercase letter, 1 digit, 1 special character #?!@$%^&*- and NO spaces',
    })
    .oneOf([ref('password')], 'Passwords do not match'),
  changePassword: string()
    .trim()
    .required(NO_VALUE_ERROR_MESSAGE)
    .min(PASSWORD_MIN_LENGTH, 'Password is too weak')
    .matches(PASSWORD_REGEX, 'Password is too weak'),
  linkURL: string()
    .trim()
    .required(NO_VALUE_ERROR_MESSAGE)
    .matches(LINK_URL_REG_EXP, {
      excludeEmptyString: true,
      message: 'URL is invalid',
    }),

  url: string().trim().matches(URL_REG_EXP, {
    excludeEmptyString: true,
    message: 'URL is invalid',
  }),

  shopifyName: string()
    .required('Store name is required to complete connection')
    .matches(SHOPIFY_STORE_REGEX, {
      message: 'Field should include only store name',
    }),

  shopifyAccessToken: string()
    .trim()
    .matches(ACCESS_TOKEN, {
      excludeEmptyString: true,
      message: 'Access token is invalid',
    })
    .required('Admin access API token is required'),

  apiKey: string().required(),
  commaSeparatedEmails: string()
    .trim()
    .matches(COMMA_SEPARATED_EMAILS_REGEX, {
      message: 'Invalid list of email',
    })
    .required('Invalid list of email'),
};

const signInPasswordShape = string()
  .trim()
  .required(NO_VALUE_ERROR_MESSAGE)
  .min(PASSWORD_MIN_LENGTH, 'Password is too weak')
  .matches(PASSWORD_REGEX, 'Password is not correct');

export const schemas = {
  signUpFormSchema: yup
    .object({
      firstName: firstNameShape,
      lastName: lastNameShape,
      email: emailShape,
      password: passwordShape,
    })
    .required(),
  signInFormSchema: yup
    .object({
      email: emailShape,
      password: signInPasswordShape,
    })
    .required(),
  forgotPasswordSchema: yup.object({
    email: emailShape,
  }),
  confirmNewPasswordSchema: yup.object({
    password: shapes.password,
    confirmPassword: shapes.confirmPassword,
  }),
  linkInputSchema: yup.object({
    linkURL: shapes.linkURL,
  }),
  urlInputSchema: yup.object({
    link: shapes.url,
  }),
  shopifyAuthForm: yup.object({
    storeName: shapes.shopifyName,
    shopifyAccessToken: shapes.shopifyAccessToken,
    customAppApiKey: shapes.apiKey,
  }),
  oraganiationFormSchema: yup.object({
    organizationName: string().required(),
    organizationLogo: string().required(),
  }),
  sendTestEmailSchema: yup.object({
    emails: shapes.commaSeparatedEmails,
  }),
};

export const addContactSchema = yup.object().shape({
  firstName: firstNameShape,
  lastName: lastNameShape,
  email: emailShape,
  phoneNumber: phoneNumberShape,
  streetAddress: string().notRequired(),
  streetLine2: string().notRequired(),
  city: string().notRequired(),
  zipCode: string().notRequired(),
  country: string().notRequired(),
});

export const createCampaignSchema = yup.object().shape({
  subject: string().trim().nullable().required('Please enter a subject line'),
  previewText: string().notRequired().nullable(),
  senderId: number().nullable(),
  senderName: string()
    .trim()
    .nullable()
    .required('Please enter a Sender name')
    .matches(NAME_REG_EXP, {
      excludeEmptyString: true,
      message: 'Sender name is invalid',
    }),
  recipientType: string(),
  templateId: yup.number().required(),
});

export const createNewPasswordSchema = yup.object().shape({
  password: passwordShape,
  confirmPassword: confirmPasswordShape,
});

export const profileSchema = yup.object({
  firstName: firstNameShape,
  lastName: lastNameShape,
});

export const changePasswordSchema = yup.object({
  currentPassword: shapes.changePassword,
  newPassword: shapes.changePassword,
  confirmPassword: shapes.changePassword.oneOf(
    [ref('newPassword')],
    'Passwords do not match'
  ),
});

export const customDomainCreateSchema = yup.object({
  customDomainEmail: emailShape,
});
