/* eslint-disable no-param-reassign */
import {
  Domain,
  LoadingState,
  Sender,
  ValidationResultsForDomains,
} from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import {
  getAvailableSendersThunk,
  getListOfDomainsThunk,
  registerCustomDomainThunk,
  validateDomainThunk,
} from './asyncThunks';

interface InitialState {
  isLoading: boolean;
  availableSenders: LoadingState & { data: Sender[] };
  listOfDomains: LoadingState & { data: Domain[] };
  validationResults: ValidationResultsForDomains[];
}

const initialState: InitialState = {
  isLoading: false,
  availableSenders: {
    data: [],
    isLoading: false,
  },
  listOfDomains: {
    data: [],
    isLoading: false,
  },
  validationResults: [],
};

const domainSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    setValidationResults(state, { payload }) {
      state.validationResults = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateDomainThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(validateDomainThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(validateDomainThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(registerCustomDomainThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(registerCustomDomainThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(registerCustomDomainThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(getListOfDomainsThunk.fulfilled, (state, { payload }) => ({
      ...state,
      listOfDomains: {
        data: payload.domains ?? [],
        isLoading: false,
      },
      isLoading: false,
    }));
    builder.addCase(getListOfDomainsThunk.rejected, (state) => ({
      ...state,
      listOfDomains: {
        data: [],
        isLoading: false,
      },
    }));
    builder.addCase(getListOfDomainsThunk.pending, (state) => ({
      ...state,
      products: {
        ...state.listOfDomains,
        isLoading: true,
      },
    }));
    builder.addCase(
      getAvailableSendersThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        availableSenders: {
          data: payload.addresses ?? [],
          isLoading: false,
        },
        isLoading: false,
      })
    );
    builder.addCase(getAvailableSendersThunk.rejected, (state) => ({
      ...state,
      avaliableSenders: {
        data: [],
        isLoading: false,
      },
    }));
    builder.addCase(getAvailableSendersThunk.pending, (state) => ({
      ...state,
      avaliableSenders: {
        ...state.availableSenders,
        isLoading: true,
      },
    }));
  },
});

export default domainSlice.reducer;

export const domainActions = domainSlice.actions;
