import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: (event: MouseEvent) => void
) => {
  const handleClick: (ev: MouseEvent) => any = (e) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback(e);
    }
  };

  useEffect(() => {
    const iframes = document.querySelectorAll('iframe');

    iframes.forEach((i) =>
      i.contentDocument?.body?.addEventListener('click', handleClick)
    );
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
      iframes.forEach((i) =>
        i.contentDocument?.body?.removeEventListener('click', handleClick)
      );
    };
  });
};
