import grapesjs from 'grapesjs';

import { generateGrapesjsButtonComponent } from '@utils/editor/components/button';
import { createEditorBlock } from '@utils/helpers';

const icon = `
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2292_6836)">
      <rect x="0.75" y="7.41666" width="30.5" height="15.8333" rx="1.25" stroke="#344054" stroke-width="1.5"/>
      <line x1="8" y1="15.25" x2="24" y2="15.25" stroke="#344054" stroke-width="1.5"/>
    </g>
    <defs>
      <clipPath id="clip0_2292_6836">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
`;
const label = createEditorBlock('Button', icon, 'button');
const content = generateGrapesjsButtonComponent({
  paddingTop: 32,
  paddingBottom: 32,
  paddingLeft: 30,
  paddingRight: 30,
  width: 'fit-content',
  buttonCustomWrapperClass: 'gjs-button-wrapper-btn',
  buttonCustomClass: 'gjs-button-btn',
});

export const ButtonBlock: grapesjs.BlockOptions = {
  label,
  content,
  select: true,
};
