import grapesjs from 'grapesjs';

export const setFullWidth = (component: grapesjs.Component) => {
  component.addStyle({ width: '100%' });
  component.addAttributes({ width: '100%' });
};

export const setWidthFitContent = (component: grapesjs.Component) => {
  component.addStyle({ width: 'fit-content' });
  component.addStyle({ padding: '0px 32px' });
  component.addAttributes({ width: 'fit-content' });
};

export const setAlign = (
  component: grapesjs.Component,
  position: 'left' | 'right' | 'center'
) => {
  component.parent()!.addStyle({ 'text-align': position });
  component.addAttributes({ alignment: position });
};

export const setAlignmentBySide = (
  component: grapesjs.Component,
  side: string
) => {
  switch (side) {
    case 'left':
      setAlign(component, 'left');
      break;
    case 'right':
      setAlign(component, 'right');
      break;
    case 'center':
      setAlign(component, 'center');
      break;
    default:
      break;
  }
};

export const setWidthByOption = (
  component: grapesjs.Component,
  option: string
) => {
  switch (option) {
    case 'fit-content':
      setWidthFitContent(component);
      break;
    case '100%':
      setFullWidth(component);
      break;
    default:
      break;
  }
};
