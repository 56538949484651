import { PreviewTemplate } from '@templates';

interface Props {
  templateId: number;
  htmlContent?: string;
  ampContent?: string;
  isSendTestEmailDisabled: boolean;
}

const PreviewView = ({
  htmlContent,
  ampContent,
  templateId,
  isSendTestEmailDisabled,
}: Props) => (
  <PreviewTemplate
    htmlContent={htmlContent}
    ampContent={ampContent}
    templateId={templateId}
    isSendTestEmailDisabled={isSendTestEmailDisabled}
  />
);

export default PreviewView;
