/* eslint-disable */
import React, { Component, useState } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track } from './components';

const sliderStyle = {
  position: 'relative' as 'relative',
  width: '100%',
};

const domain = [5, 300];
const defaultValues = [200];

interface TooltipSliderProps {
  disabled: boolean;
  onChange: (values: any) => void;
}

export const TooltipSlider = ({ onChange, disabled }: TooltipSliderProps) => {
  const [values, setValues] = useState<ReadonlyArray<number>>(
    defaultValues.slice()
  );
  const [update, setUpdate] = useState<ReadonlyArray<number>>(
    defaultValues.slice()
  );

  const onUpdate = (updated: ReadonlyArray<number>) => {
    setUpdate(updated);
  };

  const onChangeValues = (values: ReadonlyArray<number>) => {
    setValues(values);
    onChange(values);
  };

  return (
    <div style={{ width: '100%' }}>
      <Slider
        disabled={disabled}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onUpdate={onUpdate}
        onChange={onChangeValues}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, activeHandleID, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  isActive={handle.id === activeHandleID}
                  disabled={disabled}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  disabled={disabled}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  );
};
