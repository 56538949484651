import { Checkbox } from '@atoms';
import TableCell from '@organisms/Table/TableCell';

interface Props {
  withCheckbox?: boolean;
  cells: Array<string>;
  columnWidths?: Array<string>;
  onCheckboxClick?: () => void;
  isCheckboxChecked?: boolean;
  withMoreOptionsColumn: boolean;
}

const TableHeader = ({
  withCheckbox,
  cells,
  columnWidths,
  isCheckboxChecked,
  onCheckboxClick,
  withMoreOptionsColumn,
}: Props) => (
  <div className="TableRow TableHeader">
    {onCheckboxClick && withCheckbox && (
      <TableCell className="CheckboxCell">
        <Checkbox
          isChecked={!!isCheckboxChecked}
          onChange={onCheckboxClick}
          type="multiple"
        />
      </TableCell>
    )}
    {cells.map((item, index) => (
      <TableCell
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        style={
          columnWidths?.[index]
            ? { width: columnWidths[index], flexShrink: 0 }
            : undefined
        }
      >
        {item}
      </TableCell>
    ))}
    {withMoreOptionsColumn && <TableCell className="OptionsCell" />}
  </div>
);

export default TableHeader;
