import grapesjs from 'grapesjs';

import EditorSpacerBlockSettingsDesign from './EditorSpacerBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
}

const EditorSpacerBlockSettings = ({ selectedComponent }: Props) => {
  const renderSettings = () => (
    <EditorSpacerBlockSettingsDesign selectedComponent={selectedComponent} />
  );

  return (
    <div className="EditorSpacerBlockSettingsDesignSettings">
      {renderSettings()}
    </div>
  );
};
export default EditorSpacerBlockSettings;
