import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { EditorBlockSettingsComponent } from '@devTypes';
import IconPicker from '@molecules/IconPicker/IconPicker';
import { getChildComponentAt } from '@utils/helpers';

import './styles.css';

const IconBlackFb = 'https://appmail-images.s3.amazonaws.com/FB-black.png';
const IconColorFb = 'https://appmail-images.s3.amazonaws.com/FB-color.png';
const IconWhiteFb = 'https://appmail-images.s3.amazonaws.com/FB-white.png';
const IconBlackIg = 'https://appmail-images.s3.amazonaws.com/IG-black.png';
const IconColorIg = 'https://appmail-images.s3.amazonaws.com/IG-color.png';
const IconWhiteIg = 'https://appmail-images.s3.amazonaws.com/IG-white.png';
const IconBlackTt = 'https://appmail-images.s3.amazonaws.com/TikTok-black.png';
const IconColorTt = 'https://appmail-images.s3.amazonaws.com/TikTok-color.png';
const IconWhiteTt = 'https://appmail-images.s3.amazonaws.com/TikTok-white.png';
const IconBlackTw = 'https://appmail-images.s3.amazonaws.com/Twitter-black.png';
const IconColorTw = 'https://appmail-images.s3.amazonaws.com/Twitter-color.png';
const IconWhiteTw = 'https://appmail-images.s3.amazonaws.com/Twitter-white.png';
const IconBlackYt = 'https://appmail-images.s3.amazonaws.com/YouTube-black.png';
const IconColorYt = 'https://appmail-images.s3.amazonaws.com/YouTube-color.png';
const IconWhiteYt = 'https://appmail-images.s3.amazonaws.com/YouTube-white.png';

const iconPanels = [
  {
    id: 0,
    bgColor: 'white',
    title: 'Color Icon',
    icons: {
      'icon-Fb': IconColorFb,
      'icon-Ig': IconColorIg,
      'icon-Tw': IconColorTw,
      'icon-Yt': IconColorYt,
      'icon-Tt': IconColorTt,
    },
  },
  {
    id: 1,
    bgColor: 'white',
    title: 'Black Icon',
    icons: {
      'icon-Fb': IconBlackFb,
      'icon-Ig': IconBlackIg,
      'icon-Tw': IconBlackTw,
      'icon-Yt': IconBlackYt,
      'icon-Tt': IconBlackTt,
    },
  },
  {
    id: 2,
    bgColor: 'black',
    title: 'White Icon',
    icons: {
      'icon-Fb': IconWhiteFb,
      'icon-Ig': IconWhiteIg,
      'icon-Tw': IconWhiteTw,
      'icon-Yt': IconWhiteYt,
      'icon-Tt': IconWhiteTt,
    },
  },
];

interface Icon {
  'icon-Fb': string;
  'icon-Ig': string;
  'icon-Tw': string;
  'icon-Yt': string;
  'icon-Tt': string;
}

interface IconPanel {
  id: number;
  bgColor: string;
  title: string;
  icons: Icon;
}

const EditorBlockIconPanelPickerSettings = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPanel, setSelectedPanel] = useState(1);

  const changeIconColor = (
    component: grapesjs.Component,
    iconPanel: IconPanel
  ) => {
    const range = ['icon-Fb', 'icon-Ig', 'icon-Tw', 'icon-Yt', 'icon-Tt'];

    range.forEach((ic, index) => {
      const c = component.components().models[index];
      // @ts-ignore
      getChildComponentAt(c, 0).addAttributes({ src: iconPanel.icons[ic] });
    });
  };

  const setSelectedIcons = (iconPanel: IconPanel) => {
    changeIconColor(selectedComponent, iconPanel);

    selectedComponent.addAttributes({ selectedPanel: iconPanel.id });
  };

  const onClickIconPicker = (iconPanel: IconPanel) => {
    setSelectedPanel(iconPanel.id);
    setSelectedIcons(iconPanel);
  };

  useEffect(() => {
    if (!selectedComponent) {
      return;
    }

    const selectedPanelId = selectedComponent.getAttributes().selectedPanel;
    setSelectedPanel(selectedPanelId);
  }, [selectedComponent]);

  return (
    <div className="icon-picker-panels">
      {iconPanels.map((iconPanel) => (
        <div key={iconPanel.id} className="icon-picker-panel">
          <IconPicker
            value={iconPanel.bgColor}
            icons={iconPanel.icons}
            onClick={() => onClickIconPicker(iconPanel)}
            title={iconPanel.title}
            titleVariant="regular"
          />
          {/* TODO:
            <div
            className={clsx(
              selectedPanel !== iconPanel.id && 'display-hidden',
              'checkbox'
            )}
          >
            <Checkbox
              isChecked={selectedPanel === iconPanel.id}
              onChange={() => {}}
              type="primary"
            />
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default EditorBlockIconPanelPickerSettings;
