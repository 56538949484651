import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import { useAuthStatus } from '@hooks';

const AuthorizedRoute = () => {
  const isAuthorized = useAuthStatus();

  return isAuthorized ? <Outlet /> : <Navigate to={ROUTES.signIn} />;
};

export default AuthorizedRoute;
