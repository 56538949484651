import type { RootState } from '../../store';

const shopifyConnectionLoading = (state: RootState) => state.shopify.isLoading;

const shopifyProducts = (state: RootState) => state.shopify.products.data;

const shopifyProductsLoading = (state: RootState) =>
  state.shopify.products.isLoading;

const shopifyConnectionMessage = (state: RootState) =>
  state.shopify.shopConnectionMessage;

const shopifyConnected = (state: RootState) => state.shopify.isShopifyConnected;

const shopifyStore = (state: RootState) => state.shopify.shop;

export default {
  shopifyConnectionLoading,
  shopifyProducts,
  shopifyConnectionMessage,
  shopifyProductsLoading,
  shopifyConnected,
  shopifyStore,
};
