import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers, Reducer } from '@reduxjs/toolkit';

import contactsReducer from '../features/allcontacts/slice';
import authReducer from '../features/auth/slice';
import backgroundReducer from '../features/background/slice';
import campaignsReducer from '../features/campaigns/slice';
import canvasReducer from '../features/canvas/slice';
import domainsReducer from '../features/domains/slice';
import editorReducer from '../features/editor/slice';
import galleryReducer from '../features/gallery/slice';
import generalReducer from '../features/general/slice';
import generativeAIReducer from '../features/generativeAI/slice';
import organizationsReducer from '../features/organizations/slice';
import shopifyReducer from '../features/shopify/slice';
import templateReducer from '../features/templates/slice';
import usersReducer from '../features/users/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['templates', 'shopify', 'generativeAI'],
};

const combinedReducer = combineReducers({
  auth: authReducer,
  editor: editorReducer,
  gallery: galleryReducer,
  shopify: shopifyReducer,
  contacts: contactsReducer,
  campaigns: campaignsReducer,
  general: generalReducer,
  canvas: canvasReducer,
  templates: templateReducer,
  background: backgroundReducer,
  organizations: organizationsReducer,
  users: usersReducer,
  domains: domainsReducer,
  generativeAI: generativeAIReducer,
});

const reducer: Reducer = (state, action) => {
  if (action.type === 'auth/logOut') {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export const persistedReducer = persistReducer(persistConfig, reducer);
