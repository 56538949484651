import { HeaderContainer, Typography } from '@atoms';
import { Selector } from '@molecules';
import { SelectorComponent } from '@devTypes';

import './styles.css';

interface Props {
  title: string;
  selectorProps?: SelectorComponent;
}

const DashboardTabsHeader = ({ title, selectorProps }: Props) => (
  <HeaderContainer className="DashboardTabsHeaderContent">
    <Typography variant="subtitle1">{title}</Typography>
    {selectorProps && (
      <Selector
        {...selectorProps}
        className="DashboardTabsHeaderContentSelector"
      />
    )}
  </HeaderContainer>
);

export default DashboardTabsHeader;
