import { useEffect, useState } from 'react';
import { toColor, useColor } from 'react-color-palette';

import { Typography } from '@atoms';
import { UploadImageContainer } from '@molecules';
import {
  DEFAULT_OUTER_BACKGROUND_COLOR,
  EDITOR_CONTAINERS,
} from '@constants/editor';
import { GalleryItemProps } from '@devTypes';
import { useAutoSave, useTimeoutAutoSave } from '@hooks';
import ColorPickerPanel from '@molecules/ColorPickerPanel/ColorPickerPanel';
import Frames, {
  BackgroundImage,
  defaultImages,
  defaultPatternBackgroundImageStyle,
} from '@molecules/Frames/Frame';
import { editorActions, editorSelectors } from '@store/features/editor';
import { templateSelectors } from '@store/features/templates';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { formatStyleValue } from '@utils/editor/components/text/controllers/general';
import {
  convertRGBStringToObject,
  transformStylesObjectToString,
} from '@utils/helpers';

import EditorImageBlockUploadModal from '../EditorImageBlockUploadModal/EditorImageBlockUploadModal';

const retrieveCurrentSettings = (styles: string) => {
  const imageUrlMatch = styles.match(/background-image: url\((.*?)\)/);
  const colorMatch = styles.match(/background-color: (.*?);/);

  const imageUrl = imageUrlMatch && imageUrlMatch[1];
  const color = colorMatch && colorMatch[1];

  return {
    imageUrl,
    color,
  };
};

const isDefaultImage = (imageUrl: string) =>
  defaultImages.find(({ url }) => url === imageUrl);

const getCurrentBackgroundContainerStyles = (
  containerId = EDITOR_CONTAINERS.canvasContainer
) => {
  const containerEl = document.getElementById(containerId);
  const computedStyle = window.getComputedStyle(containerEl!);

  return computedStyle;
};

const customImageBackgroundStyle = {
  'background-repeat': 'repeat-y',
  'background-position': 'center top',
};

const getCustomImageBackgroundStyles = () => {
  const { width } = getCurrentBackgroundContainerStyles();

  const styles = {
    ...customImageBackgroundStyle,
    'background-size': width,
  };

  return styles;
};

const windowResizeEventHandler = () => {
  const backgroundContainerEl = document.getElementById(
    EDITOR_CONTAINERS.canvasContainer
  );

  const { backgroundSize, width } = getCurrentBackgroundContainerStyles();

  const containerWidth = formatStyleValue(width, 'px');
  const backgroundImageWidth = formatStyleValue(backgroundSize, 'px');

  if (+containerWidth > +backgroundImageWidth) {
    backgroundContainerEl!.style.backgroundSize = `${containerWidth}px`;
  }
};

const EditorBackgroundSettings = () => {
  const backgroundState = useAppSelector(
    editorSelectors.currentStateBackground
  );
  const template = useAppSelector(templateSelectors.template);
  const styles = template?.data?.backgroundStyles;
  const [selectedImage, setSelectedImage] = useState<GalleryItemProps | null>(
    null
  );
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [color, setColor] = useColor(
    'hex',
    backgroundState.color || DEFAULT_OUTER_BACKGROUND_COLOR
  );
  const [image, setImage] = useState<string | null>(backgroundState.imageUrl);
  const [imageColor, setImageColor] = useColor('hex', backgroundState.color);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const autoSave = useAutoSave();
  const autoSaveTimer = useTimeoutAutoSave();

  const [canvasWrapper, setCanvasWrapper] = useState(
    document.getElementById(EDITOR_CONTAINERS.canvasContainer)!
  );

  useEffect(() => {
    window.addEventListener('resize', windowResizeEventHandler);

    return () => window.removeEventListener('resize', windowResizeEventHandler);
  }, []);

  useEffect(() => {
    setColor(
      toColor('hex', backgroundState.color || DEFAULT_OUTER_BACKGROUND_COLOR)
    );
    setImage(backgroundState.imageUrl);
    setImageColor(toColor('hex', backgroundState.color));
    setCanvasWrapper(
      document.getElementById(EDITOR_CONTAINERS.canvasContainer)!
    );
  }, [backgroundState]);

  useEffect(() => {
    if (!canvasWrapper) return;

    if (backgroundState.color || backgroundState.imageUrl) {
      if (
        backgroundState.imageUrl &&
        isDefaultImage(backgroundState.imageUrl)
      ) {
        canvasWrapper.setAttribute(
          'style',
          transformStylesObjectToString(defaultPatternBackgroundImageStyle)
        );
        setImage(backgroundState.imageUrl);
      } else {
        canvasWrapper.setAttribute(
          'style',
          transformStylesObjectToString(getCustomImageBackgroundStyles())
        );
        setSelectedImage({
          id: Math.random(),
          url: backgroundState.imageUrl,
        });
        setUploadedImageUrl(backgroundState.imageUrl);
      }

      canvasWrapper.style.backgroundImage = `url(${backgroundState.imageUrl})`;
      canvasWrapper.style.backgroundColor = backgroundState.color;

      setColor(
        toColor('hex', backgroundState.color || DEFAULT_OUTER_BACKGROUND_COLOR)
      );
      setImage(backgroundState.imageUrl);
      setImageColor(toColor('hex', backgroundState.color));

      return;
    }

    canvasWrapper.style.backgroundImage = '';
    canvasWrapper.style.backgroundColor = DEFAULT_OUTER_BACKGROUND_COLOR;

    if (!styles) return;

    const { imageUrl, color: currentColor } = retrieveCurrentSettings(styles);

    if (imageUrl && isDefaultImage(imageUrl)) {
      setImage(imageUrl);
      canvasWrapper.setAttribute(
        'style',
        transformStylesObjectToString(defaultPatternBackgroundImageStyle)
      );
    } else if (imageUrl) {
      setSelectedImage({
        id: Math.random(),
        url: imageUrl,
      });
      setUploadedImageUrl(imageUrl);
    }

    if (currentColor) {
      setColor(toColor('rgb', convertRGBStringToObject(currentColor)));
    }

    canvasWrapper.setAttribute('style', styles);
  }, [canvasWrapper, styles]);

  const handleSelectedImageChange = (
    imageToUpload: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (Array.isArray(imageToUpload)) {
      return;
    }
    setSelectedImage(imageToUpload as GalleryItemProps);
    setImage(null);
    setColor(toColor('hex', DEFAULT_OUTER_BACKGROUND_COLOR));
    dispatch(
      editorActions.setCurrentStateBackground({
        color: '',
        imageUrl: imageToUpload.url,
        isImagePattern: true,
      })
    );

    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 2000);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setUploadedImageUrl(selectedImage?.url || null);
    setIsModalOpen(false);
  };

  const handleSelectedImageUpload = (
    imageToUpload: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (!imageToUpload || Array.isArray(imageToUpload)) {
      return;
    }

    canvasWrapper.setAttribute(
      'style',
      transformStylesObjectToString(getCustomImageBackgroundStyles())
    );
    canvasWrapper.style.backgroundImage = `url(${imageToUpload.url})`;
    canvasWrapper.style.backgroundColor = '';
    setSelectedImage(imageToUpload);
    handleClose();
  };

  const handleImageClick = (value: BackgroundImage) => {
    canvasWrapper.setAttribute(
      'style',
      transformStylesObjectToString(value.style)
    );
    canvasWrapper.style.backgroundImage = `url(${value.url})`;
    canvasWrapper.style.backgroundColor = value.baseColor;

    setImage(value.url);
    setSelectedImage(null);
    setUploadedImageUrl(null);
    setColor(toColor('hex', DEFAULT_OUTER_BACKGROUND_COLOR));
    dispatch(
      editorActions.setCurrentStateBackground({
        color: value.baseColor,
        imageUrl: value.url,
        isImagePattern: true,
      })
    );

    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 2000);
  };

  const handleImageColorChange = (colorValue: string) => {
    canvasWrapper.style.backgroundColor = colorValue;

    dispatch(
      editorActions.setCurrentStateBackground({
        color: colorValue,
        imageUrl: image,
        isImagePattern: true,
      })
    );
    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 2000);
  };

  const handleColorClick = (value: string) => {
    const colorValue = value;

    canvasWrapper.style.backgroundImage = '';
    canvasWrapper.style.backgroundColor = colorValue;
    setColor(toColor('hex', value));
    setSelectedImage(null);
    setUploadedImageUrl(null);
    setImage(null);

    dispatch(
      editorActions.setCurrentStateBackground({
        color: colorValue,
        imageUrl: '',
        isImagePattern: false,
      })
    );
    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 2000);
  };

  const handleColorChange = (colorValue: string) => {
    setSelectedImage(null);
    setUploadedImageUrl(null);
    setImage(null);
    handleColorClick(colorValue);
  };

  return (
    <>
      <ColorPickerPanel
        color={color}
        setColor={setColor}
        onChange={handleColorChange}
        onClick={handleColorClick}
        title="Background color"
      />
      <br />
      <Typography
        className="Typography Typography-gray-main"
        variant="subtitle6"
      >
        Background Pattern
      </Typography>
      <UploadImageContainer imageUrl={uploadedImageUrl} onClick={handleOpen} />
      <EditorImageBlockUploadModal
        galleryValue={selectedImage}
        onGalleryValueChange={handleSelectedImageChange}
        isOpen={isModalOpen}
        onClose={handleClose}
        onGallerySelectedImageUpload={handleSelectedImageUpload}
      />
      <Frames
        image={image}
        color={imageColor}
        setColor={setImageColor}
        onImageClick={handleImageClick}
        onColorChange={handleImageColorChange}
      />
    </>
  );
};

export default EditorBackgroundSettings;
