import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CreateNewPasswordView } from '@views';
import { ROUTES } from '@constants/routes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { createNewPasswordThunk } from '@store/features/auth/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { createNewPasswordSchema } from '@utils/validation';

const defaultValues = {
  password: '',
};
type FormValues = typeof defaultValues;

const CreateNewPasswordPage = () => {
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(createNewPasswordSchema),
  });
  const { handleSubmit } = methods;
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createNewPasswordSubmit: SubmitHandler<FormValues> = ({ password }) => {
    dispatch(createNewPasswordThunk({ token: state.token, password }))
      .unwrap()
      .then(() => {
        navigate(ROUTES.passwordUpdated);
      })
      .catch((err: Error) => toast(err.message));
  };

  return (
    <FormProvider {...methods}>
      <CreateNewPasswordView onSubmit={handleSubmit(createNewPasswordSubmit)} />
    </FormProvider>
  );
};

export default CreateNewPasswordPage;
