import { Card, Typography } from '@atoms';
import { IntegrationCard } from '@organisms';
import { CardVariant, Platform } from '@devTypes';

import './styles.css';

interface Props {
  title: string;
  items: Array<Platform>;
  isActivated: boolean;
  cardVariant: CardVariant;
  onShopifyActivateClick?: () => void;
  onShopifyDeactivateClick?: () => void;
}

const IntegrationPlatformsContainer = ({
  title,
  items,
  isActivated,
  cardVariant,
  onShopifyActivateClick,
  onShopifyDeactivateClick,
}: Props) => (
  <Card className="IntegrationPlatformsContainer" variant={cardVariant}>
    <Typography
      variant="subtitle5"
      className="IntegrationPlatformsContainer-Title"
    >
      {title}
    </Typography>
    <div className="IntegrationCardsList">
      {items.map(({ title: cardTitle, description, icon }, index) => (
        <IntegrationCard
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          title={cardTitle}
          description={description}
          icon={icon}
          isActivated={isActivated}
          onActivateClick={
            cardTitle === 'Shopify' ? onShopifyActivateClick : () => {}
          } // temp solution
          onDeactivateClick={
            cardTitle === 'Shopify' ? onShopifyDeactivateClick : () => {}
          }
        />
      ))}
    </div>
  </Card>
);

export default IntegrationPlatformsContainer;
