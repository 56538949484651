import clsx from 'clsx';

import './styles.css';

interface Props {
  variant?: 'horizontal' | 'vertical';
  className?: string;
}
const Divider = ({ variant = 'horizontal', className }: Props) => (
  <div className={clsx('Divider', `Divider-${variant}`, className)} />
);

export default Divider;
