import { LoadingState } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import { galleryAsyncThunks } from '.';

interface InitialState {
  // FIXME: add more proper type
  uploads: any[];
  loadingState: LoadingState;
}

const initialState: InitialState = {
  uploads: [],
  loadingState: {
    isLoading: false,
  },
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(galleryAsyncThunks.getAllUrlsThunk.pending, (state) => ({
      ...state,
      loadingState: {
        isLoading: true,
      },
    }));
    builder.addCase(
      galleryAsyncThunks.getAllUrlsThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        loadingState: {
          isLoading: false,
        },
        uploads: payload,
      })
    );
    builder.addCase(galleryAsyncThunks.getAllUrlsThunk.rejected, (state) => ({
      ...state,
      loadingState: {
        isLoading: false,
      },
    }));
  },
});

export default gallerySlice.reducer;
