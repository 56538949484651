import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import organizationsApi, { AddOrganizationData } from './api';

const getMeOrganizationDataThunk = createCommonAsyncThunk(
  'organizations/getMeOrganizationData',
  organizationsApi.getMeOrganizationData
);

const addOrganizationDataThunk = createCommonAsyncThunk<AddOrganizationData>(
  'organizations/addOrganizationData',
  organizationsApi.addOrganizationData
);

export default {
  addOrganizationDataThunk,
  getMeOrganizationDataThunk,
};
