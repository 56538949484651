import TagManager from 'react-gtm-module';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SignIn } from '@organisms';
import { SignInSignUpTemplate } from '@templates';
import { ROUTES } from '@constants/routes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { signInThunk } from '@store/features/auth/asyncThunks';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';
import storage from '@utils/storage';
import { schemas } from '@utils/validation';

const defaultValues = {
  email: '',
  password: '',
  rememberMe: false,
};

type FormValues = typeof defaultValues;

const SignInView = () => {
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schemas.signInFormSchema),
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSubmit } = methods;

  const createNewTemplate = async () => {
    dispatch(
      templateAsyncThunks.createTemplateThunk({ title: 'new', gjs: '' })
    ).unwrap();
  };

  const trackToGTM = ({ email }: any) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'sign_in',
        email,
      },
    };

    // Send the form data to GTM
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleValid: SubmitHandler<FormValues> = ({
    email,
    password,
    rememberMe,
  }) => {
    dispatch(signInThunk({ email, password }))
      .unwrap()
      .then(async ({ accessToken }) => {
        if (rememberMe) {
          storage.setToken(accessToken);
        } else {
          document.cookie = `token=${accessToken}`;
        }

        await createNewTemplate();

        sessionStorage.clear();

        trackToGTM({ email });

        await navigate(ROUTES.root); // do not remove await, it's temp solution to handle template creation
      })
      .catch((err) => toast.error(err.message.message));
  };

  return (
    <FormProvider {...methods}>
      <SignInSignUpTemplate
        authType="signIn"
        onSubmit={handleSubmit(handleValid)}
        // onGoogleAuthSubmit={() => {}}
        // onFacebookAuthSubmit={() => {}}
      >
        <SignIn />
      </SignInSignUpTemplate>
    </FormProvider>
  );
};

export default SignInView;
