import { MouseEventHandler } from 'react';
import clsx from 'clsx';

import { Button } from '@atoms';

interface Props {
  isActivated: boolean;
  onActivateClick?: MouseEventHandler;
  onDeactivateClick?: MouseEventHandler;
}

const Footer = ({ isActivated, onActivateClick, onDeactivateClick }: Props) => (
  <div
    className={clsx(
      'IntegrationCard-footer',
      isActivated && 'IntegrationCard-footer-activated'
    )}
  >
    {isActivated ? (
      <>
        <Button
          title="Deactivate"
          variant="text-error"
          onClick={onDeactivateClick}
        />
        <Button title="Connected" variant="text-secondary" disabled />
      </>
    ) : (
      <Button
        title="Activate"
        variant="text-primary"
        onClick={onActivateClick}
      />
    )}
  </div>
);

export default Footer;
