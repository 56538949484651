import { useEffect } from 'react';
import { toColor, useColor } from 'react-color-palette';

import { Divider, Typography } from '@atoms';
import { ColorPickerInput } from '@molecules';
import {
  DEFAULT_TEXT_COLOR_VALUE,
  MAX_FOOTER_PADDING_IN_PX,
  MIN_FOOTER_PADDING_IN_PX,
} from '@constants/editor';
import { EditorBlockSettingsComponent } from '@devTypes';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorBlockIconPanelPickerSettings from '@organisms/EditorBlockIconPanelPickerSettings/EditorBlockIconPanelPickerSettings';
import EditorFooterBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';

const EditorFooterBlockDesignSettings = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => {
  const [color, setColor] = useColor('hex', DEFAULT_TEXT_COLOR_VALUE);

  const topContainer = selectedComponent.components().models[0];
  const topSection = topContainer.components().models[0];
  const companyInfo = topSection.components().models[1];
  const socialMediaComponent = companyInfo.components().models[1];

  const handleColorChange = (colorValue: string) => {
    const footerTable = selectedComponent.components().models[0];
    footerTable.addStyle({ color: colorValue });
    footerTable.addStyle({ 'border-color': colorValue });
    footerTable.addAttributes({ color: colorValue });
  };

  useEffect(() => {
    if (!selectedComponent) {
      return;
    }

    const componentStyles = selectedComponent.getStyle();
    const colorValue = componentStyles.color;
    if (colorValue) {
      setColor(toColor('hex', colorValue));
    }
  }, [selectedComponent]);

  return (
    <div className="EditorFooterBlockDesignSettings">
      <ColorPickerInput
        color={color}
        setColor={setColor}
        onChange={handleColorChange}
        title="Text and line color"
      />
      <br />
      <div className="branding-typography">
        <Typography
          variant="subtitle4"
          weight="bold"
          className="branding-typography-icon"
        >
          ICON COLOR
        </Typography>
      </div>
      <EditorBlockIconPanelPickerSettings
        selectedComponent={socialMediaComponent}
      />
      <Divider />
      <EditorBlockBackgroundSettings selectedComponent={selectedComponent} />
      <Divider />
      <EditorFooterBlockPaddingSettings
        selectedComponent={topContainer}
        max={MAX_FOOTER_PADDING_IN_PX}
        min={MIN_FOOTER_PADDING_IN_PX}
      />
    </div>
  );
};

export default EditorFooterBlockDesignSettings;
