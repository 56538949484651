import { ForgotPassword } from '@organisms';
import { ForgotPasswordTemplate } from '@templates';
import { ReactComponent as KeyIcon } from '@assets/icons/key.svg';

interface ForgotPasswordViewProps {
  onSubmit: () => void;
}

const ForgotPasswordView = ({ onSubmit }: ForgotPasswordViewProps) => (
  <ForgotPasswordTemplate
    title="Forgot password?"
    subtitle="No worries, we'll send you reset instructions"
    svgIcon={KeyIcon}
  >
    <ForgotPassword onSubmit={onSubmit} />
  </ForgotPasswordTemplate>
);

export default ForgotPasswordView;
