import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputWithTitle, Select } from '@molecules';
import { DEFAULT_SENDER_EMAIL, RECIPIENTS_OPTIONS } from '@constants/common';
import { campaignsSelectors } from '@store/features/campaigns';
import { domainSelectors } from '@store/features/domains';
import { useAppSelector } from '@store/hooks';

const CreateCampaignForm = () => {
  const { recipientsMetadata } = useAppSelector(
    campaignsSelectors.campaignObject
  );

  const { data: availableSenders } = useAppSelector(
    domainSelectors.availableSenders
  );

  const recipientOptionsWithCount = RECIPIENTS_OPTIONS.map((item) => ({
    ...item,
    title: `${item.title} (${
      item.value === 'all'
        ? recipientsMetadata?.totalCount
        : recipientsMetadata?.subscribedCount
    })`,
  }));

  const { control, setValue, getValues, formState } = useFormContext();
  const selectedRecipient =
    recipientOptionsWithCount.find(
      (item) => item.value === getValues().recipientType
    )?.title || '';
  const selectedSender =
    availableSenders.find((item) => item.id === getValues().senderId) ||
    availableSenders[0];

  const onRecipientSelectChange = (value: String) => {
    setValue(
      'recipientType',
      recipientOptionsWithCount.find((item) => item.title === value)?.value
    );
  };

  const onSenderSelectChange = (value: string) => {
    setValue(
      'senderId',
      availableSenders.find((item) => item.address === value)?.id
    );
  };

  useEffect(() => {
    onSenderSelectChange(selectedSender?.address || DEFAULT_SENDER_EMAIL);
  }, []);

  return (
    <div className="CreateCampaignCardForm">
      <Select
        value={selectedRecipient}
        onChange={onRecipientSelectChange}
        options={[
          recipientOptionsWithCount[0].title,
          recipientOptionsWithCount[1].title,
        ]}
      />

      <InputWithTitle
        title="Subject line"
        control={control}
        name="subject"
        className="CreateCampaignCardForm-Input"
        hideErrorMessage={!formState.dirtyFields.subject}
        autoComplete="off"
      />
      <InputWithTitle
        title="Preview text"
        control={control}
        name="previewText"
        className="CreateCampaignCardForm-Input"
        placeholder="This text is displayed after the subject line"
        hideErrorMessage={!formState.dirtyFields.previewText}
        autoComplete="off"
      />
      <InputWithTitle
        title="Sender name"
        control={control}
        name="senderName"
        className="CreateCampaignCardForm-Input"
        placeholder="Company name"
        hideErrorMessage={!formState.dirtyFields.senderName}
        autoComplete="off"
      />

      <Select
        value={selectedSender?.address || 'support@appmail.co'}
        onChange={onSenderSelectChange}
        options={
          availableSenders.map((sender) => sender.address).length
            ? availableSenders.map((sender) => sender.address)
            : ['support@appmail.co']
        }
      />
    </div>
  );
};

export default CreateCampaignForm;
