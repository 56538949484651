import { SwitchRender } from '@atoms';

import EditorEcommerceBlockSettingsContent from './EditorEcommerceBlockSettingsContent';
import EditorEcommerceBlockSettingsDesign from './EditorEcommerceBlockSettingsDesign';

interface Props {
  type: string;
  onDesignTabDisabledChange: (x: boolean) => void;
}

const EditorEcommerceBlockSettings = ({
  type,
  onDesignTabDisabledChange,
}: Props) => (
  <SwitchRender comparator={type} toCompare={['content', 'design']}>
    <EditorEcommerceBlockSettingsContent
      onDesignTabDisabledChange={onDesignTabDisabledChange}
    />
    <EditorEcommerceBlockSettingsDesign />
  </SwitchRender>
);

export default EditorEcommerceBlockSettings;
