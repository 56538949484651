import { Typography } from '@atoms';

import './styles.css';

const LandingBlock = () => (
  <div className="LandingBlock">
    <Typography variant="h4">Sell more effectively</Typography>
    <Typography variant="subtitle3" style={{ maxWidth: 500 }}>
      Boost shopping carts and sales by selling products directly from your
      marketing emails
    </Typography>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flex: 0.6,
        alignItems: 'center',
      }}
    >
      <img
        src="https://appmail-images.s3.amazonaws.com/E-commerce-Gmail-Shopping.png"
        alt=""
        className="LandingBlock-Image"
      />
    </div>
  </div>
);

export default LandingBlock;
