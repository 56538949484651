import grapesjs from 'grapesjs';

export const findLogoImageComponent = (component: grapesjs.Component) =>
  component.find('img.actual-logo')[0];
export const findLogoPlaceholderComponent = (component: grapesjs.Component) =>
  component.find('div.logo-placeholder')[0];

export const removeLogoPlaceholderComponent = (
  component: grapesjs.Component
) => {
  const placeholder = findLogoPlaceholderComponent(component);

  if (placeholder) {
    placeholder.remove();
  }
};

export const setLogoImage = (
  component: grapesjs.Component,
  attributes: {
    imageUrl: string;
    imageId: number;
  }
) => {
  removeLogoPlaceholderComponent(component);

  const logoComponent = findLogoImageComponent(component);
  const { imageUrl, imageId } = attributes;

  logoComponent.addAttributes({ src: imageUrl });

  component.addAttributes({
    imageUrl,
    imageId,
  });
};

export const handleLogoSizeChange = (component: grapesjs.Component) => {
  const { size } = component.getAttributes();
  const logoComponent = findLogoImageComponent(component);

  if (!logoComponent) return;

  logoComponent.addStyle({
    width: `${size}px`,
  });
};
