import { Dispatch, SetStateAction } from 'react';
import grapesjs from 'grapesjs';

import {
  ElementParams,
  TextAlignOptions,
  TextStyleSettingClickHandler,
  TextStylesState,
} from '@devTypes';
import {
  coloredTextDefinition,
  getColoredTextDefinition,
  getLinkWithAttributesDefinition,
  highlightTextDefinition,
  letterSpacingDefinition,
  linkDefinition,
  underlinedTextDefinition,
} from '@utils/editor/components/text/definitions';
import {
  generateId,
  getEditorIframeDocument,
  transformStylesObjectToString,
} from '@utils/helpers';
import Component = grapesjs.Component;

export const getSelection: () => Selection | null = () => {
  const iframeDocument = getEditorIframeDocument();
  if (!iframeDocument) return null;

  const selection = iframeDocument.getSelection();

  return selection;
};

type ElementAttributes = { [key: string]: string | object };

export const addElementAttributes = (
  element: Element,
  attributes?: ElementAttributes,
  editor?: grapesjs.Editor
) => {
  if (!attributes) return;

  const attributesEntries = Object.entries(attributes);

  attributesEntries.forEach(([key, value]) => {
    if (key === 'style') return;

    element.setAttribute(key, String(value));
  });

  const { style } = attributes;

  // eslint-disable-next-line no-param-reassign
  if (!element.id) element.id = generateId();

  if (!style) return;

  const styleAttributeValue =
    typeof style === 'object' ? transformStylesObjectToString(style) : style;

  if (editor) {
    const cssRules = `#${element.id} { ${styleAttributeValue} }`;

    editor.Css.addRules(cssRules);

    return;
  }

  element.setAttribute('style', styleAttributeValue);
};

export const createElement: (
  elementParams: ElementParams,
  editor?: grapesjs.Editor
) => Element = (elementParams, editor) => {
  const { tagName, attributes } = elementParams;

  const element = document.createElement(tagName);

  const customTypeAttribute = elementParams.type && {
    'custom-type': elementParams.type,
  };
  addElementAttributes(
    element,
    { ...attributes, ...customTypeAttribute },
    editor
  );

  if (!elementParams.content) return element;

  const contentElement = createElement(elementParams.content);

  element.append(contentElement);

  return element;
};

export const isTextNode: (node: Node) => boolean = (node) =>
  node.nodeType === 3;
export const isElementNode: (node: Node) => boolean = (node) =>
  node.nodeType === 1;

const isTextContentEqual: (firstNode: Node, secondNode: Node) => boolean = (
  firstNode,
  secondNode
) => firstNode.textContent === secondNode.textContent;

export const getCustomTypeAttributeSelector: (
  elementParams: ElementParams
) => string = (elementParams) => `[custom-type="${elementParams.type}"]`;

export const getStyledElementSelector: (
  elementParams: ElementParams
) => string = (elementParams) => {
  const customTypeAttributeSelector =
    getCustomTypeAttributeSelector(elementParams);
  const styledElementSelector = `${elementParams.tagName}${customTypeAttributeSelector}`;

  return styledElementSelector;
};

export const replaceElementWithItsContent = (
  element: Element,
  component?: grapesjs.Component
) => {
  if (component) {
    const componentEl = component.find(`#${element.id}`)[0];
    const newContent = componentEl.replaceWith(componentEl.getEl().innerHTML);

    if (Array.isArray(newContent)) {
      return newContent.map((item) => item.getEl());
    }

    const newElement = newContent.getEl();

    if (
      newElement.parentElement &&
      isTextContentEqual(newElement, newElement.parentElement)
    ) {
      return newElement.parentElement;
    }

    return newElement;
  }

  const { childNodes } = element;
  element.replaceWith(...Array.from(childNodes));

  return null;
};

export const removeAllInnerStyledElements = (
  contents: DocumentFragment | Element,
  selector: string
) => {
  const styledElements = contents.querySelectorAll(selector);

  styledElements?.forEach((item) => replaceElementWithItsContent(item));
};

const removeComponentInnerStyledElements = (
  component: grapesjs.Component,
  selector: string
) => {
  const styledComponents = component.find(selector);

  styledComponents.forEach((item) => {
    item.replaceWith(item.getEl().innerHTML);
  });
};

const isLinkTag: (element: Element) => boolean = (element) =>
  element.tagName === 'A';

const removeLinkStyles = (element: Element | DocumentFragment) => {
  if (isElementNode(element) && !isLinkTag(element as Element)) return;

  const underlinedTextSelector = getStyledElementSelector(
    underlinedTextDefinition
  );

  removeAllInnerStyledElements(element, underlinedTextSelector);
};

const removeComponentLinkStyles = (component: grapesjs.Component) => {
  if (!isLinkTag(component.getEl())) return;

  const underlinedTextSelector = getStyledElementSelector(
    underlinedTextDefinition
  );

  removeComponentInnerStyledElements(component, underlinedTextSelector);
};

export const normalizeElement: (
  element: Element | DocumentFragment
) => Element | DocumentFragment = (element) => {
  const emptyElements = element.querySelectorAll('*:empty:not(br)');
  emptyElements.forEach((item) => item.remove());

  element.normalize();

  return element;
};

const createElementInRange: (
  range: Range | null,
  elementParams: ElementParams,
  editor?: grapesjs.Editor
) => { range: Range; element: Element } | null = (
  range,
  elementParams,
  editor
) => {
  if (!range || range.collapsed) return null;

  const rangeContents = range.extractContents();

  const element = createElement(elementParams, editor);

  const styledElementSelector = getStyledElementSelector(elementParams);
  removeAllInnerStyledElements(rangeContents, styledElementSelector);

  element.append(rangeContents);
  normalizeElement(element);

  range.insertNode(element);

  return { range, element };
};

// @ts-ignore
const getTextNodeLength = (node: Node) => node.length;

export const getFirstParentElement: (node: Node | Element) => Element | null = (
  node
) => (isElementNode(node) ? (node as Element) : node.parentElement);

const isElementStyled: (
  element: Element,
  elementParams: ElementParams
) => boolean = (element, elementParams) => {
  const isTagNameEqual =
    element.tagName.toLowerCase() === elementParams.tagName.toLowerCase();
  const isCustomTypeEqual =
    element.getAttribute('custom-type') === elementParams.type;

  return isTagNameEqual && isCustomTypeEqual;
};

const hasTextNodes = (element: Element) =>
  element.childNodes.length !== element.children.length;

/* eslint-disable no-continue, no-restricted-syntax */
const isChildNodesStyled: (
  childNodes: Array<ChildNode>,
  elementParams: ElementParams
) => boolean = (childNodes, elementParams) => {
  let isStyled = true;

  const styledElementSelector = getStyledElementSelector(elementParams);

  for (const child of childNodes) {
    if (!child.textContent) continue;

    if (isTextNode(child)) {
      if (!getTextNodeLength(child)) continue;

      const closestStyledParent = getFirstParentElement(child)?.closest(
        styledElementSelector
      );

      if (closestStyledParent) continue;

      isStyled = false;
      return false;
    }

    if (!isElementNode(child)) {
      isStyled = false;
      return false;
    }

    if (isElementStyled(child as Element, elementParams)) continue;

    if (hasTextNodes(child as Element)) {
      isStyled = false;
      return false;
    }

    if (!isChildNodesStyled(Array.from(child.childNodes), elementParams)) {
      isStyled = false;
      return false;
    }
  }

  return isStyled;
};
/* eslint-enable no-continue, no-restricted-syntax */

const isRangeChildrenStyled: (
  range: Range,
  elementParams: ElementParams
) => boolean = (range, elementParams) => {
  const rangeContents = range.cloneContents();
  const rangeContentChildNodes = Array.from(rangeContents.childNodes);

  return isChildNodesStyled(rangeContentChildNodes, elementParams);
};

export const findClosestStyledParent: (
  node: Node,
  elementParams: ElementParams
) => Element | null = (node, elementParams) => {
  const styledElementSelector = getStyledElementSelector(elementParams);

  const parentElement = getFirstParentElement(node);

  if (!parentElement) return null;

  if (isElementStyled(parentElement, elementParams)) return parentElement;

  const firstStyledParentElement = parentElement.closest(styledElementSelector);

  return firstStyledParentElement;
};

export const findNestedStyledElement: (
  content: Element | DocumentFragment,
  elementParams: ElementParams
) => Element | null = (node, elementParams) => {
  const styledElementSelector = getStyledElementSelector(elementParams);

  const nestedStyledElement = node.querySelector(styledElementSelector);

  return nestedStyledElement;
};

export const findStyledParentOrChild = (
  range: Range,
  definition: ElementParams
) => {
  const rangeContents = range.cloneContents();

  const closestStyledParent = findClosestStyledParent(
    range.commonAncestorContainer,
    definition
  );

  const nestedStyledElement = findNestedStyledElement(
    rangeContents,
    definition
  );

  return closestStyledParent || nestedStyledElement;
};

type RangeInParentElement = {
  type: string;
  parentElement: Element;
  startOffset?: number;
  endOffset?: number;
  startContainer?: Element | null;
  endContainer?: Element | null;
};

const removeStyledElementsInRangeParent: (
  range: Range,
  elementParams: ElementParams,
  component?: grapesjs.Component
) => RangeInParentElement | null = (range, elementParams, component) => {
  const styledElementSelector = getStyledElementSelector(elementParams);
  const parentElement = getFirstParentElement(range.commonAncestorContainer);

  if (!parentElement) return null;

  const rangeContentsClone = range.cloneContents();

  const startContainerElement = getFirstParentElement(range.startContainer);
  const endContainerElement = getFirstParentElement(range.endContainer);
  const endOffset = range.cloneContents()?.lastChild?.textContent?.length;

  const styledElements = parentElement.querySelectorAll(styledElementSelector);

  const startOffset =
    (startContainerElement?.textContent?.length || 0) -
    (styledElements[0]?.textContent?.length || 0);

  const newContent: grapesjs.Component[] = [];

  styledElements.forEach((item) => {
    if (range.intersectsNode(item)) {
      if (component) {
        const componentEl = component.find(`* #${item.id}`)[0];

        removeComponentLinkStyles(componentEl);

        const componentInnerHTML = componentEl.getEl().innerHTML;
        const replacedComponentEl = componentEl.replaceWith(componentInnerHTML);

        if (replacedComponentEl instanceof Array) {
          newContent.push(...replacedComponentEl);
        } else {
          newContent.push(replacedComponentEl);
        }
      } else {
        removeLinkStyles(item);
        replaceElementWithItsContent(item);
      }
    }
  });

  if (newContent.length === 1) {
    range.selectNode(newContent[0].getEl());
  } else {
    newContent.forEach((item, index, array) => {
      if (range.intersectsNode(item.getEl())) return;

      if (index === array.length - 1) {
        range.setEndAfter(item.getEl());
        return;
      }

      if (index === 0) {
        range.selectNode(item.getEl());
      }
    });
  }

  const rangeInParentElement = {
    type: 'RangeInParentElement',
    parentElement,
    ...(!isTextContentEqual(parentElement, rangeContentsClone) && {
      startOffset,
      endOffset,
      startContainer: startContainerElement,
      endContainer: endContainerElement,
    }),
  };

  return rangeInParentElement;
};

type RemovedStyledElementSiblings = {
  type: string;
  previousSibling: Element | undefined;
  nextSibling: Element | undefined;
};

const removeStylesFromTextInStyledElement: (
  range: Range,
  elementParams: ElementParams
) => RemovedStyledElementSiblings | null = (range, elementParams) => {
  const document = getEditorIframeDocument();
  const parentElement = getFirstParentElement(range.commonAncestorContainer);

  if (!parentElement || !document) return null;

  const rangeStartStyledParent = findClosestStyledParent(
    range.startContainer,
    elementParams
  );
  const rangeEndStyledParent = findClosestStyledParent(
    range.endContainer,
    elementParams
  );

  const closestStyledParentElement = findClosestStyledParent(
    parentElement,
    elementParams
  );

  const previousSiblingRange = document.createRange();

  if (rangeStartStyledParent?.previousSibling) {
    previousSiblingRange.setStartAfter(rangeStartStyledParent.previousSibling);
  } else if (rangeStartStyledParent) {
    previousSiblingRange.setStartBefore(rangeStartStyledParent);
  } else {
    previousSiblingRange.setStart(parentElement, 0);
  }

  previousSiblingRange.setEnd(range.startContainer, range.startOffset);

  const nextSiblingRange = document.createRange();

  if (rangeEndStyledParent?.nextSibling) {
    nextSiblingRange.setEndBefore(rangeEndStyledParent.nextSibling);
  } else if (rangeEndStyledParent) {
    nextSiblingRange.setEndAfter(rangeEndStyledParent);
  } else {
    nextSiblingRange.setEnd(parentElement, 1);
  }

  nextSiblingRange.setStart(range.endContainer, range.endOffset);

  if (elementParams.type === 'link') {
    const linkURL = rangeStartStyledParent?.getAttribute('href');

    if (!linkURL) return null;

    // eslint-disable-next-line no-param-reassign
    elementParams = getLinkWithAttributesDefinition(linkURL);
  }

  const startElementInRange = createElementInRange(
    previousSiblingRange,
    elementParams
  );
  const endElementInRange = createElementInRange(
    nextSiblingRange,
    elementParams
  );

  const styledElementSelector = getStyledElementSelector(elementParams);

  parentElement.querySelectorAll(styledElementSelector).forEach((item) => {
    if (
      range.intersectsNode(item) &&
      item !== startElementInRange?.element &&
      item !== endElementInRange?.element
    ) {
      removeLinkStyles(item);
      replaceElementWithItsContent(item);
    }
  });

  const siblings = {
    type: 'RemovedStyledElementSiblings',
    previousSibling: startElementInRange?.element,
    nextSibling: endElementInRange?.element,
  };

  if (!closestStyledParentElement) return siblings;

  removeLinkStyles(closestStyledParentElement);
  replaceElementWithItsContent(closestStyledParentElement);

  return siblings;
};

const updateLinkElementStyle = (linkElement: Element) => {
  if (!isLinkTag(linkElement)) return;

  const underlinedElement = createElement(underlinedTextDefinition);

  underlinedElement.append(...Array.from(linkElement.childNodes));

  linkElement.replaceChildren(underlinedElement);
};

const updateColoredTextElementStyle = (element: HTMLElement) => {
  if (element.getAttribute('custom-type') !== coloredTextDefinition.type)
    return;

  const underlinedParentElement = findClosestStyledParent(
    element,
    underlinedTextDefinition
  );

  if (!underlinedParentElement) return;

  element.style.setProperty('text-decoration', 'underline');
};

const insertContentIntoStyledElement: (
  parentElement: Element,
  elementParams: ElementParams,
  content: Element[] | NodeListOf<ChildNode>
) => Element = (parentElement, elementParams, content) => {
  const element = createElement(elementParams);
  element.replaceChildren(...Array.from(content));

  updateLinkElementStyle(element);
  updateColoredTextElementStyle(element as HTMLElement);

  parentElement.replaceChildren(element);
  return parentElement;
};

const addStyledElementsInAllChildren: (
  element: Element,
  elementParams: ElementParams
) => void = (element, elementParams) => {
  const { childNodes, children } = element;

  if (hasTextNodes(element)) {
    insertContentIntoStyledElement(element, elementParams, childNodes);

    return;
  }

  Array.from(children).forEach((item) => {
    addStyledElementsInAllChildren(item, elementParams);
  });
};

export const isRangeEdgesInsideStyledElements: (
  range: Range,
  elementParams: ElementParams
) => boolean = (range, elementParams) => {
  const parentElement = getFirstParentElement(range.commonAncestorContainer);

  if (!parentElement) return false;

  const rangeContents = range.cloneContents();

  if (!rangeContents.firstChild || !rangeContents.lastChild) return false;

  const closestFirstChildStyledParentElement = findClosestStyledParent(
    range.startContainer,
    elementParams
  );
  const closestLastChildStyledParentElement = findClosestStyledParent(
    range.endContainer,
    elementParams
  );

  const isRangeStartInsideStyledParent =
    !!closestFirstChildStyledParentElement &&
    !isTextContentEqual(
      closestFirstChildStyledParentElement,
      rangeContents.firstChild
    );

  const isRangeEndInsideStyledParent =
    !!closestLastChildStyledParentElement &&
    !isTextContentEqual(
      closestLastChildStyledParentElement,
      rangeContents.lastChild
    );

  return isRangeStartInsideStyledParent || isRangeEndInsideStyledParent;
};

const styleRangeEdges: (
  element: Element,
  index: number,
  elementParams: ElementParams
) => Element | null = (element, index, elementParams) => {
  const document = getEditorIframeDocument();
  if (!document) return null;

  const documentElement = document.getElementById(element.id);

  const isRangeEdgeContainerSelectedPartially =
    documentElement && element.textContent;
  const isElementAtRangeStart = !index;

  if (!isRangeEdgeContainerSelectedPartially) return null;

  const styledElementContent = createElement(elementParams);

  styledElementContent.replaceChildren(...Array.from(element.childNodes));

  updateLinkElementStyle(styledElementContent);
  updateColoredTextElementStyle(styledElementContent as HTMLElement);

  if (isElementAtRangeStart) {
    documentElement.prepend(styledElementContent);
  } else {
    documentElement.append(styledElementContent);
  }

  return styledElementContent;
};

const addStyledElementsInRangeNestedElements: (
  range: Range,
  elementParams: ElementParams
) => Array<Element> = (range, elementParams) => {
  const styledElementSelector = getStyledElementSelector(elementParams);
  const rangeContents = normalizeElement(range.extractContents());

  removeAllInnerStyledElements(rangeContents, styledElementSelector);
  if (elementParams.removeLink) removeLinkStyles(rangeContents);

  const rangeContentsChildren = Array.from(rangeContents.children);

  const rangeEdgesElements: Array<Element> = [];

  rangeContentsChildren.reverse().forEach((item, index, array) => {
    const isFirstOrLastChild = index === 0 || index === array.length - 1;

    if (isFirstOrLastChild) {
      const styledEdge = styleRangeEdges(item, index, elementParams);
      if (styledEdge) rangeEdgesElements.push(styledEdge);

      return;
    }

    addStyledElementsInAllChildren(item, elementParams);
    range.insertNode(item);
  });

  return rangeEdgesElements;
};

const hasRangeNestedNotStyledElements: (range: Range) => boolean = (range) => {
  const rangeContents = range.cloneContents();

  const nestedNotStyledElements = rangeContents.querySelectorAll(
    ':not([custom-type]):not(br)'
  );

  return !!nestedNotStyledElements.length;
};

export const replaceSelectedTextWithElement: (
  elementParams: ElementParams,
  editor?: grapesjs.Editor,
  component?: grapesjs.Component
) =>
  | Element
  | Element[]
  | RemovedStyledElementSiblings
  | RangeInParentElement
  | undefined
  | null = (elementParams, editor, component) => {
  const selection = getSelection();

  if (!selection?.rangeCount) return;

  const range = selection?.getRangeAt(0);

  if (!range) return;

  const parentElement = getFirstParentElement(range.commonAncestorContainer);

  if (
    !parentElement ||
    !range.startContainer.parentElement ||
    !range.endContainer.parentElement
  )
    return;

  const closestStyledParentElement = findClosestStyledParent(
    range.commonAncestorContainer,
    elementParams
  );

  const rangeContents = range.cloneContents();

  const isChildrenStyled = isRangeChildrenStyled(range, elementParams);
  const isParentElementStyled = !!closestStyledParentElement;
  const isRangeParentElementsStyled =
    isRangeEdgesInsideStyledElements(range, elementParams) && isChildrenStyled;

  const hasRangeNestedElements = hasRangeNestedNotStyledElements(range);
  const hasStyledParentOnlyRangeContent =
    isParentElementStyled &&
    isTextContentEqual(closestStyledParentElement, rangeContents);

  const isStyled = isChildrenStyled || isParentElementStyled;

  const isRemoveLinkType = elementParams.removeLink;
  const isRemovable =
    (elementParams.type !== linkDefinition.type &&
      elementParams.type !== letterSpacingDefinition.type &&
      elementParams.type !== highlightTextDefinition.type &&
      elementParams.type !== coloredTextDefinition.type) ||
    isRemoveLinkType;

  const isNestedElementsStylesRemoved =
    !isRangeParentElementsStyled &&
    isRemovable &&
    (isChildrenStyled || hasStyledParentOnlyRangeContent || isRemoveLinkType);

  if (
    hasRangeNestedElements &&
    (!isRangeParentElementsStyled || !isRemovable)
  ) {
    if (isNestedElementsStylesRemoved) {
      removeStyledElementsInRangeParent(range, elementParams, component);
      normalizeElement(parentElement);

      return;
    }

    const rangeEdgesElements = addStyledElementsInRangeNestedElements(
      range,
      elementParams
    );
    normalizeElement(parentElement);

    // eslint-disable-next-line consistent-return
    return rangeEdgesElements;
  }

  if (isRemoveLinkType || (isStyled && isRemovable)) {
    if (hasStyledParentOnlyRangeContent) {
      removeLinkStyles(closestStyledParentElement);
      const newContent = replaceElementWithItsContent(
        closestStyledParentElement,
        component
      );

      // eslint-disable-next-line consistent-return
      return newContent;
    }

    if (
      !isRangeParentElementsStyled &&
      (isChildrenStyled || isRemoveLinkType)
    ) {
      removeStyledElementsInRangeParent(range, elementParams, component);
      normalizeElement(parentElement);

      return;
    }

    const element = removeStylesFromTextInStyledElement(range, elementParams);
    normalizeElement(parentElement);

    // eslint-disable-next-line consistent-return
    return element;
  }

  const elementInRange = createElementInRange(range, elementParams, editor);

  if (!elementInRange) return;

  const { element } = elementInRange;

  updateLinkElementStyle(element);
  updateColoredTextElementStyle(element as HTMLElement);

  // eslint-disable-next-line consistent-return
  return element;
};

export const formatStyleValue = (style: string, divider: string) =>
  style?.split(divider)[0];

export const getComponentText = (component: grapesjs.Component) =>
  component.attributes.attributes?.buttonText || component.getEl()?.innerText;

export const setAppliedStylesState = (
  component: grapesjs.Component,
  setStyles: Dispatch<SetStateAction<TextStylesState>>,
  stylesSettings: string[]
) => {
  const attributes = component.getAttributes();

  stylesSettings.forEach((id) => {
    const state = attributes[id];

    setStyles((prevState) => ({ ...prevState, [id]: state }));
  });
};

export const getDocumentComponentElement: (
  component: grapesjs.Component
) => Element | null = (component) => {
  const document = getEditorIframeDocument();
  if (!document) return null;

  const componentId = component.getId();

  const element = document.getElementById(componentId);

  return element;
};

export const updateComponent = (
  component: grapesjs.Component,
  editor?: grapesjs.Editor
) => {
  const cssRules = editor?.Css?.getRules();

  const componentElement = getDocumentComponentElement(component);
  if (!componentElement) return;

  const normalizedElement = normalizeElement(componentElement) as Element;

  component.components(normalizedElement.innerHTML);

  if (cssRules) {
    cssRules?.forEach((rule) => {
      editor?.Css?.addRules(rule.toCSS());
    });
  }
};

export const replaceTextNodeWithElement: (node: Node) => Element = (node) => {
  const element = createElement({ tagName: 'span' });
  element.append(node.cloneNode());

  node.parentElement?.insertBefore(element, node);
  // eslint-disable-next-line no-param-reassign
  node.textContent = '';

  return element;
};

export const setSelectionAtElements = (
  elements:
    | Element
    | Element[]
    | RemovedStyledElementSiblings
    | RangeInParentElement
) => {
  const document = getEditorIframeDocument();
  const selection = getSelection();

  if (!document) return;

  const newRange = document.createRange();

  if (elements instanceof Array) {
    if (elements.length === 1) {
      const documentElement = document.getElementById(elements[0].id);

      if (!documentElement) return;
      newRange.selectNode(documentElement);
      selection?.removeAllRanges();
      selection?.addRange(newRange);
      return;
    }

    const startContainer = document.getElementById(elements[1].id);
    const nextContainer = document.getElementById(elements[0].id);

    if (!startContainer || !nextContainer) return;

    if (startContainer.previousSibling) {
      newRange.setStartAfter(startContainer.previousSibling);
    } else {
      newRange.setStartBefore(startContainer);
    }

    if (nextContainer.nextSibling) {
      newRange.setEndBefore(nextContainer.nextSibling);
    } else {
      newRange.setEndAfter(nextContainer);
    }

    selection?.removeAllRanges();
    selection?.addRange(newRange);
    return;
  }

  if ('type' in elements && elements.type === 'RangeInParentElement') {
    const {
      parentElement,
      startOffset,
      endOffset,
      startContainer,
      endContainer,
    } = elements as RangeInParentElement;

    const documentParentElement = document.getElementById(parentElement.id);

    if (!documentParentElement) return;

    if (startOffset && endOffset && startContainer && endContainer) {
      const documentStartContainer = document.getElementById(startContainer.id);
      const documentEndContainer = document.getElementById(endContainer.id);

      if (
        !documentStartContainer?.firstChild ||
        !documentEndContainer?.firstChild
      )
        return;
    } else {
      newRange.selectNodeContents(documentParentElement);
    }

    selection?.removeAllRanges();
    selection?.addRange(newRange);
    return;
  }

  if ('type' in elements && elements.type === 'RemovedStyledElementSiblings') {
    const previousSiblingId = (elements as RemovedStyledElementSiblings)
      .previousSibling?.id;

    if (!previousSiblingId) return;

    const documentPreviousSiblingElement =
      document.getElementById(previousSiblingId);

    if (
      !documentPreviousSiblingElement ||
      !documentPreviousSiblingElement.nextSibling
    )
      return;

    newRange.selectNode(documentPreviousSiblingElement.nextSibling);
    selection?.removeAllRanges();
    selection?.addRange(newRange);
    return;
  }

  const documentElement = document.getElementById((elements as Element).id);

  if (!documentElement) return;

  newRange.selectNode(documentElement);

  selection?.removeAllRanges();
  selection?.addRange(newRange);
};

export const addTextColor = (component: grapesjs.Component, color: string) => {
  const elementParams = getColoredTextDefinition(color);

  const elements = replaceSelectedTextWithElement(
    elementParams,
    undefined,
    component
  );

  if (!elements) return;
  setSelectionAtElements(elements);
};

export const handleTextAlignSettingClick: TextStyleSettingClickHandler =
  (component, setStyles) => (textAlign: TextAlignOptions) => {
    component.addAttributes({ textAlign });
    component.addStyle({ 'text-align': textAlign });
    setStyles((prevState) => ({ ...prevState, textAlign }));
  };

export const handleTextFormatFontWeightChange = (
  component: grapesjs.Component
) => {
  component.components().map((item) => {
    const el = `<b custom-type="bold">${item.toHTML()}</b>`;

    return item.replaceWith(el);
  });

  component.addStyle({ 'font-weight': '400' });
};
