import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ImagePreview, InputWithTitle } from '@molecules';
import { useDebounce } from '@hooks';
import { generalAsyncThunks, generalSelectors } from '@store/features/general';
import { useAppDispatch, useAppSelector } from '@store/hooks';

interface Props {
  image: File | null;
  onChange: (file: File | null) => void;
}

const UploadImageFromUrl = ({ image, onChange }: Props) => {
  const isLoading = useAppSelector(generalSelectors.isGetImageFileByUrlLoading);
  const { control } = useFormContext();

  const imageUrl = useDebounce(useWatch({ name: 'imageUrl' }), 1000);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!imageUrl) {
      onChange(null);
      return;
    }

    dispatch(generalAsyncThunks.getImageFileByUrl({ url: imageUrl }))
      .unwrap()
      .then((res) => {
        onChange(res);
      })
      .catch(() => toast.error('Unable to get image by given URL'));
  }, [imageUrl]);

  return (
    <ImagePreview image={image} isLoading={isLoading}>
      <InputWithTitle
        name="imageUrl"
        title="Image URL"
        control={control}
        isHintTextHidden
      />
      <InputWithTitle
        name="altText"
        title="Alternative image text"
        control={control}
        hintText="This text will appear when the image fails to load"
      />
    </ImagePreview>
  );
};

export default UploadImageFromUrl;
