import { Link } from 'react-router-dom';

import { Typography } from '@atoms';

import exampleImageSrc from '../../../assets/images/CsvExample.png';

import './styles.css';

const CsvDropzoneContent = () => (
  <div className="CsvDropzoneContent">
    <div className="CsvDropzoneContent-Text">
      <Typography variant="subtitle4">
        The columns in your CSV file should be structured as below. Please
        ensure the naming of your column labels matches our example.
      </Typography>
    </div>
    <div className="CsvDropZoneContent-Example">
      <img src={exampleImageSrc} alt="Csv-example" height="114" />
    </div>
    <Typography variant="subtitle4">
      <Link
        className="text-primary-600"
        to="/files/contacts-template.csv"
        target="_blank"
        download
      >
        Download
      </Link>
      &nbsp; our CSV file for reference.
    </Typography>
  </div>
);

export default CsvDropzoneContent;
