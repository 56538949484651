/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { BackgroundState } from '../background/slice';

interface State {
  gjs: string;
  editorHeight: number;
  background: BackgroundState;
}

interface CurrentState extends State {
  id: number;
}

interface InitialState {
  isTemplateAutoSaving: boolean;
  previousStates: State[];
  nextStates: State[];
  currentState: CurrentState;
  isApplyCancelDisabled: boolean;
}

const initialState: InitialState = {
  isTemplateAutoSaving: false,
  previousStates: [],
  nextStates: [],
  currentState: {
    gjs: '',
    id: -1,
    background: {
      color: '',
      imageUrl: '',
      isImagePattern: false,
    },
    editorHeight: 500,
  },
  isApplyCancelDisabled: true,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setIsApplyCancelDisabled(state, { payload }) {
      state.isApplyCancelDisabled = payload;
    },
    setCurrentStateId(state, { payload }) {
      state.currentState.id = payload;
    },
    setInitialState(state, { payload }) {
      state.currentState.gjs = payload.gjs;
      state.currentState.id = payload.id;
    },
    setCurrentStateGjs(state, { payload }) {
      if (payload !== state.currentState.gjs) {
        state.currentState.gjs = payload;
      }
    },
    setCurrentStateBackground(state, { payload }) {
      state.currentState.background = payload;
    },
    resetCurrentStateBackground(state) {
      state.currentState.background = initialState.currentState.background;
    },
    resetCurrentState(state) {
      state.currentState = initialState.currentState;
    },
    setCurrentStateEditorHeight(state, { payload }) {
      if (!payload) return;
      state.currentState.editorHeight = payload;
    },
    clearPreviousStates(state) {
      state.previousStates = [];
    },
    clearNextStates(state) {
      state.nextStates = [];
    },
    addPreviousState(state, { payload }) {
      if (
        payload?.gjs &&
        payload.gjs !==
          state.previousStates[state.previousStates.length - 1]?.gjs
      ) {
        state.previousStates.push(payload);
      }
    },
    addNextState(state, { payload }) {
      state.nextStates.unshift(payload);
    },
    popPreviousState(state) {
      const arr = state.previousStates;
      arr.pop();

      state.previousStates = arr;
    },
    shiftPreviousState(state) {
      const arr = state.previousStates;
      arr.shift();

      state.previousStates = arr;
    },
    shiftNextState(state) {
      const arr = state.nextStates;
      arr.shift();

      state.nextStates = arr;
    },
    popNextState(state) {
      const arr = state.nextStates;
      arr.pop();

      state.nextStates = arr;
    },
    setIsTemplateAutoSaving(state, { payload }) {
      state.isTemplateAutoSaving = payload;
    },
    resetState() {
      return initialState;
    },
  },
});

export default editorSlice.reducer;

export const editorActions = editorSlice.actions;
