import { Icon, Typography } from '@atoms';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';

import './styles.css';

interface Props {
  title: string;
  subtitle: [string, string];
}

const BlockEmptyState = ({ title, subtitle }: Props) => (
  <div className="EcommerceLayoutEmptyState bg-white flexbox-column flexbox-gap-16px flexbox-jc-center flexbox-ai-center">
    <Typography variant="subtitle3" weight="medium">
      {title}
    </Typography>
    <div className="EcommerceLayoutEmptyStateSubtitle flexbox-column">
      <Typography variant="subtitle3" weight="medium">
        {subtitle[0]}
      </Typography>
      <div className="flexbox-row flexbox-gap-10px flexbox-jc-center flexbox-ai-center">
        <Icon SVG={ArrowLeftIcon} />
        <Typography variant="subtitle3" weight="medium">
          {subtitle[1]}
        </Typography>
      </div>
    </div>
  </div>
);

export default BlockEmptyState;
