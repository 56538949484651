import { ReactNode } from 'react';

import './styles.css';

interface Props {
  src?: string;
  children?: ReactNode;
}

const Avatar = ({ src, children }: Props) => (
  <div className="AvatarWrapper">
    {children ? (
      <div className="AvatarLetters">{children}</div>
    ) : (
      <img src={src} className="AvatarImage" alt="User avatar" />
    )}
  </div>
);

export default Avatar;
