import { createContext, useContext } from 'react';
import grapesjs from 'grapesjs';

const EditorContext = createContext<grapesjs.Editor | undefined>(undefined);

export const useEditor = () => {
  const editor = useContext(EditorContext);

  return editor;
};

export default EditorContext;
