import { To } from 'react-router-dom';

import { Typography } from '@atoms';

import Link from '../Link/Link';

import './styles.css';

interface Props {
  text: string;
  link: string;
  to?: To;
  onClick?: () => void;
}
const RedirectLabel = ({ text, link, to = '', onClick }: Props) => (
  <div className="RedirectLabel">
    <Typography variant="subtitle3">{text}</Typography>
    &nbsp;
    <Link text={link} to={to} onClick={onClick} />
  </div>
);

export default RedirectLabel;
