import { MouseEventHandler, ReactNode, useEffect } from 'react';

import {
  EditorSidebarSettings,
  GenerateAISettings,
  Header,
  Sidebar,
} from '@organisms';
import { EDITOR_SIDEBAR_TABS } from '@constants/common';
import { EditorSidebarTab } from '@devTypes';
import { useEditor } from '@hooks';
import { canvasActions, canvasSelectors } from '@store/features/canvas';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import './styles.css';

interface Props {
  children: ReactNode;
  onPreviewClick: MouseEventHandler;
  onSaveClick: MouseEventHandler;
  onGoBackClick: MouseEventHandler;
  isEditorSidebarSettingsOpen: boolean;
  onEditorSidebarSettingsClose: () => void;
}

const EditorTemplate = ({
  children,
  onPreviewClick,
  onSaveClick,
  onGoBackClick,
  isEditorSidebarSettingsOpen,
  onEditorSidebarSettingsClose,
}: Props) => {
  const selectedTab = useAppSelector(canvasSelectors.editorSelectedTab);
  const dispatch = useAppDispatch();
  const editor = useEditor();

  useEffect(() => {
    dispatch(canvasActions.setEditorSelectedTab(EDITOR_SIDEBAR_TABS.addBlock));
  }, []);

  const handleTabChange = (tab: EditorSidebarTab) => {
    dispatch(canvasActions.setEditorSelectedTab(tab));
    editor?.select(undefined);
  };

  return (
    <>
      <Header onPreviewClick={onPreviewClick} onSaveClick={onSaveClick} />
      <div className="EditorScreen-Content">
        {isEditorSidebarSettingsOpen && (
          <EditorSidebarSettings
            onClose={onEditorSidebarSettingsClose}
            content={
              <GenerateAISettings onClose={onEditorSidebarSettingsClose} />
            }
          />
        )}
        <Sidebar
          items={Object.values(EDITOR_SIDEBAR_TABS)}
          value={selectedTab}
          onChange={handleTabChange}
          onGoBackClick={onGoBackClick}
        />
        {children}
      </div>
    </>
  );
};

export default EditorTemplate;
