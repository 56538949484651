/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { generateAIThunk } from '@store/features/generativeAI/asyncThunks';

interface State {
  id: string;
  text: string;
}

interface CurrentState extends State {
  index: number;
}

interface InitialState {
  isLoading: boolean;
  currentState: {
    input: CurrentState;
    outputForPrompt: CurrentState;
    outputForRewrite: CurrentState;
    prompt: CurrentState;
  };
  states: {
    input: State[];
    outputForPrompt: State[];
    outputForRewrite: State[];
    prompt: State[];
  };
}

const initialState: InitialState = {
  isLoading: false,
  currentState: {
    input: { index: 0, id: '', text: '' },
    outputForPrompt: { index: 0, id: '', text: '' },
    outputForRewrite: { index: 0, id: '', text: '' },
    prompt: { index: 0, id: '', text: '' },
  },
  states: {
    input: [],
    outputForRewrite: [],
    outputForPrompt: [],
    prompt: [],
  },
};

type StateType = keyof InitialState['currentState'];

const generativeAISlice = createSlice({
  name: 'generativeAI',
  initialState,
  reducers: {
    setCurrentState(
      state,
      { payload: { input, outputForPrompt, outputForRewrite, prompt } }
    ) {
      if (input) state.currentState.input = input;
      if (prompt) state.currentState.prompt = prompt;
      if (outputForRewrite)
        state.currentState.outputForRewrite = outputForRewrite;
      if (outputForPrompt) state.currentState.outputForPrompt = outputForPrompt;
    },
    setStates(
      { states },
      {
        payload: { type, state },
      }: { payload: { type: StateType; state: State } }
    ) {
      states[type] = [state];
    },
    addState(
      { states },
      {
        payload: { type, state },
      }: { payload: { type: StateType; state: State } }
    ) {
      states[type].push(state);
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateAIThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(generateAIThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
  },
});

export default generativeAISlice.reducer;

export const generativeAIActions = generativeAISlice.actions;
