/* eslint-disable jsx-a11y/iframe-has-title */
import './styles.css';

interface Props {
  content: string;
}

const MobilePreview = ({ content }: Props) => (
  <div className="MobilePreview">
    <div className="MobilePreview-Header">
      <div className="MobilePreview-Dot" />
      <div className="MobilePreview-Elipse" />
    </div>
    <div className="MobilePreview-Content">
      <iframe
        srcDoc={content}
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-presentation allow-top-navigation"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    </div>
    <div className="MobilePreview-Footer" />
  </div>
);

export default MobilePreview;
