import { useState } from 'react';

import { Typography } from '@atoms';
import { SliderWithInput } from '@molecules';
import { ReactComponent as HorizontalSpaceIcon } from '@assets/icons/horizontal-space.svg';
import { ReactComponent as VerticalSpaceIcon } from '@assets/icons/vertical-space.svg';
import {
  MAX_COLUMNS_HORIZONTAL_SPACE_IN_PX,
  MAX_COLUMNS_VERTICAL_SPACE_IN_PX,
} from '@constants/editor';
import { EditorBlockSettingsComponent } from '@devTypes';
import { columnsComponentController } from '@utils/editor/components/columns';

const EditorColumnsBlockSpacingSettings = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => {
  const [horizontalSpace, setHorizontalSpace] = useState(
    columnsComponentController.getColumnsHorizontalSpace(selectedComponent) ?? 0
  );
  const [verticalSpace, setVerticalSpace] = useState(
    columnsComponentController.getColumnsVerticalSpace(selectedComponent) ?? 0
  );

  const handleHorizontalSpaceChange = (newSpace: number) => {
    setHorizontalSpace(newSpace);

    columnsComponentController.setColumnsHorizontalSpace(
      selectedComponent,
      newSpace
    );
  };

  const handleVerticalSpaceChange = (newSpace: number) => {
    setVerticalSpace(newSpace);

    columnsComponentController.setColumnsVerticalSpace(
      selectedComponent,
      newSpace
    );
  };

  return (
    <>
      <Typography
        className="EditorColumnsBlockSettingsDesign-ColumnSpacingTitle"
        variant="subtitle5"
      >
        Column spacing
      </Typography>
      <SliderWithInput
        title="Horizontal"
        startIcon={HorizontalSpaceIcon}
        max={MAX_COLUMNS_HORIZONTAL_SPACE_IN_PX}
        value={horizontalSpace}
        onChange={handleHorizontalSpaceChange}
      />
      <SliderWithInput
        title="Vertical"
        startIcon={VerticalSpaceIcon}
        max={MAX_COLUMNS_VERTICAL_SPACE_IN_PX}
        value={verticalSpace}
        onChange={handleVerticalSpaceChange}
      />
    </>
  );
};

export default EditorColumnsBlockSpacingSettings;
