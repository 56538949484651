import { Button, Divider, Icon, Typography } from '@atoms';
import { Select } from '@molecules';
import { ReactComponent as DeleteIcon } from '@assets/icons/trash.svg';

type Action = {
  title: string;
  onClick: () => void;
};

interface Props {
  selectedItemsAmount: number;
  onDeleteButtonClick: () => void;
  actions: Action[];
}

const renderSelectedContactsAmountLabel = (amount: number) => {
  const isPlural = amount > 1;

  return `${amount} contact${isPlural ? 's' : ''} selected`;
};

const SelectedContactsToolbar = ({
  selectedItemsAmount,
  onDeleteButtonClick,
  actions,
}: Props) => (
  <div className="SelectedContactsToolbar">
    <Select
      value={null}
      options={actions}
      placeholder="Actions"
      className="ActionsSelect"
    />
    <Divider variant="vertical" />
    <Button
      title="Delete"
      variant="secondary"
      startIcon={<Icon SVG={DeleteIcon} height={20} width={20} />}
      onClick={onDeleteButtonClick}
    />
    <Typography variant="subtitle4">
      {renderSelectedContactsAmountLabel(selectedItemsAmount)}
    </Typography>
  </div>
);

export default SelectedContactsToolbar;
