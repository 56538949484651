import './styles.css';

interface Props {
  value: number;
}

const Progress = ({ value }: Props) => (
  <div className="Progress">
    <div className="Progress-track">
      <div className="Progress-line" style={{ width: `${value}%` }} />
    </div>
  </div>
);

export default Progress;
