import clsx from 'clsx';
import grapesjs from 'grapesjs';

import { Typography } from '@atoms';
import { EDITOR_SIDEBAR_TABS } from '@constants/common';
import Background from '@organisms/Background/Background';
import BlockManagerPanel from '@organisms/BlockManagerPanel/BlockManagerPanel';
import EditorBlockSettings from '@organisms/EditorBlockSettings/EditorBlockSettings';
import MiniApps from '@organisms/MiniApps/MiniApps';
import { campaignsSelectors } from '@store/features/campaigns';
import { canvasSelectors } from '@store/features/canvas';
import { useAppSelector } from '@store/hooks';

import './styles.css';

interface Props {
  editor: grapesjs.Editor | undefined;
  selectedComponent: grapesjs.Component | null;
}

const EditorPanel = ({ editor, selectedComponent }: Props) => {
  const selectedSidebarTab = useAppSelector(canvasSelectors.editorSelectedTab);
  const campaignObject = useAppSelector(campaignsSelectors.campaignObject);

  const currentCampaignTitle =
    campaignObject?.campaign?.title ?? 'My marketing campaign';

  return (
    <div className="EditorPanel" id="EditorPanel">
      <div className="EditorPanelHeader">
        <Typography variant="subtitle6">{currentCampaignTitle}</Typography>
      </div>
      <EditorBlockSettings
        selectedComponent={selectedComponent}
        editor={editor}
      />
      <div className={clsx(selectedComponent !== null && 'display-hidden')}>
        <div className="EditorPanelContent">
          <div
            className={clsx(
              selectedSidebarTab?.id !== EDITOR_SIDEBAR_TABS.addBlock.id &&
                'display-hidden'
            )}
          >
            <BlockManagerPanel />
          </div>
          <div
            className={clsx(
              selectedSidebarTab?.id !== EDITOR_SIDEBAR_TABS.miniApps.id &&
                'display-hidden'
            )}
          >
            <MiniApps editor={editor} />
          </div>
          <div
            className={clsx(
              selectedSidebarTab?.id !== EDITOR_SIDEBAR_TABS.background.id &&
                'display-hidden'
            )}
          >
            <Background />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorPanel;
