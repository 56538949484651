import { ReactNode } from 'react';
import clsx from 'clsx';

import { Icon } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';

export type Tab = {
  id: number;
  icon: SvgFunctionComponent;
  title: string;
  renderDevicePreview: (content: string) => ReactNode;
};

interface Props {
  tab: Tab;
  isSelected: boolean;
  onChange: (tab: Tab) => void;
  className?: string;
}

const DeviceViewTab = ({ tab, isSelected, onChange, className }: Props) => (
  <div
    role="tab"
    tabIndex={0}
    className={clsx(
      'DeviceViewTab',
      isSelected && 'DeviceViewTab-selected',
      className
    )}
    onClick={() => onChange(tab)}
  >
    <Icon SVG={tab.icon} height={20} width={20} />
  </div>
);

export default DeviceViewTab;
