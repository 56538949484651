import { Checkbox, Typography } from '@atoms';

interface Props {
  title: string;
  isChecked: boolean;
  onCheckboxChange: () => void;
}

const WritingStylesDropdownOption = ({
  title,
  isChecked,
  onCheckboxChange,
}: Props) => (
  <div className="WritingStylesDropdownOption">
    <Typography variant="subtitle4">{title}</Typography>
    <Checkbox isChecked={isChecked} onChange={onCheckboxChange} />
  </div>
);

export default WritingStylesDropdownOption;
