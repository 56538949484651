import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import { TemplatesPageFrom } from '@constants/template';
import { TemplateObject } from '@devTypes';
import { usePresetTemplate } from '@hooks';
import { templateSelectors } from '@store/features/templates';
import { fetchPresetTemplatesThunk } from '@store/features/templates/asyncThunks';
import { usersAsyncThunks, usersSelectors } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import SelectTemplateView from '@views/SelectTemplateView/SelectTemplateView';

interface TemplatesPageProps {
  from: TemplatesPageFrom;
}

const TemplatesPage = ({ from }: TemplatesPageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const integrations = useAppSelector(usersSelectors.userIntegrations);
  const { templates, isLoading } = useSelector(
    templateSelectors.presetTemplates
  );
  const { state } = useLocation();
  const campaignId = state?.campaignId;

  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateObject | null>(null);
  const [isNoShopifyModalOpen, setIsNoShopifyModalOpen] = useState(false);

  const onUseTemplate = usePresetTemplate(campaignId);

  useEffect(() => {
    dispatch(fetchPresetTemplatesThunk({}));
    dispatch(usersAsyncThunks.getUserThunk(null));
  }, []);

  const onPreviewClick = (item: TemplateObject) => {
    navigate(`${ROUTES.template.preview}/${item.id}`, {
      state: {
        selectedTemplateItem: item,
        isSendTestEmailDisabled: !campaignId,
        isPreset: true,
      },
    });
  };

  const navigateToIntegrationsPage = () => {
    navigate(ROUTES.dashboard.integrations, {
      state: {
        selectedPresetTemplate: selectedTemplate,
        campaignId,
      },
    });
  };

  const handleNoShopifyModalClose = () => setIsNoShopifyModalOpen(false);
  const handleNoShopifyModalOpen = () => setIsNoShopifyModalOpen(true);

  const handleDisabledUseTemplateClick = (item: TemplateObject) => {
    handleNoShopifyModalOpen();
    setSelectedTemplate(item);
  };

  const isUseTemplatesDisabled = !integrations?.shopify;

  return (
    <SelectTemplateView
      templates={templates}
      onPreviewClick={onPreviewClick}
      onUseTemplateClick={
        isUseTemplatesDisabled ? handleDisabledUseTemplateClick : onUseTemplate
      }
      hasBackButton={from === TemplatesPageFrom.Editor}
      isNoShopifyModalOpen={isNoShopifyModalOpen}
      onNoShopifyModalClose={handleNoShopifyModalClose}
      onShopifyConnect={navigateToIntegrationsPage}
      isLoading={isLoading}
    />
  );
};

export default TemplatesPage;
