/* eslint-disable jsx-a11y/iframe-has-title */

import './styles.css';

interface Props {
  content: string;
}

const DesktopPreview = ({ content }: Props) => (
  // This uses to display the desktop preview
  // even if iframe width is less than 700px
  // const desktopContent = useMemo(
  //   () =>
  //     content?.replace(
  //       /@media\s*?\(.*?max-width\s*:\s*(\d+.*?)\)/,
  //       '@media (min-width: $1)'
  //     ),
  //   [content]
  // );

  <div className="DesktopPreview">
    <div className="DesktopPreview-Header">
      <div className="DesktopPreview-Dot" />
      <div className="DesktopPreview-Dot" />
      <div className="DesktopPreview-Dot" />
    </div>
    <div className="DesktopPreview-Content">
      <iframe
        srcDoc={content}
        sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-presentation allow-top-navigation"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />
    </div>
  </div>
);
export default DesktopPreview;
