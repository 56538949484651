import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Typography } from '@atoms';
import { ChangeCampaignNameModal, ConfirmModal, Table } from '@organisms';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/editor-duplicate.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/editor-trash.svg';
import { RECENT_CAMPAIGNS_TABLE_ROWS_AMOUNT } from '@constants/common';
import { ROUTES } from '@constants/routes';
import { RowData } from '@organisms/Table/TableRow';
import { campaignsAsyncThunks } from '@store/features/campaigns';
import { getCampaignByIdThunk } from '@store/features/campaigns/asyncThunks';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';

import './styles.css';

const headerCells = ['Campaign name', 'Status', 'Open rate', 'Click rate'];

interface ICampaignsTemplateProps {
  campaigns: any;
  isCampaignsLoading: boolean;
}

const CampaignsTemplate = ({
  campaigns,
  isCampaignsLoading,
}: ICampaignsTemplateProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [rowItemWithOpenedMoreOptions, setRowItemWithOpenedMoreOptions] =
    useState<RowData>();
  const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] =
    useState(false);
  const [isChangeCampaignNameModalOpen, setIsChangeCampaignNameModalOpen] =
    useState(false);

  // TODO: try to make CampaignsTable reusable because a lot of code is duplicated
  const renderTableRow = ({
    title,
    status,
    openRate,
    clickRate,
  }: {
    [key: string]: string;
  }) => [title, status, openRate, clickRate];

  const fetchCampaigns = () => {
    dispatch(
      campaignsAsyncThunks.getCampaignsThunk({
        offset: 0,
        limit: RECENT_CAMPAIGNS_TABLE_ROWS_AMOUNT,
        sortBy: 'createdAt',
        order: 'DESC',
      })
    );
  };

  const onTableRowClick = async (item: any) => {
    if (item.status === 'Published') {
      return;
    }

    const {
      payload: { campaign },
    } = await dispatch(getCampaignByIdThunk({ id: item.id }));
    navigate(ROUTES.dashboard.createCampaign, {
      state: { selectedCampaign: campaign },
    });
  };

  const handleSeeAllClick = () => {
    navigate(ROUTES.dashboard.allcampaigns);
  };

  const onTableRowViewReportClick = (id: number) => {
    navigate(`/dashboard/campaigns/${id}/report`);
  };

  const handleMoreOptionsItemClick = (handler: any) => (rowItem: any) => {
    setRowItemWithOpenedMoreOptions(rowItem);

    handler();
  };

  const deleteCampaign = async () => {
    await dispatch(
      campaignsAsyncThunks.deleteCampaign({
        id: rowItemWithOpenedMoreOptions?.id,
      })
    );

    fetchCampaigns();
  };

  const renameCampaign = async (title: string) => {
    await dispatch(
      campaignsAsyncThunks.updateCampaignThunk({
        id: rowItemWithOpenedMoreOptions?.id,
        title,
      })
    );

    fetchCampaigns();
  };

  const duplicateCampaign = async (rowItem: any) => {
    const {
      title,
      subject,
      previewText,
      senderName,
      senderId,
      templateId,
      recipientType,
    } = rowItem;

    let templateCopyId = null;

    if (templateId) {
      const templateCopy = await dispatch(
        templateAsyncThunks.duplicateTemplateThunk({ id: templateId })
      ).unwrap();
      templateCopyId = templateCopy.id;
    }

    await dispatch(
      campaignsAsyncThunks.createCampaignThunk({
        title: `Copy of ${title}`,
        subject,
        senderName,
        previewText,
        senderId,
        templateId: templateCopyId,
        recipientType,
      })
    );

    fetchCampaigns();
  };

  return (
    <div className="CampaignsTemplate">
      <ConfirmModal
        isOpen={isDeleteCampaignModalOpen}
        onClose={() => setIsDeleteCampaignModalOpen(false)}
        onConfirm={deleteCampaign}
        title="Delete campaign"
        subtitle={
          <span className="flex flex-col gap-2">
            <span>Are you sure you want to delete the selected campaign?</span>
            <span>“Campaign for new website”</span>
          </span>
        }
      />
      <ChangeCampaignNameModal
        isOpen={isChangeCampaignNameModalOpen}
        onClose={() => setIsChangeCampaignNameModalOpen(false)}
        onConfirm={renameCampaign}
        campaignName={rowItemWithOpenedMoreOptions?.title}
      />
      {/* <div className="CampaignsTemplateContainer">
        <Typography
          variant="subtitle5"
          className="CampaignsTemplateContainer-Title"
        >
          Tips to get you started
        </Typography>
        <div className="CampaignsTemplateTips">
          <TipCard iconClassName="stroke-primary-main" icon={RocketIcon} title="Getting Started Email Marketing" />
          <TipCard iconClassName="stroke-success-main" icon={RocketIcon} title="Getting Started Email Marketing" />
          <TipCard iconClassName="stroke-warning-main" icon={RocketIcon} title="Getting Started Email Marketing" />
        </div>
      </div> */}
      <div className="CampaignsTemplateContainer">
        <div className="CampaignsTemplateContainer-TitleContainer">
          <Typography
            variant="subtitle5"
            className="CampaignsTemplateContainer-Title"
          >
            Most recent email campaigns
          </Typography>
          <Button
            title="See all campaigns"
            variant="secondary"
            size="sm"
            onClick={handleSeeAllClick}
          />
        </div>
        <Card>
          {campaigns ? (
            <Table
              data={campaigns}
              headerCells={headerCells}
              renderRowData={renderTableRow}
              onTableRowClick={onTableRowClick}
              isLoading={isCampaignsLoading}
              buttonProps={{
                isVisible: true,
                title: 'View report',
                onClick: onTableRowViewReportClick,
              }}
              moreOptionsPopup={{
                selectOptions: [
                  {
                    icon: EditIcon,
                    title: 'Rename',
                    onClick: handleMoreOptionsItemClick(() =>
                      setIsChangeCampaignNameModalOpen(true)
                    ),
                  },
                  {
                    icon: DuplicateIcon,
                    title: 'Duplicate',
                    onClick: duplicateCampaign,
                  },
                  {
                    icon: TrashIcon,
                    title: 'Delete',
                    onClick: handleMoreOptionsItemClick(() =>
                      setIsDeleteCampaignModalOpen(true)
                    ),
                  },
                ],
              }}
            />
          ) : (
            <Typography variant="subtitle4">No campaigns</Typography>
          )}
        </Card>
      </div>
    </div>
  );
};

export default CampaignsTemplate;
