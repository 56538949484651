import grapesjs from 'grapesjs';

import EditorColumnsBlockSettingsContent from './EditorColumnsBlockSettingsContent';
import EditorColumnsBlockSettingsDesign from './EditorColumnsBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorColumnsBlockSettings = ({ selectedComponent, type }: Props) => {
  const renderSettings = () => {
    if (type === 'content') {
      return (
        <EditorColumnsBlockSettingsContent
          selectedComponent={selectedComponent}
        />
      );
    }

    if (type === 'design') {
      return (
        <EditorColumnsBlockSettingsDesign
          selectedComponent={selectedComponent}
        />
      );
    }

    return null;
  };

  return <div className="EditorColumnsBlockSettings">{renderSettings()}</div>;
};

export default EditorColumnsBlockSettings;
