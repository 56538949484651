import { useState } from 'react';

import { Dropdown } from '@molecules';

import FiltersDropdownContent from './FiltersDropdownContent';

interface Props {
  isUnsubscribedContactsChecked: boolean;
  isSubscribedContactsChecked: boolean;
  onUnsubscribedContactsChange: () => void;
  onSubscribedContactsChange: () => void;
  onApplyClick: () => void;
}

const FiltersDropdown = ({
  isUnsubscribedContactsChecked,
  isSubscribedContactsChecked,
  onUnsubscribedContactsChange,
  onSubscribedContactsChange,
  onApplyClick,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownOpen = () => setIsDropdownOpen(true);
  const handleDropdownClose = () => setIsDropdownOpen(false);

  const handleApplyClick = () => {
    onApplyClick();
    handleDropdownClose();
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      onOpen={handleDropdownOpen}
      onClose={handleDropdownClose}
      placeholder="Filter"
      className="FilterSelect"
      dropdownContent={
        <FiltersDropdownContent
          isUnsubscribedContactsChecked={isUnsubscribedContactsChecked}
          isResubscribedContactsChecked={isSubscribedContactsChecked}
          onUnsubscribedContactsChange={onUnsubscribedContactsChange}
          onSubscribedContactsChange={onSubscribedContactsChange}
          onCancelClick={handleDropdownClose}
          onApplyClick={handleApplyClick}
        />
      }
    />
  );
};

export default FiltersDropdown;
