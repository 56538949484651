import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { PopupListItemComponent } from '@devTypes';

export interface Props extends PopupListItemComponent {
  selectedItem?: string;
  onClick: () => void;
}

const PopupListItem = ({ icon, title, selectedItem, onClick }: Props) => {
  const isSelected = title === selectedItem;

  return (
    <div
      className={clsx('PopupListItem', isSelected && 'PopupListItem-selected')}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      {icon && <Icon SVG={icon} height={25} width={25} />}
      <Typography variant="subtitle4" className="PopupListItemTitle">
        {title}
      </Typography>
    </div>
  );
};

export default PopupListItem;
