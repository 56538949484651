import { useEffect } from 'react';

import { ProfileView } from '@views';
import { Domain } from '@devTypes';
import { domainAsyncThunks } from '@store/features/domains';
import domainsSelector from '@store/features/domains/selectors';
import { domainActions } from '@store/features/domains/slice';
import { organizationsAsyncThunks } from '@store/features/organizations';
import { usersAsyncThunks } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const ProfilePage = () => {
  const dispatch = useAppDispatch();

  const validationResults = useAppSelector(
    domainsSelector.validationResultsSelector
  );

  useEffect(() => {
    dispatch(organizationsAsyncThunks.getMeOrganizationDataThunk(null));
    dispatch(usersAsyncThunks.getUserThunk(null));
    dispatch(domainAsyncThunks.getListOfDomainsThunk(null))
      .unwrap()
      .then((response) => {
        if (response.domains.length > 0) {
          response.domains.forEach((domain: Domain) => {
            if (domain.status === 'Failed') {
              dispatch(domainAsyncThunks.validateDomainThunk(domain.id))
                .unwrap()
                .then((results) => {
                  dispatch(
                    domainActions.setValidationResults([
                      ...validationResults,
                      {
                        domainId: domain.id,
                        validationResults: results.validationResults,
                      },
                    ])
                  );
                });
            }
          });
        }
      });
  }, []);

  return <ProfileView />;
};

export default ProfilePage;
