import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { ModalComponent } from '@devTypes';

import './styles.css';

const bodyElement = document.body;

interface Props extends ModalComponent {
  children: React.ReactNode;
  type?: 'default' | 'fullScreen' | 'fullScreen-left';
  isCrossButtonVisible?: boolean;
}

const MODAL_CONTEXT_ID = 'modal-context';

const Modal = ({
  children,
  isOpen,
  onClose,
  type,
  className,
  isCrossButtonVisible = true,
}: Props) => {
  const [context, setContext] = useState<HTMLElement>(bodyElement);

  useEffect(() => {
    if (!isOpen) {
      if (context === bodyElement) {
        return;
      }

      context.remove();
      return;
    }

    const modal = document.createElement('div');
    modal.id = MODAL_CONTEXT_ID;

    document.getElementById('root')?.appendChild(modal);

    setContext(modal);
  }, [isOpen]);

  useEffect(
    () => () => {
      const modal = document.getElementById(MODAL_CONTEXT_ID);

      if (modal) {
        modal.remove();
      }
    },
    []
  );

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div
      className={clsx(
        'Modal',
        type !== 'default' && `Modal-${type}`,
        className
      )}
    >
      {isCrossButtonVisible && <CrossIcon onClick={onClose} />}
      {children}
    </div>,
    context
  );
};

export default Modal;
