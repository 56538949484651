import { Card, Divider } from '@atoms';

import Content from './Content';
import Header from './Header';

import './styles.css';

interface ShopDetailsProps {
  onSave: () => void;
  isSaveDisabled: boolean;
}

const ShopDetails = ({ onSave, isSaveDisabled }: ShopDetailsProps) => (
  <Card className="ShopDetails">
    <Header
      buttonTitle="Save"
      title="Organization details"
      subtitle="Your organization's details for the email campaigns"
      onSave={onSave}
      isSaveDisabled={isSaveDisabled}
    />
    <Divider />
    <Content />
  </Card>
);

export default ShopDetails;
