import grapesjs from 'grapesjs';

import * as ImageIcon from '@assets/images/image.png';
import {
  DEFAULT_BACKGROUND_VALUE,
  DEFAULT_LOGO_ALIGNMENT,
  EditorComponentTypes,
  MAX_LOGO_SIZE_IN_PX,
} from '@constants/editor';

const logoPlaceholderComponent: grapesjs.ComponentDefinition = {
  tagName: 'div',
  classes: 'logo-placeholder',
  selectable: false,
  droppable: false,
  hoverable: false,
  components: [
    {
      selectable: false,
      droppable: false,
      draggable: false,
      hoverable: false,
      tagName: 'img',
      classes: 'logo-placeholder-icon',
      attributes: {
        src: ImageIcon.default,
      },
    },
    'Upload your logo',
  ],
};

const logoImageComponent = {
  tagName: 'a',
  classes: 'logo-link',
  selectable: false,
  droppable: false,
  hoverable: false,
  components: [
    {
      tagName: 'img',
      classes: 'actual-logo',
      selectable: false,
      droppable: false,
      hoverable: false,
    },
  ],
};

export const generateLogoComponent = ({
  attributes,
}: {
  attributes: Record<string, any>;
}) => ({
  tagName: 'div',
  type: EditorComponentTypes.Logo,
  classes: 'logo-wrapper',
  droppable: false,
  attributes: {
    ...attributes,
    size: MAX_LOGO_SIZE_IN_PX,
    paddingtop: 32,
    paddingbottom: 32,
    alignId: DEFAULT_LOGO_ALIGNMENT.id,
  },
  components: [logoPlaceholderComponent, logoImageComponent],

  styles: `
  .logo-wrapper {
    width: 100%;
    text-align: center;
    padding: 32px 0 32px 0;
    line-height: 0;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
  }

  .actual-logo {
    display: inline;
    width: ${MAX_LOGO_SIZE_IN_PX}px;
  }

  .logo-link {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  
  .logo-placeholder {
    display: inline-block;
    width: 220px;
    height: 56px;
    padding: 16px 26px;
    background-color: #98A2B3;
    color: white;
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  .logo-placeholder-icon {
    float: left;
    margin: 2px 8px 0 0;
    width: 18px;
    height: 18px;
  }
  `,
});
