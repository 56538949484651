import { ReactNode, useRef } from 'react';
import clsx from 'clsx';

import { SelectOptionType } from '@devTypes';
import DropdownHeader from '@molecules/Dropdown/DropdownHeader';
import { useOutsideClick } from '@utils/hooks';

import './styles.css';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  value?: SelectOptionType | null | undefined;
  placeholder?: string;
  dropdownContent?: ReactNode;
  renderDropdownHeader?: (args: any) => ReactNode | null;
  className?: string;
}

const Dropdown = ({
  isOpen,
  onOpen,
  onClose,
  placeholder,
  className,
  value,
  dropdownContent,
  renderDropdownHeader = () => null,
}: Props) => {
  const ref = useRef(null);
  useOutsideClick(ref, onClose);
  const handleHeaderClick = isOpen ? onClose : onOpen;

  return (
    <div
      className={clsx(
        'DropdownContainer',
        isOpen && 'Dropdown-open',
        className
      )}
      ref={ref}
    >
      {renderDropdownHeader({ onClick: handleHeaderClick }) || (
        <DropdownHeader
          onClick={handleHeaderClick}
          value={value}
          placeholder={placeholder}
        />
      )}
      <div className="Dropdown">{dropdownContent}</div>
    </div>
  );
};

export default Dropdown;
