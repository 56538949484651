import { ReactNode } from 'react';
import clsx from 'clsx';

import './styles.css';

interface Props {
  content: ReactNode;
  onClose: () => void;
}

const EditorSidebarSettings = ({ content, onClose }: Props) => (
  <div className={clsx('EditorSidebarSettings')}>
    {content}
    <div
      className="EditorSidebarSettings-Background"
      onClick={onClose}
      role="button"
      tabIndex={0}
      aria-label="Close Generative AI settings"
    />
  </div>
);

export default EditorSidebarSettings;
