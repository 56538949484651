import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import grapesjs from 'grapesjs';
import * as htmlToImage from 'html-to-image';

import { Loader } from '@atoms';
import { EditorView } from '@views';
import { EDITOR_CONTAINERS, TemplateContractType } from '@constants/editor';
import { ROUTES } from '@constants/routes';
import { TemplateObject } from '@devTypes';
import { EditorContext, TimeoutAutoSaveContext } from '@hooks';
import { editorActions } from '@store/features/editor';
import {
  templateAsyncThunks,
  templateSelectors,
} from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';
import { autoSaveService } from '@utils/autoSaveService';
import buildJsonTemplate from '@utils/editor/jsonBuilder';
import { getEditorHeight } from '@utils/helpers';

const generateNewPreviewImage: (
  editor: grapesjs.Editor,
  templateId: number
) => Promise<File | null> = async (editor, templateId) => {
  try {
    const selectedElement = editor?.getSelected();

    if (selectedElement) {
      editor?.selectRemove(selectedElement);
    }

    const node = editor?.Canvas.getElement();
    const fileName = `${templateId}.png`;

    if (!node) return null;

    const dataUrl = await htmlToImage.toPng(node, { cacheBust: true });
    const blob = await (await fetch(dataUrl)).blob();

    const file = new File([blob], fileName, { type: 'image/png' });

    return file;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

const EditorPage = () => {
  const [editor, setEditor] = useState<grapesjs.Editor>();

  const templateObject = useSelector(templateSelectors.templateObject);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const initiateEditor = (editorInstance: grapesjs.Editor) => {
    setEditor(editorInstance);
  };
  const templateId = Number(id) || templateObject?.id;

  const updateTemplatePreview = async (preview: File, url: string) => {
    await axios(url, {
      method: 'PUT',
      data: preview,
      headers: { 'Content-Type': '' },
    });

    const previewUrl = url.split('?')[0];

    await dispatch(
      templateAsyncThunks.updateTemplateImageURLThunk({
        id: templateId!,
        url: previewUrl,
      })
    );

    return previewUrl;
  };

  const generateBackgroundSettings = () => {
    const emptyBackgroundImage = 'background-image: url();';
    const generatedBackground =
      document
        .getElementById(EDITOR_CONTAINERS.canvasContainer) // replace needed to avoid double quotes in background image
        ?.style.cssText.replaceAll('"', '') || '';
    if (generatedBackground.indexOf(emptyBackgroundImage) !== -1) {
      return generatedBackground.replaceAll(emptyBackgroundImage, '');
    }
    return generatedBackground;
  };

  const updateTemplate = async () => {
    if (!templateId || !editor) return;

    const wrapper = editor.getWrapper();

    wrapper.addAttributes({
      editorHeight: getEditorHeight(editor),
    });

    const preview = await generateNewPreviewImage(editor, templateId);

    if (!preview) return;

    setIsLoading(true);

    const result: TemplateContractType = buildJsonTemplate(
      editor,
      generateBackgroundSettings()
    );
    const requestBody = {
      ...result,
      id: templateId,
    };
    const response = await dispatch(
      templateAsyncThunks.updateTemplateThunk(requestBody)
    );
    dispatch(editorActions.setCurrentStateGjs(response.payload.gjs));

    const {
      payload: { url },
    } = await dispatch(
      templateAsyncThunks.getPresignedUrlThunk({
        fileName: preview.name,
      })
    );

    const previewUrl = await updateTemplatePreview(preview, url);

    setIsLoading(false);

    const template: TemplateObject = {
      ...response.payload,
      backgroundStyles: result.backgroundStyles,
      previewUrl,
    };

    // eslint-disable-next-line consistent-return
    return template;
  };

  const onPreviewClick = async () => {
    if (!templateId || !editor) return;
    const wrapper = editor.getWrapper();

    wrapper.addAttributes({
      editorHeight: getEditorHeight(editor),
    });

    const result: TemplateContractType = buildJsonTemplate(
      editor,
      generateBackgroundSettings()
    );

    setIsLoading(true);

    const requestBody = {
      ...result,
      id: templateId,
    };

    const response = await dispatch(
      templateAsyncThunks.updateTemplateThunk(requestBody)
    );
    dispatch(editorActions.setCurrentStateGjs(response.payload.gjs));

    setIsLoading(false);

    navigate(`${ROUTES.template.preview}/${templateId}`);
    dispatch(editorActions.resetState());
  };

  const onSaveClick = async () => {
    setIsLoading(true);
    const template = await updateTemplate();

    navigate(ROUTES.dashboard.createCampaign, {
      state: {
        selectedTemplateItem: template,
      },
    });
    setIsLoading(false);
    dispatch(editorActions.resetState());
  };

  const handleGoBack = async () => {
    setIsLoading(true);
    const template = await updateTemplate();

    navigate(ROUTES.dashboard.createCampaign, {
      state: {
        selectedTemplateItem: template,
      },
    });
    setIsLoading(false);
    dispatch(editorActions.resetState());
    localStorage.removeItem('gjsProject');
  };

  const handleEditorChange = (e: grapesjs.Editor) => {
    setEditor(e);
  };

  return (
    <EditorContext.Provider value={editor}>
      <DndProvider backend={HTML5Backend}>
        <TimeoutAutoSaveContext.Provider value={autoSaveService}>
          <EditorView
            onPreviewClick={onPreviewClick}
            initiateEditor={initiateEditor}
            onSaveClick={onSaveClick}
            onGoBackClick={handleGoBack}
            onEditorChange={handleEditorChange}
          />
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                zIndex: 1200,
                backgroundColor: 'rgb(242, 244, 247)',
                opacity: '0.8',
                height: '100%',
                width: '100%',
              }}
            >
              <Loader />
            </div>
          )}
        </TimeoutAutoSaveContext.Provider>
      </DndProvider>
    </EditorContext.Provider>
  );
};

export default EditorPage;
