export const BLOCK_TYPES = {
  TEXT_BLOCK_TYPE: 'text',
  COLUMNS_BLOCK_TYPE: 'column',
  CELL_TYPE: 'cell',
  BUTTON_TYPE: 'button',
  DIVIDER_TYPE: 'divider',
  GJSDIVIDER_TYPE: 'gjs-divider',
  GJSBUTTON_TYPE: 'gjs-button',
  SPACER: 'spacer',
  LOGO: 'logo',
  CUSTOM_IMAGE_TYPE: 'custom-image',
  ECOMMERCE_BLOCK_TYPE: 'e-commerce',
  CAROUSEL: 'carousel',
  FOOTER: 'footer',
};
