import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import allContactsApi, {
  ChangeStatusData,
  ContactData,
  ContactDataCsv,
  DeleteRecipientsData,
  EditRecipientData,
  RecipientsData,
} from './api';

export const getRecipientsThunk = createCommonAsyncThunk<RecipientsData>(
  'getRecipients',
  allContactsApi.getRecipients
);

export const createRecipientWithFormThunk = createCommonAsyncThunk<ContactData>(
  'createRecipientWithForm',
  allContactsApi.createRecipientWithForm
);

export const createRecipientWithFileThunk =
  createCommonAsyncThunk<ContactDataCsv>(
    'createRecipientWithFile',
    allContactsApi.createRecipientsWithFile
  );

export const updateRecipientStatusThunk =
  createCommonAsyncThunk<ChangeStatusData>(
    'updateRecipientStatus',
    allContactsApi.updateRecipientStatus
  );

export const editRecipientThunk = createCommonAsyncThunk<EditRecipientData>(
  'editRecipient',
  allContactsApi.editRecipient
);

export const deleteRecipientsThunk =
  createCommonAsyncThunk<DeleteRecipientsData>(
    'deleteRecipients',
    allContactsApi.deleteRecipients
  );

export const getSpamReportsThunk = createCommonAsyncThunk(
  'spamContacts',
  allContactsApi.getSpamContacts
);

export const getBouncedContactsThunk = createCommonAsyncThunk(
  'bouncedContacts',
  allContactsApi.getBouncedContacts
);

export const getInvalidEmailThunk = createCommonAsyncThunk(
  'invalidEmails',
  allContactsApi.getInvalidEmailContacts
);

export default {
  createRecipientWithFormThunk,
  createRecipientWithFileThunk,
  updateRecipientStatusThunk,
  editRecipientThunk,
  deleteRecipientsThunk,
  getSpamReportsThunk,
  getBouncedContactsThunk,
  getInvalidEmailThunk,
};
