import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import authApi, {
  ChangePasswordData,
  CreateNewPasswordData,
  ForgotPasswordData,
  SignInData,
  SignUpData,
} from './api';

export const signInThunk = createCommonAsyncThunk<SignInData>(
  'auth/signIn',
  authApi.signIn
);

export const signUpThunk = createCommonAsyncThunk<SignUpData>(
  'auth/signUp',
  authApi.signUp
);

export const forgotPasswordThunk = createCommonAsyncThunk<ForgotPasswordData>(
  'auth/forgotPassword',
  authApi.forgotPassword
);

export const createNewPasswordThunk =
  createCommonAsyncThunk<CreateNewPasswordData>(
    'auth/createNewPassword',
    authApi.createNewPassword
  );

export const changePasswordThunk = createCommonAsyncThunk<ChangePasswordData>(
  'auth/changePassword',
  authApi.changePassword
);

export default {
  signInThunk,
  signUpThunk,
  forgotPasswordThunk,
  createNewPasswordThunk,
  changePasswordThunk,
};
