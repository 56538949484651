import { EventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { Color, ColorPicker as ReactColorPicker } from 'react-color-palette';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

import { Icon } from '@atoms';
import { ReactComponent as CheckIcon } from '@assets/icons/colorpicker-check.svg';
import { useOutsideClick } from '@utils/hooks';

import 'react-color-palette/lib/css/styles.css';
import './styles.css';

const COLOR_PICKER_HALF_HEIGHT_IN_PX = 184;
const CONTAINER_OVERLAP_OFFSET_IN_PX = 20;

export const COLOR_PICKER_PALETTE_CLASSNAME = 'rcp';
export const COLOR_PICKER_WRAPPER_CLASSNAME = 'ColorPickerPalette-Wrapper';
export const COLOR_PICKER_WRAPPER_HIDDEN_CLASSNAME = 'hidden';

interface Props {
  anchorEl: ReactNode;
  isOpen: boolean;
  onClose: EventHandler<any>;
  color: Color;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  onChange?: (color: string) => void;
  className?: string;
}

const calculateColorPickerPosition = (element: HTMLDivElement | null) => {
  if (!element) return { top: 0, left: 0 };
  const { x, y } = element.getBoundingClientRect();

  return {
    top: y - COLOR_PICKER_HALF_HEIGHT_IN_PX,
    left: x + element.clientWidth - CONTAINER_OVERLAP_OFFSET_IN_PX,
  };
};

const ColorPicker = ({
  anchorEl,
  isOpen,
  onClose,
  color,
  setColor,
  className,
  onChange = () => {},
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [colorPickerContainer, setColorPickerContainer] =
    useState<HTMLElement | null>(null);

  useOutsideClick(containerRef, (event) => {
    if (!colorPickerContainer?.contains(event.target as Node)) {
      onClose(event);
    }
  });

  // @ts-ignore
  useEffect(() => {
    const root = document.body;

    const containerElement = document.createElement('div');
    setColorPickerContainer(containerElement);

    if (!root || !containerElement) return;

    root.appendChild(containerElement);

    // eslint-disable-next-line consistent-return
    return () => root.removeChild(containerElement);
  }, []);

  useEffect(() => {
    const editorPanel = document.getElementById('EditorPanel');
    editorPanel?.addEventListener('scroll', onClose);

    return () => editorPanel?.removeEventListener('scroll', onClose);
  }, []);

  const handleColorChange = (colorValue: Color) => {
    onChange(colorValue.hex);
    setColor(colorValue);
  };

  if (!colorPickerContainer) return null;

  return (
    <div
      className={clsx('ColorPicker-Container', className)}
      ref={containerRef}
    >
      {anchorEl}
      {createPortal(
        <div
          className={clsx(
            COLOR_PICKER_WRAPPER_CLASSNAME,
            !isOpen && COLOR_PICKER_WRAPPER_HIDDEN_CLASSNAME
          )}
          style={calculateColorPickerPosition(containerRef.current)}
        >
          <div className="ColorPickerPalette">
            <ReactColorPicker
              width={310}
              height={150}
              color={color}
              onChange={handleColorChange}
              hideHSV
              hideRGB
            />
            <div
              className="PlusButton"
              onClick={onClose}
              role="button"
              tabIndex={0}
            >
              <Icon SVG={CheckIcon} height={20} width={20} />
            </div>
          </div>
        </div>,
        colorPickerContainer
      )}
    </div>
  );
};

export default ColorPicker;
