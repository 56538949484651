import { useNavigate } from 'react-router-dom';

import { PasswordUpdatedView } from '@views';
import { ROUTES } from '@constants/routes';

const PasswordUpdatedPage = () => {
  const navigate = useNavigate();

  const handleSubmit = () => navigate(ROUTES.signIn);

  return <PasswordUpdatedView onSubmit={handleSubmit} />;
};

export default PasswordUpdatedPage;
