import ReactDOM from 'react-dom/client';
import ReactFbq from 'react-fbq';
import TagManager from 'react-gtm-module';

import { NON_PROD_HOSTNAMES } from '@constants/common';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: 'GTM-KSWWD3LC',
};

if (NON_PROD_HOSTNAMES.includes(window.location.hostname)) {
  TagManager.initialize(tagManagerArgs);
  // @ts-ignore
  ReactFbq.initialize({ id: '187373824319192' });
}

// TODO: enable Strict Mode for non editor pages
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
