import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CampaignsView } from '@views';
import { RECENT_CAMPAIGNS_TABLE_ROWS_AMOUNT } from '@constants/common';
import { ROUTES } from '@constants/routes';
import {
  createCampaignThunk,
  getCampaignsThunk,
} from '@store/features/campaigns/asyncThunks';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';
import { trackToGTM } from '@utils/helpers';

const CampaignsPage = () => {
  // TODO: code in this file is dubplicate in AllCampaignsPage.tsx.
  // Try to make it reusable
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(templateAsyncThunks.fetchTemplatesThunk({}));
    sessionStorage.removeItem('form');
    // fetch 5 latest campaigns
    dispatch(
      getCampaignsThunk({
        offset: 0,
        limit: RECENT_CAMPAIGNS_TABLE_ROWS_AMOUNT,
        sortBy: 'createdAt',
        order: 'DESC',
      })
    );
  }, []);

  const createCampaignAction = async () => {
    const response = await dispatch(createCampaignThunk());
    return response;
  };

  const createNewCampaign = async () => {
    await createCampaignAction();
    trackToGTM({ event: 'campaign_new' });
    navigate(ROUTES.dashboard.createCampaign);
  };

  return <CampaignsView createNewCampaign={createNewCampaign} />;
};

export default CampaignsPage;
