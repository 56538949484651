import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Typography } from '@atoms';
import { InputWithTitle, Modal } from '@molecules';
import { ModalComponent } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { campaignsSelectors } from '@store/features/campaigns';
import { sendTestEmailThunk } from '@store/features/campaigns/asyncThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { COMMA_OR_COMMA_WITH_SPACE_REGEX, schemas } from '@utils/validation';

import './styles.css';

interface Props extends ModalComponent {
  templateId: number;
}

type FormValues = {
  emails: string;
};

const SendTestEmailModal = ({ isOpen, onClose, templateId }: Props) => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const campaign = useAppSelector(campaignsSelectors.campaignObject);
  const { control, getValues, reset } = useForm<FormValues>({
    defaultValues: { emails: '' },
    resolver: yupResolver(schemas.sendTestEmailSchema),
    reValidateMode: 'onBlur',
    mode: 'all',
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleSendTestEmail = (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const emails = value.split(COMMA_OR_COMMA_WITH_SPACE_REGEX);
    const { id } = campaign.campaign;

    if (!campaign) return;

    dispatch(
      sendTestEmailThunk({
        id,
        emails,
        templateId,
        isPreset: !!state?.isPreset,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Email sent');
        handleClose();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleSubmit = () => {
    const { emails } = getValues();

    handleSendTestEmail(emails);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="SendTestEmailModal">
      <Typography variant="subtitle1">Send a test email</Typography>
      <InputWithTitle
        title="Send to"
        name="emails"
        control={control}
        placeholder="Ex: john@email.com, doe@email.com"
        hintText="Use commas to separate multiple emails."
      />
      <div className="ButtonGroup">
        <Button title="Cancel" variant="secondary" onClick={handleClose} />
        <Button title="Send" variant="primary" onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default SendTestEmailModal;
