import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ForgotPasswordCheckEmailView } from '@views';
import { forgotPasswordThunk } from '@store/features/auth/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const ForgotPasswordCheckEmailPage = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const resendEmail = () => {
    dispatch(forgotPasswordThunk({ email: state?.email }))
      .unwrap()
      .then(() => {
        toast.success('Email sent');
      })
      .catch((err: Error) => toast(err.message));
  };

  return (
    <ForgotPasswordCheckEmailView
      email={state?.email}
      onClickResend={resendEmail}
    />
  );
};

export default ForgotPasswordCheckEmailPage;
