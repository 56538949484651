import { ChangeEventHandler } from 'react';

import { Typography } from '@atoms';
import { CheckboxWithTitle } from '@molecules';

interface Props {
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const Header = ({ isChecked, onChange }: Props) => (
  <div className="PaddingSettingsHeader">
    <Typography variant="subtitle5" className="PaddingTitle">
      Padding
    </Typography>
    <CheckboxWithTitle
      title="Constrain"
      isChecked={isChecked}
      onChange={onChange}
    />
  </div>
);

export default Header;
