import clsx from 'clsx';

import { Typography } from '@atoms';
import { InputComponent } from '@devTypes';

import Input from '../Input/Input';

import './styles.css';

interface Props extends InputComponent {
  title: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  className?: string;
  onFocus?: () => void;
}

const InputWithTitle = ({
  title,
  placeholder,
  type,
  isRequired,
  startIcon,
  endIcon,
  name,
  control,
  hintText,
  className,
  isHintTextHidden,
  isDisabled,
  autoComplete,
  onFocus,
  onBlur,
  hideErrorMessage,
}: Props) => {
  const label = `${title} ${isRequired ? '*' : ''}`;

  return (
    <div className={clsx('InputWithTitle', className)}>
      <Typography variant="subtitle4" className="InputWithTitle-Title">
        {label}
      </Typography>
      <Input
        name={name}
        placeholder={placeholder}
        type={type}
        startIcon={startIcon}
        endIcon={endIcon}
        control={control}
        hintText={hintText}
        isHintTextHidden={isHintTextHidden}
        isDisabled={isDisabled}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete={autoComplete}
        hideErrorMessage={hideErrorMessage}
      />
    </div>
  );
};

export default InputWithTitle;
