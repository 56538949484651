import { useEffect } from 'react';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as ErrorIcon } from '@assets/icons/alert-triangle.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/checkbox-check.svg';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { NotificationType } from '@devTypes';

import './styles.css';

export interface NotificationProps {
  type?: NotificationType;
  text?: string;
  isOpen: boolean;
  onClose: () => void;
  untilClose?: boolean;
}

const NOTIFICATION_AUTOCLOSE_TIME_IN_MS = 5000;

const Notification = ({
  type,
  text,
  isOpen,
  onClose,
  untilClose,
}: NotificationProps) => {
  useEffect(() => {
    if (untilClose) return;

    const autoCloseTimer = setTimeout(
      onClose,
      NOTIFICATION_AUTOCLOSE_TIME_IN_MS
    );

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(autoCloseTimer);
  }, [isOpen, untilClose]);

  return (
    <div
      className={clsx(
        'Notification',
        `Notification-${type}`,
        !isOpen && 'Notification-hidden'
      )}
    >
      <Icon
        SVG={type === 'error' ? ErrorIcon : CheckIcon}
        height={24}
        width={24}
      />
      <Typography variant="subtitle3">{text}</Typography>
      <Icon
        className="stroke-white stroke ml-auto cursor-pointer"
        SVG={CrossIcon}
        height={24}
        width={24}
        onClick={onClose}
      />
    </div>
  );
};

export default Notification;
