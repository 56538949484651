import React, { useRef } from 'react';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { GalleryItemProps } from '@devTypes';

import './styles.css';

interface Props extends GalleryItemProps {
  isSelected: boolean;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
  onDelete?: (id: number) => void;
  closeIcon?: boolean;
  orderNumber?: number;
}

const GalleryItem = ({
  id,
  url,
  isSelected,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onDelete = () => {},
  closeIcon,
  orderNumber,
}: Props) => {
  const rootRef = useRef(null);
  const maskRef = useRef(null);

  const handleSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === rootRef.current || event.target === maskRef.current) {
      onClick();
    }
  };

  return (
    <div
      className={clsx('GalleryImage', isSelected && 'GalleryImage-selected')}
      key={id}
      style={{ backgroundImage: `url(${url})` }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleSelect}
      role="button"
      tabIndex={0}
      ref={rootRef}
    >
      {(isHovered || isSelected) && (
        <div ref={maskRef} className="GalleryImageMask" />
      )}
      {isSelected && orderNumber && (
        <div className="GalleryImageOrderNumber">
          <Typography variant="subtitle4">{orderNumber}</Typography>
        </div>
      )}
      {closeIcon ? (
        <div
          className="GalleryImageDeleteIcon"
          onClick={() => onDelete(id)}
          tabIndex={0}
          role="button"
        >
          <Icon SVG={CrossIcon} />
        </div>
      ) : null}
    </div>
  );
};

export default GalleryItem;
