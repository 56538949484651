import clsx from 'clsx';

import { Icon } from '@atoms';
import { ReactComponent as CheckIcon } from '@assets/icons/blue-check.svg';
import { MenuItemComponent } from '@devTypes';

import './styles.css';

const LayoutMenuItem = ({ icon, isSelected, onClick }: MenuItemComponent) => (
  <div
    className={clsx('LayoutMenuItem', isSelected && 'LayoutMenuItem-selected')}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <Icon SVG={icon} width={200} height={100} />
    <Icon
      SVG={CheckIcon}
      width={20}
      height={20}
      className="LayoutMenuItem-CheckIcon"
    />
  </div>
);

export default LayoutMenuItem;
