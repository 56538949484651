import { Dispatch, SetStateAction } from 'react';

import { Icon } from '@atoms';
import { SelectPopup } from '@molecules';
import { ReactComponent as ThreeDotsIcon } from '@assets/icons/more-vertical.svg';
import { PopupListItemComponent } from '@devTypes';
import { PopupListClickHandler } from '@molecules/SelectPopup/PopupList';

import './styles.css';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClick: PopupListClickHandler;
  items: Array<PopupListItemComponent>;
}

const MoreOptionsPopup = ({ isOpen, setIsOpen, onClick, items }: Props) => {
  const handlePopupClose = () => setIsOpen(false);

  const handlePopupToggle = () => setIsOpen((prevState) => !prevState);

  return (
    <SelectPopup
      isOpen={isOpen}
      onClose={handlePopupClose}
      anchorEl={
        <Icon
          SVG={ThreeDotsIcon}
          height={24}
          width={24}
          onClick={handlePopupToggle}
          className="ThreeDotsIcon"
        />
      }
      items={items}
      onClick={onClick}
      className="MoreOptionsPopup"
    />
  );
};

export default MoreOptionsPopup;
