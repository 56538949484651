import { Typography } from '@atoms';

interface Props {
  title: string;
  subtitle: string;
}

const HeaderTitle = ({ title, subtitle }: Props) => (
  <div className="HeaderTitle">
    <Typography variant="subtitle3">{title}</Typography>
    <Typography variant="subtitle4" className="Subtitle">
      {subtitle}
    </Typography>
  </div>
);

export default HeaderTitle;
