import { ENDPOINTS } from '@constants/api';

import { wrappedAxiosRequest } from '../../api';

export interface SignInData {
  email: string;
  password: string;
}

export interface SignUpData {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

export interface ForgotPasswordData {
  email: string;
}

export interface CreateNewPasswordData {
  token: string;
  password: string;
}

export interface ChangePasswordData {
  newPassword: string;
  oldPassword: string;
}

const authApi = {
  async signIn({ email, password }: SignInData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.auth.signIn,
      data: {
        email,
        password,
      },
    });
  },

  async signUp({ firstName, lastName, email, password }: SignUpData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.auth.signUp,
      data: {
        firstName,
        lastName,
        email,
        password,
      },
    });
  },

  async forgotPassword({ email }: ForgotPasswordData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.auth.forgotPassword,
      data: {
        email,
      },
    });
  },

  async createNewPassword({ token, password }: CreateNewPasswordData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: `${ENDPOINTS.auth.resetPassword}?token=${token}`,
      data: {
        password,
      },
    });
  },

  async changePassword(data: ChangePasswordData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.auth.changePassword,
      data,
    });
  },
};

export default authApi;
