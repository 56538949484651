import { useCallback } from 'react';
import { toast } from 'react-toastify';
import grapesjs from 'grapesjs';

import { EditorComponentTypes } from '@constants/editor';
import { useEditor } from '@hooks';
import { editorActions, editorSelectors } from '@store/features/editor';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { applyForEachComponent, getEditorHeight } from '@utils/helpers';

const getEditorProjectData = (editor: grapesjs.Editor) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const data = editor.getProjectData();

      if (!data) {
        reject(new Error('No data found'));
      }

      resolve(data);
    }, 2000);
  });

const useAutoSave = () => {
  const previousStates = useAppSelector(editorSelectors.previousStates);
  const nextStates = useAppSelector(editorSelectors.nextStates);
  const currentState = useAppSelector(editorSelectors.currentState);
  const dispatch = useAppDispatch();
  const editor = useEditor();

  const autoSave = useCallback(async () => {
    if (!editor) {
      return;
    }

    let counter = 0;

    applyForEachComponent(editor.getWrapper(), (component) => {
      const componentType = component.get('type');
      const allowedTypes = Object.values(EditorComponentTypes) as string[];

      if (!allowedTypes.includes(componentType!)) {
        return;
      }

      component.addAttributes({ customId: `${componentType}-${counter}` });
      counter += 1;
    });

    dispatch(editorActions.setIsTemplateAutoSaving(true));

    if (nextStates.length > 0) {
      dispatch(editorActions.clearNextStates());
    }

    if (previousStates.length >= 5) {
      dispatch(editorActions.shiftPreviousState());
    }

    if (currentState.gjs !== '') {
      const previousState = {
        gjs: currentState.gjs,
        editorHeight: currentState.editorHeight,
        background: currentState.background,
      };

      dispatch(editorActions.addPreviousState(previousState));

      if (editor) {
        const editorHeight = getEditorHeight(editor!);
        dispatch(editorActions.setCurrentStateEditorHeight(editorHeight));

        const wrapper = editor.getWrapper();

        wrapper.addAttributes({
          editorHeight,
        });
      }
    }

    try {
      const gjsData = await getEditorProjectData(editor);

      dispatch(editorActions.setCurrentStateGjs(JSON.stringify(gjsData)));
      dispatch(
        editorActions.setCurrentStateEditorHeight(getEditorHeight(editor!))
      );
    } catch (error) {
      toast.error('Error while saving template');
    } finally {
      document.dispatchEvent(
        new CustomEvent('settingsChange', { detail: true })
      );

      dispatch(editorActions.setIsTemplateAutoSaving(false));
      dispatch(editorActions.setIsApplyCancelDisabled(true));

      const selected = editor.getSelected();

      selected?.addAttributes({ settingsChanged: false });
    }
  }, [currentState, editor]);

  return autoSave;
};

export default useAutoSave;
