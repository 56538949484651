import { ValidationErrorsList } from '@molecules';

const passwordErrors = [
  'One lowercase character',
  'One uppercase character',
  'One number',
  'One special character #?!@$%^&*-',
  '8 characters minimum',
  'No spaces',
];

type Error = {
  status: 'error' | 'success';
  title: string;
};

const getValidationErrors: (message: string) => Error[] = (message: string) => {
  const errors = message.split(',');

  return passwordErrors.map((error) => ({
    title: error,
    status: errors.includes(error) ? 'error' : 'success',
  }));
};

interface Props {
  isVisible: boolean;
  errorMessage: string;
}

const PasswordValidationErrorsList = ({ isVisible, errorMessage }: Props) =>
  isVisible ? (
    <ValidationErrorsList
      items={getValidationErrors(errorMessage)}
      className="PasswordValidationErrors"
    />
  ) : null;

export default PasswordValidationErrorsList;
