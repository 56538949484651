/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disble react/jsx-no-target-blank */
import { useEffect, useState } from 'react';

import { Card, HeaderContainer, Typography } from '@atoms';
import HomeRouteLayout from '@organisms/HomeRouteLayout/HomeRouteLayout';
import { getRecipientsThunk } from '@store/features/allcontacts/asyncThunks';
import { getCampaignsThunk } from '@store/features/campaigns/asyncThunks';
import { usersSelectors } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const DashboardHomeView = () => {
  const [isShopifyConnected, setIsShopifyConnected] = useState(false);
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelectors.user);

  const integrations = useAppSelector(usersSelectors.userIntegrations);

  useEffect(() => {
    if (integrations?.shopify) {
      setIsShopifyConnected(true);
    }
    dispatch(getRecipientsThunk({}));

    dispatch(
      getCampaignsThunk({
        offset: 0,
        limit: 5,
        sortBy: 'createdAt',
        order: 'DESC',
      })
    );
  }, []);

  useEffect(() => {
    if (integrations?.shopify) {
      setIsShopifyConnected(true);
    }
  }, [integrations]);

  return (
    <div className="DashboardHomeView">
      <div style={{ width: '100%' }}>
        <HeaderContainer>
          <Typography variant="subtitle1">Hello {user?.firstName}</Typography>
        </HeaderContainer>
        <HomeRouteLayout isShopifyConnected={isShopifyConnected} />
      </div>
      <div className="HelpfulResources">
        <Typography variant="subtitle3" style={{ color: 'white' }}>
          Helpful resources
        </Typography>
        <Card className="HelpfulResourcesCard">
          <video controls width="279" height="177">
            <source
              src="https://appmail-images.s3.amazonaws.com/AppMail_+Creating+Immersive+Experiences+in+Marketing+Emails.mp4"
              type="video/mp4"
            />
          </video>
          <div style={{ display: 'flex', gap: '6px', flexDirection: 'column' }}>
            <Typography variant="subtitle3" weight="semiBold">
              AppMail Demo
            </Typography>
            <Typography variant="subtitle3" style={{ color: '#344054' }}>
              Want to see AppMail’s interactive email in action? Check out this
              brief demo.
            </Typography>
          </div>
        </Card>
        {/* <Card className="HelpfulResourcesCard">
          <video controls width="279" height="177">
            <source
              src="https://appmail-images.s3.amazonaws.com/Shopify+Integration+Tutorial.mp4"
              type="video/mp4"
            />
          </video>
          <div style={{ display: 'flex', gap: '6px', flexDirection: 'column' }}>
            <Typography variant="subtitle3" weight="semiBold">
              Shopify Integration
            </Typography>
            <Typography variant="subtitle3" style={{ color: '#344054' }}>
              Connect your Shopify store to synchronize your products and
              inventory system.
            </Typography>
          </div>
        </Card> */}
      </div>
    </div>
  );
};

export default DashboardHomeView;
