import { User, UserIntegrations } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import {
  getUserThunk,
  shopifyConnectionStatusThunk,
  updateUserThunk,
} from './asyncThunks';

type LoadingState = {
  isLoading: boolean;
};

interface UserState extends LoadingState {
  data: {
    user: User | null;
    integrations: UserIntegrations | null;
  };
}

const UserInitialState: UserState = {
  isLoading: false,
  data: {
    user: null,
    integrations: null,
  },
};

interface InitialState extends LoadingState {
  user: UserState;
}

const initialState: InitialState = {
  isLoading: false,
  user: UserInitialState,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserThunk.fulfilled, (state, { payload }) => ({
      ...state,
      user: {
        isLoading: false,
        data: payload,
      },
    }));
    builder.addCase(getUserThunk.pending, (state) => ({
      ...state,
      user: {
        ...state.user,
        isLoading: true,
      },
    }));
    builder.addCase(updateUserThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(updateUserThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(shopifyConnectionStatusThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(shopifyConnectionStatusThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
  },
});

export default usersSlice.reducer;
