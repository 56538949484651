import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { SwitchRender } from '@atoms';
import { EditorBlockSettingContainer, LayoutMenuItem } from '@molecules';
import { ReactComponent as ColumnsLayoutAIcon } from '@assets/icons/columns-layout-a.svg';
import { ReactComponent as ColumnsLayoutBIcon } from '@assets/icons/columns-layout-b.svg';
import { ReactComponent as ColumnsLayoutCIcon } from '@assets/icons/columns-layout-c.svg';
import { ReactComponent as OneColumnLayout } from '@assets/icons/one-column-layout.svg';
import { COLUMNS_LAYOUTS } from '@constants/editor';
import { SvgFunctionComponent } from '@devTypes';
import Menu from '@organisms/Menu/Menu';

type LayoutMenuOption = {
  id: number;
  layout: { id: string; columnsAmount: number };
  icon: SvgFunctionComponent;
};

const twoColumnsLayouts: LayoutMenuOption[] = [
  {
    id: 0,
    layout: COLUMNS_LAYOUTS.layoutA,
    icon: ColumnsLayoutAIcon,
  },
  {
    id: 1,
    layout: COLUMNS_LAYOUTS.layoutB,
    icon: ColumnsLayoutBIcon,
  },
  {
    id: 2,
    layout: COLUMNS_LAYOUTS.layoutC,
    icon: ColumnsLayoutCIcon,
  },
];

const oneColumnsLayouts: LayoutMenuOption[] = [
  {
    id: 0,
    layout: COLUMNS_LAYOUTS.layoutA1,
    icon: OneColumnLayout,
  },
];

interface Props {
  selectedComponent: grapesjs.Component;
  columnsAmount: number;
}

const EditorColumnsBlockLayoutSettings = ({
  selectedComponent,
  columnsAmount,
}: Props) => {
  const [selectedTwoColumnsLayoutOption, setSelectedTwoColumnsLayoutOption] =
    useState<LayoutMenuOption | null>(null);
  const [selectedOneColumnsLayoutOption, setSelectedOneColumnsLayoutOption] =
    useState<LayoutMenuOption | null>(null);

  useEffect(() => {
    const { layout } = selectedComponent.getAttributes();

    if (columnsAmount === 2) {
      const selectedLayout = twoColumnsLayouts.find(
        (item) => item.layout.id === layout
      );

      if (!selectedLayout) {
        return;
      }

      setSelectedTwoColumnsLayoutOption(selectedLayout);
      setSelectedOneColumnsLayoutOption(null);

      return;
    }

    if (columnsAmount === 1 && layout === oneColumnsLayouts[0].layout.id) {
      setSelectedOneColumnsLayoutOption(oneColumnsLayouts[0]);
      setSelectedTwoColumnsLayoutOption(null);
    }
  }, [columnsAmount]);

  const handleLayoutOptionChange =
    (setter: React.Dispatch<React.SetStateAction<LayoutMenuOption | null>>) =>
    (option: LayoutMenuOption) => {
      const { layout } = option;

      selectedComponent.addAttributes({
        layout: layout.id,
        amount: layout.columnsAmount,
      });

      setter(option);
    };

  return (
    <EditorBlockSettingContainer title="Select a layout">
      <SwitchRender comparator={columnsAmount} toCompare={[1, 2]}>
        <Menu
          className="[&>div]:h-[146px]"
          items={oneColumnsLayouts}
          value={selectedOneColumnsLayoutOption}
          onChange={handleLayoutOptionChange(setSelectedOneColumnsLayoutOption)}
          ItemComponent={LayoutMenuItem}
          spacing={24}
        />
        <Menu
          className="[&>div]:h-[146px]"
          items={twoColumnsLayouts}
          value={selectedTwoColumnsLayoutOption}
          onChange={handleLayoutOptionChange(setSelectedTwoColumnsLayoutOption)}
          ItemComponent={LayoutMenuItem}
          spacing={24}
        />
      </SwitchRender>
    </EditorBlockSettingContainer>
  );
};

export default EditorColumnsBlockLayoutSettings;
