import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  EditorBlockSettingContainer,
  Input,
  UploadImageContainer,
} from '@molecules';
import { EditorBlockSettingsComponent } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDebounce } from '@hooks';
import { getChildComponentAt } from '@utils/helpers';
import { schemas } from '@utils/validation';

interface Props extends EditorBlockSettingsComponent {
  onUploadImageOpen: () => void;
}

const EditorLogoBlockSettingsContent = ({
  selectedComponent,
  onUploadImageOpen,
}: Props) => {
  const { control, getFieldState, setValue } = useForm({
    defaultValues: { link: '' },
    mode: 'onBlur',
    resolver: yupResolver(schemas.urlInputSchema),
  });

  const link = useDebounce(useWatch({ control, name: 'link' }), 500);

  useEffect(() => {
    const linkComponent = getChildComponentAt(selectedComponent, 0);
    if (!linkComponent) return;

    const { href } = linkComponent.getAttributes();

    setValue('link', href);
  }, [selectedComponent]);

  useEffect(() => {
    if (!selectedComponent) return;
    const linkComponent = getChildComponentAt(selectedComponent, 0);

    const linkFieldState = getFieldState('link');

    if (linkFieldState.error || link === '') {
      linkComponent.removeAttributes('href');
      return;
    }

    linkComponent.addAttributes({ href: link });
  }, [link, selectedComponent]);

  const { imageUrl } = selectedComponent.getAttributes();

  return (
    <div className="EditorImageBlockSettingsContent">
      <UploadImageContainer imageUrl={imageUrl} onClick={onUploadImageOpen} />
      <EditorBlockSettingContainer title="Image URL">
        <Input name="link" placeholder="www.mysite.com" control={control} />
      </EditorBlockSettingContainer>
    </div>
  );
};

export default EditorLogoBlockSettingsContent;
