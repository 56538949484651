import { MouseEventHandler } from 'react';

import { Typography } from '@atoms';

import './styles.css';

interface Props {
  value: string;
  onClick?: MouseEventHandler;
  icons: any;
  title?: string;
  titleVariant?: 'bold' | 'regular';
}

const IconPicker = ({
  value,
  icons,
  onClick,
  title,
  titleVariant = 'regular',
}: Props) => (
  <div className="IconPickerWithTitle">
    {title && (
      <Typography
        className="Typography-gray-main"
        weight={titleVariant}
        variant={titleVariant === 'bold' ? 'subtitle5' : 'subtitle4'}
      >
        {title}
      </Typography>
    )}
    <div
      className="IconPicker"
      onClick={onClick}
      style={{ backgroundColor: `${value}` }}
      role="button"
      tabIndex={0}
    >
      <div className="icon_1">
        <img src={icons['icon-Fb']} alt="" />{' '}
      </div>
      <div className="icon_2">
        <img src={icons['icon-Ig']} alt="" />{' '}
      </div>
      <div className="icon_3">
        <img src={icons['icon-Tw']} alt="" />{' '}
      </div>
      <div className="icon_4">
        <img src={icons['icon-Yt']} alt="" />{' '}
      </div>
      <div className="icon_5">
        <img src={icons['icon-Tt']} alt="" />{' '}
      </div>
    </div>
  </div>
);

export default IconPicker;
