import { LoadingState } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import generalAsyncThunks from './asyncThunks';

interface GetImageFileByUrl extends LoadingState {}

interface InitialState {
  getImageFileByUrl: GetImageFileByUrl;
}

const initialState: InitialState = {
  getImageFileByUrl: {
    isLoading: false,
  },
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generalAsyncThunks.getImageFileByUrl.pending, (state) => ({
      ...state,
      getImageFileByUrl: { isLoading: true },
    }));
    builder.addCase(
      generalAsyncThunks.getImageFileByUrl.fulfilled,
      (state) => ({
        ...state,
        getImageFileByUrl: { isLoading: false },
      })
    );
    builder.addCase(generalAsyncThunks.getImageFileByUrl.rejected, (state) => ({
      ...state,
      getImageFileByUrl: { isLoading: false },
    }));
  },
});

export default generalSlice.reducer;

export const { actions: generalActions } = generalSlice;
