import { Button, Typography } from '@atoms';
import { Modal } from '@molecules';
// import ShopifyStoreForm from '@organisms/ShopDetails/ShopifyStoreForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ConnectShopifyModal = ({ isOpen, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className="ShopifyModal-Content">
      <Typography variant="subtitle3">
        To get started, connect your Shopify store
      </Typography>
      <Typography variant="subtitle3">
        by installing the AppMail app from the Shopify App Store
      </Typography>
      {/* <ShopifyStoreForm /> */}
      <div className="ShopifyModal-ButtonGroup">
        <Button
          variant="primary"
          onClick={() => {
            onClose();
          }}
          title="Close"
        />
      </div>
    </div>
  </Modal>
);

export default ConnectShopifyModal;
