import { Icon } from '@atoms';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/carousel-arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/icons/carousel-arrow-right.svg';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';

import './styles.css';

interface ICarouselPreviewItemProps {
  url: string;
  hovered: boolean;
  hasLeftArrow: boolean;
  onLeftArrowClick: () => void;
  hasRightArrow: boolean;
  onRightArrowClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onDelete: () => void;
}

const CarouselPreviewItem = ({
  url,
  hovered,
  hasLeftArrow,
  onLeftArrowClick,
  hasRightArrow,
  onRightArrowClick,
  onMouseEnter,
  onMouseLeave,
  onDelete,
}: ICarouselPreviewItemProps) => (
  <div
    className="CarouselPreviewItem"
    style={{ backgroundImage: `url(${url})` }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {hovered ? (
      <>
        <div className="CarouselPreviewItemMask" />
        <div
          className="CarouselPreviewItemDeleteIcon"
          onClick={onDelete}
          role="button"
          tabIndex={0}
        >
          <Icon SVG={CrossIcon} />
        </div>
        {hasLeftArrow && (
          <div
            className="CarouselPreviewItemLeftArrow"
            onClick={onLeftArrowClick}
            role="button"
            tabIndex={0}
          >
            <Icon SVG={ArrowLeftIcon} width={12} height={12} />
          </div>
        )}
        {hasRightArrow && (
          <div
            className="CarouselPreviewItemRightArrow"
            onClick={onRightArrowClick}
            role="button"
            tabIndex={0}
          >
            <Icon SVG={ArrowRightIcon} width={12} height={12} />
          </div>
        )}
      </>
    ) : null}
  </div>
);

export default CarouselPreviewItem;
