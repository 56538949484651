import {
  Accept,
  DropEvent,
  ErrorCode,
  FileRejection,
  useDropzone,
} from 'react-dropzone';
import { toast } from 'react-toastify';

import { Button, Icon, Typography } from '@atoms';
import { ButtonVariant, SvgFunctionComponent } from '@devTypes';

import './styles.css';

interface Props {
  icon?: SvgFunctionComponent;
  title: string;
  subtitle: string;
  onDrop: <T extends File>(files: T[], event: DropEvent) => void;
  maxFiles: number;
  maxSize: number;
  dropZoneType: Accept;
  buttonVariant?: ButtonVariant;
}

const Dropzone = ({
  onDrop,
  icon,
  title,
  subtitle,
  maxSize,
  maxFiles,
  dropZoneType,
  buttonVariant = 'text-primary',
}: Props) => {
  const handleDropRejected = (fileRejections: FileRejection[]) => {
    const { code } = fileRejections[0].errors[0];

    switch (code) {
      case ErrorCode.FileTooLarge:
        toast.error('File is too large. Try again');
        break;

      default:
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: handleDropRejected,
    accept: dropZoneType,
    maxSize,
    maxFiles,
    noClick: true,
  });

  return (
    <div className="Dropzone">
      <div className="DropzoneContent" {...getRootProps()}>
        <input {...getInputProps()} />
        {icon && <Icon SVG={icon} width={56} height={56} />}
        <div className="DropzoneContentTitle">
          <Typography variant="subtitle4">{title}</Typography>
          <Typography variant="subtitle4">or</Typography>
          <Button
            variant={buttonVariant}
            title="Upload from computer"
            onClick={open}
          />
        </div>
        <Typography variant="subtitle6">{subtitle}</Typography>
      </div>
    </div>
  );
};
export default Dropzone;
