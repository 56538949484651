import { useEffect, useState } from 'react';

import { Typography } from '@atoms';
import { ChipButtonsList } from '@molecules';

import WritingStylesDropdown from './WritingStylesDropdown';

interface Props {
  submittedWritingStyles: string[];
  onWritingStylesSubmit: (values: string[]) => void;
}

const ContextTab = ({
  submittedWritingStyles,
  onWritingStylesSubmit,
}: Props) => {
  const [selectedWritingStyles, setSelectedWritingStyles] = useState<string[]>(
    []
  );

  useEffect(() => {
    setSelectedWritingStyles(submittedWritingStyles);
  }, []);

  const handleSelectedWritingStyleDelete = (id: string) => {
    const newSelectedWritingStyles = selectedWritingStyles.filter(
      (item) => item !== id
    );

    setSelectedWritingStyles(newSelectedWritingStyles);
    onWritingStylesSubmit(newSelectedWritingStyles);
  };

  const handleWritingStylesSelect = (value: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedWritingStyles((prevState) =>
        prevState.filter((item) => item !== value)
      );

      return;
    }

    setSelectedWritingStyles((prevState) => [...prevState, value]);
  };

  const handleWritingStylesSubmit = () =>
    onWritingStylesSubmit(selectedWritingStyles);

  const handleCheckedWritingStylesCancel = () =>
    setSelectedWritingStyles(submittedWritingStyles);

  return (
    <div className="ContextTab">
      <Typography variant="subtitle4" className="mb-[10px]">
        Tone/Writing style
      </Typography>
      <WritingStylesDropdown
        selectedWritingStyles={selectedWritingStyles}
        onChange={handleWritingStylesSelect}
        onSubmit={handleWritingStylesSubmit}
        onCancelClick={handleCheckedWritingStylesCancel}
      />
      <ChipButtonsList
        items={submittedWritingStyles.map((item) => ({
          id: item,
          title: item,
        }))}
        onDelete={handleSelectedWritingStyleDelete}
        className="mt-[16px]"
      />
    </div>
  );
};

export default ContextTab;
