import {
  DEFAULT_TEXT_ATTRIBUTES,
  DEFAULT_TEXT_STYLE,
  EditorComponentTypes,
} from '@constants/editor';
import { transformStylesObjectToString } from '@utils/helpers';

const TEXT_BLOCK_CONTAINER_CLASSNAME = 'text-block';

export const generateGrapesjsTextComponent = ({
  content,
  classes = [],
  styles = '',
  attributes = {},
}: any) => ({
  tagName: 'div',
  type: EditorComponentTypes.Text,
  classes: [TEXT_BLOCK_CONTAINER_CLASSNAME, ...classes],
  attributes: {
    ...DEFAULT_TEXT_ATTRIBUTES,
    ...attributes,
  },
  content,
  styles: `
  .${TEXT_BLOCK_CONTAINER_CLASSNAME} {
    ${transformStylesObjectToString(DEFAULT_TEXT_STYLE)}]
   }

    ${styles}
  `,
});
