import clsx from 'clsx';

import { SelectorComponent, SelectorOptionType } from '@devTypes';

import SelectorOption from './SelectorOption';

const Selector = ({
  value,
  options,
  onChange,
  className,
}: SelectorComponent) => {
  const handleChange = (option: SelectorOptionType) => {
    onChange(option);
  };

  if (!value) return null;

  return (
    <div className={clsx('Selector', className)}>
      {options.map((option) => {
        const isActive = value.id === option.id;

        return (
          <SelectorOption
            key={option.id}
            tooltipText={option.tooltipText}
            text={option.text}
            isActive={isActive}
            disabled={option.disabled}
            onClick={() => handleChange(option)}
          />
        );
      })}
    </div>
  );
};

export default Selector;
