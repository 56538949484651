import { ReactNode } from 'react';

import './styles.css';

interface Props {
  children: ReactNode;
}

const ShopifyAuthFormTemplate = ({ children }: Props) => (
  <div className="ShopifyAuthFormTemplate">{children}</div>
);

export default ShopifyAuthFormTemplate;
