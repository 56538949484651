import { IntegrationPlatformsContainer } from '@organisms';
import { SvgFunctionComponent } from '@devTypes';

import './styles.css';

type Platform = {
  title: string;
  description: string;
  icon: SvgFunctionComponent;
};

interface IntegrationsTemplateProps {
  connected: Platform[];
  availableConnections: Platform[];
  onShopifyActivateClick?: () => void;
  onShopifyDeactivateClick?: () => void;
}

const IntegrationsTemplate = ({
  connected,
  availableConnections,
  onShopifyActivateClick,
  onShopifyDeactivateClick,
}: IntegrationsTemplateProps) => (
  <div className="IntegrationsTemplate">
    {connected.length > 0 && (
      <IntegrationPlatformsContainer
        title="Connected Platforms"
        cardVariant="secondary"
        items={connected}
        isActivated
        onShopifyDeactivateClick={onShopifyDeactivateClick}
      />
    )}

    {availableConnections.length > 0 && (
      <IntegrationPlatformsContainer
        title="Available For Connection"
        cardVariant="primary"
        items={availableConnections}
        isActivated={false}
        onShopifyActivateClick={onShopifyActivateClick}
      />
    )}
  </div>
);

export default IntegrationsTemplate;
