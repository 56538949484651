import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import generalApi, { GetImageFileByUrlData } from './api';

const getImageFileByUrl = createCommonAsyncThunk<GetImageFileByUrlData>(
  'general/getImageFileByUrl',
  generalApi.getImageFileByUrl
);

const unsubscribeRecipient = createCommonAsyncThunk<{ token: string }>(
  'general/unsubscribe',
  generalApi.unsubscribeRecipient
);

export default { unsubscribeRecipient, getImageFileByUrl };
