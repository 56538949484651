import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import generativeAIApi, { GenerateAIData } from './api';

export const generateAIThunk = createCommonAsyncThunk<GenerateAIData>(
  'generate',
  generativeAIApi.generate
);

export default {
  generateAIThunk,
};
