interface Props {
  images: any;
  placeholders: any;
}

const EditorCarouselFallback = ({ images, placeholders }: Props) => (
  <div className="flex justify-center items-center py-10">
    <img
      className="w-[563px] h-[360px] max-w-full max-h-full object-contain"
      src={images[0].url ?? placeholders[0].url}
      alt=""
    />
  </div>
);

export default EditorCarouselFallback;
