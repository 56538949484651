import { ReactNode } from 'react';

import { Button, Icon, Typography } from '@atoms';
import { ReactComponent as AppMailLogo } from '@assets/icons/AppMailLogo.svg';

import './styles.css';

interface Props {
  type: 'resetPassword' | 'passwordUpdated';
  onSubmit: () => void;
  children?: ReactNode;
}

const typeOptions = {
  resetPassword: {
    title: 'Reset password',
    subtitle: 'Please enter the password you would like to use',
    submit: 'Update',
  },
  passwordUpdated: {
    title: 'Your password is updated',
    subtitle: 'You have successfully changed your password',
    submit: 'Log in',
  },
};

const CreateNewPasswordTemplate = ({ onSubmit, children, type }: Props) => (
  <div className="CreateNewPasswordTemplate">
    <div className="CreateNewPasswordContainer">
      <Icon
        SVG={AppMailLogo}
        width={153}
        height={28}
        className="SignInSignUpTemplate-Logo"
      />
      <Typography variant="h6" className="CreateNewPasswordTemplate-Title">
        {typeOptions[type].title}
      </Typography>
      <Typography variant="subtitle3" className="SignInSignUpTemplate-Subtitle">
        {typeOptions[type].subtitle}
      </Typography>
      {children}
      <Button
        title={typeOptions[type].submit}
        variant="primary"
        className="CreateNewPasswordTemplate-Submit"
        onClick={onSubmit}
      />
    </div>
  </div>
);

export default CreateNewPasswordTemplate;
