import { Dispatch, SetStateAction } from 'react';

import { PaginationButtons as LeftRightArrowButtons } from '@molecules';

export interface PaginationButtonsProps {
  currentRowsOffset: number;
  setCurrentRowsOffset: Dispatch<SetStateAction<number>>;
  maxVisibleRowsAmount: number;
  totalRowsAmount: number;
}

const PaginationButtons = ({
  currentRowsOffset,
  setCurrentRowsOffset,
  maxVisibleRowsAmount,
  totalRowsAmount,
}: PaginationButtonsProps) => {
  const totalPagesAmount =
    Math.ceil(totalRowsAmount / maxVisibleRowsAmount) || 1;
  const currentPage = Math.ceil(currentRowsOffset / maxVisibleRowsAmount) + 1;

  const handlePageChange = (type: 'more' | 'less') => () => {
    setCurrentRowsOffset((prevState) =>
      type === 'less'
        ? prevState - maxVisibleRowsAmount
        : prevState + maxVisibleRowsAmount
    );
  };

  return (
    <LeftRightArrowButtons
      onLeftArrowClick={handlePageChange('less')}
      onRightArrowClick={handlePageChange('more')}
      currentPage={currentPage}
      totalPagesAmount={totalPagesAmount}
    />
  );
};

export default PaginationButtons;
