import { useState } from 'react';
import clsx from 'clsx';

import { Button } from '@atoms';
import { Dropdown } from '@molecules';
import { GENERATIVE_AI_WRITING_STYLES } from '@constants/common';
import WritingStylesDropdownOption from '@organisms/GenerateAISettings/WritingStylesDropdownOption';

interface Props {
  selectedWritingStyles: string[];
  onChange: (values: string, isChecked: boolean) => void;
  onSubmit: () => void;
  onCancelClick: () => void;
}

const WritingStylesDropdown = ({
  selectedWritingStyles,
  onChange,
  onSubmit,
  onCancelClick,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleDropdownOpen = () => setIsDropdownOpen(true);
  const handleDropdownClose = () => setIsDropdownOpen(false);

  const handleSubmit = () => {
    onSubmit();
    handleDropdownClose();
  };

  const handleCancelClick = () => {
    onCancelClick();
    handleDropdownClose();
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      onOpen={handleDropdownOpen}
      onClose={handleDropdownClose}
      placeholder="Select writing style"
      dropdownContent={
        <div className="p-[14px 0]">
          <div className="flex flex-col h-[200px] overflow-scroll">
            {GENERATIVE_AI_WRITING_STYLES.map((item, index) => {
              const isChecked = selectedWritingStyles.includes(item);

              return (
                <WritingStylesDropdownOption
                  title={item}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  isChecked={isChecked}
                  onCheckboxChange={() => onChange(item, isChecked)}
                />
              );
            })}
          </div>
          <div className="flex justify-end gap-[10px] mt-[24px] p-[14px]">
            <Button
              title="Cancel"
              variant="secondary"
              onClick={handleCancelClick}
            />
            <Button title="Apply" variant="primary" onClick={handleSubmit} />
          </div>
        </div>
      }
      className={clsx(
        'WritingStylesDropdown',
        isDropdownOpen && 'WritingStylesDropdown-open'
      )}
    />
  );
};

export default WritingStylesDropdown;
