import { DashboardSidebar } from '@organisms';

import './styles.css';

interface Props {
  children: React.ReactNode;
}

const DashboardTemplate = ({ children }: Props) => (
  <div className="DashboardTemplate">
    <DashboardSidebar />
    <div className="DashboardTemplateContent">{children}</div>
  </div>
);

export default DashboardTemplate;
