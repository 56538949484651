import { ReactComponent as AddIcon } from '@assets/icons/add.svg';
import { ReactComponent as BackgroundIcon } from '@assets/icons/background.svg';
import { ReactComponent as MiniAppsIcon } from '@assets/icons/miniapp.svg';
import { ReactComponent as ShopifyIcon } from '@assets/icons/shopify.svg';

export const FIVE_MEGABYTES = 5 * 1024 * 1000;

export const AVAILABLE_INTEGRATIONS = [
  {
    title: 'Shopify',
    description:
      'Connect your Shopify store to fetch product details and inventory info.',
    icon: ShopifyIcon,
  },
];

export const EDITOR_SIDEBAR_TABS = {
  addBlock: {
    id: 0,
    title: 'Add block',
    icon: AddIcon,
  },
  miniApps: {
    id: 1,
    title: 'Mini apps',
    icon: MiniAppsIcon,
  },
  background: {
    id: 2,
    title: 'Background',
    icon: BackgroundIcon,
  },
};

export const CONTACTS_TABLE_ROWS_PER_PAGE = 6;
export const RECENT_CAMPAIGNS_TABLE_ROWS_AMOUNT = 5;
export const MAX_SHOPIFY_PRODUCTS_AMOUNT = 3;

export const HOURS_ARRAY = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];
export const MINUTES_ARRAY = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59',
];

export const RECIPIENTS_OPTIONS = [
  {
    title: 'All Contacts - Subscribed and Unsubscribed',
    value: 'all',
  },
  { title: 'Only subscribed Contacts', value: 'subscribed' },
];

const PROD_SENDER_EMAIL = 'donotreply@appmail.com';
const DEV_SENDER_EMAIL = 'support@appmail.co';

export const NON_PROD_HOSTNAMES = [
  'localhost',
  'dev.appmail.co',
  'qa.appmail.co',
];

export const DEFAULT_SENDER_EMAIL = NON_PROD_HOSTNAMES.includes(
  window.location.hostname
)
  ? DEV_SENDER_EMAIL
  : PROD_SENDER_EMAIL;

export const GENERATIVE_AI_WRITING_STYLES = [
  'Friendly',
  'Formal',
  'Casual',
  'Personable',
  'Enthusiastic',
  'Professional',
  'Concise',
  'Captivating',
  'Creative',
];
