import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  Sidebar,
  sidebarClasses,
  useProSidebar,
} from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Icon } from '@atoms';
import { ReactComponent as HomeIcon } from '@assets/icons/home.svg';
import { ReactComponent as LayoutIcon } from '@assets/icons/layout.svg';
import { ReactComponent as MailIcon } from '@assets/icons/mail.svg';
import { ReactComponent as PieChartIcon } from '@assets/icons/pie-chart.svg';
import { ReactComponent as ShareIcon } from '@assets/icons/share.svg';
import { ReactComponent as TemplateIcon } from '@assets/icons/template.svg';
import { ReactComponent as UsersIcon } from '@assets/icons/users.svg';
import { ROUTES } from '@constants/routes';
import { DashboardTab, DashboardTabs } from '@devTypes';
import { authActions } from '@store/features/auth';
import { useAppDispatch } from '@store/hooks';

import DashboardSidebarFooter, {
  menuItemStyles,
} from './DashboardSidebarFooter';
import DashboardSidebarHeader from './DashboardSidebarHeader';

import './styles.css';

const dashboardTabs: DashboardTabs = [
  {
    id: 0,
    icon: HomeIcon,
    link: ROUTES.dashboard.home,
    title: 'Home',
  },
  {
    id: 1,
    icon: MailIcon,
    link: ROUTES.dashboard.campaigns,
    title: 'Campaigns',
  },
  {
    id: 2,
    icon: TemplateIcon,
    link: ROUTES.dashboard.templates,
    title: 'Templates',
  },
  {
    id: 3,
    icon: ShareIcon,
    link: ROUTES.dashboard.campaigns,
    title: 'Automations',
  },
  {
    id: 4,
    icon: UsersIcon,
    link: ROUTES.dashboard.allcontacts,
    title: 'Audience',
  },
  {
    id: 5,
    icon: PieChartIcon,
    link: ROUTES.dashboard.campaigns,
    title: 'Analytics',
  },
  {
    id: 6,
    icon: LayoutIcon,
    link: ROUTES.dashboard.integrations,
    title: 'Integration',
  },
];

const COMING_SOON_TAB_IDS = [3, 5];

const setSidebarItemSelected = (
  pathname: string,
  setSelectedTab: Dispatch<SetStateAction<DashboardTab | null>>
) => {
  const selectedItem = dashboardTabs.find(({ link }) =>
    pathname.startsWith(String(link))
  );

  if (selectedItem) {
    setSelectedTab(selectedItem);
  } else {
    setSelectedTab(null);
  }
};

const DashboardSidebar = () => {
  const { collapsed, collapseSidebar } = useProSidebar();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState<DashboardTab | null>(null);
  const [isLogoutExpanded, setIsLogoutExpanded] = useState<boolean>(false);

  useEffect(() => {
    setSidebarItemSelected(pathname, setSelectedValue);
  }, [pathname]);

  const handleMouseEnter = () => {
    collapseSidebar(false);
  };

  const handleMouseLeave = () => {
    setIsLogoutExpanded(false);
    collapseSidebar(true);
  };

  const handleLogoutClick = async () => {
    dispatch(authActions.logOut());
    navigate(ROUTES.signIn);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="DashboardSidebar"
    >
      <Sidebar
        defaultCollapsed
        collapsedWidth="96px"
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 12px',
            backgroundColor: 'var(--gray-900-color)',
            overflow: isLogoutExpanded ? 'visible' : 'hidden',
          },
        }}
      >
        <DashboardSidebarHeader collapsed={collapsed} />

        <div className="DashboardSidebarMain">
          <Menu
            menuItemStyles={menuItemStyles}
            className="DashboardSidebarMenu"
          >
            {dashboardTabs.map((tab) => (
              <MenuItem
                key={tab.id}
                icon={<Icon SVG={tab.icon} width={24} height={24} />}
                prefix={tab.title}
                component={
                  <Link
                    to={tab.link}
                    className={clsx(
                      COMING_SOON_TAB_IDS.includes(tab.id) &&
                        'pointer-events-none'
                    )}
                    style={{
                      color: COMING_SOON_TAB_IDS.includes(tab.id)
                        ? 'var(--gray-500-color)'
                        : '',
                    }}
                  />
                }
                active={selectedValue?.id === tab.id}
                className={clsx(
                  COMING_SOON_TAB_IDS.includes(tab.id) && '!pointer-events-none'
                )}
              />
            ))}
          </Menu>
        </div>
        <DashboardSidebarFooter
          isLogoutExpanded={isLogoutExpanded}
          setIsLogoutExpandedState={setIsLogoutExpanded}
          handleLogoutClick={handleLogoutClick}
        />
      </Sidebar>
    </div>
  );
};

export default DashboardSidebar;
