export const setToken = (token: string) => localStorage.setItem('token', token);

export const getToken = () => localStorage.getItem('token');

export const clearToken = () => localStorage.removeItem('token');

export const clearEmail = () => localStorage.removeItem('email');

export const clear = () => localStorage.clear();

export default {
  setToken,
  getToken,
  clearToken,
  clearEmail,
  clear,
};
