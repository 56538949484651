import { useState } from 'react';
import grapesjs from 'grapesjs';

import { SwitchRender } from '@atoms';
import { GalleryItemProps } from '@devTypes';
import EditorImageBlockUploadModal from '@organisms/EditorImageBlockUploadModal/EditorImageBlockUploadModal';
import { imageComponentController } from '@utils/editor/components/image';

import EditorImageBlockSettingsContent from './EditorImageBlockSettingsContent';
import EditorImageBlockSettingsDesign from './EditorImageBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorImageBlockSettings = ({ selectedComponent, type }: Props) => {
  const [selectedImage, setSelectedImage] = useState<GalleryItemProps | null>({
    id: selectedComponent.getAttributes().imageId as number,
    url: selectedComponent.getAttributes().imageUrl as string,
  });
  const [isModalOpen, setIsModalOpen] = useState(
    selectedComponent.getAttributes().isModalOpen
  );

  const handleSelectedImageChange = (
    image: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (Array.isArray(image)) {
      return;
    }

    setSelectedImage(image);
  };

  const handleOpen = () => {
    const { imageId, imageUrl } = selectedComponent.getAttributes();

    if (imageId !== selectedImage?.id) {
      setSelectedImage({ id: imageId, url: imageUrl });
    }

    setIsModalOpen(true);
    selectedComponent.addAttributes({ isModalOpen: true });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    selectedComponent.addAttributes({ isModalOpen: false });
  };

  const handleSelectedImageUpload = (
    image: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (!selectedImage || Array.isArray(image)) {
      return;
    }

    imageComponentController.setImage(selectedComponent, image.url, image.id);
    setSelectedImage(image);
    handleClose();
  };

  return (
    <div className="EditorColumnsBlockSettings">
      <SwitchRender comparator={type} toCompare={['content', 'design']}>
        <EditorImageBlockSettingsContent
          selectedComponent={selectedComponent}
          onUploadImageOpen={handleOpen}
        />
        <EditorImageBlockSettingsDesign selectedComponent={selectedComponent} />
      </SwitchRender>
      <EditorImageBlockUploadModal
        galleryValue={selectedImage}
        isOpen={isModalOpen}
        onClose={handleClose}
        onGalleryValueChange={handleSelectedImageChange}
        onGallerySelectedImageUpload={handleSelectedImageUpload}
      />
    </div>
  );
};

export default EditorImageBlockSettings;
