export const autoSaveService = {
  timeoutId: 0,
  triggerTimeout(callback: () => void, timeout = 5000) {
    window.clearTimeout(this.timeoutId);

    this.timeoutId = window.setTimeout(() => {
      callback();
    }, timeout);
  },
  clearTimeout() {
    window.clearTimeout(this.timeoutId);
  },
};
