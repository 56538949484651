import { Recipient } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import { BouncedEmailData, InvalidEmailData, SpamReportData } from './api';
import {
  createRecipientWithFileThunk,
  createRecipientWithFormThunk,
  deleteRecipientsThunk,
  editRecipientThunk,
  getBouncedContactsThunk,
  getInvalidEmailThunk,
  getRecipientsThunk,
  getSpamReportsThunk,
  updateRecipientStatusThunk,
} from './asyncThunks';

type LoadingState = {
  isLoading: boolean;
};

interface RecipientsState extends LoadingState {
  data: Array<Recipient>;
  total: number;
  limit: number;
  offset: number;
  metadata: {
    totalCount: number;
    subsvriberCount: number;
    unsubscribedCount: number;
  };
}

const RecipientsInitialState: RecipientsState = {
  isLoading: false,
  data: [],
  total: 0,
  limit: 0,
  offset: 0,
  metadata: {
    totalCount: 0,
    subsvriberCount: 0,
    unsubscribedCount: 0,
  },
};

interface InitialState extends LoadingState {
  recipients: RecipientsState;
  spamContacts?: SpamReportData[];
  invalidEmails?: InvalidEmailData[];
  bouncedEmails?: BouncedEmailData[];
}

const initialState: InitialState = {
  isLoading: false,
  recipients: RecipientsInitialState,
};

const allContactsSlice = createSlice({
  name: 'allContacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecipientsThunk.fulfilled, (state, { payload }) => ({
      ...state,
      recipients: {
        isLoading: false,
        ...payload,
      },
    }));
    builder.addCase(getRecipientsThunk.pending, (state) => ({
      ...state,
      recipients: {
        ...state.recipients,
        isLoading: true,
      },
    }));
    builder.addCase(createRecipientWithFormThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(createRecipientWithFormThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(createRecipientWithFileThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(createRecipientWithFileThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(updateRecipientStatusThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(updateRecipientStatusThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(editRecipientThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(editRecipientThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(editRecipientThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(deleteRecipientsThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(deleteRecipientsThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(deleteRecipientsThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(getSpamReportsThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getSpamReportsThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(getSpamReportsThunk.fulfilled, (state, { payload }) => ({
      ...state,
      isLoading: false,
      spamContacts: payload,
    }));
    builder.addCase(getInvalidEmailThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getInvalidEmailThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(getInvalidEmailThunk.fulfilled, (state, { payload }) => ({
      ...state,
      isLoading: false,
      invalidEmails: payload,
    }));
    builder.addCase(getBouncedContactsThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getBouncedContactsThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(
      getBouncedContactsThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        isLoading: false,
        bouncedEmails: payload,
      })
    );
  },
});

export default allContactsSlice.reducer;
