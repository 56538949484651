import { MouseEventHandler, useState } from 'react';
import { Color } from 'react-color-palette';
import clsx from 'clsx';

import { Button, Checkbox } from '@atoms';
import { ColorPicker } from '@molecules';

import './styles.css';

export type BackgroundImage = {
  url: string;
  baseColor: string;
  style: object;
};

interface Props {
  color: Color;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  image: string | null;
  onImageClick: (img: BackgroundImage) => void;
  onColorChange: (color: string) => void;
}

export const defaultPatternBackgroundImageStyle = {
  'background-size': '700px',
  'background-repeat': 'repeat',
};

export const defaultImages: BackgroundImage[] = [
  {
    url: 'https://appmail-images.s3.amazonaws.com/background/pattern-1.png',
    baseColor: '#6dbdd6',
    style: defaultPatternBackgroundImageStyle,
  },
  {
    url: 'https://appmail-images.s3.amazonaws.com/background/pattern-2.png',
    baseColor: '#0f2e6e',
    style: defaultPatternBackgroundImageStyle,
  },
];

const Frame = ({
  image,
  color,
  setColor,
  onImageClick,
  onColorChange,
}: Props) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const handleColorPickerOpen: MouseEventHandler = () => {
    setIsColorPickerOpen(true);
  };

  const handleColorPickerClose: MouseEventHandler = () => {
    setIsColorPickerOpen(false);
  };

  const handleImageSelect: (
    item: BackgroundImage
  ) => MouseEventHandler<HTMLDivElement> = (item) => (event) => {
    // @ts-ignore
    if (!event.target.classList.contains('bg-preset-image')) return;

    onImageClick(item);
  };

  return (
    <div className="color-picker-with-images">
      <div className="image-panel-container">
        {defaultImages.map((item) => {
          const isSelected = image === item.url;

          return (
            <div
              key={item.url}
              className={clsx(isSelected && 'selected', 'bg-preset-image')}
              style={{
                backgroundColor: item.baseColor,
                backgroundImage: `url(${item.url})`,
              }}
              role="button"
              tabIndex={0}
              onClick={handleImageSelect(item)}
            >
              {isSelected && (
                <>
                  <div className={clsx('checkbox')}>
                    <Checkbox isChecked onChange={() => {}} type="primary" />
                  </div>
                  <ColorPicker
                    anchorEl={
                      <Button
                        title="EDIT"
                        variant="secondary"
                        onClick={handleColorPickerOpen}
                        className={clsx(
                          'bg-preset-image-btn',
                          !isSelected && 'display-hidden'
                        )}
                      />
                    }
                    isOpen={isColorPickerOpen}
                    onClose={handleColorPickerClose}
                    color={color}
                    setColor={setColor}
                    onChange={onColorChange}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Frame;
