import parse from 'html-react-parser';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Divider } from '@atoms';
import { EcommerceDropdown, EcommerceSelect } from '@molecules';
import noImageAvailable from '@assets/images/no-image-available.png';
import { TEXT_FONT_FAMILY_OPTIONS } from '@constants/editor';
import {
  EcommerceLayoutProps,
  ShopifyProduct,
  ShopifyProductImage,
  ShopifyProductOption,
} from '@devTypes';
import EcommerceLayoutCart from '@organisms/EcommerceLayout1Cart/EcommerceLayout1Cart';

import EcommerceLayout1Navigation from './EcommerceLayout1Navigation';

import './styles.css';

const EcommerceLayout1 = ({
  products,
  selectedProduct,
  onChange,
  isProductDescription,
  headerFont,
  textColor,
  headerTextColor,
  isHeaderBold,
  isHeaderItalic,
  productTabFillColor,
  productTabLineColor,
  productTabTextColor,
  dropdownGeneralButtonsStrokeColor,
  checkoutButtonTextColor,
  checkoutButtonFillColor,
  checkoutButtonBorderColor,
  checkoutButtonBorderRadius,
  checkoutButtonBorderWidth,
  checkoutButtonText,
  isCartOpen,
  onCartChange,
  onHeroImageChange,
  currentHeroImage,
  backgroundColor,
}: EcommerceLayoutProps) => {
  const styles = `
    .EcommerceLayout1 {
      background-color: ${backgroundColor};
    }

    .EcommerceLayout1 .EcommerceLayout1Navigation  {
      border-color: ${productTabLineColor};
    }

    .EcommerceLayout1 .EcommerceLayout1NavigationItem{
      border-color: ${productTabLineColor};
      color: ${textColor};
    }

    .EcommerceLayout1 .EcommerceLayout1NavigationItem-selected:last-child{
      border: 1px solid;
      border-color: ${productTabLineColor};
      border-bottom: 0;
    }
    
    .EcommerceLayout1 .EcommerceLayout1NavigationItem:not(:first-child){
      border-left: 0;
    }

    .EcommerceLayout1 .EcommerceLayout1NavigationItem-selected{
      color: ${productTabTextColor};
      background-color: ${productTabFillColor};
    }

    .EcommerceLayout1Cart {
      fill: ${productTabTextColor};
    }

    .EcommerceLayout1NavigationItem-last {
      border-top-right-radius: 4px;
    }

    .EcommerceLayout1 .EcommerceLayout1Content .Divider {
      border-color: ${productTabLineColor};
    }

    .EcommerceLayout1 .EcommerceLayout1ContentTitle  {
      font-family: ${TEXT_FONT_FAMILY_OPTIONS[headerFont]};
      color: ${headerTextColor};
      font-weight: ${isHeaderBold ? 700 : 400};
      font-style: ${isHeaderItalic ? 'italic' : 'normal'};
    }

    .EcommerceLayout1 .EcommerceLayout1ContentPrice  {
      color: ${textColor};
    }

    .EcommerceLayout1 .EcommerceSelect > p  {
      color: ${textColor};
    }

    .EcommerceLayout1 .EcommerceLayout1ContentStartingFrom {
      color: ${textColor};
    }

    .EcommerceLayout1 .EcommerceButton, .EcommerceSelect .DropdownHeader {
      border-color: ${dropdownGeneralButtonsStrokeColor};
    }

    .EcommerceSelect .DropdownHeader svg {
      stroke: ${dropdownGeneralButtonsStrokeColor};
    }

    .EcommerceLayout1 .EcommerceButton, .EcommerceSelect .Select SelectOption SelectOption-selected {
      color: ${textColor}!important;
    }

    .EcommerceLayout1 .EcommerceButton, .EcommerceSelect .DropdownHeader .Typography-root {
      color: ${textColor};
    }

    .EcommerceLayout1 .EcommerceLayout1CartCheckoutButton{
      color: ${checkoutButtonTextColor};
      background-color: ${checkoutButtonFillColor};
      border-color: ${checkoutButtonBorderColor};
      border-radius: ${checkoutButtonBorderRadius}px;
      border-width: ${checkoutButtonBorderWidth}px;
      border-style: solid;
    }
  `;

  const isHiddenOptions = () =>
    selectedProduct.options[0].values.includes('Default Title');

  return (
    <div className="EcommerceLayout1 p-5">
      <style>{styles}</style>
      <EcommerceLayout1Navigation
        options={products}
        value={selectedProduct}
        onChange={(x: ShopifyProduct) => {
          onHeroImageChange(x.images[0]);
          onChange(x);
        }}
        onCartOpen={onCartChange}
        productAmount={products.length}
      />
      {isCartOpen ? (
        <EcommerceLayoutCart
          products={products}
          checkoutButtonText={checkoutButtonText}
        />
      ) : (
        <div className="EcommerceLayout1Content flexbox-row flexbox-jc-space-between">
          <div className="flexbox-column flexbox-gap-20px">
            <img
              className="EcommerceLayout1ContentMainImage"
              src={
                currentHeroImage?.src ??
                selectedProduct?.images[0]?.src ??
                noImageAvailable
              }
              alt={selectedProduct?.title}
            />
            <Swiper
              modules={[Navigation]}
              spaceBetween={15}
              slidesPerView="auto"
              slidesPerGroupAuto
              edgeSwipeThreshold={15}
              navigation={{
                disabledClass:
                  'EcommerceLayout1Content-Swiper-NavigationDisabled',
              }}
              className="EcommerceLayout1Content-Swiper"
            >
              {selectedProduct?.images.map((img: ShopifyProductImage) => (
                <SwiperSlide
                  key={img.id}
                  className="EcommerceLayout1Content-SwiperSlide"
                >
                  <span
                    className="EcommerceLayout1ContentSecondaryImageWrapper"
                    role="button"
                    tabIndex={0}
                    onMouseDown={() => onHeroImageChange(img)}
                    onClick={() => onHeroImageChange(img)}
                  >
                    <img
                      className={`EcommerceLayout1ContentSecondaryImage cursor-pointer ${
                        img.id === currentHeroImage?.id && 'border border-black'
                      }`}
                      src={img.src}
                      alt={selectedProduct.title}
                    />
                  </span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="flexbox-column">
            <div className="flexbox-column flexbox-gap-10px">
              <div className="EcommerceLayout1ContentTitle">
                {selectedProduct?.title}
              </div>
              <div>
                <div className="EcommerceLayout1ContentStartingFrom">
                  Starting from
                </div>
                <div className="EcommerceLayout1ContentPrice">
                  ${selectedProduct?.variants[0].price}
                </div>
              </div>
            </div>
            <Divider />
            <div className="flex flex-col">
              {!isHiddenOptions() && (
                <div className="EcommerceLayout1ContentOptionsList mb-6 flexbox-row flexbox-wrap flexbox-jc-space-between flexbox-gap-12px">
                  {selectedProduct.options.map(
                    (option: ShopifyProductOption) => (
                      <div
                        style={{ maxWidth: 150, width: 150 }}
                        key={option.id}
                      >
                        <EcommerceSelect
                          title={option.name.toUpperCase()}
                          options={option.values}
                          value={option.values[0]}
                        />
                      </div>
                    )
                  )}
                </div>
              )}
              <div className="EcommerceButton mb-6 flex justify-center items-center border rounded p-2.5 font-Helvetica">
                Add to cart
              </div>
              {isProductDescription && (
                <EcommerceDropdown
                  title="Product description"
                  content={parse(selectedProduct.body_html)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EcommerceLayout1;
