import { LoadingState, ShopifyProduct } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import {
  createShopifyConnectionThunk,
  deleteShopifyConnectionThunk,
  storeNameThunk,
  verifyShopifyConnectionThunk,
} from './asyncThunks';

interface InitialState {
  isLoading: boolean;
  shopConnectionMessage: string;
  isShopifyConnected: boolean;
  products: LoadingState & { data: ShopifyProduct[] };
  shop: any;
}

const initialState: InitialState = {
  isLoading: false,
  isShopifyConnected: false,
  shopConnectionMessage: '',
  products: {
    data: [],
    isLoading: false,
  },
  shop: {},
};

const shopifySlice = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteShopifyConnectionThunk.fulfilled, (state) => ({
      ...state,
      isShopifyConnected: false,
    }));
    builder.addCase(
      createShopifyConnectionThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        shopConnectionMessage: payload?.message || '',
        isLoading: false,
      })
    );
    builder.addCase(createShopifyConnectionThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(createShopifyConnectionThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(
      verifyShopifyConnectionThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        products: {
          data: payload.products ?? [],
          isLoading: false,
        },
        isLoading: false,
        isShopifyConnected: true,
      })
    );
    builder.addCase(verifyShopifyConnectionThunk.rejected, (state) => ({
      ...state,
      products: {
        data: [],
        isLoading: false,
      },
    }));
    builder.addCase(verifyShopifyConnectionThunk.pending, (state) => ({
      ...state,
      products: {
        ...state.products,
        isLoading: true,
      },
    }));
    builder.addCase(storeNameThunk.fulfilled, (state, { payload }) => ({
      ...state,
      shop: {
        data: payload.shop ?? {},
        isLoading: false,
      },
      isLoading: false,
      isShopifyConnected: true,
    }));
    builder.addCase(storeNameThunk.rejected, (state) => ({
      ...state,
      shop: {
        data: {},
        isLoading: false,
      },
    }));
    builder.addCase(storeNameThunk.pending, (state) => ({
      ...state,
      shop: {
        ...state.shop,
        isLoading: true,
      },
    }));
  },
});

export default shopifySlice.reducer;
