import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { ROUTES } from '@constants/routes';
import { HoursFormat, RecipientType, Sender, TemplateObject } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { allContactsSelectors } from '@store/features/allcontacts';
import { getRecipientsThunk } from '@store/features/allcontacts/asyncThunks';
import { campaignsSelectors } from '@store/features/campaigns';
import {
  getCampaignsThunk,
  scheduleCampaignThunk,
  sendCampaignThunk,
  updateCampaignThunk,
} from '@store/features/campaigns/asyncThunks';
import { domainAsyncThunks } from '@store/features/domains';
import { useAppDispatch } from '@store/hooks';
import { buildCampaingName, trackToGTM, trackToZappier } from '@utils/helpers';
import { createCampaignSchema } from '@utils/validation';
import CreateCampaignView from '@views/CreateCampaignView/CreateCampaignView';

type DefaultValues = {
  id: number;
  title: string;
  status?: string;
  subject: string;
  previewText: string;
  senderId: number | null;
  senderName: string;
  recipientType: RecipientType;
  date?: string;
  time?: string;
  hours: '';
  minutes: '';
  hoursFormat: HoursFormat;
  templateId: undefined | number;
};

const CreateCampaignPage = () => {
  const [selectedSender, setSelectedSender] = useState<Sender>();
  const [scheduleModalIsOpen, setScheduleModalIsOpen] = useState(false);
  const { data: recipients } = useSelector(allContactsSelectors.recipients);
  const { campaign } = useSelector(campaignsSelectors.campaignObject);
  const { state } = useLocation();
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateObject | null>(
      state?.selectedTemplateItem || campaign?.template
    );

  const defaultValues: DefaultValues = {
    id: 0,
    title:
      campaign?.title ||
      buildCampaingName(campaign?.createdAt || new Date().toISOString()),
    subject: '',
    previewText: '',
    senderId: null,
    senderName: '',
    recipientType: 'all',
    date: format(new Date(), 'MM/dd/yyyy'),
    hours: '',
    minutes: '',
    hoursFormat: 'AM',
    templateId: 0,
  };

  type FormValues = typeof defaultValues;

  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(createCampaignSchema),
  });

  const { handleSubmit, setValue, watch, clearErrors, trigger } = methods;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useFormPersist('form', { watch, setValue });

  useEffect(() => {
    if (selectedTemplate) {
      setValue('templateId', selectedTemplate.id);
      trigger();
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (state?.selectedTemplateItem?.id) {
      setSelectedTemplate(state.selectedTemplateItem);
      setValue('templateId', state.selectedTemplateItem.id);
    } else if (campaign?.template) {
      setSelectedTemplate(campaign.template);
      setValue('templateId', campaign.template?.id);
    }
  }, [campaign?.templateId, state?.selectededTemplateItem]);

  const setCampaignDefaultValues = () => {
    setValue('title', buildCampaingName(campaign.createdAt || ''));
    setValue('senderId', null);
  };

  const initiateCampaignValues = () => {
    setValue('id', campaign.id);
    setValue('title', campaign.title || buildCampaingName(campaign.createdAt));
    setValue('previewText', campaign.previewText);
    setValue('subject', campaign.subject);
    setValue('recipientType', campaign.recipientType);
    setValue('senderId', selectedSender?.id || campaign.senderId || null);
    setValue('senderName', campaign.senderName);
    setValue(
      'templateId',
      campaign.templateId !== null ? campaign.templateId : selectedTemplate?.id
    );
  };

  useEffect(() => {
    clearErrors();
    dispatch(domainAsyncThunks.getAvailableSendersThunk(null))
      .unwrap()
      .then(async ({ addresses }: any) => {
        await setSelectedSender(addresses[0]);
        if (!recipients) {
          dispatch(getRecipientsThunk({}));
        }
        if (campaign) {
          initiateCampaignValues();
        } else {
          setCampaignDefaultValues();
        }
        trigger();
      });
  }, []);

  const onSaveCampaign = async () => {
    const campaignBody = methods.getValues();

    const { date, time, hours, minutes, hoursFormat, ...campaignRequestBody } =
      campaignBody;

    await dispatch(updateCampaignThunk(campaignRequestBody));
  };

  const onSendCampaign = async () => {
    const campaignBody = methods.getValues();
    const { title } = campaignBody;
    await onSaveCampaign();
    await dispatch(sendCampaignThunk({ id: campaign.id }));

    trackToGTM({ event: 'campaign_published', title });
    trackToZappier({ event: 'campaign_published', title });

    await dispatch(getCampaignsThunk({}));
    navigate(ROUTES.dashboard.allcampaigns);
    sessionStorage.removeItem('form');
  };

  const onScheduleCampaign: SubmitHandler<FormValues> = async (values) => {
    setScheduleModalIsOpen(false);

    const {
      id,
      title,
      subject,
      previewText,
      senderId,
      senderName,
      templateId,
      recipientType,
      date,
      hours,
      minutes,
      hoursFormat,
    } = values;
    if (!templateId) return;

    let scheduledAt;

    if (date) {
      const [month, day, year] = date.split('/');
      const fullHours =
        hoursFormat === 'PM' ? parseInt(hours, 10) + 12 : parseInt(hours, 10);
      scheduledAt = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1, // since month index is starting from 0 to 11
        parseInt(day, 10),
        +fullHours,
        +minutes
      ).toISOString();
    } else {
      scheduledAt = new Date().toISOString();
    }

    const scheduleCampaignData = {
      id,
      title,
      subject,
      previewText,
      senderName,
      senderId,
      templateId,
      recipientType,
      scheduledAt,
    };

    setScheduleModalIsOpen(false);

    dispatch(scheduleCampaignThunk(scheduleCampaignData))
      .unwrap()
      .then(async () => {
        trackToZappier({ event: 'campaign_scheduled', title });
        trackToGTM({ event: 'campaign_scheduled', title });
        await dispatch(getCampaignsThunk({}));
        navigate(ROUTES.dashboard.allcampaigns);
        sessionStorage.removeItem('form');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickTemplate = () => {
    onSaveCampaign();
    navigate(`${ROUTES.template.editor}/${selectedTemplate?.id}`);
  };

  return (
    <FormProvider {...methods}>
      <CreateCampaignView
        linkedTemplate={selectedTemplate}
        onSaveCampaign={onSaveCampaign}
        onClickTemplate={onClickTemplate}
        onSendCampaign={onSendCampaign}
        onScheduleCampaign={handleSubmit(onScheduleCampaign)}
        setSelectedTemplate={setSelectedTemplate}
        scheduleModalIsOpen={scheduleModalIsOpen}
        setScheduleModalIsOpen={setScheduleModalIsOpen}
      />
    </FormProvider>
  );
};

export default CreateCampaignPage;
