import { Button, Icon } from '@atoms';
import { EditorTextBlockFormatSettings } from '@molecules';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { EditorBlockSettingsComponent } from '@devTypes';

import './styles.css';

interface Props extends EditorBlockSettingsComponent {}

const EditorTextBlockContentSettings = ({ selectedComponent }: Props) => (
  <div className="EditorTextBlockContentSettings">
    <EditorTextBlockFormatSettings selectedComponent={selectedComponent} />
    {/* <EditorTextBlockStylesSettings
      selectedComponent={selectedComponent}
      editor={editor}
    /> */}
    <Button
      title="Add dynamic value"
      variant="secondary"
      startIcon={<Icon SVG={PlusIcon} />}
    />
  </div>
);
export default EditorTextBlockContentSettings;
