import { Link as RRDLink, To } from 'react-router-dom';
import clsx from 'clsx';

import { Typography } from '@atoms';
import { TypographyVariant } from '@devTypes';

import './styles.css';

interface Props {
  to: To;
  text: string;
  typographyVariant?: TypographyVariant;
  onClick?: () => void;
  className?: string;
}

const Link = ({
  to,
  text,
  typographyVariant = 'subtitle3',
  onClick,
  className,
}: Props) => (
  <RRDLink to={to} onClick={onClick} className={clsx('Link', className)}>
    <Typography variant={typographyVariant}>{text}</Typography>
  </RRDLink>
);

export default Link;
