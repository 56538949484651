import clsx from 'clsx';

import { Button, HeaderContainer, Icon } from '@atoms';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';

import { Tab } from './DeviceViewTab';
import DeviceViewTabs from './DeviceViewTabs';

interface Props {
  selectedTab: Tab;
  tabs: Tab[];
  onDeviceTabChange: (tab: Tab) => void;
  onGoBackClick: () => void;
  onSendTestEmail: () => void;
  isSendTestEmailDisabled: boolean;
}

const Header = ({
  selectedTab,
  tabs,
  onDeviceTabChange,
  onGoBackClick,
  onSendTestEmail,
  isSendTestEmailDisabled,
}: Props) => (
  <HeaderContainer
    className={clsx(isSendTestEmailDisabled && 'PreviewTemplate-Preset-Header')}
  >
    <Button
      title="Go back"
      variant="secondary"
      startIcon={<Icon SVG={ArrowLeftIcon} height={20} width={20} />}
      onClick={onGoBackClick}
    />
    <DeviceViewTabs
      tabs={tabs}
      selectedTab={selectedTab}
      onChange={onDeviceTabChange}
    />
    {!isSendTestEmailDisabled && (
      <Button
        title="Send Test Email"
        variant="primary"
        onClick={onSendTestEmail}
      />
    )}
  </HeaderContainer>
);

export default Header;
