import grapesjs from 'grapesjs';

import { createEditorBlock } from '@utils/helpers';

const icon = `
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3028_9786)">
      <mask id="path-2-inside-1_3028_9786" fill="white">
        <rect x="17.3333" y="13.3333" width="13.3333" height="20" rx="1"/>
      </mask>
      <rect x="17.3333" y="13.3333" width="13.3333" height="20" rx="1" stroke="#344054" stroke-width="3" mask="url(#path-2-inside-1_3028_9786)"/>
      <mask id="path-3-inside-2_3028_9786" fill="white">
        <rect x="8" y="16" width="6.66667" height="14.6667" rx="1"/>
      </mask>
      <rect x="8" y="16" width="6.66667" height="14.6667" rx="1" stroke="#344054" stroke-width="3" mask="url(#path-3-inside-2_3028_9786)"/>
      <mask id="path-4-inside-3_3028_9786" fill="white">
        <rect x="33.3333" y="16" width="6.66667" height="14.6667" rx="1"/>
      </mask>
      <rect x="33.3333" y="16" width="6.66667" height="14.6667" rx="1" stroke="#344054" stroke-width="3" mask="url(#path-4-inside-3_3028_9786)"/>
    </g>
    <defs>
      <clipPath id="clip0_3028_9786">
        <rect width="32" height="32" fill="white" transform="translate(8 8)"/>
      </clipPath>
    </defs>
  </svg>

`;
const label = createEditorBlock('Image carousel', icon, 'carousel');

export const CarouselBlock: grapesjs.BlockOptions = {
  label,
  content: '<div data-gjs-type="carousel"></div>',
  select: true,
};
