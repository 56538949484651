import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Button, SwitchRender, Typography } from '@atoms';
import { FileUploadBar, Modal, TabLikeMenuItem } from '@molecules';
import { ReactComponent as CloudIcon } from '@assets/icons/cloud.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import { ReactComponent as UploadIcon } from '@assets/icons/upload.svg';
import { GalleryItemProps, ModalComponent } from '@devTypes';
import Menu from '@organisms/Menu/Menu';
import { galleryAsyncThunks } from '@store/features/gallery';
import { useAppDispatch } from '@store/hooks';

import EditorImageBlockUploadModalMyUploads from './EditorImageBlockUploadModalMyUploads';
import EditorImageBlockUploadModalFromUrl from './EditorImageBlockUploadModalUploadFromUrl';
import EditorImageBlockUploadModalUploadNew from './EditorImageBlockUploadModalUploadNew';

import './styles.css';

const UPLOAD_NEW_ID = 1;
const MY_UPLOADS_ID = 2;
const UPLOAD_FROM_URL_ID = 3;

const tabs = [
  {
    id: UPLOAD_NEW_ID,
    icon: UploadIcon,
    title: 'Upload new',
  },
  {
    id: MY_UPLOADS_ID,
    icon: CloudIcon,
    title: 'My uploads',
  },
  {
    id: UPLOAD_FROM_URL_ID,
    icon: LinkIcon,
    title: 'Upload from URL',
  },
];

interface Props extends ModalComponent {
  galleryValue: GalleryItemProps | GalleryItemProps[] | null;
  onGalleryValueChange: (value: GalleryItemProps[] | GalleryItemProps) => void;
  onGallerySelectedImageUpload: (
    value: GalleryItemProps[] | GalleryItemProps
  ) => void;
  onImageUploaded?: (image: GalleryItemProps) => void;
}

const uploadNewDefaultValues: { altText: string; image: File | null } = {
  altText: '',
  image: null,
};

const uploadByUrlDefaultValues: {
  image: File | null;
  altText: string;
  imageUrl: string;
} = {
  altText: '',
  image: null,
  imageUrl: '',
};

const EditorImageBlockUploadModal = ({
  galleryValue,
  isOpen,
  onClose,
  onGalleryValueChange,
  onGallerySelectedImageUpload,
  onImageUploaded = () => {},
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const uploadNewMethods = useForm({ defaultValues: uploadNewDefaultValues });
  const uploadByUrlMethods = useForm({
    defaultValues: uploadByUrlDefaultValues,
  });

  const { handleSubmit: handleUploadNewSubmit } = uploadNewMethods;
  const { handleSubmit: handleUploadByUrlSubmit } = uploadByUrlMethods;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(galleryAsyncThunks.getAllUrlsThunk(null));
  }, []);

  const resetModalState = () => {
    setSelectedTab(tabs[0]);
    uploadNewMethods.reset();
    uploadByUrlMethods.reset();
  };

  const uploadImage = async (imageToUpload: File) => {
    try {
      const {
        payload: { url },
      } = await dispatch(
        galleryAsyncThunks.getPresignedUrlThunk({
          fileName: imageToUpload.name,
        })
      );

      setIsFileUploading(true);

      await axios(url, {
        method: 'PUT',
        data: imageToUpload,
        headers: { 'Content-Type': '' },
        onUploadProgress: (progressEvent) => {
          if (!progressEvent.total) {
            setIsFileUploading(false);
            return;
          }

          setImageUploadProgress(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (progressEvent.loaded === progressEvent.total) {
            setIsFileUploading(false);
          }
        },
      });

      const imageLink = url.split('?')[0];

      const {
        payload: { id },
      } = await dispatch(galleryAsyncThunks.saveUrlThunk({ url: imageLink }));

      const uploadedImage = {
        id,
        url: imageLink,
      };

      onGallerySelectedImageUpload(uploadedImage);
      onGalleryValueChange(uploadedImage);
      onImageUploaded(uploadedImage);
      resetModalState();
    } catch {
      toast.error('Error uploading an image');
    } finally {
      await dispatch(galleryAsyncThunks.getAllUrlsThunk(null));
    }
  };

  const handleUploadValidSubmit = (values: any) => {
    if (!values.image) {
      return;
    }

    uploadImage(values.image);
  };

  const onSubmit = () => {
    switch (selectedTab.id) {
      case UPLOAD_NEW_ID:
        handleUploadNewSubmit(handleUploadValidSubmit)();
        break;
      case MY_UPLOADS_ID:
        if (galleryValue) {
          onGallerySelectedImageUpload(galleryValue);
          resetModalState();
        }
        break;
      case UPLOAD_FROM_URL_ID:
        handleUploadByUrlSubmit(handleUploadValidSubmit)();
        break;
      default:
    }
  };

  const handleTabChange = (newTab: typeof tabs[0]) => {
    setSelectedTab(newTab);
  };

  const handleClose = () => {
    resetModalState();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="EditorImageBlockUploadModal">
        <Typography variant="subtitle1">Image</Typography>
        <div className="EditorImageBlockUploadModalContent">
          <Menu
            value={selectedTab}
            items={tabs}
            ItemComponent={TabLikeMenuItem}
            onChange={handleTabChange}
            spacing={16}
          />
          <div className="EditorImageBlockUploadModalContentCurrentTab flexbox-column flexbox-gap-32px">
            <SwitchRender
              comparator={selectedTab.id}
              toCompare={[UPLOAD_NEW_ID, MY_UPLOADS_ID, UPLOAD_FROM_URL_ID]}
            >
              <FormProvider {...uploadNewMethods}>
                <EditorImageBlockUploadModalUploadNew />
              </FormProvider>
              <EditorImageBlockUploadModalMyUploads
                galleryValue={galleryValue}
                onGalleryValueChange={onGalleryValueChange}
              />
              <FormProvider {...uploadByUrlMethods}>
                <EditorImageBlockUploadModalFromUrl />
              </FormProvider>
            </SwitchRender>
          </div>
        </div>
        <div className="EditorImageBlockUploadModalFooter">
          {isFileUploading ? (
            <FileUploadBar value={imageUploadProgress} />
          ) : (
            <div className="flexbox-row flexbox-jc-flex-end flexbox-gap-16px">
              <Button
                title="Cancel"
                variant="secondary"
                onClick={handleClose}
              />
              <Button
                title="Upload image"
                variant="primary"
                onClick={onSubmit}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditorImageBlockUploadModal;
