import grapesjs from 'grapesjs';

import EditorFooterBlockSettingsContent from './EditorFooterBlockContentSettings';
import EditorFooterBlockSettingsDesign from './EditorFooterBlockDesignSettings';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorFooterBlockSettings = ({ selectedComponent, type }: Props) => {
  const renderSettings = () => {
    if (type === 'content') {
      return (
        <EditorFooterBlockSettingsContent
          selectedComponent={selectedComponent}
        />
      );
    }

    if (type === 'design') {
      return (
        <EditorFooterBlockSettingsDesign
          selectedComponent={selectedComponent}
        />
      );
    }

    return null;
  };

  return <div className="EditorFooterBlockSettings">{renderSettings()}</div>;
};

export default EditorFooterBlockSettings;
