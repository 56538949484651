import clsx from 'clsx';

import { DefaultInputProps } from '@devTypes';

const DefaultInput = ({
  placeholder,
  type,
  startIcon,
  endIcon,
  onChange,
  value,
}: DefaultInputProps) => (
  <div className="Input">
    {startIcon}
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={clsx({
        'Input-with-start-icon': startIcon,
        'Input-with-end-icon': endIcon,
      })}
      placeholder={placeholder}
    />
    {endIcon}
  </div>
);

export default DefaultInput;
