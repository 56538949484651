import { useState } from 'react';
import { Color } from 'react-color-palette';

import { Typography } from '@atoms';
import ColorPicker from '@molecules/ColorPicker/ColorPicker';

import './styles.css';

interface Props {
  color: Color;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  onChange?: (color: string) => void;
  onClose?: () => void;
  title?: string;
  titleVariant?: 'bold' | 'regular';
}

const ColorPickerInput = ({
  color,
  setColor,
  onChange,
  title,
  titleVariant,
  onClose = () => {},
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleColorPickerClose = () => {
    if (isOpen) {
      onClose();
    }
    setIsOpen(false);
  };

  const handleColorPickerToggle = () => setIsOpen((prevState) => !prevState);

  return (
    <div className="ColorPickerInputWithTitle">
      {title && (
        <Typography
          className="Typography-gray-main"
          weight={titleVariant}
          variant={titleVariant === 'bold' ? 'subtitle5' : 'subtitle4'}
        >
          {title}
        </Typography>
      )}

      <ColorPicker
        anchorEl={
          <div
            className="ColorPickerInput"
            onClick={handleColorPickerToggle}
            role="button"
            tabIndex={0}
          >
            <input
              value={color.hex}
              className="TextInput ColorPickerInput-Text"
              disabled
            />
            <div
              className="ColorPickerInput-value"
              style={{ backgroundColor: color.hex }}
            />
          </div>
        }
        isOpen={isOpen}
        onClose={handleColorPickerClose}
        color={color}
        setColor={setColor}
        onChange={onChange}
      />
    </div>
  );
};

export default ColorPickerInput;
