import { Button } from '@atoms';
import HeaderTitle from '@organisms/ShopDetails/HeaderTitle';

interface HeaderProps {
  onSave: () => void;
  isSaveDisabled: boolean;
  title: string;
  subtitle: string;
  buttonTitle: string;
}

const Header = ({
  onSave,
  isSaveDisabled,
  title,
  subtitle,
  buttonTitle,
}: HeaderProps) => (
  <div className="ShopDetails-Header">
    <HeaderTitle title={title} subtitle={subtitle} />
    <Button
      className="min-w-[135px]"
      variant="primary"
      title={buttonTitle}
      onClick={onSave}
      size="md"
      disabled={isSaveDisabled}
      disabledVariant="gray"
    />
  </div>
);

export default Header;
