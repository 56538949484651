import { ShopifyProduct } from '@devTypes';

import EcommerceLayout1FallbackItem from './EcommerceLayout1FallbackItem';

import './styles.css';

interface Props {
  products: ShopifyProduct[];
  dropdownGeneralButtonsStrokeColor: string;
  headerFont: string;
  headerTextColor: string;
  textColor: string;
  isHeaderBold: boolean;
  isHeaderItalic: boolean;
  backgroundColor: string;
}

const EcommerceLayout1Fallback = ({
  products,
  dropdownGeneralButtonsStrokeColor,
  headerFont,
  headerTextColor,
  textColor,
  isHeaderBold,
  isHeaderItalic,
  backgroundColor,
}: Props) => {
  const styles = `

    .EcommerceLayout1FallbackItemInfo {
      background-color: ${backgroundColor};
    }

    .EcommerceLayout1FallbackItemInfoTitle {
      font-family: ${headerFont};
      color: ${headerTextColor};
      font-weight: ${isHeaderBold ? 700 : 400};
      font-style: ${isHeaderItalic ? 'italic' : 'normal'};
    }

    .EcommerceLayout1FallbackItemInfoPrice {
      color: ${textColor};
    }

    .EcommerceLayout1FallbackItemInfoStartingFrom{
      color: ${textColor};
    }

    .EcommerceButton, .EcommerceSelect .DropdownHeader {
      border-color: ${dropdownGeneralButtonsStrokeColor};
    }

    .EcommerceButton {
      color: ${textColor};
    }

  `;
  return (
    <div className="EcommerceLayout1Fallback flexbox-column flexbox-gap-24px">
      <style>{styles}</style>
      {products.map(({ id, image, title, variants }) => (
        <EcommerceLayout1FallbackItem
          key={id}
          image={image.src}
          title={title}
          price={variants[0].price}
        />
      ))}
    </div>
  );
};

export default EcommerceLayout1Fallback;
