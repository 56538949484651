import clsx from 'clsx';

import { Divider, Icon } from '@atoms';
import { ReactComponent as CollapsedAppMailLogo } from '@assets/icons/dashboard-sidebar-appmail-logo.svg';
import { ReactComponent as AppMailLogo } from '@assets/icons/full-dashboard-sidebar-appmail-logo.svg';

interface IdashboardSidebarHeader {
  collapsed: boolean;
}

const DashboardSidebarHeader = ({ collapsed }: IdashboardSidebarHeader) => (
  <div
    className={clsx(
      'DashboardSidebarHeader',
      !collapsed && 'DashboardSidebarHeader-open'
    )}
  >
    {collapsed ? (
      <Icon SVG={CollapsedAppMailLogo} width={32} height={32} />
    ) : (
      <Icon SVG={AppMailLogo} width={216} height={32} />
    )}
    <Divider className="DashboardSidebarHeader-Divider" />
  </div>
);

export default DashboardSidebarHeader;
