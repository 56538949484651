import { Control } from 'react-hook-form';

import { Icon, Select, Typography } from '@atoms';
import { Input } from '@molecules';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';

export type SortOption = {
  title: string;
  fieldToSortBy: 'createdAt' | 'title';
  order: 'ASC' | 'DESC';
};

const alphabeticSortOption: SortOption = {
  title: 'Name A to Z',
  fieldToSortBy: 'title',
  order: 'ASC',
};
export const createdDateSortOption: SortOption = {
  title: 'Date created',
  fieldToSortBy: 'createdAt',
  order: 'DESC',
};

export const sortBySelectOptions = [
  alphabeticSortOption,
  createdDateSortOption,
];

export const searchDefaultValues = {
  search: '',
};

export type SearchFormValues = typeof searchDefaultValues;

interface Props {
  control: Control<SearchFormValues, any>;
  sortByValue: SortOption | null;
  onSortBySelect: (value: SortOption) => void;
}

const CampaignsTableSearchAndFilters = ({
  control,
  sortByValue,
  onSortBySelect,
}: Props) => (
  <div className="CampaignsTableSearchAndFilters">
    <Input
      name="search"
      control={control}
      startIcon={<Icon SVG={SearchIcon} />}
      placeholder="Search"
      autoComplete="off"
    />

    <Typography variant="subtitle4" className="SortByLabel">
      Sort by
    </Typography>
    <Select
      value={sortByValue}
      onChange={onSortBySelect}
      options={sortBySelectOptions}
      placeholder="Sort by"
      className="SortBySelect"
    />
  </div>
);

export default CampaignsTableSearchAndFilters;
