import { useEffect, useState } from 'react';

import { Icon, Typography } from '@atoms';
import { ReactComponent as AutoSaveIcon } from '@assets/icons/auto-save.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check.svg';
import { editorSelectors } from '@store/features/editor';
import { useAppSelector } from '@store/hooks';

import './styles.css';

const SavedStateLabel = (
  <>
    <Icon SVG={CheckIcon} width={20} height={20} />
    <Typography className="text-success-500" variant="subtitle4">
      Saved
    </Typography>
  </>
);

const AutoSavingLabel = (
  <>
    <Icon className="animate-spin" SVG={AutoSaveIcon} width={20} height={20} />
    <Typography className="text-gray-500" variant="subtitle4">
      Auto saving
    </Typography>
  </>
);

const SavedState = () => {
  const [isSavedStateLabelVisible, setIsSavedStateLabelVisible] =
    useState(true);
  const isTemplateAutoSaving = useAppSelector(
    editorSelectors.isTemplateAutoSaving
  );

  useEffect(() => {
    if (isTemplateAutoSaving) {
      setIsSavedStateLabelVisible(false);
    } else {
      setIsSavedStateLabelVisible(true);
      setTimeout(() => {
        setIsSavedStateLabelVisible(false);
      }, 3000);
    }
  }, [isTemplateAutoSaving]);

  return (
    <div className="SavedState">
      {isTemplateAutoSaving
        ? AutoSavingLabel
        : isSavedStateLabelVisible && SavedStateLabel}
    </div>
  );
};

export default SavedState;
