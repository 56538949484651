import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import campaignsApi, {
  CampaignData,
  CampaignReportData,
  IdCampaignData,
  ScheduleCampaignData,
  SendTestEmailData,
} from './api';

export const getCampaignsThunk = createCommonAsyncThunk(
  'getCampaigns',
  campaignsApi.getCampaigns
);

export const getCampaignByIdThunk = createCommonAsyncThunk(
  'getCampaignById',
  campaignsApi.getCampaignById
);

export const createCampaignThunk = createCommonAsyncThunk(
  'createCampaign',
  campaignsApi.createCampaign
);

export const updateCampaignThunk = createCommonAsyncThunk<CampaignData>(
  'updateCampaign',
  campaignsApi.updateCampaign
);

export const scheduleCampaignThunk =
  createCommonAsyncThunk<ScheduleCampaignData>(
    'scheduleCampaign',
    campaignsApi.scheduleCampaign
  );

export const sendCampaignThunk = createCommonAsyncThunk<IdCampaignData>(
  'sendCampaign',
  campaignsApi.sendCampaign
);

export const sendTestEmailThunk = createCommonAsyncThunk<SendTestEmailData>(
  'sendTestEmail',
  campaignsApi.sendTestEmail
);

export const getCampaignReport = createCommonAsyncThunk<CampaignReportData>(
  'campaignReport',
  campaignsApi.getCampaignReport
);

export const deleteCampaign = createCommonAsyncThunk<IdCampaignData>(
  'deleteCampaign',
  campaignsApi.deleteCampaignById
);

export default {
  deleteCampaign,
  getCampaignReport,
  getCampaignsThunk,
  getCampaignByIdThunk,
  createCampaignThunk,
  updateCampaignThunk,
  scheduleCampaignThunk,
  sendCampaignThunk,
  sendTestEmailThunk,
};
