import grapesjs from 'grapesjs';

import {
  COLUMNS_LAYOUTS,
  DEFAULT_COLUMNS_LAYOUT,
  EditorComponentTypes,
} from '@constants/editor';
import { getChildComponentAt } from '@utils/helpers';

import {
  generateSingleColumnComponent,
  grapesjsColumnsLayouts,
} from '../components/columns';

const settings = [
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'horizontalSpace',
  'verticalSpace',
  'layout',
  'backgroundColor',
];

const columnsPlugin = (editor: grapesjs.Editor) => {
  editor.DomComponents.addType(EditorComponentTypes.Columns, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Columns,
          layout: DEFAULT_COLUMNS_LAYOUT,
        },
      },
      init() {
        this.listenTo(this, 'change:attributes:layout', this.handleAttrChange);
        this.listenTo(
          this,
          settings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          settings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );

        // Temporary columns fix
        // this.addAttributes({ settingsChanged: true });
      },

      // TODO: should be refactored FOR SURE
      handleAttrChange(first: grapesjs.Component, layout: any) {
        const LAYOUT_A = COLUMNS_LAYOUTS.layoutA.id;
        const LAYOUT_B = COLUMNS_LAYOUTS.layoutB.id;
        const LAYOUT_C = COLUMNS_LAYOUTS.layoutC.id;
        const LAYOUT_A1 = COLUMNS_LAYOUTS.layoutA1.id;

        // @ts-ignore
        const { _previousAttributes: previousAttributes } = first;

        const { layout: previousLayout } = previousAttributes.attributes;

        if (previousLayout === LAYOUT_A && layout === LAYOUT_B) {
          const [firstColumn, secondColumn] = first.components().models;
          const firstColumnText = firstColumn.components().models[1];
          const firstColumnButton = firstColumn.components().models[2];

          secondColumn.components('');

          firstColumnButton.move(secondColumn, { at: 0 });
          firstColumnText.move(secondColumn, { at: 0 });
        }

        if (previousLayout === LAYOUT_A && layout === LAYOUT_C) {
          const [firstColumn, secondColumn] = first.components().models;
          const secondColumnText = secondColumn.components().models[1];
          const secondColumnButton = secondColumn.components().models[2];

          firstColumn.components('');

          secondColumnButton.move(firstColumn, { at: 0 });
          secondColumnText.move(firstColumn, { at: 0 });
        }

        if (previousLayout === LAYOUT_B && layout === LAYOUT_A) {
          const [firstColumn, secondColumn] = first.components().models;
          const secondColumnText = secondColumn.components().models[0];
          const secondColumnButton = secondColumn.components().models[1];

          secondColumnText.move(firstColumn, { at: 1 });
          secondColumnButton.move(firstColumn, { at: 2 });

          secondColumn.components(
            // @ts-ignore
            grapesjsColumnsLayouts.imageTextButtonSingleColumn
          );
        }

        if (previousLayout === LAYOUT_C && layout === LAYOUT_A) {
          const [firstColumn, secondColumn] = first.components().models;
          const firstColumnText = firstColumn.components().models[0];
          const firstColumnButton = firstColumn.components().models[1];

          firstColumnText.move(secondColumn, { at: 1 });
          firstColumnButton.move(secondColumn, { at: 2 });

          firstColumn.components(
            // @ts-ignore
            grapesjsColumnsLayouts.imageTextButtonSingleColumn
          );
        }

        if (
          (previousLayout === LAYOUT_B || previousLayout === LAYOUT_C) &&
          (layout === LAYOUT_B || layout === LAYOUT_C)
        ) {
          const [firstColumn, secondColumn] = first.components().models;

          secondColumn.move(first, { at: 0 });
          firstColumn.move(first, { at: 1 });
        }

        if (previousLayout === LAYOUT_B && layout === LAYOUT_A1) {
          const [firstColumn, secondColumn] = first.components().models;
          const secondColumnText = secondColumn.components().models[0];
          const secondColumnButton = secondColumn.components().models[1];

          secondColumnText.move(firstColumn, { at: 1 });
          secondColumnButton.move(firstColumn, { at: 2 });

          secondColumn.remove();
        }

        if (previousLayout === LAYOUT_C && layout === LAYOUT_A1) {
          const [firstColumn, secondColumn] = first.components().models;
          const secondColumnImage = secondColumn.components().models[0];

          secondColumnImage.move(firstColumn, { at: 0 });

          secondColumn.remove();
        }

        if (previousLayout === LAYOUT_A && layout === LAYOUT_A1) {
          first.components().remove(first.components().models[1]);
        }

        if (previousLayout === LAYOUT_A1 && layout === LAYOUT_A) {
          first
            .components()
            .add(
              generateSingleColumnComponent(
                grapesjsColumnsLayouts.imageTextButtonSingleColumn
              )
            );
        }

        if (previousLayout === LAYOUT_A1 && layout === LAYOUT_B) {
          const firstColumn = first.components().models[0];
          const firstColumnText = firstColumn.components().models[1];
          const firstColumnButton = firstColumn.components().models[2];

          const secondColumn = first
            .components()
            .add(generateSingleColumnComponent([]));

          firstColumnText.move(secondColumn, { at: 1 });
          firstColumnButton.move(secondColumn, { at: 2 });
        }

        if (previousLayout === LAYOUT_A1 && layout === LAYOUT_C) {
          const firstColumn = first.components().models[0];
          const firstColumnImage = firstColumn.components().models[0];

          const secondColumn = first
            .components()
            .add(generateSingleColumnComponent([]));

          firstColumnImage.move(secondColumn, { at: 0 });
        }
      },
    },
  });
};

export default columnsPlugin;
