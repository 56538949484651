import grapesjs from 'grapesjs';

import { grapesjsColumnsLayouts } from '@utils/editor/components/columns';
import { createEditorBlock } from '@utils/helpers';

const icon = `
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path transform="translate(11, 11)" d="M13 1V25M13 1H22.3333C23.0406 1 23.7189 1.28095 24.219 1.78105C24.719 2.28115 25 2.95942 25 3.66667V22.3333C25 23.0406 24.719 23.7189 24.219 24.219C23.7189 24.719 23.0406 25 22.3333 25H13V1ZM13 1H3.66667C2.95942 1 2.28115 1.28095 1.78105 1.78105C1.28095 2.28115 1 2.95942 1 3.66667V22.3333C1 23.0406 1.28095 23.7189 1.78105 24.219C2.28115 24.719 2.95942 25 3.66667 25H13V1Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;
const label = createEditorBlock('Layout', icon, 'columns');
const content = grapesjsColumnsLayouts.layoutA;

export const ColumnsBlock: grapesjs.BlockOptions = {
  label,
  content,
  select: true,
};
