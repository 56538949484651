import './styles.css';

interface Props {
  isChecked: boolean;
  onChange: () => void;
  isDisabled?: boolean;
}

const Toggle = ({ isChecked, onChange, isDisabled = false }: Props) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="switch">
    {!isDisabled ? (
      <input type="checkbox" checked={isChecked} onChange={onChange} />
    ) : null}
    <span className="slider round" />
  </label>
);

export default Toggle;
