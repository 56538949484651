import { MouseEventHandler, useEffect, useState } from 'react';

import { Button, Icon } from '@atoms';
import { SavedState, UndoRedoButton } from '@molecules';
import { ReactComponent as AppMailLogo } from '@assets/icons/AppMailLogo.svg';
import { useEditor } from '@hooks';
import { editorActions, editorSelectors } from '@store/features/editor';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { resizeCanvas } from '@utils/helpers';

import './styles.css';

interface HeaderProps {
  onPreviewClick: MouseEventHandler;
  onSaveClick: MouseEventHandler;
}

const Header = ({ onPreviewClick, onSaveClick }: HeaderProps) => {
  const previousStates = useAppSelector(editorSelectors.previousStates);
  const nextStates = useAppSelector(editorSelectors.nextStates);
  const currentState = useAppSelector(editorSelectors.currentState);
  const [isUndoRedoDisabled, setIsUndoRedoDisabled] = useState(false);

  const dispatch = useAppDispatch();
  const editor = useEditor();

  const isUndoActive = previousStates?.length > 0;
  const isRedoActive = nextStates?.length > 0;

  useEffect(() => {
    document.addEventListener('settingsChange', (event: any) => {
      setIsUndoRedoDisabled(event.detail);
      if (editor) {
        resizeCanvas(editor);
      }
    });
  }, []);

  if (!editor) {
    return null;
  }

  const handleUndo = () => {
    const previousState = previousStates[previousStates.length - 1];

    dispatch(
      editorActions.addNextState({
        gjs: currentState.gjs,
        background: currentState.background,
        editorHeight: currentState.editorHeight,
      })
    );
    dispatch(editorActions.popPreviousState());
    dispatch(editorActions.setCurrentStateGjs(previousState.gjs));
    dispatch(editorActions.setCurrentStateBackground(previousState.background));
    dispatch(
      editorActions.setCurrentStateEditorHeight(previousState.editorHeight)
    );

    editor.loadProjectData(JSON.parse(previousState.gjs));
  };

  const handleRedo = () => {
    const nextState = nextStates[0];

    dispatch(
      editorActions.addPreviousState({
        gjs: currentState.gjs,
        background: currentState.background,
        editorHeight: currentState.editorHeight,
      })
    );
    dispatch(editorActions.shiftNextState());
    dispatch(editorActions.setCurrentStateGjs(nextState.gjs));
    dispatch(editorActions.setCurrentStateBackground(nextState.background));
    dispatch(editorActions.setCurrentStateEditorHeight(nextState.editorHeight));

    editor.loadProjectData(JSON.parse(nextState.gjs));
  };

  return (
    <div className="Header">
      <Icon SVG={AppMailLogo} width={115} height={25} />
      <div className="Header-Toolbar">
        <SavedState />
        <div className="Header-Toolbar-ActionsContainer">
          <UndoRedoButton
            type="undo"
            isActive={isUndoRedoDisabled && isUndoActive}
            onClick={handleUndo}
          />
          <UndoRedoButton
            type="redo"
            isActive={isUndoRedoDisabled && isRedoActive}
            onClick={handleRedo}
          />
        </div>
        <div className="Header-Toolbar-ButtonsContainer">
          <Button
            variant="secondary"
            title="Preview and Test"
            onClick={onPreviewClick}
          />
          <Button
            variant="primary"
            title="Save and exit"
            onClick={onSaveClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
