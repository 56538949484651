import {
  DEFAULT_BACKGROUND_VALUE,
  DEFAULT_SPACER_HEIGHT_IN_PX,
  EditorComponentTypes,
} from '@constants/editor';

export const SPACER_WRAPPER_CLASS = 'gjs-spacer-wrapper';
export const generateGrapesjsSpacerComponent = () => ({
  tagName: 'div',
  attributes: {
    class: SPACER_WRAPPER_CLASS,
    height: DEFAULT_SPACER_HEIGHT_IN_PX,
  },
  type: EditorComponentTypes.Spacer,
  droppable: false,
  styles: `
  .${SPACER_WRAPPER_CLASS} {
    height: ${DEFAULT_SPACER_HEIGHT_IN_PX}px;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
  }
  `,
});
