const EditorAddBlockContainer = () => (
  <div
    className="flex justify-center items-center py-8"
    style={{ backgroundColor: '#fff' }}
  >
    <div className="bg-[url('/src/assets/icons/add-block-border.svg')] flex items-center justify-center  w-[640px] h-[260px]" />
  </div>
);

export default EditorAddBlockContainer;
