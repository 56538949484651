import clsx from 'clsx';

import { Button, Icon } from '@atoms';
import { ReactComponent as UploadIcon } from '@assets/icons/upload.svg';

import './styles.css';

interface Props {
  imageUrl?: string | null;
  onClick: () => void;
  className?: string;
}

const UploadImageContainer = ({ className, imageUrl, onClick }: Props) => (
  <div
    className={clsx('UploadImageContainer', className)}
    style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : 'unset' }}
  >
    {imageUrl ? (
      <Button
        className="py-2.5 px-8 bg-white text-gray-main"
        variant="none"
        title="Change image"
        onClick={onClick}
        hoverDisabled
      />
    ) : (
      <Button
        variant="text-secondary"
        title="Upload image"
        startIcon={<Icon SVG={UploadIcon} width={18} height={18} />}
        onClick={onClick}
      />
    )}
  </div>
);

export default UploadImageContainer;
