import { Typography } from '@atoms';
import { ReportCard } from '@molecules';

const AccordionData = ({
  amp,
  html,
}: {
  amp: { value: number; percentage: string };
  html: { value: number; percentage: string };
}) => (
  <div className="flex flex-col">
    <div className="flex justify-between py-2">
      <Typography variant="subtitle4">AMP</Typography>
      <div className="flex justify-between w-1/4">
        <Typography variant="subtitle4">{amp.value}</Typography>
        <Typography variant="subtitle4">{amp.percentage}</Typography>
      </div>
    </div>
    <div className="flex justify-between py-2">
      <Typography variant="subtitle4">Fallback HTML</Typography>
      <div className="flex justify-between w-1/4">
        <Typography variant="subtitle4">{html.value}</Typography>
        <Typography variant="subtitle4">{html.percentage}</Typography>
      </div>
    </div>
  </div>
);

const ReportCardsList = ({
  revenue,
  opens,
  uniqueOpens,
  uniqueClicks,
  clicks,
  averageOrderValue,
  totalCartsCreated,
  paidCarts,
  totalItemsAddedToCart,
  totalBookedCartValue,
}: any) => (
  <>
    <div className="flex gap-6">
      <div className="flex flex-col w-1/2 gap-6">
        <ReportCard title="Revenue" value={revenue} />
        <ReportCard
          title="Opens"
          value={opens.total.value}
          percent={opens.total.percentage}
          accordionData={<AccordionData amp={opens.amp} html={opens.html} />}
        />
        <ReportCard
          title="Unique opens"
          value={uniqueOpens.total.value}
          percent={uniqueOpens.total.percentage}
          accordionData={
            <AccordionData amp={uniqueOpens.amp} html={uniqueOpens.html} />
          }
        />
      </div>
      <div className="flex flex-col w-1/2 gap-6">
        <ReportCard title="Average order value" value={averageOrderValue} />
        <ReportCard
          title="Clicks"
          value={clicks.total.value}
          percent={clicks.total.percentage}
          accordionData={<AccordionData amp={clicks.amp} html={clicks.html} />}
        />
        <ReportCard
          title="Unique clicks"
          value={uniqueClicks.total.value}
          percent={uniqueClicks.total.percentage}
          accordionData={
            <AccordionData amp={uniqueClicks.amp} html={uniqueClicks.html} />
          }
        />
      </div>
    </div>
    <ReportCard
      className="flex-grow"
      title="Total carts created"
      value={totalCartsCreated}
      accordionData={
        <div className="flex gap-12">
          <div className="flex w-1/2 flex-col">
            <div className="flex justify-between py-2">
              <Typography variant="subtitle4">Paid carts</Typography>
              <Typography variant="subtitle4">{paidCarts}</Typography>
            </div>
            <div className="flex justify-between py-2">
              <Typography variant="subtitle4">
                Total items added to cart
              </Typography>
              <Typography variant="subtitle4">
                {totalItemsAddedToCart}
              </Typography>
            </div>
          </div>
          <div className="flex w-1/2 justify-between py-2">
            <Typography variant="subtitle4">Total booked cart value</Typography>
            <Typography variant="subtitle4">{totalBookedCartValue}</Typography>
          </div>
        </div>
      }
    />
  </>
);

export default ReportCardsList;
