import { FocusEventHandler, useState } from 'react';
import { useController } from 'react-hook-form';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as ClosedEyeIcon } from '@assets/icons/closed-eye.svg';
import { ReactComponent as OpenedEyeIcon } from '@assets/icons/opened-eye.svg';
import { InputComponent } from '@devTypes';

import './styles.css';

// todo: add disabled state

const Input = ({
  placeholder,
  type,
  startIcon,
  endIcon,
  name,
  control,
  isHintTextHidden,
  hintText = ' ',
  isDisabled,
  onFocus,
  onBlur,
  autoComplete,
  hideErrorMessage,
  className,
}: InputComponent) => {
  const {
    field: { value, onChange, onBlur: onFieldBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [isTextVisible, setIsTextVisible] = useState(false);

  const handlePasswordIconClick = () =>
    setIsTextVisible((prevState) => !prevState);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onFieldBlur();

    if (onBlur) {
      onBlur(event);
    }
  };

  const passwordIcon =
    type === 'password' ? (
      <Icon
        SVG={isTextVisible ? OpenedEyeIcon : ClosedEyeIcon}
        className="Icon-input Password-icon"
        height={15}
        width={15}
        onClick={handlePasswordIconClick}
      />
    ) : null;

  const isPasswordShown = type === 'password' && isTextVisible;

  return (
    <div className={`Input-container ${className}`}>
      <div className="Input">
        {startIcon}
        <input
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          className={clsx({
            'Input-with-start-icon': startIcon,
            'Input-with-end-icon': endIcon || passwordIcon,
            'Input-error': error && !hideErrorMessage,
          })}
          placeholder={placeholder}
          type={isPasswordShown ? 'text' : type}
          name={name}
          ref={ref}
          disabled={isDisabled}
        />
        {endIcon || passwordIcon}
      </div>
      {!isHintTextHidden && (
        <Typography
          variant="subtitle4"
          className={clsx(
            'Hint-text',
            error && !hideErrorMessage && 'Error-label'
          )}
        >
          {error && !hideErrorMessage ? error.message : hintText}
        </Typography>
      )}
    </div>
  );
};

export default Input;
