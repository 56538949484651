import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import templateApi, {
  AutoSaveTemplateData,
  InitialTemplateData,
  TemplateByIdData,
  TemplateData,
} from './api';

export const createTemplateThunk = createCommonAsyncThunk<InitialTemplateData>(
  'createTemplate',
  templateApi.createTemplate
);

export const updateTemplateThunk = createCommonAsyncThunk<TemplateData>(
  'template',
  templateApi.updateTemplate
);

export const fetchTemplatesThunk = createCommonAsyncThunk(
  'fetchTemplates',
  templateApi.fetchTemplates
);

export const fetchPresetTemplatesThunk = createCommonAsyncThunk(
  'fetchPresetTemplates',
  templateApi.fetchPresetTemplates
);

export const autoSaveTemplateThunk =
  createCommonAsyncThunk<AutoSaveTemplateData>(
    'autoSaveTemplate',
    templateApi.autoSaveTemplate
  );

export const fetchTemplateByIdThunk = createCommonAsyncThunk<TemplateByIdData>(
  'fetchTemplateById',
  templateApi.fetchTemplateById
);

export const getTemplateByIdThunk = createCommonAsyncThunk(
  'getTemplateById',
  templateApi.getTemplateById
);

export const getPresignedUrlThunk = createCommonAsyncThunk(
  'getPresignedUrl',
  templateApi.getPresignedUrl
);

export const updateTemplateImageURLThunk = createCommonAsyncThunk(
  'updateTemplateImageURL',
  templateApi.updateTemplateImageURL
);

export const duplicateTemplateThunk = createCommonAsyncThunk(
  'duplicateTemplate',
  templateApi.duplicateTemplate
);

export default {
  createTemplateThunk,
  updateTemplateThunk,
  fetchTemplatesThunk,
  fetchPresetTemplatesThunk,
  autoSaveTemplateThunk,
  fetchTemplateByIdThunk,
  getTemplateByIdThunk,
  getPresignedUrlThunk,
  updateTemplateImageURLThunk,
  duplicateTemplateThunk,
};
