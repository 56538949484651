import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { Typography } from '@atoms';
import { InputWithTitle } from '@molecules';

const CustomAppDetailsForm = () => {
  const { control, setValue } = useFormContext();
  const {
    state: { shopifyStoreName },
  } = useLocation();

  useEffect(() => {
    setValue('storeName', shopifyStoreName);
  }, []);

  return (
    <div className="ShopDetailsForm">
      <InputWithTitle
        placeholder="Enter store name"
        title="Store name"
        control={control}
        name="storeName"
        hintText="Enter your shopify store name"
        className="ShopDetailsForm-Input ShopDetailsForm-Input-StoreName"
        startIcon={<Typography variant="subtitle4">https://</Typography>}
        endIcon={<Typography variant="subtitle3">.myshopify.com</Typography>}
      />
      <InputWithTitle
        title="Custom app API Key"
        placeholder="Enter key"
        control={control}
        name="customAppApiKey"
        hintText="Creating a custom app in Shopify is required in order to generate an API key"
        className="ShopDetailsForm-Input"
        autoComplete="off"
      />
      <InputWithTitle
        autoComplete="new-password"
        title="Admin Access API Token"
        placeholder="Enter token"
        control={control}
        name="shopifyAccessToken"
        type="password"
        className="ShopDetailsForm-Input"
      />
    </div>
  );
};

export default CustomAppDetailsForm;
