import { MouseEventHandler } from 'react';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as CheckIcon } from '@assets/icons/select-check.svg';
import { SelectOptionType } from '@devTypes';

interface Props<T> {
  selectedCheckMark: boolean;
  item: T;
  onClick: MouseEventHandler;
  selectedValue: T | null;
}

const SelectMenuItem = <T extends SelectOptionType>({
  selectedCheckMark,
  item,
  onClick,
  selectedValue,
}: Props<T>) => {
  const isSelected = selectedValue === item;

  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      className={clsx('SelectOption', isSelected && 'SelectOption-selected')}
    >
      {typeof item !== 'string' && item.icon && (
        <div style={{ width: 25, height: 25, paddingRight: 10 }}>
          <Icon SVG={item.icon} width={20} height={20} />
        </div>
      )}
      <Typography variant="subtitle4">
        {typeof item !== 'string' ? item.title : item}
      </Typography>
      {isSelected && selectedCheckMark && (
        <Icon SVG={CheckIcon} width={20} height={20} />
      )}
    </div>
  );
};

export default SelectMenuItem;
