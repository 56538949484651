import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import DefaultInput from '@molecules/DefaultInput/DefaultInput';

interface Props {
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
}

const SearchInput = ({ value, onChange, placeholder }: Props) => (
  <DefaultInput
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    startIcon={<SearchIcon />}
  />
);

export default SearchInput;
