import React, { useState } from 'react';

import { Icon, Typography } from '@atoms';
import { ReactComponent as ChevronDownIcon } from '@assets/icons/chevron-down.svg';

interface Props {
  title: string;
  value: string | number;
  percent?: string;
  accordionData?: React.ReactNode;
  className?: string;
}

const ReportCard = ({
  accordionData,
  title,
  value,
  percent,
  className,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`w-full flex flex-col justify-center items-center bg-white rouneed ${className}`}
    >
      <div className="w-full flex flex-col justify-start items-center">
        <div className="w-full flex flex-col gap-3 py-8 items-center ">
          <Typography variant="subtitle4">{title}</Typography>
          <div className="flex flex-col items-center gap">
            <Typography variant="subtitle1" weight="semiBold">
              {value}
            </Typography>
            {percent && (
              <Typography className="text-primary-main" variant="subtitle4">
                {percent}
              </Typography>
            )}
          </div>
        </div>
        {accordionData && (
          <div className="w-full flex justify-center py-1 border-t border-gray-300">
            <Icon
              width={24}
              height={24}
              onClick={() => setIsOpen((prev) => !prev)}
              SVG={ChevronDownIcon}
              className={`stroke-gray-main cursor-pointer ${
                isOpen ? 'transform rotate-180' : ''
              }`}
            />
          </div>
        )}
      </div>
      {isOpen && accordionData && (
        <div className="w-full h-[104px] py-4 px-6 text-white bg-gray-main rounded-b-lg">
          {accordionData}
        </div>
      )}
    </div>
  );
};

export default ReportCard;
