const currentStateBackground = (state: any) =>
  state.editor.currentState.background;

const isApplyCancelDisabled = (state: any) =>
  state.editor.isApplyCancelDisabled;

const currentState = (state: any) => state.editor.currentState;

const nextStates = (state: any) => state.editor.nextStates;

const previousStates = (state: any) => state.editor.previousStates;

const isTemplateAutoSaving = (state: any) => state.editor.isTemplateAutoSaving;

export default {
  currentStateBackground,
  isApplyCancelDisabled,
  currentState,
  nextStates,
  previousStates,
  isTemplateAutoSaving,
};
