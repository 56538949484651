import { useEffect, useState } from 'react';

import { ReactComponent as AlignCenterIcon } from '@assets/icons/align-center.svg';
import { ReactComponent as AlignJustifyIcon } from '@assets/icons/align-justify.svg';
import { ReactComponent as AlignLeftIcon } from '@assets/icons/align-left.svg';
import { ReactComponent as AlignRightIcon } from '@assets/icons/align-right.svg';
import { PopupListItemComponent, SvgFunctionComponent } from '@devTypes';
import SettingsOptionWithPopup from '@organisms/EditorTextBlockStylesSettings/SettingsOptionWithPopup';

interface Props {
  componentTextAlign: string;
  svgIcon: SvgFunctionComponent;
  isActive: boolean;
  onClick: (textAlign: string) => void;
  name: string;
  className?: string;
}

const textAlignOptions = [
  {
    icon: AlignLeftIcon,
    title: 'Left',
  },
  {
    icon: AlignRightIcon,
    title: 'Right',
  },
  {
    icon: AlignCenterIcon,
    title: 'Center',
  },
  {
    icon: AlignJustifyIcon,
    title: 'Justify',
  },
] as Array<PopupListItemComponent>;

const defaultTextAlignOption = textAlignOptions[2];

const TextAlignOption = ({
  componentTextAlign,
  svgIcon,
  isActive,
  name,
  onClick,
  className,
}: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<PopupListItemComponent>(
    defaultTextAlignOption
  );

  useEffect(() => {
    const item = textAlignOptions.find(
      (option) =>
        option.title?.toLowerCase() === componentTextAlign?.toLowerCase()
    );

    setSelectedItem(item || defaultTextAlignOption);
  }, [componentTextAlign]);

  const handlePopupToggle = () => setIsPopupOpen((prevState) => !prevState);
  const handlePopupClose = () => setIsPopupOpen(false);

  const handlePopupClick = (item: string) => () => {
    const selectedTextAlign = textAlignOptions.find(
      (option) => option.title?.toLowerCase() === item?.toLowerCase()
    );

    if (selectedTextAlign) setSelectedItem(selectedTextAlign);

    onClick(item);
    setIsPopupOpen(false);
  };

  return (
    <SettingsOptionWithPopup
      svgIcon={selectedItem.icon || svgIcon}
      isActive={isActive}
      className={className}
      isPopupOpen={isPopupOpen}
      onPopupClose={handlePopupClose}
      selectedItem={selectedItem.title}
      popoverListItems={textAlignOptions}
      onPopupClick={handlePopupClick}
      onClick={handlePopupToggle}
      name={name}
    />
  );
};

export default TextAlignOption;
