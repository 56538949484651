import { createContext, useContext } from 'react';

const TimeoutAutoSaveContext = createContext<any>(undefined);

export const useTimeoutAutoSave = () => {
  const autoSaveTimer = useContext(TimeoutAutoSaveContext);

  return autoSaveTimer;
};

export default TimeoutAutoSaveContext;
