import { useState } from 'react';
import grapesjs from 'grapesjs';

import { SwitchRender } from '@atoms';
import { MAX_CAROUSEL_IMAGES } from '@constants/editor';
import { GalleryItemProps } from '@devTypes';
import EditorImageBlockUploadModal from '@organisms/EditorImageBlockUploadModal/EditorImageBlockUploadModal';

import EditorCarouselBlockSettingsContent from './EditorCarouselBlockSettingsContent';
import EditorCarouselBlockSettingsDesign from './EditorCarouselBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorCarouselBlockSettings = ({ selectedComponent, type }: Props) => {
  const [selectedImages, setSelectedImages] = useState<GalleryItemProps[]>(
    selectedComponent.getAttributes().images
  );
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);

  const handleSelectedImagesChange = (
    images: GalleryItemProps[] | GalleryItemProps
  ) => {
    if (!Array.isArray(images)) {
      return;
    }

    setSelectedImages(images);
  };

  const handleImageUploadModalOpen = () => {
    setIsImageUploadModalOpen(true);
  };

  const handleImageUploadModalClose = () => {
    setIsImageUploadModalOpen(false);
  };

  const handleSelectedImagesUpload = (
    images: GalleryItemProps[] | GalleryItemProps
  ) => {
    if (!Array.isArray(images)) {
      return;
    }

    selectedComponent.addAttributes({ images });
    setSelectedImages(images);

    handleImageUploadModalClose();
  };

  const handleImageUploaded = (image: GalleryItemProps) => {
    const { images } = selectedComponent.getAttributes();

    if (images.length >= MAX_CAROUSEL_IMAGES) {
      return;
    }

    selectedComponent.addAttributes({ images: [...images, image] });
    setSelectedImages([...images, image]);
    handleImageUploadModalClose();
  };

  return (
    <div>
      <EditorImageBlockUploadModal
        galleryValue={selectedImages}
        onGallerySelectedImageUpload={handleSelectedImagesUpload}
        onGalleryValueChange={handleSelectedImagesChange}
        isOpen={isImageUploadModalOpen}
        onClose={handleImageUploadModalClose}
        onImageUploaded={handleImageUploaded}
      />
      <SwitchRender comparator={type} toCompare={['content', 'design']}>
        <EditorCarouselBlockSettingsContent
          onImageUploadModalOpen={handleImageUploadModalOpen}
          onChange={handleSelectedImagesChange}
          maxImages={MAX_CAROUSEL_IMAGES}
        />
        <EditorCarouselBlockSettingsDesign
          selectedComponent={selectedComponent}
        />
      </SwitchRender>
    </div>
  );
};

export default EditorCarouselBlockSettings;
