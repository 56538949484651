import { getCookie } from '@utils/helpers';
import storage from '@utils/storage';

const useAuthStatus = () => {
  const isStorageToken = !!storage.getToken();
  const isCookieToken = getCookie('token');

  return isStorageToken || isCookieToken;
};

export default useAuthStatus;
