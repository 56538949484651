import { Icon } from '@atoms';
import { ReactComponent as CheckIcon } from '@assets/icons/e-commerce-check.svg';
import { ReactComponent as MinusIcon } from '@assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { ShopifyProductOption } from '@devTypes';
import { useCounter } from '@hooks';

interface Props {
  title: string;
  options: ShopifyProductOption[];
}

const EcommerceLayout1CartItem = ({ title, options }: Props) => {
  const { count, decrementCount, incrementCount } = useCounter(1);
  return (
    <div className="pb-5 flex flex-col gap-1.5 last:border-0 border-b border-solid border-gray-300">
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Icon SVG={CheckIcon} width={24} height={24} />
          <div className="font-medium ">{title}</div>
          <div className="text-ecommerce-600 divide-x divide-solid divide-ecommerce-600">
            {options.map((option) => (
              <span key={option.id} className="last:pr-0 pr-1.5 pl-1.5">
                {option.name}
              </span>
            ))}
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <div className="flex items-center gap-2.5">
            <div
              className={`p-0.5 border border-black rounded ${
                count === 1 ? 'opacity-30 [&>svg]:cursor-default' : ''
              }`}
            >
              <Icon
                className="stroke-black cursor-pointer"
                SVG={MinusIcon}
                width={20}
                height={20}
                onClick={decrementCount}
              />
            </div>
            <div className="px-2 py-0.5 rounded-sm">{count}</div>
            <div className="p-0.5 border border-black rounded">
              <Icon
                className="stroke-black cursor-pointer"
                SVG={PlusIcon}
                width={20}
                height={20}
                onClick={incrementCount}
              />
            </div>
          </div>
          <div>Delete</div>
        </div>
      </div>
    </div>
  );
};

export default EcommerceLayout1CartItem;
