import { Icon } from '@atoms';
import { ReactComponent as LineCart } from '@assets/icons/line-cart.svg';
import { ShopifyProduct } from '@devTypes';

import EcommerceLayout2CartItem from './EcommerceLayout2CartItem';

interface Props {
  products: ShopifyProduct[];
  checkoutButtonText: string;
}

const EcommerceLayout2Cart = ({ products, checkoutButtonText }: Props) => (
  <div className="flex flex-col w-full gap-5 bg-white px-16 py-8 font-Helvetica rounded-lg">
    <div className="flex flex-col gap-5">
      {products.map((product) => (
        <EcommerceLayout2CartItem
          key={product.id}
          title={product.title}
          options={product.options}
        />
      ))}
    </div>
    <div className="flex justify-center items-center pb-4">
      <div className="border-t w-full border-ecommerce-300" />
      <Icon className="absolute" SVG={LineCart} width={34} height={36} />
    </div>
    <div className="flex justify-between font-semibold ">
      <div>Total:</div>
      <div>$40.50</div>
    </div>
    <div className="EcommerceLayout2CartCheckoutButton text-center py-2.5 cursor-pointer">
      {checkoutButtonText}
    </div>
  </div>
);

export default EcommerceLayout2Cart;
