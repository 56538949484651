import { useMemo } from 'react';
import grapesjs from 'grapesjs';

import { getChildComponentAt } from '@utils/helpers';

import EditorDividerBlockSettingsContent from './EditorDividerBlockSettingsContent';
import EditorDividerBlockSettingsDesign from './EditorDividerBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorDividerBlockSettings = ({ selectedComponent, type }: Props) => {
  const dividerComponent = useMemo(
    () => getChildComponentAt(selectedComponent, 0),
    [selectedComponent]
  );

  const renderSettings = () => {
    if (type === 'content') {
      return (
        <EditorDividerBlockSettingsContent
          dividerComponent={dividerComponent}
        />
      );
    }

    if (type === 'design') {
      return (
        <EditorDividerBlockSettingsDesign
          dividerContainerComponent={selectedComponent}
          dividerComponent={dividerComponent}
        />
      );
    }

    return null;
  };

  return <div className="EditorDividerBlockSettings">{renderSettings()}</div>;
};

export default EditorDividerBlockSettings;
