import grapesjs from 'grapesjs';

export const setImageFit = (component: grapesjs.Component, value: string) => {
  component.addAttributes({ imageFit: value });
};

export const setNavigationColor = (
  component: grapesjs.Component,
  color: string
) => {
  component.addAttributes({ navigationColor: color });
};
