import clsx from 'clsx';

import Tab from './Tab';

import './styles.css';

export type TabItem = {
  id: string;
  label: string;
  [key: string]: any;
};

interface Props {
  items: TabItem[];
  selectedTab: TabItem;
  setSelectedTab: (item: TabItem) => void;
  className?: string;
  disabledTab?: TabItem;
}

const Tabs = ({
  items,
  selectedTab,
  setSelectedTab,
  className,
  disabledTab,
}: Props) => {
  const handleTabChange = (item: TabItem) => {
    if (disabledTab?.id === item.id) {
      return;
    }
    setSelectedTab(item);
  };

  return (
    <div className={clsx('Tabs', className)}>
      {items.map((item) => {
        const isActive = item.id === selectedTab.id;
        const isDisabled = disabledTab && item.id === disabledTab.id;

        return (
          <Tab
            key={item.id}
            label={item.label}
            isActive={isActive}
            isDisabled={!!isDisabled}
            onClick={() => handleTabChange(item)}
          />
        );
      })}
    </div>
  );
};

export default Tabs;
