import { useState } from 'react';
import { Color } from 'react-color-palette';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ColorPicker } from '@molecules';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';

import './styles.css';

const DEFAULT_COLORS = [
  '#000000',
  '#FFFFFF',
  '#ECDCF3',
  '#BBE1F1',
  '#AFCFFE',
  '#3431C6',
];

interface Props {
  color: Color;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  onChange: (color: string) => void;
  onClick: any;
  title?: string;
}

const ColorPickerPanel = ({
  color,
  setColor,
  onChange,
  onClick,
  title,
}: Props) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const handleColorPickerOpen = () => setIsColorPickerOpen(true);
  const handleColorPickerClose = () => setIsColorPickerOpen(false);

  return (
    <div className="color-picker-with-title">
      {title && (
        <Typography
          className="Typography Typography-gray-main"
          variant="subtitle6"
        >
          {title}
        </Typography>
      )}
      <div className="circular-color-buttons">
        {DEFAULT_COLORS.map((colorValue) => (
          <div
            key={colorValue}
            className={clsx(
              colorValue.toLowerCase() === color.hex.toLowerCase() &&
                'selected',
              'color-picker-value-circle'
            )}
            onClick={() => onClick(colorValue)}
            style={{ backgroundColor: colorValue }}
            aria-hidden="true"
          />
        ))}
        <ColorPicker
          anchorEl={
            <div
              className="color-picker-value"
              onClick={handleColorPickerOpen}
              role="button"
              tabIndex={0}
            >
              <Icon
                SVG={PlusIcon}
                height={18}
                width={18}
                className="PlusIcon"
              />
            </div>
          }
          isOpen={isColorPickerOpen}
          onClose={handleColorPickerClose}
          color={color}
          setColor={setColor}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default ColorPickerPanel;
