import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CONTACTS_TABLE_ROWS_PER_PAGE } from '@constants/common';
import { NotificationType } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  createRecipientWithFileThunk,
  createRecipientWithFormThunk,
  getBouncedContactsThunk,
  getInvalidEmailThunk,
  getRecipientsThunk,
  getSpamReportsThunk,
} from '@store/features/allcontacts/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { trackToGTM, trackToZappier } from '@utils/helpers';
import { addContactSchema } from '@utils/validation';
import AllContactsView from '@views/AllContactsView/AllContactsView';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  streetAddress: '',
  streetLine2: '',
  city: '',
  zipCode: '',
  country: '',
};

type FormValues = typeof defaultValues;

type Notification = {
  type: NotificationType;
  text: string;
  untilClose?: boolean;
};

const errorNotification: Notification = {
  type: 'error',
  text: 'There is an issue with the columns or naming in your CSV file. Please check it and upload again',
  untilClose: true,
};

const successNotification: Notification = {
  type: 'success',
  text: 'Your CSV file uploaded successful',
};

const AllContactsPage = () => {
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(addContactSchema),
  });

  const { handleSubmit, reset } = methods;
  const dispatch = useAppDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [notification, setNotification] = useState<Notification | null>(null);

  const handleSave: SubmitHandler<FormValues> = ({
    firstName,
    lastName,
    email,
    phoneNumber,
    streetAddress,
    streetLine2,
    city,
    zipCode,
    country,
  }) => {
    dispatch(
      createRecipientWithFormThunk({
        firstName,
        lastName,
        email,
        ...(phoneNumber && { phoneNumber: `+${phoneNumber}` }),
        ...(streetAddress && { streetAddress }),
        ...(streetLine2 && { streetLine2 }),
        ...(city && { city }),
        ...(zipCode && { zipCode }),
        ...(country && { country }),
      })
    )
      .unwrap()
      .then(() => {
        trackToGTM({
          event: 'list_new',
          type: 'via form',
          email,
          firstName,
          lastName,
        });
        trackToZappier({
          event: 'list_new',
          type: 'via form',
          email,
          firstName,
          lastName,
        });
        setModalIsOpen(false);
        reset();
        dispatch(getRecipientsThunk({ limit: CONTACTS_TABLE_ROWS_PER_PAGE }));
      })
      .catch((err: Error) => toast(err.message));
  };

  const handleSaveFile = (csvFile: File | undefined) => {
    if (csvFile)
      dispatch(createRecipientWithFileThunk({ csvFile }))
        .unwrap()
        .then(() => {
          trackToZappier({ event: 'list_new', type: 'via csv' });
          trackToGTM({ event: 'list_new', type: 'via csv' });
          dispatch(getRecipientsThunk({ limit: CONTACTS_TABLE_ROWS_PER_PAGE }));
          setNotification(successNotification);
        })
        .catch(() => {
          setNotification(errorNotification);
        })
        .finally(() => {
          setModalIsOpen(false);
        });
  };

  const handleNotificationClose = () => setNotification(null);

  useEffect(() => {
    dispatch(getSpamReportsThunk({}));
    dispatch(getInvalidEmailThunk({}));
    dispatch(getBouncedContactsThunk({}));
  }, []);

  return (
    <FormProvider {...methods}>
      <AllContactsView
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        handleSaveForm={handleSubmit(handleSave)}
        handleSaveFile={handleSaveFile}
        notificationProps={{
          ...notification,
          isOpen: !!notification,
          onClose: handleNotificationClose,
        }}
      />
    </FormProvider>
  );
};

export default AllContactsPage;
