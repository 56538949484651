import grapesjs from 'grapesjs';

import { BlockEmptyState } from '@molecules';
import {
  EcommerceLayout1,
  EcommerceLayout1Fallback,
  EcommerceLayout2,
  EcommerceLayout2Fallback,
} from '@organisms';
import { EditorComponentTypes } from '@constants/editor';
import { EcommerceBlock } from '@devTypes';
import { getChildComponentAt } from '@utils/helpers';

const settings = [
  'isProductDescription',
  'checkoutButtonText',
  'isHeaderBold',
  'isHeaderItalic',
  'headerTextColor',
  'textColor',
  'font',
  'headerFont',
  'productTabTextColor',
  'productTabFillColor',
  'productTabLineColor',
  'dropdownGeneralButtonsStrokeColor',
  'checkoutButtonTextColor',
  'checkoutButtonFillColor',
  'checkoutButtonBorderColor',
  'checkoutButtonBorderRadius',
  'checkoutButtonBorderWidth',
  'paddingtop',
  'paddingbottom',
  'paddingleft',
  'paddingright',
  'backgroundColor',
  'layout',
  'products',
];

const ECOMMERCE_BLOCK_HEIGHT = {
  placeholder: 261,
  amp: {
    layout1: 503,
    layout2: 1138,
  },
  fallback: {
    layout1: 397,
    layout2: 779,
  },
};

const ecommercePlugin = (editor: grapesjs.Editor) => {
  const ecommerceBlocks = {
    fallback: {
      layout1: EcommerceLayout1Fallback,
      layout2: EcommerceLayout2Fallback,
    },
    amp: { layout1: EcommerceLayout1, layout2: EcommerceLayout2 },
  };

  editor.DomComponents.addType(EditorComponentTypes.Ecommerce, {
    extend: 'react-component',
    model: {
      defaults: {
        tagName: 'div',
        droppable: false,
        attributes: {
          title: 'E-commerce block',
          subtitle: ['Add a product to get started', 'from the left settings'],
          isProductDescription: true,
          checkoutButtonText: 'Checkout',
          isHeaderBold: false,
          isHeaderItalic: false,
          headerTextColor: '#000000',
          textColor: '#000000',
          font: 'Helvetica',
          headerFont: 'Helvetica',
          productTabTextColor: '#ffffff',
          productTabFillColor: '#000000',
          productTabLineColor: '#000000',
          dropdownGeneralButtonsStrokeColor: '#000000',
          checkoutButtonTextColor: '#ffffff',
          checkoutButtonFillColor: '#000000',
          checkoutButtonBorderColor: '#000000',
          checkoutButtonBorderRadius: 8,
          checkoutButtonBorderWidth: 1,
          backgroundColor: '#ffffff',
          height: ECOMMERCE_BLOCK_HEIGHT.placeholder,
        },
      },
      // If products is not "falsy" or products.length is not zero set component to layout
      init(this: EcommerceBlock) {
        const { products } = this.getAttributes();

        if (!products || !products.length) {
          this.attributes.component = BlockEmptyState;
        } else {
          const { emailType, layout } = this.getAttributes();

          this.attributes.component = ecommerceBlocks[emailType][layout];
        }

        this.addAttributes({
          // Product change handler
          onChange: (product: any) => {
            if (this.get('type') !== EditorComponentTypes.Ecommerce) {
              return;
            }

            this?.addAttributes({
              selectedProduct: product,
              currentHeroImage: product.image,
            });
          },
          // Cart state change handler
          onCartChange: (x: boolean) => {
            this?.addAttributes({ isCartOpen: x });
          },
          // Hero image change handler
          onHeroImageChange: (x: any) => {
            this?.addAttributes({ currentHeroImage: x });
          },
        });

        this.listenTo(
          this,
          'change:attributes:emailType change:attributes:layout',
          this.handleComponentTypeChange
        );

        this.listenTo(
          this,
          'change:attributes:products',
          this.handleProductsChange
        );
        this.listenTo(
          this,
          settings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          settings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
      handleProductsChange(this: EcommerceBlock) {
        const attributes = this.getAttributes();

        if (!attributes.products || !attributes.products.length) {
          this.attributes.component = BlockEmptyState;
          return;
        }

        this.attributes.component =
          ecommerceBlocks[attributes.emailType][attributes.layout];
      },

      handleComponentTypeChange(this: EcommerceBlock) {
        const attributes = this.getAttributes();

        if (!attributes.products) {
          return;
        }

        const productHeight =
          ECOMMERCE_BLOCK_HEIGHT[attributes.emailType][attributes.layout];
        const height =
          attributes.emailType === 'fallback'
            ? productHeight * attributes.products.length
            : productHeight;
        this.addAttributes({ height });

        this.attributes.component =
          ecommerceBlocks[attributes.emailType][attributes.layout];
      },
    },
  });
};

export default ecommercePlugin;
