import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { ReportPageView } from '@views';
import { ROUTES } from '@constants/routes';
import {
  campaignsAsyncThunks,
  campaignsSelectors,
} from '@store/features/campaigns';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const ReportPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(campaignsAsyncThunks.getCampaignReport({ id: Number(id) }));
  }, []);

  const campaignReport = useAppSelector(campaignsSelectors.campaignReport);
  const isCampaignReportLoading = campaignReport
    ? useAppSelector(campaignsSelectors.isCampaignReportLoading)
    : true;

  const handlePreviewClick = async () => {
    const campaign = await dispatch(
      campaignsAsyncThunks.getCampaignByIdThunk({
        id: Number(campaignReport.campaignData.campaignId),
      })
    ).unwrap();

    const { templateId } = campaign.campaign;

    navigate(`${ROUTES.template.preview}/${templateId}`, {
      state: {
        id: templateId,
      },
    });
  };

  const handleDownloadReport = () => {
    const input = document.getElementById('pdf');

    if (!input) return;

    html2canvas(input, {
      backgroundColor: '#F2F4F7',
      ignoreElements: (element) => element.classList.contains('Button'),
    }).then((canvas) => {
      const imgData = canvas.toDataURL();

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('download.pdf');
    });
  };

  if (isCampaignReportLoading) return <div>Loading...</div>;

  return (
    <ReportPageView
      onDownloadReport={handleDownloadReport}
      onPreview={handlePreviewClick}
      {...campaignReport}
    />
  );
};

export default ReportPage;
