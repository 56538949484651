import EditorBackgroundSettings from '@organisms/EditorBackgroundSettings/EditorBackgroundSettings';

import './styles.css';

const BackgroundOuterSelectorSettings = () => (
  <div className="editor-background-block-outer-settings">
    <EditorBackgroundSettings />
  </div>
);

export default BackgroundOuterSelectorSettings;
