import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { SliderWithInput } from '@molecules';
import {
  MAX_SPACER_HEIGHT_IN_PX,
  MIN_SPACER_HEIGHT_IN_PX,
} from '@constants/editor';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';

import './styles.css';

interface Props {
  selectedComponent: grapesjs.Component;
}

const EditorSpacerBlockSettingsDesign = ({ selectedComponent }: Props) => {
  const [height, setHeight] = useState<number>(5);

  useEffect(() => {
    const spacerState = selectedComponent.getAttributes();
    setHeight(spacerState.height);
  }, [selectedComponent]);

  const handleHeightChange = (newSpace: number) => {
    selectedComponent.addStyle({
      height: `${newSpace}px`,
    });
    selectedComponent.addAttributes({ height: newSpace });

    setHeight(newSpace);
  };

  return (
    <div>
      <SliderWithInput
        title="Space height"
        value={height}
        onChange={handleHeightChange}
        min={MIN_SPACER_HEIGHT_IN_PX}
        max={MAX_SPACER_HEIGHT_IN_PX}
      />

      <EditorBlockBackgroundSettings selectedComponent={selectedComponent} />
    </div>
  );
};

export default EditorSpacerBlockSettingsDesign;
