import { ReactNode } from 'react';

import { LandingBlock } from '@organisms';

import './styles.css';

interface Props {
  children: ReactNode;
}

const AuthPageTemplate = ({ children }: Props) => (
  <div className="AuthPage">
    <div className="AuthPage-Content">{children}</div>
    <LandingBlock />
  </div>
);

export default AuthPageTemplate;
