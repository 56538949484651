import { useNavigate } from 'react-router-dom';

import { Icon, Typography } from '@atoms';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/arrow-left.svg';
import { ROUTES } from '@constants/routes';
import { SvgFunctionComponent } from '@devTypes';
import AuthPageTemplate from '@templates/AuthPageTemplate/AuthPageTemplate';

import './styles.css';

interface Props {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  email?: string;
  svgIcon: SvgFunctionComponent;
}

const ForgotPasswordTemplate = ({
  children,
  title,
  subtitle,
  email,
  svgIcon,
}: Props) => {
  const navigate = useNavigate();

  const handleBackToLoginClick = () => {
    navigate(ROUTES.signIn);
  };
  return (
    <AuthPageTemplate>
      <div className="ForgotPasswordTemplateWrapper">
        <Icon SVG={svgIcon} width={44} height={44} />
        <div className="ForgotPasswordTemplateTitle">
          <Typography variant="h6">{title}</Typography>
          <div>
            <Typography variant="subtitle3">{subtitle}</Typography>
            <Typography
              className="ForgotPasswordTemplateTitleEmail"
              variant="subtitle3"
            >
              {email}
            </Typography>
          </div>
        </div>
        <div className="ForgotPasswordTemplateContent">{children}</div>
        <div
          className="ForgotPasswordTemplateBackToLogin"
          role="button"
          tabIndex={0}
          onClick={handleBackToLoginClick}
        >
          <Icon SVG={ArrowLeftIcon} width={12} height={12} />
          <Typography variant="subtitle4">Back to login</Typography>
        </div>
      </div>
    </AuthPageTemplate>
  );
};

export default ForgotPasswordTemplate;
