import { ENDPOINTS } from '@constants/api';
import { Order, Recipient } from '@devTypes';

import { wrappedAxiosRequest } from '../../api';

export interface ContactData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  streetAddress?: string;
  streetLine2?: string;
  city?: string;
  zipCode?: string;
  country?: string;
}

type SortBy = keyof Recipient;
type Status = 'subscribed' | 'unsubscribed';

export interface RecipientsData {
  limit?: number;
  offset?: number;
  order?: Order;
  sortBy?: SortBy;
  q?: string;
  status?: Status;
}

export interface ContactDataCsv {
  csvFile: File;
}

export interface ChangeStatusData {
  status: Status;
  ids: number[];
}

export interface DeleteRecipientsData {
  ids: number[];
}

export interface EditRecipientData {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: Status;
  phoneNumber?: string;
  streetAddress?: string;
  streetLine2?: string;
  city?: string;
  zipCode?: string;
  country?: string;
}

export interface SpamReportData {
  ip: number;
  email: string;
  created: string;
}

export interface BouncedEmailData {
  created: number;
  email: string;
  reason: string;
  status: string;
}

export interface InvalidEmailData {
  created: number;
  email: string;
  reason: string;
}

const allContactsApi = {
  async getRecipients(data: RecipientsData) {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.recipients.createRecipient,
      params: data,
    });
  },

  async createRecipientWithForm(data: ContactData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.recipients.createRecipient,
      data,
    });
  },

  async createRecipientsWithFile({ csvFile }: ContactDataCsv) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.recipients.importCsvFile,
      data: { file: csvFile },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async updateRecipientStatus(data: ChangeStatusData) {
    return wrappedAxiosRequest({
      method: 'patch',
      url: ENDPOINTS.recipients.status,
      data,
    });
  },

  async editRecipient({ id, ...data }: EditRecipientData) {
    return wrappedAxiosRequest({
      method: 'patch',
      url: ENDPOINTS.recipients.update(id),
      data,
    });
  },

  async deleteRecipients(data: DeleteRecipientsData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.recipients.delete,
      data,
    });
  },

  async getSpamContacts() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.recipients.getSpamReports,
    });
  },

  async getBouncedContacts() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.recipients.getBouncedEmails,
    });
  },

  async getInvalidEmailContacts() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.recipients.getInvalidEmails,
    });
  },
};

export default allContactsApi;
