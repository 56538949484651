import clsx from 'clsx';

import './styles.css';

interface Props {
  fullScreen?: boolean;
  height?: number;
}

export const Loader = ({ fullScreen, height }: Props) => (
  <div
    className={clsx(
      'LoaderContainer',
      fullScreen && 'LoaderContainer-fullScreen'
    )}
    style={{ height }}
  >
    <div className="Loader" />
  </div>
);

export default Loader;
