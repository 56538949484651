import { Dispatch, SetStateAction, useEffect } from 'react';
import { Menu, MenuItem, MenuItemStyles } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';

import { Avatar, Icon, Loader } from '@atoms';
import { DashboardSidebarPopup } from '@molecules';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';
import { ROUTES } from '@constants/routes';
import { usersAsyncThunks, usersSelectors } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getUserInitials, getUserName } from '@utils/helpers';

const settingsTab = {
  icon: SettingsIcon,
  title: 'Settings',
};

const logoutSubTab = {
  icon: LogoutIcon,
  title: 'Log out',
};

export const menuItemStyles: MenuItemStyles = {
  root: ({ active }) => ({
    border: '1px solid transparent',
    transition: 'all 0.3s',
    '&:hover': {
      border: !active ? '1px solid var(--white-color)' : undefined,
      borderRadius: !active ? '6px' : undefined,
    },
  }),
  subMenuContent: {
    backgroundColor: 'transparent',
  },
  button: ({ active }) => ({
    color: 'var(--white-color)',
    backgroundColor: active ? 'var(--primary-900-color)' : undefined,
    fontFamily: 'var(--Poppins-font)',
    borderRadius: active ? '6px' : undefined,
    '&:hover': {
      backgroundColor: active ? 'var(--primary-900-color)' : 'transparent',
    },
  }),
};

export interface Props {
  isLogoutExpanded: boolean;
  setIsLogoutExpandedState: Dispatch<SetStateAction<boolean>>;
  handleLogoutClick: () => Promise<void>;
}

const DashboardSidebarFooter = ({
  isLogoutExpanded,
  setIsLogoutExpandedState,
  handleLogoutClick,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelectors.user);

  useEffect(() => {
    dispatch(usersAsyncThunks.getUserThunk(null));
  }, []);

  const navigateToProfilePage = () => {
    navigate(ROUTES.dashboard.profile);
  };

  return (
    <div className="DashboardSidebarFooter">
      <Menu menuItemStyles={menuItemStyles} className="DashboardSidebarMenu">
        <MenuItem
          icon={<Icon SVG={settingsTab.icon} width={24} height={24} />}
          prefix={settingsTab.title}
          onClick={navigateToProfilePage}
        />
        {!user ? (
          <Loader />
        ) : (
          <MenuItem
            className="ProfileMenuItem"
            icon={<Avatar>{getUserInitials(user)}</Avatar>}
            prefix={getUserName(user)}
            onClick={() => {
              setIsLogoutExpandedState(!isLogoutExpanded);
            }}
          >
            <DashboardSidebarPopup
              isOpen={isLogoutExpanded}
              // setIsOpen={setIsLogoutExpandedState}
              item={{ icon: logoutSubTab.icon, title: logoutSubTab.title }}
              onClick={handleLogoutClick}
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default DashboardSidebarFooter;
