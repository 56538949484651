import { Typography } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';
import PlatformLogo from '@organisms/IntegrationCard/PlatformLogo';

interface Props {
  title: string;
  description: string;
  icon: SvgFunctionComponent;
}

const PlatformInfo = ({ title, description, icon }: Props) => (
  <div className="PlatformInfo">
    <PlatformLogo icon={icon} />
    <div className="PlatformInfoText">
      <Typography variant="subtitle3">{title}</Typography>
      <Typography variant="subtitle4" className="PlatformInfoDescription">
        {description}
      </Typography>
    </div>
  </div>
);

export default PlatformInfo;
