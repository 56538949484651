import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Typography } from '@atoms';
import { InputWithTitle, UploadImageContainer } from '@molecules';
import { GalleryItemProps } from '@devTypes';
import EditorImageBlockUploadModal from '@organisms/EditorImageBlockUploadModal/EditorImageBlockUploadModal';
import { organizationsSelectors } from '@store/features/organizations';
import { useAppSelector } from '@store/hooks';

const ShopDetailsForm = () => {
  const meOrganizationData = useAppSelector(
    organizationsSelectors.getMeOrganizationData
  );
  const [selectedGalleryImage, setSelectedGalleryImage] =
    useState<GalleryItemProps | null>(null);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);

  const { control, setValue } = useFormContext();
  const organizaitonLogo = useWatch({ control, name: 'organizationLogo' });

  const isEditOraganizationDisabled = !!meOrganizationData.title;

  const handleGalleryValueChange = (
    value: GalleryItemProps | GalleryItemProps[] | null
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    setSelectedGalleryImage(value);
  };

  const handleGallerySelectedImageUpload = (
    value: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (Array.isArray(value)) {
      return;
    }

    setValue('organizationLogo', value.url);
    setIsGalleryModalOpen(false);
  };

  const handleGalleryModalOpen = () => {
    setIsGalleryModalOpen(true);
  };

  const handleGalleryModalClose = () => {
    setIsGalleryModalOpen(false);
  };

  return (
    <div className="flex w-full gap-[30px]">
      <EditorImageBlockUploadModal
        galleryValue={selectedGalleryImage}
        onGalleryValueChange={handleGalleryValueChange}
        onGallerySelectedImageUpload={handleGallerySelectedImageUpload}
        isOpen={isGalleryModalOpen}
        onClose={handleGalleryModalClose}
      />
      <div className="flex flex-col basis-1/2 gap-6">
        <InputWithTitle
          name="organizationName"
          title="Organization"
          control={control}
          hintText="Enter your organization's name"
          className="ShopDetailsForm-Input"
          isDisabled={isEditOraganizationDisabled}
          hideErrorMessage
        />
        <div className="flex flex-col gap-2.5">
          <Typography variant="subtitle4" weight="semiBold">
            Brand logo
          </Typography>
          <Typography variant="subtitle4">
            Upload your organization&apos;s logo
          </Typography>
          <UploadImageContainer
            className="w-[219px] py-7  [.ButtonContent]:text-red"
            imageUrl={organizaitonLogo}
            onClick={handleGalleryModalOpen}
          />
        </div>
      </div>
      <div className="flex flex-col basis-1/2 gap-6">
        <InputWithTitle
          title="Website URL"
          control={control}
          name="websiteURL"
          hintText="Enter your website URL"
          className="ShopDetailsForm-Input"
        />
        <InputWithTitle
          title="Customer support email"
          control={control}
          name="supportEmail"
          hintText="Enter your email for customer support tickets"
          className="ShopDetailsForm-Input"
        />
        <InputWithTitle
          title="Other notification email"
          control={control}
          name="notificationEmail"
          hintText="Enter your email to receive order notifications"
          className="ShopDetailsForm-Input"
        />
      </div>
    </div>
  );
};

export default ShopDetailsForm;
