import { useState } from 'react';

import { HeaderContainer, Notification, Select, Typography } from '@atoms';
import { NotificationProps } from '@atoms/Notification/Notification';
import { AudienceImportType } from '@devTypes';
import AllContactsTemplate from '@templates/AllContactsTemplate/AllContactsTemplate';

import AddContactsModal, {
  CsvAudienceSelectOption,
  FormAudienceSelectOption,
} from './AddContactsModal';

import './styles.css';

interface AllContactsViewProps {
  modalIsOpen: boolean;
  setModalIsOpen: (modalIsOpen: boolean) => void;
  handleSaveForm: () => void;
  handleSaveFile: (csvFile: File | undefined) => void;
  notificationProps: NotificationProps;
}

const AllContactsView = ({
  modalIsOpen,
  setModalIsOpen,
  handleSaveForm,
  handleSaveFile,
  notificationProps,
}: AllContactsViewProps) => {
  const [selectedImportType, setSelectedImportType] = useState<
    AudienceImportType | string
  >('FORM');

  const handleDropDownChange = (value: AudienceImportType) => {
    setModalIsOpen(true);
    setSelectedImportType(value);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <HeaderContainer>
        <Typography variant="subtitle1">All Contacts</Typography>
        <div className="AddContactSelect">
          <Select
            withIcon
            value={{ title: 'Add contacts', icon: undefined }}
            onChange={handleDropDownChange}
            options={[CsvAudienceSelectOption, FormAudienceSelectOption]}
            selectedCheckMark={false}
          />
        </div>
      </HeaderContainer>
      <Notification {...notificationProps} />
      <AllContactsTemplate />
      <AddContactsModal
        isOpen={modalIsOpen}
        onClose={onClose}
        selectedImportType={selectedImportType}
        onSaveFile={handleSaveFile}
        onSaveForm={handleSaveForm}
      />
    </>
  );
};

export default AllContactsView;
