import { Icon, Typography } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';

import './styles.css';

interface Props {
  title: string;
  children: React.ReactNode;
  startIcon?: SvgFunctionComponent;
}

const EditorBlockSettingContainer = ({ title, children, startIcon }: Props) => (
  <div className="EditorBlockSettingContainer">
    <div className="EditorBlockSettingContainerTitle">
      {startIcon && <Icon SVG={startIcon} />}
      <Typography variant="subtitle4">{title}</Typography>
    </div>
    {children}
  </div>
);

export default EditorBlockSettingContainer;
