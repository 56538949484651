import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as EditIcon } from '@assets/icons/ai-edit.svg';

import './styles.css';

interface Props {
  onClick: () => void;
  className?: string;
}

const GenerativeAIButton = ({ onClick, className }: Props) => (
  <div
    className={clsx('GenerativeAIButton', className)}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <Typography variant="subtitle6">GenAI</Typography>
    <Icon SVG={EditIcon} height={18} width={18} />
  </div>
);

export default GenerativeAIButton;
