/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-bind */
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';

import { Button, Select, Typography } from '@atoms';
import { InputWithTitle } from '@molecules';
import { HOURS_ARRAY, MINUTES_ARRAY } from '@constants/common';

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

const DateInput = ({ control, openCalendar }: any) => (
  <InputWithTitle
    title="Delivery date"
    onFocus={openCalendar}
    control={control}
    name="date"
    className="CreateCampaignCardForm-Input"
  />
);

const ScheduleSendForm = ({ onCancel, onSave }: Props) => {
  const { control, setValue } = useFormContext();
  const [hoursState, setHoursState] = useState('01');
  const [minsState, setMinsState] = useState('00');
  const [hoursFormat, setHoursFormat] = useState('AM');

  return (
    <div className="ChangeCampaignNameForm">
      <DatePicker
        onChange={(value) => {
          setValue('date', value?.toString());
        }}
        render={<DateInput control={control} />}
        format="MM/DD/YYYY"
      />
      <Typography variant="subtitle4" className="TimeSelect-Title">
        Delivery time
      </Typography>
      <div className="flexbox-row flexbox-gap-16px">
        <Select
          value={hoursState}
          onChange={(value) => {
            setValue('hours', value);
            setHoursState(value);
          }}
          options={HOURS_ARRAY}
        />
        <Select
          value={minsState}
          onChange={(value) => {
            setValue('minutes', value);
            setMinsState(value);
          }}
          options={MINUTES_ARRAY}
        />
        <Select
          value={hoursFormat}
          options={['AM', 'PM']}
          onChange={(value) => {
            setHoursFormat(value);
            setValue('hoursFormat', value);
          }}
        />
      </div>

      <div className="flexbox-row flexbox-gap-16px ChangeCampaignButtons">
        <Button
          title="Cancel"
          variant="secondary"
          onClick={() => {
            onCancel();
          }}
        />
        <Button title="Schedule campaign" variant="primary" onClick={onSave} />
      </div>
    </div>
  );
};

export default ScheduleSendForm;
