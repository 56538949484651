import { LoadingState } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import organizationsAsyncThunks from './asyncThunks';

interface GetMeOrganizationDataState extends LoadingState {
  title: string;
  imageUrl: string;
}

interface InitialState {
  getMeOrganizationData: GetMeOrganizationDataState;
}

const initialState: InitialState = {
  getMeOrganizationData: {
    title: '',
    imageUrl: '',
    isLoading: false,
  },
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      organizationsAsyncThunks.getMeOrganizationDataThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        getMeOrganizationData: {
          title: payload.title,
          imageUrl: payload.imageUrl,
          isLoading: false,
        },
      })
    );
    builder.addCase(
      organizationsAsyncThunks.getMeOrganizationDataThunk.pending,
      (state) => ({
        ...state,
        getMeOrganizationData: {
          ...state.getMeOrganizationData,
          isLoading: true,
        },
      })
    );
    builder.addCase(
      organizationsAsyncThunks.getMeOrganizationDataThunk.rejected,
      (state) => ({
        ...state,
        getMeOrganizationData: {
          ...state.getMeOrganizationData,
          isLoading: false,
        },
      })
    );
  },
});

export default organizationsSlice.reducer;
