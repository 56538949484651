import { useState } from 'react';

import { Typography } from '@atoms';
import { Tabs } from '@molecules';

import BackgroundOuterSelectorSettings from './BackgroundOuterSelectorSettings';

const items = [
  { id: 'outer', label: 'Outer' },
  { id: 'inner', label: 'Inner (Coming)' },
];

const Background = () => {
  const [selectedTab, setSelectedTab] = useState(items[0]);

  const renderSettings = () => (
    <>
      <Typography
        className="Typography Typography-gray-main block-heading"
        variant="subtitle4"
      >
        Background
      </Typography>
      <Tabs
        items={items}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        disabledTab={items[1]}
      />
      <div>
        {selectedTab.id === 'outer' && <BackgroundOuterSelectorSettings />}
      </div>
    </>
  );

  return (
    <div className="EditorBackgroundBlockSettings">{renderSettings()}</div>
  );
};

export default Background;
