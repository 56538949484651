import {
  DEFAULT_BACKGROUND_VALUE,
  DEFAULT_BUTTON_ALIGNMENT,
  DEFAULT_BUTTON_BORDER_COLOR,
  DEFAULT_BUTTON_BORDER_RADIUS,
  DEFAULT_BUTTON_BORDER_WIDTH,
  DEFAULT_BUTTON_FILL_COLOR,
  DEFAULT_BUTTON_FONT_FAMILY,
  DEFAULT_BUTTON_HEIGHT,
  DEFAULT_BUTTON_TEXT_COLOR,
  EditorComponentTypes,
} from '@constants/editor';

export const BUTTON_CLASS = 'gjs-button';
export const BUTTON_WRAPPER_CLASS = 'gjs-button-wrapper';

export const generateGrapesjsButtonComponent = ({
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  width,
  buttonCustomWrapperClass,
  buttonCustomClass,
  buttonCustomWrapperStyles,
}: any) => ({
  tagName: 'div',
  classes: [BUTTON_WRAPPER_CLASS, buttonCustomWrapperClass],
  attributes: {
    paddingleft: paddingLeft,
    paddingright: paddingRight,
    paddingtop: paddingTop,
    paddingbottom: paddingBottom,
  },
  type: EditorComponentTypes.Button,
  droppable: false,
  components: [
    {
      selectable: false,
      droppable: false,
      draggable: false,
      hoverable: false,
      tagName: 'a',
      classes: [BUTTON_CLASS, buttonCustomClass],
      attributes: {
        fillColor: DEFAULT_BUTTON_FILL_COLOR,
        textColor: DEFAULT_BUTTON_TEXT_COLOR,
        borderColor: DEFAULT_BUTTON_BORDER_COLOR,
        borderRadius: DEFAULT_BUTTON_BORDER_RADIUS,
        borderWidth: DEFAULT_BUTTON_BORDER_WIDTH,
        width,
        height: DEFAULT_BUTTON_HEIGHT,
        font: DEFAULT_BUTTON_FONT_FAMILY,
        alignment: DEFAULT_BUTTON_ALIGNMENT,
      },
      components: 'Button',
    },
  ],
  styles: `
  .${BUTTON_WRAPPER_CLASS} {
    width: 100%;
    text-align: ${DEFAULT_BUTTON_ALIGNMENT};
    overflow-wrap: anywhere;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
  }

  .${buttonCustomClass} {
    width: ${width};
  }
  
  .${buttonCustomWrapperClass} {
    padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px;
    height: ${DEFAULT_BUTTON_HEIGHT + paddingTop + paddingBottom}px;
    ${buttonCustomWrapperStyles}
  }

  .${BUTTON_CLASS} {
    border-style: solid;
    transition: all 0.3s;
    cursor: pointer; 
    display: inline-block;
    text-decoration: none;
    
    height: ${DEFAULT_BUTTON_HEIGHT}px;
    line-height: ${DEFAULT_BUTTON_HEIGHT}px;
    color: ${DEFAULT_BUTTON_TEXT_COLOR};
    background-color: ${DEFAULT_BUTTON_FILL_COLOR};
    border-width: ${DEFAULT_BUTTON_BORDER_WIDTH}px;
    border-radius: ${DEFAULT_BUTTON_BORDER_RADIUS}px;
    font-family: ${DEFAULT_BUTTON_FONT_FAMILY};
    border-color: ${DEFAULT_BUTTON_BORDER_COLOR};
    overflow: hidden;
    padding: 0px 32px;
  }
  
  .${buttonCustomClass} {
    width: ${width};
  }
  `,
});
