import clsx from 'clsx';

import { Typography } from '@atoms';
import { TabComponent } from '@devTypes';

const Tab = ({ label, isActive, isDisabled, onClick }: TabComponent) => (
  <div
    role="button"
    tabIndex={0}
    className={clsx(
      'Tab',
      isActive && 'Tab-active',
      isDisabled && 'Tab-disabled'
    )}
    onClick={onClick}
  >
    <Typography variant="subtitle4">{label}</Typography>
  </div>
);

export default Tab;
