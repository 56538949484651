/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

import { Icon, Typography } from '@atoms';
import { ReactComponent as DragIcon } from '@assets/icons/drag.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg';

import { ItemTypes } from './ItemTypes';

const style = {
  cursor: 'move',
};

export interface CardProps {
  id: any;
  title: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDelete: (id: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DraggableListItem: FC<CardProps> = ({
  id,
  title,
  index,
  moveCard,
  onDelete,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //   const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={style}
      className="DraggableListItemContainer"
    >
      <div className="DraggableListItem flexbox-row flexbox-ai-center flexbox-jc-space-between">
        <div className="flexbox-row flexbox-ai-center flexbox-gap-6px">
          <span className="flexbox-row flexbox-ai-center">
            <Icon SVG={DragIcon} width={20} height={20} />
          </span>
          <Typography variant="subtitle4" style={{ maxWidth: 200 }}>
            {title}
          </Typography>
        </div>
        <Icon
          className="DraggableListItemDeleteIcon"
          SVG={TrashIcon}
          width={20}
          height={20}
          onClick={() => onDelete(id)}
        />
      </div>
    </div>
  );
};
