import { useEffect } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, HeaderContainer, Typography } from '@atoms';
import { Modal } from '@molecules';
import { ROUTES } from '@constants/routes';
import { TemplateObject } from '@devTypes';
import CreateCampaignDetails from '@organisms/CreateCampaignCard/CreateCampaignDetails';
import ScheduleSendForm from '@organisms/CreateCampaignCard/ScheduleSendForm';
import { backgroundActions } from '@store/features/background';
import { templateActions } from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';

import './styles.css';

interface CreateCampaignViewProps {
  onClickTemplate: (item: TemplateObject) => void;
  onSendCampaign: () => void;
  onScheduleCampaign: () => void;
  setSelectedTemplate: (template: TemplateObject | null) => void;
  setScheduleModalIsOpen: (isOpen: boolean) => void;
  scheduleModalIsOpen: boolean;
  onSaveCampaign: () => void;
  linkedTemplate: TemplateObject | undefined | null;
}

const CreateCampaignView = ({
  linkedTemplate,
  onClickTemplate,
  onSendCampaign,
  onScheduleCampaign,
  setSelectedTemplate,
  setScheduleModalIsOpen,
  scheduleModalIsOpen,
  onSaveCampaign,
}: CreateCampaignViewProps) => {
  const { resetField, getValues } = useFormContext();
  const { isValid } = useFormState();

  const { state } = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state?.selectedTemplateItem) {
      setSelectedTemplate(state.selectedTemplateItem);
    }
  }, [state?.selectedTemplateItem]);

  const backToCampaigns = async () => {
    onSaveCampaign();

    setSelectedTemplate(null);
    delete state?.selectedTemplateItem;
    dispatch(backgroundActions.resetState());
    dispatch(templateActions.resetTemplateState());
    navigate(ROUTES.dashboard.allcampaigns);
  };

  return (
    <>
      <HeaderContainer>
        <Button
          title="Back to Campaigns"
          variant="secondary"
          onClick={backToCampaigns}
        />
        <div className="flexbox-row flexbox-gap-16px">
          <Button
            title="Schedule"
            variant="secondary"
            onClick={() => setScheduleModalIsOpen(true)}
            disabled={!isValid || getValues('recipientType').length === 0}
          />

          <Button
            title="Send"
            variant="primary"
            onClick={onSendCampaign}
            disabled={!isValid || getValues('recipientType').length === 0}
          />
        </div>
      </HeaderContainer>
      <Modal
        isOpen={scheduleModalIsOpen}
        onClose={() => {
          setScheduleModalIsOpen(false);
          resetField('sendAt');
        }}
      >
        <Typography variant="subtitle2">Schedule your campaign</Typography>
        <ScheduleSendForm
          onCancel={() => setScheduleModalIsOpen(false)}
          onSave={onScheduleCampaign}
        />
      </Modal>
      <CreateCampaignDetails
        onEditClick={onClickTemplate}
        onSaveCampaign={onSaveCampaign}
        selectedTemplate={state?.selectedTemplateItem || linkedTemplate}
      />
    </>
  );
};

export default CreateCampaignView;
