import { ForgotPasswordCheckEmail } from '@organisms';
import { ForgotPasswordTemplate } from '@templates';
import { ReactComponent as EmailIcon } from '@assets/icons/email.svg';

interface ForgotPasswordCheckEmailProps {
  email: string;
  onClickResend: () => void;
}

const ForgotPasswordCheckEmailView = ({
  email,
  onClickResend,
}: ForgotPasswordCheckEmailProps) => (
  <ForgotPasswordTemplate
    title="Check your email"
    subtitle="We sent a password reset link to"
    email={email}
    svgIcon={EmailIcon}
  >
    <ForgotPasswordCheckEmail onClickResend={onClickResend} />
  </ForgotPasswordTemplate>
);

export default ForgotPasswordCheckEmailView;
