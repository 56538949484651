import { Button, Typography } from '@atoms';
import { Modal } from '@molecules';
import { ModalComponent } from '@devTypes';

interface Props extends ModalComponent {
  selectedContactsIds: Array<number>;
  onSubmit: (ids: number[]) => void;
}

const renderModalText = (selectedContactsAmount: number) => {
  const isPlural = selectedContactsAmount > 1;

  return {
    title: `Delete ${selectedContactsAmount} contact${isPlural ? 's' : ''}`,
    subtitle: `Are you sure you want to delete the selected contact${
      isPlural ? 's' : ''
    }?`,
  };
};

const DeleteContactsModal = ({
  isOpen,
  onClose,
  selectedContactsIds,
  onSubmit,
}: Props) => {
  const { title, subtitle } = renderModalText(selectedContactsIds.length);

  const handleSubmit = () => {
    onSubmit(selectedContactsIds);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="DeleteContactsModal">
        <Typography variant="subtitle2">{title}</Typography>
        <Typography
          variant="subtitle3"
          className="DeleteContactsModal-subtitle"
        >
          {subtitle}
        </Typography>
        <div className="DeleteContactsModal-Buttons">
          <Button title="Cancel" variant="secondary" onClick={onClose} />
          <Button title="Confirm" variant="error" onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteContactsModal;
