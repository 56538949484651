import { SelectComponent } from '@devTypes';

import Select from '../Select/Select';

import './styles.css';

const ColorSelect = <T extends { title: string }>({
  options,
  value,
  onChange,
}: SelectComponent<T>) => (
  <div className="ColorSelect flexbox-row flex-jc-space-between">
    <Select options={options} value={value} onChange={onChange} />
    <div
      className="ColorSelectCircle"
      style={{ backgroundColor: value?.title }}
    />
  </div>
);

export default ColorSelect;
