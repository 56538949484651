/* eslint-disable jsx-a11y/control-has-associated-label */
import clsx from 'clsx';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { EcommerceSelect } from '@molecules';
import noImageAvailable from '@assets/images/no-image-available.png';
import { EcommerceLayoutProps, ShopifyProductOption } from '@devTypes';
import EcommerceLayout2Cart from '@organisms/EcommerceLayout2Cart/EcommerceLayout2Cart';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './styles.css';
import 'swiper/css';

const EcommerceLayout2 = ({
  products,
  selectedProduct,
  onChange,
  headerFont,
  headerTextColor,
  isHeaderBold,
  isHeaderItalic,
  font,
  textColor,
  dropdownGeneralButtonsStrokeColor,
  productTabLineColor,
  checkoutButtonTextColor,
  checkoutButtonFillColor,
  checkoutButtonBorderColor,
  checkoutButtonBorderRadius,
  checkoutButtonBorderWidth,
  checkoutButtonText,
}: EcommerceLayoutProps) => {
  const styles = `
    .EcommerceLayout2ProductTitle {
      font-family: ${headerFont};
      color: ${headerTextColor};
      font-weight: ${isHeaderBold ? 700 : 400};
      font-style: ${isHeaderItalic ? 'italic' : 'normal'};
    }

    .EcommerceLayout2ProductPrice {
      color: ${textColor};
    }

    .EcommerceButton {
      font-family: ${font};
    }

    .EcommerceSelect > p  {
      font-family: ${font};
      color: ${textColor};
    }

    .EcommerceSelect p {
      font-family: ${font};
    }

    .Select p {
      font-family: ${font}!important;
      color: ${textColor}!important;
    }

    .EcommerceDropdown div {
      font-family: ${font};
      color: ${textColor};
    }

    .EcommerceButton, .EcommerceSelect .DropdownHeader {
      border-color: ${dropdownGeneralButtonsStrokeColor};
    }

    .EcommerceSelect .DropdownHeader svg {
      stroke: ${dropdownGeneralButtonsStrokeColor};
    }

    .EcommerceButton, .EcommerceSelect .DropdownHeader .Typography-root {
      color: ${textColor};
    }

    .EcommerceLayout2NavigationImage-selected {
      border-color: ${productTabLineColor};
    }

    .EcommerceLayout2CartCheckoutButton{
      color: ${checkoutButtonTextColor};
      background-color: ${checkoutButtonFillColor};
      border-color: ${checkoutButtonBorderColor};
      border-radius: ${checkoutButtonBorderRadius}px;
      border-width: ${checkoutButtonBorderWidth}px;
      border-style: solid;
    }
  `;

  return (
    <div className="flex flex-col items-end p-5 gap-y-6 font-Helvetica">
      <style>{styles}</style>
      <div className="flexbox-row flexbox-gap-20px">
        <div className="flexbox-column flexbox-gap-16px">
          {products.map((p) => (
            <div
              key={p.id}
              role="button"
              tabIndex={0}
              onClick={() => onChange(p)}
            >
              <img
                className={clsx(
                  'EcommerceLayout2NavigationImage',
                  p.id === selectedProduct.id &&
                    'EcommerceLayout2NavigationImage-selected'
                )}
                src={p?.image?.src ?? noImageAvailable}
                alt=""
              />
            </div>
          ))}
        </div>
        <div className="EcommerceLayout2Content flexbox-column flexbox-gap-24px flexbox-ai-center">
          <div className="flexbox-column flexbox-gap-6px flexbox-ai-center">
            <div className="EcommerceLayout2ProductTitle">
              {selectedProduct?.title}
            </div>
            <div className="EcommerceLayout2ProductPrice">
              ${selectedProduct?.variants[0].price}
            </div>
          </div>
          <div className="flexbox-column flexbox-jc-center flexbox-ai-center gap-12">
            <div className="EcommerceLayout2ProductImage flexbox-row flexbox-ai-center">
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                navigation
                pagination
              >
                {!selectedProduct.images.length && (
                  <SwiperSlide>
                    <img src={noImageAvailable} alt="" className="w-full" />
                  </SwiperSlide>
                )}
                {selectedProduct?.images.map((image) => (
                  <SwiperSlide key={image.id}>
                    <img
                      key={image.id}
                      src={image.src}
                      alt={selectedProduct.title}
                      className="w-full"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="EcommerceLayout2Footer flexbox-column flexbox-jc-center flexbox-ai-center flexbox-gap-20px">
              {selectedProduct.options.length > 1 && (
                <div className="EcommerceLayout2ProductOptions flexbox-row flexbox-gap-20px flexbox-jc-flex-start">
                  {selectedProduct.options.map(
                    (option: ShopifyProductOption) => (
                      <EcommerceSelect
                        key={option.id}
                        options={option.values}
                        value={option.values[0]}
                      />
                    )
                  )}
                </div>
              )}
              <div className="EcommerceButton w-full flex justify-center items-center border rounded p-2.5 font-Helvetica">
                Add to cart
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-20 w-full">
        <EcommerceLayout2Cart
          products={products}
          checkoutButtonText={checkoutButtonText}
        />
      </div>
    </div>
  );
};

export default EcommerceLayout2;
