import { useRef } from 'react';
import { ViewportList } from 'react-viewport-list';

import { Typography } from '@atoms';
import { ProductItem } from '@molecules';
import { ProductSelect } from '@devTypes';

import './styles.css';

interface Props {
  value: ProductSelect[];
  options: ProductSelect[];
  onChange: (option: ProductSelect[]) => void;
  max: number;
}

const ProductsSelect = ({ value, options, onChange, max }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div className="flexbox-column flexbox-gap-24px">
      <div className="ProductSelectHeader flexbox-row flexbox-jc-space-between">
        <Typography variant="subtitle3">Select up to {max} products</Typography>
        <Typography variant="subtitle3">
          {value.length} of {max}
        </Typography>
      </div>
      <div className="ProductSelectItems" ref={ref}>
        <ViewportList viewportRef={ref} items={options}>
          {(option) => {
            const isSelected = !!value.find((v) => v.id === option.id);
            const handleChange = () => {
              if (!isSelected && value.length < max) {
                onChange(value.concat(option));
              } else {
                onChange(value.filter((v) => v.id !== option.id));
              }
            };
            return (
              <div className="ProductSelectProductItemWrapper" key={option.id}>
                <ProductItem
                  isSelected={isSelected}
                  onChange={handleChange}
                  {...option}
                />
              </div>
            );
          }}
        </ViewportList>
      </div>
    </div>
  );
};

export default ProductsSelect;
