import { ReactNode, useEffect, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import clsx from 'clsx';

import { Button, Typography } from '@atoms';
import { PaginationButtons } from '@molecules';
import TextArea from '@atoms/TextArea/TextArea';

interface Props {
  title: string;
  name: string;
  control: Control<any, any>;
  isTextAreaEnabled?: boolean;
  onSaveAndSubmitClick?: () => void;
  onEditClick?: () => void;
  onShowPrevTextClick: () => void;
  onShowNextTextClick: () => void;
  onCancelClickForInput?: (name: string) => void;
  currentTextNumber: number;
  totalTextsAmount: number;
  placeholder?: string;
  className?: string;
  editButtonState?: 'hidden' | 'disabled' | 'enabled';
}

interface InfoBoxProps {
  children: ReactNode;
  isVisible: boolean;
}

const TEXT_AREA_ROWS_AMOUNT = 3;

const InfoBox = ({ children, isVisible }: InfoBoxProps) => (
  <div
    className="InfoBoxModal"
    style={{ visibility: isVisible ? 'visible' : 'hidden' }}
  >
    {children}
  </div>
);

const InputCopyInfo = () => (
  <div className="tooltiptext">
    <Typography variant="subtitle4" style={{ color: 'white' }}>
      <b>What is the Input copy?</b>
    </Typography>
    <Typography variant="subtitle4" style={{ color: 'gray' }}>
      The input copy is what the AI will use as context, and it will generate an
      output response based on this copy.
    </Typography>
  </div>
);

const OutputInfo = () => (
  <div className="tooltiptext">
    <Typography variant="subtitle4" style={{ color: 'white' }}>
      <b>What is the Output copy?</b>
    </Typography>
    <Typography variant="subtitle4" style={{ color: 'gray' }}>
      The output copy is the response generated by the AI based on the given
      prompt or input copy.
    </Typography>
  </div>
);

const PromptInfo = () => (
  <div className="tooltiptext">
    <Typography variant="subtitle4" style={{ color: 'gray' }}>
      <Typography variant="subtitle4" style={{ color: 'white' }}>
        <b>What is a Prompt?</b>
      </Typography>
      A prompt serves as an instruction or guidance for the AI to generate a
      meaningful and relevant response. The prompt sets the context and helps
      direct the AI output towards a desired outcome.
    </Typography>
  </div>
);

const PromptExamples = () => (
  <div className="tooltiptextprompt">
    <Typography variant="subtitle4" style={{ color: 'gray' }}>
      <Typography variant="subtitle4">
        1. <b style={{ color: 'white' }}>Write</b> a personalized greeting
        message that delights the customer with exciting news about our latest
        products in store.
      </Typography>
      <Typography variant="subtitle4">
        2. <b style={{ color: 'white' }}>Write</b> a captivating marketing
        message for our exclusive Black Friday offer with 30% discount on our
        entire range.
      </Typography>
      <Typography variant="subtitle4">
        3. <b style={{ color: 'white' }}>Write</b> a concise and impactful
        message highlighting the benefits of our organic juices.
      </Typography>
      <Typography variant="subtitle4">
        4.
        <b style={{ color: 'white' }}>Create</b> an attention-grabbing Header
        about our latest addition to our summer collection: a stunning array of
        hats
      </Typography>
    </Typography>
  </div>
);

const InputBox = ({
  title,
  name,
  control,
  isTextAreaEnabled,
  onSaveAndSubmitClick,
  onEditClick,
  onCancelClickForInput,
  onShowPrevTextClick,
  onShowNextTextClick,
  currentTextNumber,
  totalTextsAmount,
  placeholder,
  className,
  editButtonState = 'hidden',
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isInfoBoxOpenForInput, setInfoBoxOpenForInput] = useState(false);
  const [infoBoxOpenForPropmt, setInfoBoxOpenForPropmt] = useState(false);
  const [infoBoxOpenForOutput, setInfoBoxOpenForOutput] = useState(false);
  const [infoBoxOpenForExamples, setInfoBoxOpenForExamples] = useState(false);

  const focusTextArea = () => {
    if (isTextAreaEnabled) {
      ref?.current?.focus();
    }
  };

  useEffect(() => {
    focusTextArea();
  }, [isTextAreaEnabled]);

  const handleEditClick = () => {
    if (onEditClick) onEditClick();
    focusTextArea();
  };

  const onMouseEnterHandler = (inputName: string) => {
    switch (inputName) {
      case 'input':
        setInfoBoxOpenForInput(true);
        break;
      case 'prompt':
        setInfoBoxOpenForPropmt(true);
        break;
      case 'outputForPrompt':
        setInfoBoxOpenForOutput(true);
        break;
      case 'outputForRewrite':
        setInfoBoxOpenForOutput(true);
        break;
      case 'prompt examples':
        setInfoBoxOpenForExamples(true);
        break;
      default:
        break;
    }
  };

  const onMouseOutHandler = (inputName: string) => {
    switch (inputName) {
      case 'input':
        setInfoBoxOpenForInput(false);
        break;
      case 'prompt':
        setInfoBoxOpenForPropmt(false);
        break;
      case 'outputForPrompt':
        setInfoBoxOpenForOutput(false);
        break;
      case 'outputForRewrite':
        setInfoBoxOpenForOutput(false);
        break;
      case 'prompt examples':
        setInfoBoxOpenForExamples(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className={clsx('InputBox', className)}>
      <div className="flex justify-start items-center gap-[10px]">
        <Typography
          variant="subtitle4"
          className="font-semibold whitespace-nowrap"
        >
          {title}
        </Typography>
        <div>
          <img
            src={require('@assets/icons/info.png')}
            alt=""
            width={18}
            height={18}
            className="infoImage"
            role="button"
            tabIndex={0}
            onMouseEnter={() => onMouseEnterHandler(name)}
            onMouseOut={() => onMouseOutHandler(name)}
            onBlur={() => {}}
            aria-label="info image"
          />
        </div>
        {editButtonState !== 'hidden' && (
          <Button
            title="Edit"
            variant="text-primary"
            onClick={handleEditClick}
            className={clsx(
              editButtonState === 'disabled' &&
                'cursor-default text-gray-main opacity-[0.3]'
            )}
          />
        )}

        <InfoBox isVisible={isInfoBoxOpenForInput}>
          <InputCopyInfo />
        </InfoBox>

        <InfoBox isVisible={infoBoxOpenForPropmt}>
          <PromptInfo />
        </InfoBox>

        <InfoBox isVisible={infoBoxOpenForOutput}>
          <OutputInfo />
        </InfoBox>

        <InfoBox isVisible={infoBoxOpenForExamples}>
          <PromptExamples />
        </InfoBox>

        <PaginationButtons
          onLeftArrowClick={onShowPrevTextClick}
          onRightArrowClick={onShowNextTextClick}
          currentPage={currentTextNumber}
          totalPagesAmount={totalTextsAmount}
          className="ml-auto p-0 h-auto"
        />
      </div>
      <TextArea
        name={name}
        control={control}
        isDisabled={!isTextAreaEnabled}
        rows={TEXT_AREA_ROWS_AMOUNT}
        placeholder={placeholder}
        className="my-[15px]"
        ref={ref}
      />

      {onCancelClickForInput && isTextAreaEnabled && totalTextsAmount >= 1 && (
        <div
          aria-label="cancel"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            alignSelf: 'flex-end',
            opacity: isTextAreaEnabled ? 1 : 0.3,
          }}
        >
          <Button
            disabled={!isTextAreaEnabled}
            variant="text-secondary"
            title="Cancel"
            onClick={() => onCancelClickForInput(name)}
          />
          <Button
            disabled={!isTextAreaEnabled}
            variant="text-primary"
            title="Save & Submit"
            onClick={onSaveAndSubmitClick}
          />
        </div>
      )}

      {name === 'prompt' && (
        <div
          onMouseEnter={() => onMouseEnterHandler('prompt examples')}
          onMouseOut={() => onMouseOutHandler('prompt examples')}
          onBlur={() => {}}
          aria-label="prompt examples"
          style={{ width: '33%' }}
        >
          <Typography variant="subtitle6" className="promptExamples">
            Prompt examples
          </Typography>
        </div>
      )}
    </div>
  );
};

export default InputBox;
