import { LoadingState } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';
import { deleteAllCookies } from '@utils/helpers';
import storage from '@utils/storage';

import {
  changePasswordThunk,
  createNewPasswordThunk,
  forgotPasswordThunk,
  signInThunk,
  signUpThunk,
} from './asyncThunks';

interface SignInState extends LoadingState {}

interface SignUpState extends LoadingState {}

interface ForgotPasswordState extends LoadingState {}

interface CreateNewPasswordState extends LoadingState {}

interface InitialState extends LoadingState {
  signIn: SignInState;
  signUp: SignUpState;
  forgotPassword: ForgotPasswordState;
  createNewPassword: CreateNewPasswordState;
}

const initialState: InitialState = {
  isLoading: false,
  signIn: {
    isLoading: false,
  },
  signUp: {
    isLoading: false,
  },
  forgotPassword: {
    isLoading: false,
  },
  createNewPassword: {
    isLoading: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut() {
      storage.clear();
      deleteAllCookies();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInThunk.fulfilled, (state) => ({
      ...state,
      signIn: {
        isLoading: false,
      },
    }));
    builder.addCase(signInThunk.pending, (state) => ({
      ...state,
      signIn: {
        ...state.signIn,
        isLoading: true,
      },
    }));
    builder.addCase(signInThunk.rejected, (state) => ({
      ...state,
      signIn: {
        ...state.signIn,
        isLoading: false,
      },
    }));
    builder.addCase(signUpThunk.fulfilled, (state) => ({
      ...state,
      signUp: {
        isLoading: false,
      },
    }));
    builder.addCase(signUpThunk.pending, (state) => ({
      ...state,
      signUp: {
        ...state.signUp,
        isLoading: true,
      },
    }));
    builder.addCase(signUpThunk.rejected, (state) => ({
      ...state,
      signUp: {
        ...state.signUp,
        isLoading: false,
      },
    }));
    builder.addCase(forgotPasswordThunk.fulfilled, (state) => ({
      ...state,
      forgotPassword: {
        isLoading: false,
      },
    }));
    builder.addCase(forgotPasswordThunk.pending, (state) => ({
      ...state,
      forgotPassword: {
        ...state.forgotPassword,
        isLoading: true,
      },
    }));
    builder.addCase(createNewPasswordThunk.fulfilled, (state) => ({
      ...state,
      createNewPassword: {
        isLoading: false,
      },
    }));
    builder.addCase(createNewPasswordThunk.pending, (state) => ({
      ...state,
      createNewPassword: {
        ...state.createNewPassword,
        isLoading: true,
      },
    }));
    builder.addCase(changePasswordThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(changePasswordThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(changePasswordThunk.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export default authSlice.reducer;

export const authActions = { ...authSlice.actions };
