import './styles.css';

interface Props {
  image: string;
  title: string;
  price: string;
}

const EcommerceLayout1FallbackItem = ({ image, title, price }: Props) => (
  <div className="EcommerceLayout1FallbackItem flexbox-row">
    <img className="EcommerceLayout1FallbackItemImage" src={image} alt="main" />
    <div className="EcommerceLayout1FallbackItemInfo flexbox-column flexbox-gap-24px flexbox-jc-center flexbox-ai-center">
      <div className="flexbox-column flexbox-gap-6px flexbox-ai-center">
        <div className="EcommerceLayout1FallbackItemInfoTitle">{title}</div>
        <div className="EcommerceLayout1FallbackItemInfoPriceContainer">
          <div className="EcommerceLayout1FallbackItemInfoStartingFrom">
            Starting from
          </div>
          <div className="EcommerceLayout1FallbackItemInfoPrice">${price}</div>
        </div>
      </div>
      <div className="EcommerceButton flex justify-center items-center border rounded py-2.5 px-8 font-Roboto">
        Shop now
      </div>
    </div>
  </div>
);
export default EcommerceLayout1FallbackItem;
