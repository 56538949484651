import clsx from 'clsx';

import { Select, Typography } from '@atoms';
import { SelectComponent, SelectOptionType } from '@devTypes';

import './styles.css';

interface Props<T> extends SelectComponent<T> {
  title: string;
  titleWeight?: 'bold' | 'regular';
}

const SelectWithTitle = <T extends SelectOptionType>({
  title,
  titleWeight = 'regular',
  value,
  onChange,
  options,
  placeholder,
  className,
  selectedCheckMark = true,
}: Props<T>) => (
  <div className={clsx(className, 'SelectWithTitle')}>
    <Typography variant="subtitle4" weight={titleWeight}>
      {title}
    </Typography>
    <Select
      placeholder={placeholder}
      value={value}
      options={options}
      onChange={onChange}
      selectedCheckMark={selectedCheckMark}
    />
  </div>
);

export default SelectWithTitle;
