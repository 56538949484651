import { RootState } from '../../store';

const editorSelectedTab = (state: RootState) => state.canvas.selectedTab;

const editorEmailType = (state: RootState) => state.canvas.emailType;

export default {
  editorSelectedTab,
  editorEmailType,
};
