import { CreateNewPasswordTemplate } from '@templates';

interface Props {
  onSubmit: () => void;
}

const PasswordUpdatedView = ({ onSubmit }: Props) => (
  <CreateNewPasswordTemplate onSubmit={onSubmit} type="passwordUpdated" />
);

export default PasswordUpdatedView;
