import { Typography } from '@atoms';

import './styles.css';

interface ForgotPasswordCheckEmailProps {
  onClickResend: () => void;
}

const ForgotPasswordCheckEmail = ({
  onClickResend,
}: ForgotPasswordCheckEmailProps) => (
  <div className="ForgotPasswordCheckEmail">
    <div className="RedirectLabel">
      <Typography variant="subtitle3">Didn&apos;t receive an email?</Typography>
      &nbsp;
      <Typography
        onClick={onClickResend}
        variant="subtitle3"
        className="ResendLabel"
      >
        Click here to resend
      </Typography>
    </div>
  </div>
);

export default ForgotPasswordCheckEmail;
