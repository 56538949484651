import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import { campaignsSelectors } from '@store/features/campaigns';
import { createCampaignThunk } from '@store/features/campaigns/asyncThunks';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';
import { trackToGTM } from '@utils/helpers';
import AllCampaignsView from '@views/AllCampaignsView/AllCampaignsView';

const AllCampaignsPage = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data = useSelector(campaignsSelectors.campaigns);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(templateAsyncThunks.fetchTemplatesThunk({}));
    sessionStorage.removeItem('form');
  }, []);

  const createCampaignAction = async () => {
    const response = await dispatch(createCampaignThunk());
    return response;
  };

  const createNewCampaign = async () => {
    await createCampaignAction();
    trackToGTM({ event: 'campaign_new' });
    navigate(ROUTES.dashboard.createCampaign);
  };

  return <AllCampaignsView createNewCampaign={createNewCampaign} />;
};

export default AllCampaignsPage;
