import grapesjs from 'grapesjs';

const setColumnsAmount = (component: grapesjs.Component, amount: number) => {
  component.addAttributes({ amount });
};

const getColumnsAmount = (component: grapesjs.Component) => {
  const { amount } = component.getAttributes();

  return amount;
};

const setColumnsHorizontalSpace = (
  component: grapesjs.Component,
  horizontalSpace: number
) => {
  component.addAttributes({ horizontalSpace });

  component.components().models.forEach((model) => {
    model.components().forEach((item) => {
      if (item.index() === model.components().length - 1) {
        return;
      }

      item.addStyle({ 'margin-bottom': `${horizontalSpace}px` });
    });
  });
};

const getColumnsHorizontalSpace = (component: grapesjs.Component) => {
  const { horizontalSpace } = component.getAttributes();

  return horizontalSpace;
};

const setColumnsVerticalSpace = (
  component: grapesjs.Component,
  verticalSpace: number
) => {
  component.addAttributes({ verticalSpace });

  const columnPadding = verticalSpace / 2;

  component
    .components()
    .models[0].addStyle({ 'padding-right': `${columnPadding}px` });

  component
    .components()
    .models[1].addStyle({ 'padding-left': `${columnPadding}px` });
};

const getColumnsVerticalSpace = (component: grapesjs.Component) => {
  const { verticalSpace } = component.getAttributes();

  return verticalSpace;
};

export default {
  getColumnsAmount,
  setColumnsAmount,
  getColumnsHorizontalSpace,
  setColumnsHorizontalSpace,
  getColumnsVerticalSpace,
  setColumnsVerticalSpace,
};
