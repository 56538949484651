import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { string } from 'yup';

import { Notification } from '@atoms';
import { FullPageLoader } from '@molecules';
import { IntegrationsView } from '@views';
import { AVAILABLE_INTEGRATIONS } from '@constants/common';
import { NotificationType, Platform } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { shopifyAsyncThunks } from '@store/features/shopify';
import { verifyShopifyConnectionThunk } from '@store/features/shopify/asyncThunks';
import { usersAsyncThunks, usersSelectors } from '@store/features/users';
import { getUserThunk } from '@store/features/users/asyncThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const defaultValues = {
  shopifyStoreName: '',
};

type FormValues = typeof defaultValues;

type INotification = {
  type: NotificationType;
  text: string;
  untilClose?: boolean;
};

const successNotification: INotification = {
  type: 'success',
  text: 'Your Shopify store has successfully been connected',
};

const IntegrationsPage = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [notification, setNotification] = useState<INotification | null>(null);
  const [shopifyConnection, setShopifyConnection] = useState<Platform[]>([]); // temp approach
  const [availableConnections, setAvailableConnections] = useState<Platform[]>(
    AVAILABLE_INTEGRATIONS
  );
  const [isConnectShopifyModalOpen, setIsConnectShopifyModalOpen] =
    useState(false);
  const [isDisconnectShopifyModalOpen, setIsDisconnectShopifyModalOpen] =
    useState(false);
  const isGetUserLoading = useAppSelector(usersSelectors.isGetUserLoading);
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      yup.object({
        shopifyStoreName: string().required('Shopify store name is required'),
      })
    ),
  });

  useEffect(() => {
    if (state?.shopifyConnected) {
      setNotification(successNotification);
      window.history.replaceState({}, document.title);
    }
  }, [state]);

  // const { handleSubmit, getValues } = methods;

  const filterConnections = async () => {
    const { integrations } = await dispatch(
      usersAsyncThunks.getUserThunk(null)
    ).unwrap();

    if (integrations.shopify) {
      setShopifyConnection([AVAILABLE_INTEGRATIONS[0]]);
      const updatedConnections = availableConnections.filter(
        (item) => item.title !== 'Shopify'
      );
      setAvailableConnections(updatedConnections);
    } else {
      setShopifyConnection([]);
      setAvailableConnections(AVAILABLE_INTEGRATIONS);
    }
  };

  useEffect(() => {
    filterConnections();
  }, []);

  // const activateShopify = async () => {
  //   setIsConnectShopifyModalOpen(false);
  //   navigate(ROUTES.dashboard.shopifyAuth, {
  //     state: {
  //       ...state,
  //       shopifyStoreName: getValues().shopifyStoreName,
  //     },
  //   });
  // };

  const deactivateShopify = async () => {
    dispatch(shopifyAsyncThunks.deleteShopifyConnectionThunk({}))
      .unwrap()
      .then(() => {
        setShopifyConnection([]);
        setAvailableConnections(AVAILABLE_INTEGRATIONS);
        setIsDisconnectShopifyModalOpen(false);
        dispatch(verifyShopifyConnectionThunk({}));
        dispatch(getUserThunk(null));
      })
      .catch((err: Error) => toast(err.message));
  };

  // const openShopifyDashboard: SubmitHandler<FormValues> = ({
  //   shopifyStoreName,
  // }) => {
  //   window.open(
  //     `https://admin.shopify.com/store/${shopifyStoreName}/settings/apps/development`
  //   );
  //   trackToZappier({ event: 'Shopify store link provided', shopifyStoreName });
  //   activateShopify();
  // };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  if (isGetUserLoading) return <FullPageLoader />;

  return (
    <FormProvider {...methods}>
      {!!notification && (
        <Notification
          {...notification}
          isOpen={!!notification}
          onClose={handleNotificationClose}
        />
      )}
      <IntegrationsView
        connected={shopifyConnection}
        availableConnections={availableConnections}
        deactivateShopify={deactivateShopify}
        isConnectShopifyModalOpen={isConnectShopifyModalOpen}
        setIsConnectShopifyModalOpen={setIsConnectShopifyModalOpen}
        isDisconnectShopifyModalOpen={isDisconnectShopifyModalOpen}
        setIsDisconnectShopifyModalOpen={setIsDisconnectShopifyModalOpen}
      />
    </FormProvider>
  );
};

export default IntegrationsPage;
