import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Typography } from '@atoms';
import {
  EditorBlockSettingContainer,
  Input,
  UploadImageContainer,
} from '@molecules';
import { EditorBlockSettingsComponent } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDebounce } from '@hooks';
import { getChildComponentAt } from '@utils/helpers';
import { schemas } from '@utils/validation';

import './styles.css';

interface Props extends EditorBlockSettingsComponent {
  onUploadImageOpen: () => void;
}

const EditorImageBlockSettingsContent = ({
  selectedComponent,
  onUploadImageOpen,
}: Props) => {
  const { control, getFieldState, setValue } = useForm({
    defaultValues: { link: '' },
    mode: 'onBlur',
    resolver: yupResolver(schemas.urlInputSchema),
  });

  const link = useDebounce(useWatch({ control, name: 'link' }), 500);

  useEffect(() => {
    const linkComponent = getChildComponentAt(selectedComponent, 1);
    if (!linkComponent) return;

    const { link: linkAttr } = linkComponent.getAttributes();

    setValue('link', linkAttr);
  }, [selectedComponent]);

  useEffect(() => {
    const linkComponent = getChildComponentAt(selectedComponent, 1);

    const linkFieldState = getFieldState('link');

    if (linkFieldState.error || link === '') {
      linkComponent.removeAttributes('href');
      return;
    }

    if (!link) {
      return;
    }

    if (/^https?:\/\/.+/.test(link)) {
      const formattedLink = link.replace(/^https?:\/\//, '');
      linkComponent.addAttributes({
        href: link,
        link: formattedLink,
      });
      setValue('link', formattedLink);
      return;
    }

    linkComponent.addAttributes({ href: `https://${link}`, link });
  }, [link]);

  const { imageUrl } = selectedComponent.getAttributes();

  return (
    <div className="EditorImageBlockSettingsContent">
      <UploadImageContainer imageUrl={imageUrl} onClick={onUploadImageOpen} />
      <EditorBlockSettingContainer title="Image URL">
        <Input
          name="link"
          placeholder="www.mysite.com"
          className="[&_.Input-with-start-icon]:pl-[85px] [&>div>p]:!left-0"
          control={control}
          startIcon={
            <Typography
              className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
              variant="subtitle4"
            >
              https://
            </Typography>
          }
        />
      </EditorBlockSettingContainer>
    </div>
  );
};

export default EditorImageBlockSettingsContent;
