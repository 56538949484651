import { MouseEventHandler, useState } from 'react';
import clsx from 'clsx';

import { Icon, Tooltip } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';

interface Props {
  svgIcon: SvgFunctionComponent;
  isActive: boolean;
  name: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const EditorTextBlockStylesSettingsOption = ({
  name,
  svgIcon,
  isActive,
  onClick,
  className,
}: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleTooltipToggle = () =>
    setIsTooltipVisible((prevState) => !prevState);

  return (
    <div
      className={clsx(
        'EditorTextBlockSettingsOption',
        isActive && 'EditorTextBlockSettingsOption-active',
        className
      )}
      onMouseEnter={handleTooltipToggle}
      onMouseLeave={handleTooltipToggle}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      {isTooltipVisible && name && <Tooltip label={name} />}
      <Tooltip
        label={name}
        className="EditorTextBlockStylesSettingsOption-Tooltip"
      >
        <Icon SVG={svgIcon} height={13} />
      </Tooltip>
    </div>
  );
};

export default EditorTextBlockStylesSettingsOption;
