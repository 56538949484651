import { Button, Typography } from '@atoms';
import { ReportCardsList } from '@organisms';
import { DashboardTemplate } from '@templates';
import { ReactComponent as DownloadIcon } from '@assets/icons/download.svg';
import { ReactComponent as ShareIcon } from '@assets/icons/share-report.svg';

interface Props {
  campaignData: {
    title: string;
    previewUrl: string;
    campaignId: number;
    audience: 'all';
    subject: string;
    deliveredAt: string;
    recipients: number;
  };
  sendgridData: {
    sent: number;
    delivered: {
      value: number;
      percentage: string;
    };
    unsubscribed: {
      value: number;
      percentage: string;
    };
    bounced: {
      value: number;
      percentage: string;
    };
    opens: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
    clicks: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
    uniqueOpens: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
    uniqueClicks: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
  };
  shopifyData: {
    totalCartsCreated: number;
    paidCarts: number;
    totalBookedCartValue: string;
    totalItemsAddedToCart: number;
    revenue: string;
    averageOrderValue: string;
  };
  onDownloadReport: () => void;
  onPreview: () => void;
}

const audienceTitles = {
  all: 'All contacts - Subscribed and unsubscribed',
};

const ReportPageView = ({
  sendgridData,
  campaignData,
  shopifyData,
  onDownloadReport,
  onPreview,
}: Props) => {
  const {
    bounced,
    clicks,
    delivered,
    opens,
    sent,
    uniqueClicks,
    uniqueOpens,
    unsubscribed,
  } = sendgridData;
  const {
    revenue,
    averageOrderValue,
    paidCarts,
    totalBookedCartValue,
    totalCartsCreated,
    totalItemsAddedToCart,
  } = shopifyData;
  const { audience, deliveredAt, subject, title, previewUrl, recipients } =
    campaignData;

  return (
    <DashboardTemplate>
      <div>
        <div className="flex items-center justify-between px-8 py-5 border-b border-gray-300">
          <Typography variant="subtitle1">Report</Typography>
          <div className="flex gap-4">
            <Button
              startIcon={<ShareIcon />}
              variant="secondary"
              title="Share report"
            />
            <Button
              startIcon={<DownloadIcon />}
              variant="primary"
              title="Download report"
              onClick={onDownloadReport}
            />
          </div>
        </div>
        <div id="pdf" className="flex flex-col px-8 py-7">
          <Typography className="pb-9" variant="subtitle3" weight="semiBold">
            {title}
          </Typography>
          <div className="flex gap-6">
            <div className="flex flex-col gap-12">
              <div className="w-[463px] flex flex-col gap-4 bg-white p-4 rounded">
                <div className="flex justify-between">
                  <Typography variant="subtitle2" weight="bold">
                    Sent
                  </Typography>
                  <Typography variant="subtitle2" weight="bold">
                    {sent}
                  </Typography>
                </div>
                <div className="flex justify-between border-b border-gray-200">
                  <Typography variant="subtitle3">Delivered</Typography>
                  <div className="flex w-1/3 justify-between">
                    <Typography variant="subtitle3">
                      {delivered.value}
                    </Typography>
                    <Typography
                      className="text-primary-main"
                      variant="subtitle3"
                    >
                      {delivered.percentage}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between border-b border-gray-200">
                  <Typography variant="subtitle3">Unsubscribed</Typography>
                  <div className="flex w-1/3 justify-between">
                    <Typography variant="subtitle3">
                      {unsubscribed.value}
                    </Typography>
                    <Typography
                      className="text-primary-main"
                      variant="subtitle3"
                    >
                      {unsubscribed.percentage}
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between border-b border-gray-200">
                  <Typography variant="subtitle3">Bounced</Typography>
                  <div className="flex w-1/3 justify-between">
                    <Typography variant="subtitle3">{bounced.value}</Typography>
                    <Typography
                      className="text-primary-main"
                      variant="subtitle3"
                    >
                      {bounced.percentage}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="flex gap-6 w-[463px]">
                <div className="flex flex-col gap-4">
                  <div className="w-[177px] h-[220px]">
                    {previewUrl ? (
                      <img
                        className="w-full h-full"
                        src={previewUrl}
                        alt="Preview"
                      />
                    ) : (
                      <div className="w-full h-full bg-primary-50 rounded flex items-center text-center">
                        <Typography variant="subtitle2">
                          Preview not available
                        </Typography>
                      </div>
                    )}
                  </div>

                  <Button
                    variant="secondary"
                    title="Preview"
                    onClick={onPreview}
                  />
                </div>
                <div className="flex flex-col w-[282px] gap-4">
                  <div className="flex gap-1.5">
                    <Typography className="text-primary-main" variant="h6">
                      {recipients}
                    </Typography>
                    <Typography variant="h6">Recipients</Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="subtitle4" weight="bold">
                      Audience
                    </Typography>
                    <Typography variant="subtitle4">
                      {/* TODO: add other audience titles */}
                      {audienceTitles[audience]}
                    </Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="subtitle4" weight="bold">
                      Subject
                    </Typography>
                    <Typography variant="subtitle4">{subject}</Typography>
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="subtitle4" weight="bold">
                      Delivered
                    </Typography>
                    <Typography variant="subtitle4">
                      {/* TODO: align the date time format */}
                      {deliveredAt &&
                        new Intl.DateTimeFormat('en-US', {
                          dateStyle: 'full',
                          timeStyle: 'short',
                        }).format(new Date(deliveredAt))}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-6">
              <ReportCardsList
                revenue={revenue}
                opens={opens}
                clicks={clicks}
                uniqueClicks={uniqueClicks}
                uniqueOpens={uniqueOpens}
                totalCartsCreated={totalCartsCreated}
                averageOrderValue={averageOrderValue}
                totalBookedCartValue={totalBookedCartValue}
                totalItemsAddedToCart={totalItemsAddedToCart}
                paidCarts={paidCarts}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default ReportPageView;
