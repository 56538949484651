import { Checkbox, Typography } from '@atoms';
import { ProductItemComponent } from '@devTypes';

import './styles.css';

const ProductItem = ({
  title,
  url,
  isSelected,
  onChange,
}: ProductItemComponent) => (
  <div className="ProductItem flexbox-row flexbox-jc-space-between flexbox-ai-center">
    <div className="flexbox-row flexbox-gap-12px flexbox-ai-center">
      <img
        className="ProductItemImage"
        src={url}
        alt="product"
        loading="lazy"
      />
      <Typography variant="subtitle3" weight="medium">
        {title}
      </Typography>
    </div>
    <div>
      <Checkbox isChecked={isSelected} onChange={onChange} />
    </div>
  </div>
);

export default ProductItem;
