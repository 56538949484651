import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Card, Typography } from '@atoms';
import { Tabs } from '@molecules';
import { ChangeCampaignNameModal, ConfirmModal, Table } from '@organisms';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as DuplicateIcon } from '@assets/icons/editor-duplicate.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/editor-trash.svg';
import { CONTACTS_TABLE_ROWS_PER_PAGE } from '@constants/common';
import { ROUTES } from '@constants/routes';
import { useDebounce } from '@hooks';
import { TabItem } from '@molecules/Tabs/Tabs';
import { RowData } from '@organisms/Table/TableRow';
import {
  campaignsAsyncThunks,
  campaignsSelectors,
} from '@store/features/campaigns';
import {
  getCampaignByIdThunk,
  getCampaignsThunk,
} from '@store/features/campaigns/asyncThunks';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import CampaignsTableSearchAndFilters, {
  createdDateSortOption,
  searchDefaultValues,
  SearchFormValues,
  SortOption,
} from './CampaignsTableSearchAndFilters';

const headerCells = ['Campaign name', 'Status', 'Open rate', 'Click rate'];

const getStatusTabLabel = (title: string, itemsAmount: number) =>
  `${title} (${itemsAmount})`;

// const columnWidths = ['40%', '15%', '15%', '15%', '10%', '5%'];
const columnWidths = ['40%'];

const CampaignsTable = () => {
  const {
    campaigns: {
      data: campaignsList,
      total,
      metadata: { draftCampaignsCount, publishedCampaignsCount },
    },
    isLoading,
  } = useAppSelector(campaignsSelectors.campaigns);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const allCampaignsAmount = draftCampaignsCount + publishedCampaignsCount;
  const allCampaignsStatusTab = {
    id: '1',
    label: getStatusTabLabel('All', allCampaignsAmount),
    status: 'all',
  };

  const [selectedTab, setSelectedTab] = useState<TabItem>(
    allCampaignsStatusTab
  );

  const [selectedItemsIds, setSelectedItemsIds] = useState<Array<number>>([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [sortByValue, setSortByValue] = useState<SortOption | null>(
    createdDateSortOption
  );
  const [rowItemWithOpenedMoreOptions, setRowItemWithOpenedMoreOptions] =
    useState<RowData>();
  const [isDeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] =
    useState(false);
  const [isChangeCampaignNameModalOpen, setIsChangeCampaignNameModalOpen] =
    useState(false);

  const { control } = useForm<SearchFormValues>({
    defaultValues: searchDefaultValues,
  });

  const handleSortBySelect = (value: SortOption) => setSortByValue(value);

  const search = useWatch({ control, name: 'search' });
  const searchValue = useDebounce(search, 500);

  const fetchCampaigns = () => {
    dispatch(
      getCampaignsThunk({
        offset: currentOffset,
        limit: CONTACTS_TABLE_ROWS_PER_PAGE,
        ...(searchValue && { title: searchValue }),
        sortBy: sortByValue?.fieldToSortBy,
        order: sortByValue?.order || 'DESC',
        ...(selectedTab.status !== 'all' && { status: selectedTab.status }),
      })
    );
  };

  useEffect(() => {
    fetchCampaigns();
  }, [currentOffset, searchValue, sortByValue, selectedTab]);

  const renderTableRow = ({
    title,
    status,
    openRate,
    clickRate,
  }: {
    [key: string]: string;
  }) => [title, status, openRate, clickRate];

  const onTableRowClick = async (item: any) => {
    if (item.status !== 'Published') {
      const {
        payload: { campaign },
      } = await dispatch(getCampaignByIdThunk({ id: item.id }));

      await navigate(ROUTES.dashboard.createCampaign, {
        state: { selectedCampaign: campaign },
      });
    }
  };

  const onTableRowViewReportClick = (id: number) => {
    navigate(`/dashboard/campaigns/${id}/report`);
  };

  const handleMoreOptionsItemClick = (handler: any) => (rowItem: any) => {
    setRowItemWithOpenedMoreOptions(rowItem);

    handler();
  };

  const deleteCampaign = async () => {
    await dispatch(
      campaignsAsyncThunks.deleteCampaign({
        id: rowItemWithOpenedMoreOptions?.id,
      })
    );

    fetchCampaigns();
  };

  const renameCampaign = async (title: string) => {
    await dispatch(
      campaignsAsyncThunks.updateCampaignThunk({
        id: rowItemWithOpenedMoreOptions?.id,
        title,
      })
    );

    fetchCampaigns();
  };

  const duplicateCampaign = async (rowItem: any) => {
    const {
      title,
      subject,
      previewText,
      senderName,
      senderId,
      templateId,
      recipientType,
    } = rowItem;

    let templateCopyId = null;

    if (templateId) {
      const templateCopy = await dispatch(
        templateAsyncThunks.duplicateTemplateThunk({ id: templateId })
      ).unwrap();
      templateCopyId = templateCopy.id;
    }

    await dispatch(
      campaignsAsyncThunks.createCampaignThunk({
        title: `Copy of ${title}`,
        subject,
        senderName,
        previewText,
        senderId,
        templateId: templateCopyId,
        recipientType,
      })
    );

    fetchCampaigns();
  };

  const statusTabs: TabItem[] = [
    allCampaignsStatusTab,
    {
      id: '2',
      label: getStatusTabLabel('Draft', draftCampaignsCount),
      status: 'draft',
    },
    {
      id: '3',
      label: getStatusTabLabel('Published', publishedCampaignsCount),
      status: 'published',
    },
  ];

  return (
    <div>
      <ConfirmModal
        isOpen={isDeleteCampaignModalOpen}
        onClose={() => setIsDeleteCampaignModalOpen(false)}
        onConfirm={deleteCampaign}
        title="Delete campaign"
        subtitle={
          <span className="flex flex-col gap-2">
            <span>Are you sure you want to delete the selected campaign?</span>
            <span>“Campaign for new website”</span>
          </span>
        }
      />
      <ChangeCampaignNameModal
        isOpen={isChangeCampaignNameModalOpen}
        onClose={() => setIsChangeCampaignNameModalOpen(false)}
        onConfirm={renameCampaign}
        campaignName={rowItemWithOpenedMoreOptions?.title}
      />
      <div>
        <CampaignsTableSearchAndFilters
          control={control}
          sortByValue={sortByValue}
          onSortBySelect={handleSortBySelect}
        />
      </div>
      <Tabs
        items={statusTabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        className="CampaignsTabs"
      />
      <Card className="CampaignsTable">
        {campaignsList ? (
          <Table
            selectedItemsIds={selectedItemsIds}
            setSelectedItemsIds={setSelectedItemsIds}
            data={campaignsList}
            headerCells={headerCells}
            columnWidths={columnWidths}
            renderRowData={renderTableRow}
            onTableRowClick={onTableRowClick}
            paginationButtonsProps={{
              currentRowsOffset: currentOffset,
              setCurrentRowsOffset: setCurrentOffset,
              maxVisibleRowsAmount: CONTACTS_TABLE_ROWS_PER_PAGE,
              totalRowsAmount: total,
            }}
            buttonProps={{
              isVisible: true,
              title: 'View report',
              onClick: onTableRowViewReportClick,
            }}
            moreOptionsPopup={{
              selectOptions: [
                {
                  icon: EditIcon,
                  title: 'Rename',
                  onClick: handleMoreOptionsItemClick(() =>
                    setIsChangeCampaignNameModalOpen(true)
                  ),
                },
                {
                  icon: DuplicateIcon,
                  title: 'Duplicate',
                  onClick: duplicateCampaign,
                },
                {
                  icon: TrashIcon,
                  title: 'Delete',
                  onClick: handleMoreOptionsItemClick(() =>
                    setIsDeleteCampaignModalOpen(true)
                  ),
                },
              ],
            }}
            isLoading={isLoading}
          />
        ) : (
          <Typography variant="subtitle4">No campaigns</Typography>
        )}
      </Card>
    </div>
  );
};

export default CampaignsTable;
