import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { Card } from '@atoms';
import { Table } from '@organisms';
import { CONTACTS_TABLE_ROWS_PER_PAGE } from '@constants/common';
import { Recipient, RecipientStatus } from '@devTypes';
import { useDebounce } from '@hooks';
import { allContactsSelectors } from '@store/features/allcontacts';
import { EditRecipientData } from '@store/features/allcontacts/api';
import {
  deleteRecipientsThunk,
  editRecipientThunk,
  getRecipientsThunk,
  updateRecipientStatusThunk,
} from '@store/features/allcontacts/asyncThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import DeleteContactsModal from '@templates/AllContactsTemplate/DeleteContactsModal';
import EditContactModal from '@templates/AllContactsTemplate/EditContactModal';
import SelectedContactsToolbar from '@templates/AllContactsTemplate/SelectedContactsToolbar';

import ContactsTableSearchAndFilters, {
  searchDefaultValues,
  SearchFormValues,
  SortOption,
} from './ContactsTableSearchAndFilters';

const defaultFiltersValues = {
  unsubscribed: false,
  subscribed: false,
};

interface Props {
  selectedTab: string;
}

const DEFAULT_HEADER_CELLS = [
  'First name',
  'Last name',
  'Email address',
  'Data added',
];

type FiltersValues = typeof defaultFiltersValues;
type FilterField = keyof FiltersValues;

const ContactsTable = ({ selectedTab }: Props) => {
  const dispatch = useAppDispatch();
  const { data, total, isLoading } = useSelector(
    allContactsSelectors.recipients
  );

  const [filtersValues, setFiltersValues] =
    useState<FiltersValues>(defaultFiltersValues);

  const [selectedItemsIds, setSelectedItemsIds] = useState<Array<number>>([]);
  const [selectedContact, setSelectedContact] = useState<Recipient | null>(
    null
  );
  const [currentOffset, setCurrentOffset] = useState(0);

  const [sortByValue, setSortByValue] = useState<SortOption | null>(null);

  const [selectedData, setSelectedData] = useState<any[]>(data || []);

  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [isDeleteContactsModalOpen, setIsDeleteContactsModalOpen] =
    useState(false);
  const [headerCells, setHeaderCells] = useState(DEFAULT_HEADER_CELLS);
  const [unsubscribedRecipients, setUnsubscribedRecipients] =
    useState(DEFAULT_HEADER_CELLS);
  const [allRecipients, setAllRecipients] = useState([]);

  const spamContacts = useAppSelector(allContactsSelectors.spamContacts);
  const invalidEmails = useAppSelector(allContactsSelectors.invalidEmails);

  const bouncedEmails = useAppSelector(allContactsSelectors.bouncedContacts);

  const { control } = useForm<SearchFormValues>({
    defaultValues: searchDefaultValues,
  });

  const searchValue = useDebounce(useWatch({ control, name: 'search' }), 500);

  const fetchRecipients = async () => {
    const { subscribed, unsubscribed } = filtersValues;

    const isStatusFilterApplied = unsubscribed !== subscribed;

    const fetchedRecipients = await dispatch(
      getRecipientsThunk({
        offset: currentOffset,
        limit: CONTACTS_TABLE_ROWS_PER_PAGE,
        ...(searchValue && { q: searchValue }),
        sortBy: sortByValue?.fieldToSortBy,
        order: 'ASC',
        ...(isStatusFilterApplied && {
          status: subscribed ? 'subscribed' : 'unsubscribed',
        }),
      })
    );
    if (selectedTab === '') {
      setSelectedData(fetchedRecipients.payload.data);
      if (!isStatusFilterApplied) {
        setAllRecipients(fetchedRecipients.payload.data);
      }
    }
  };

  useEffect(() => {
    fetchRecipients();
  }, [currentOffset, searchValue, sortByValue]);

  const handleFiltersChange = async (filterField: FilterField) => {
    setFiltersValues((prevState) => ({
      ...prevState,
      [filterField]: !prevState[filterField],
    }));
  };

  useEffect(() => {
    if (selectedTab === '') {
      setSelectedData(data);
    }

    if (
      spamContacts !== undefined &&
      invalidEmails !== undefined &&
      bouncedEmails !== undefined
    ) {
      setHeaderCells([
        'First name',
        'Last name',
        'Email address',
        selectedTab === '' ? 'Date added' : 'Reason',
      ]);

      switch (selectedTab) {
        case 'spam':
          setSelectedData(spamContacts);
          break;
        case 'invalid':
          setSelectedData(invalidEmails);
          break;
        case 'bounce':
          setSelectedData(bouncedEmails);
          break;
        case 'unsubscribes':
          setSelectedData(unsubscribedRecipients);
          break;
        default:
          setSelectedData(allRecipients);
          break;
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    dispatch(
      getRecipientsThunk({
        offset: currentOffset,
        limit: CONTACTS_TABLE_ROWS_PER_PAGE,
        ...(searchValue && { q: searchValue }),
        sortBy: sortByValue?.fieldToSortBy,
        order: 'ASC',
        status: 'unsubscribed',
      })
    ).then((response) => {
      setUnsubscribedRecipients(response.payload.data);
    });
  }, []);

  const renderTableRow = ({
    firstName,
    lastName,
    email,
    createdAt,
  }: Recipient) => [
    firstName,
    lastName,
    email,
    format(new Date(createdAt), 'MMM dd, y'),
  ];

  const renderTableRowInvalid = ({
    firstName,
    lastName,
    email,
    reason,
  }: any) => [firstName, lastName, email, reason];

  const handleSortBySelect = (value: SortOption) => setSortByValue(value);

  const handleEditContactModalToggle = () =>
    setIsEditContactModalOpen((prevState) => !prevState);

  const handleDeleteContactsModalToggle = () =>
    setIsDeleteContactsModalOpen((prevState) => !prevState);

  const handleEditModalClose = async () => {
    await setIsEditContactModalOpen(false);
    setSelectedContact(null);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteContactsModalOpen(false);
    setSelectedContact(null);
  };

  const handleFiltersApply = () => {
    fetchRecipients();
  };

  const handleRecipientStatusChange =
    (status: RecipientStatus) => (rowItem?: Recipient) => {
      const isOneRecipientUpdated = !!rowItem?.id;

      if (rowItem?.status === status) {
        toast.info(`This recipient is already ${status}`);
        return;
      }

      const recipientIds = isOneRecipientUpdated
        ? [rowItem.id]
        : selectedItemsIds;

      dispatch(
        updateRecipientStatusThunk({
          status,
          ids: recipientIds,
        })
      )
        .unwrap()
        .then(async () => {
          setSelectedItemsIds([]);
          await fetchRecipients();
          toast.success(
            isOneRecipientUpdated
              ? `This recipient was successfully ${status}`
              : `Recipients  were successfully ${status}`
          );
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    };

  const handleRecipientsDelete = (ids: number[]) => {
    dispatch(
      deleteRecipientsThunk({
        ids,
      })
    )
      .unwrap()
      .then(() => {
        handleDeleteContactsModalToggle();
        fetchRecipients();
        setSelectedContact(null);
        setSelectedItemsIds([]);
      })
      .catch((error) => {
        toast.error(error.message.message);
      });
  };

  const handleRecipientEdit = ({ id, ...recipientData }: EditRecipientData) => {
    dispatch(
      editRecipientThunk({
        id,
        ...recipientData,
      })
    )
      .unwrap()
      .then(async () => {
        setIsEditContactModalOpen(false);
        await fetchRecipients();
        setSelectedContact(null);
      })
      .catch((error) => {
        toast(error.message.message);
      });
  };

  const editAction = { title: 'Edit', onClick: handleEditContactModalToggle };

  const deleteAction = {
    title: 'Delete',
    onClick: handleDeleteContactsModalToggle,
  };

  const unsubscribeAction = {
    title: 'Unsubscribe',
    onClick: handleRecipientStatusChange('unsubscribed'),
  };

  const subscribeAction = {
    title: 'Subscribe',
    onClick: handleRecipientStatusChange('subscribed'),
  };

  const moreOptionsSelectList = [
    editAction,
    unsubscribeAction,
    subscribeAction,
    deleteAction,
  ];
  const selectedRecipientsActions = [unsubscribeAction, subscribeAction];

  const selectedItemsAmount = selectedItemsIds.length;

  return (
    <Card className="ContactsTable">
      {selectedTab === '' &&
        (selectedItemsAmount ? (
          <SelectedContactsToolbar
            selectedItemsAmount={selectedItemsAmount}
            onDeleteButtonClick={handleDeleteContactsModalToggle}
            actions={selectedRecipientsActions}
          />
        ) : (
          <ContactsTableSearchAndFilters
            control={control}
            sortByValue={sortByValue}
            onSortBySelect={handleSortBySelect}
            onSubscribedContactsChange={() => handleFiltersChange('subscribed')}
            onUnsubscribedContactsChange={() =>
              handleFiltersChange('unsubscribed')
            }
            onApplyClick={handleFiltersApply}
            isUnsubscribedContactsChecked={filtersValues.unsubscribed}
            isSubscribedContactsChecked={filtersValues.subscribed}
          />
        ))}
      <Table
        selectedItemsIds={selectedItemsIds}
        setSelectedItemsIds={setSelectedItemsIds}
        setActiveRow={setSelectedContact}
        data={selectedData}
        headerCells={headerCells}
        renderRowData={
          selectedTab === '' ? renderTableRow : renderTableRowInvalid
        }
        paginationButtonsProps={{
          currentRowsOffset: currentOffset,
          setCurrentRowsOffset: setCurrentOffset,
          maxVisibleRowsAmount: CONTACTS_TABLE_ROWS_PER_PAGE,
          totalRowsAmount: total,
        }}
        isLoading={isLoading}
        moreOptionsPopup={{
          selectOptions: moreOptionsSelectList,
        }}
        withCheckbox
      />
      {selectedContact && (
        <EditContactModal
          selectedContact={selectedContact}
          isOpen={isEditContactModalOpen}
          onClose={handleEditModalClose}
          onSubmit={handleRecipientEdit}
        />
      )}
      <DeleteContactsModal
        selectedContactsIds={
          selectedContact ? [selectedContact.id] : selectedItemsIds
        }
        isOpen={isDeleteContactsModalOpen}
        onClose={handleDeleteModalClose}
        onSubmit={handleRecipientsDelete}
      />
    </Card>
  );
};

export default ContactsTable;
