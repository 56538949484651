import clsx from 'clsx';

import { Icon } from '@atoms';
import { ReactComponent as CartIcon } from '@assets/icons/cart.svg';

import './styles.css';

interface Props {
  options: any[];
  value: any;
  onChange: (product: any) => void;
  onCartOpen: (x: boolean) => void;
  productAmount: number;
}

const EcommerceLayout1Navigation = ({
  options,
  value,
  onChange,
  onCartOpen,
  productAmount,
}: Props) => (
  <div className="EcommerceLayout1Navigation flexbox-row">
    {options.map(({ id, title, ...rest }, index) => (
      <div
        key={id}
        className={clsx(
          'EcommerceLayout1NavigationItem',
          id === value.id &&
            options.length > 1 &&
            'EcommerceLayout1NavigationItem-selected',
          index === options.length - 1 && 'EcommerceLayout1NavigationItem-last'
        )}
        onClick={() => {
          onChange({ id, title, ...rest });
          onCartOpen(false);
        }}
        tabIndex={0}
        role="button"
        style={{
          maxHeight: '40px',
          maxWidth: '189px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </div>
    ))}
    <div className="EcommerceLayout1NavigationItem-selected relative bg-black ml-auto flex items-center justify-center w-9 rounded-t">
      <div className="absolute top-[-14px] right-[-14px] bg-[#FF0A0A] text-white rounded-full w-6 h-6 text-center">
        {productAmount}
      </div>
      <Icon
        className="EcommerceLayout1Cart cursor-pointer"
        onClick={() => onCartOpen(true)}
        SVG={CartIcon}
        width={16}
        height={16}
      />
    </div>
  </div>
);

export default EcommerceLayout1Navigation;
