import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { Divider } from '@atoms';
import { EditorBlockSettingContainer, Selector } from '@molecules';
import { COLUMNS_LAYOUTS } from '@constants/editor';
import { SelectorOptionType } from '@devTypes';
import EditorColumnsBlockLayoutSettings from '@organisms/EditorColumnsBlockLayoutSettings/EditorColumnsBlockLayoutSettings';

const options = [
  { id: 1, text: '1', tooltipText: '1 Column' },
  { id: 2, text: '2', tooltipText: '2 Column' },
  {
    id: 3,
    text: '3',
    tooltipText: '3 Column',
    disabled: true,
  },
];

interface Props {
  selectedComponent: grapesjs.Component;
}
const EditorColumnsBlockSettingsContent = ({ selectedComponent }: Props) => {
  const [selectedColumn, setSelectedColumn] = useState<SelectorOptionType>();

  useEffect(() => {
    const { amount } = selectedComponent.getAttributes();
    const optionToSelect = options.find(({ id }) => id === amount);

    if (!optionToSelect) {
      return;
    }

    setSelectedColumn(optionToSelect);
  }, []);

  const handleChange = (option: SelectorOptionType) => {
    setSelectedColumn(option);

    if (option.id === 1) {
      selectedComponent.setAttributes({
        layout: COLUMNS_LAYOUTS.layoutA1.id,
        amount: COLUMNS_LAYOUTS.layoutA1.columnsAmount,
      });
    }

    if (option.id === 2) {
      selectedComponent.setAttributes({
        layout: 'layoutA',
        amount: 2,
      });
    }
  };

  if (!selectedColumn) {
    return null;
  }

  return (
    <EditorBlockSettingContainer title="Amount of columns">
      <Selector
        options={options}
        value={selectedColumn}
        onChange={handleChange}
      />
      <Divider />
      <EditorColumnsBlockLayoutSettings
        columnsAmount={selectedColumn.id as number}
        selectedComponent={selectedComponent}
      />
    </EditorBlockSettingContainer>
  );
};

export default EditorColumnsBlockSettingsContent;
