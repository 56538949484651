import { MouseEventHandler } from 'react';
import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/chevron-down.svg';
import { SelectOptionType } from '@devTypes';

interface Props<T> {
  onClick: MouseEventHandler;
  value?: T | null;
  placeholder?: string;
}

const DropdownHeader = <T extends SelectOptionType>({
  value,
  onClick,
  placeholder,
}: Props<T>) => (
  <div className="DropdownHeader" onClick={onClick} role="button" tabIndex={0}>
    <Typography
      variant="subtitle4"
      className={clsx('DropdownHeaderTitle', !value && 'Placeholder')}
    >
      {(typeof value === 'string' ? value : value?.title) || placeholder}
    </Typography>
    <Icon
      className="stroke-gray-500"
      SVG={ArrowDownIcon}
      width={20}
      height={20}
    />
  </div>
);

export default DropdownHeader;
