import {
  DEFAULT_BACKGROUND_VALUE,
  DEFAULT_DIVIDER_HEIGHT,
  DEFAULT_TEXT_COLOR_VALUE,
  EditorComponentTypes,
} from '@constants/editor';

export const DIVIDER_CLASS = 'gjs-divider';
export const DIVIDER_WRAPPER_CLASS = 'gjs-divider-wrapper';

export const generateGrapesjsDividerComponent = () => ({
  tagName: 'div',
  attributes: {
    class: DIVIDER_WRAPPER_CLASS,
    paddingtop: 32,
    paddingbottom: 32,
    paddingleft: 32,
    paddingright: 32,
  },
  type: EditorComponentTypes.Divider,
  droppable: false,
  components: [
    {
      selectable: false,
      droppable: false,
      draggable: false,
      hoverable: false,
      tagName: 'div',
      attributes: {
        class: DIVIDER_CLASS,
      },
      components: '',
    },
  ],
  styles: `
  .${DIVIDER_WRAPPER_CLASS} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 32px;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
  }

  .${DIVIDER_CLASS} {
    width: 100%;
    border-bottom: ${DEFAULT_DIVIDER_HEIGHT} solid ${DEFAULT_TEXT_COLOR_VALUE};
  }
  `,
});
