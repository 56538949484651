/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { Button, Card, Icon, Typography } from '@atoms';
import { Accordion, InputWithTitle } from '@molecules';
import { ReactComponent as CopyIcon } from '@assets/icons/copy.svg';
import { ReactComponent as Rotate } from '@assets/icons/rotate-cw.svg';
import { Domain, Record, ValidationResults } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TabItem } from '@molecules/Tabs/Tabs';
import { domainAsyncThunks, domainSelectors } from '@store/features/domains';
import { sendErrorThunk } from '@store/features/domains/asyncThunks';
import { domainActions } from '@store/features/domains/slice';
import { usersSelectors } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { customDomainCreateSchema } from '@utils/validation';

import './styles.css';

const defaultValues = {
  customDomainEmail: '',
};

type FormValues = typeof defaultValues;

interface CreateDomainFormProps {
  domain?: Domain;
  onBackToMyDomains: () => void;
  onContinue: () => void;
  validationResults: ValidationResults[] | undefined;
  activeTab: TabItem;
}

interface RecordProps {
  sendError: (error: string, record: Record) => void;
  record: Record;
  validationResult: any;
}

const RecordComponent = ({
  record,
  validationResult,
  sendError,
}: RecordProps) => {
  const [isErrorShown, setIsErrorShown] = useState(!!validationResult);

  return (
    <div className="RecordsSection">
      <div className="HostContainer">
        <div className="HostName">
          <Typography className="SenderSettingLabel" variant="subtitle4">
            Host
          </Typography>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              wordWrap: 'break-word',
              paddingRight: '10px',
            }}
          >
            <p style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
              <span>{record.host}</span>
              <div style={{ marginLeft: '5px' }}>
                <Icon
                  SVG={CopyIcon}
                  height={24}
                  width={24}
                  onClick={() => {
                    navigator.clipboard.writeText(record.host);
                    toast.info('Copied to clipboard!');
                  }}
                />
              </div>
            </p>
          </div>
        </div>
        <div className="HostType">
          <Typography className="SenderSettingLabel" variant="subtitle4">
            Type
          </Typography>
          <Typography variant="subtitle4">
            {record.type.toUpperCase()}
          </Typography>
        </div>
        <div className="HostStatus">
          <Typography className="SenderSettingLabel" variant="subtitle4">
            Status
          </Typography>
          <Typography
            variant="subtitle4"
            className={clsx(
              'RecordStatus',
              // eslint-disable-next-line no-nested-ternary
              record.status === 'Pending'
                ? 'Yellow'
                : record.status === 'Failed'
                ? 'Red'
                : 'Green'
            )}
          >
            {record.status}
          </Typography>
        </div>
        {validationResult &&
          validationResult.reason &&
          validationResult.reason !== null &&
          record.status !== 'Verified' && (
            <div className="HostError">
              <Button
                variant="text-primary"
                onClick={() => setIsErrorShown(!isErrorShown)}
                title={isErrorShown ? 'Hide error' : 'Show error'}
              />
            </div>
          )}
      </div>
      {isErrorShown &&
        validationResult &&
        validationResult.reason &&
        validationResult.reason !== null &&
        record.status !== 'Verified' && (
          <Card className="ErrorCard">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle4">
                Error: We received the following error when validating the
                record.
              </Typography>
              <Button
                title="Send error to AppMail"
                variant="text-primary"
                onClick={() => sendError(validationResult.reason, record)}
              />
            </div>
            <Card className="ErrorMessageCard">
              <Typography variant="subtitle4">
                {validationResult.reason}
              </Typography>
            </Card>
          </Card>
        )}
      <div className="RecordValue">
        <Typography className="SenderSettingLabel" variant="subtitle4">
          Value
        </Typography>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <span>{record.value}&nbsp;</span>
          <Icon
            SVG={CopyIcon}
            height={24}
            width={24}
            onClick={() => {
              navigator.clipboard.writeText(record.value);
              toast.info('Copied to clipboard!');
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CreateDomainForm = ({
  domain,
  validationResults,
  onBackToMyDomains,
}: CreateDomainFormProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(usersSelectors.user);
  const [currentDomain, setCurrentDomain] = useState<Domain | undefined>(
    domain
  );
  const [activeStep, setActiveStep] = useState(
    currentDomain?.domain !== '' ? 1 : 0
  );

  const [verifyResultsState, setVerifyResult] = useState<
    ValidationResults[] | undefined
  >(validationResults);

  const validationResultsSelector = useAppSelector(
    domainSelectors.validationResultsSelector
  );

  const methods = useForm<FormValues>({
    resolver: yupResolver(customDomainCreateSchema),
    mode: 'onBlur',
    defaultValues: {
      customDomainEmail: '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setCurrentDomain(domain);
  }, [domain]);

  const onContinue: SubmitHandler<FormValues> = async ({
    customDomainEmail,
  }) => {
    setActiveStep(1);
    dispatch(domainAsyncThunks.registerCustomDomainThunk({ customDomainEmail }))
      .unwrap()
      .then((response) => {
        setCurrentDomain(response);
      })
      .catch((err) => {
        toast.error(err.message.message);
      });

    dispatch(domainAsyncThunks.getListOfDomainsThunk(null)).catch((err) =>
      toast(err.message)
    );
  };

  const onClickVerifyDomain = async () => {
    if (currentDomain?.id) {
      await dispatch(domainAsyncThunks.validateDomainThunk(currentDomain?.id))
        .unwrap()
        .then((results) => {
          dispatch(
            domainActions.setValidationResults([
              ...validationResultsSelector,
              {
                domainId: domain?.id,
                validationResults: results.validationResults,
              },
            ])
          );
          setVerifyResult(results.validationResults);
        })
        .catch((err) => {
          toast.error(err.message.message);
        });
      await dispatch(domainAsyncThunks.getListOfDomainsThunk(null)).catch(
        (err) => {
          toast.error(err.message.message);
        }
      );
    }
  };

  const sendError = (error: string, record: Record) => {
    dispatch(sendErrorThunk(JSON.stringify({ user, record, error })));
  };

  const CREATE_DOMAIN_TUTORIAL_STEPS = [
    {
      title: 'STEP 1 - New custom sender email',
      content: (
        <div
          style={{ flexDirection: 'column', display: 'flex', width: '100%' }}
        >
          <Typography variant="subtitle4">
            Add your own custom sender email. This is the email you will use to
            send to your subscribers.
          </Typography>
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <div style={{ flex: 1, display: 'flex' }}> Placeholder</div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <InputWithTitle
                title="Enter custom email"
                control={control}
                name="customDomainEmail"
                placeholder="Custom email"
                className="AddContactDetailsForm-Input"
              />
              <div
                className="InputsSection"
                style={{ justifyContent: 'flex-end' }}
              >
                <Button onClick={() => {}} title="Cancel" variant="secondary" />
                <Button
                  disabled={!!errors.customDomainEmail}
                  onClick={handleSubmit(onContinue)}
                  title="Continue"
                  variant="primary"
                />
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'STEP 2 - Configure SMTP to send emails with your domain',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography variant="subtitle4">
            1. You will need to add the records below into your DNS settings in
            your hosting account
          </Typography>
          <Typography variant="subtitle4">
            2. Once you have added the records, click on &quot;Verify
            domain&quot; to get the latest status
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              flex: 1,
              padding: 12,
              paddingRight: 0,
            }}
          >
            <Button
              title="Verify domain"
              onClick={onClickVerifyDomain}
              variant="secondary"
              startIcon={<Rotate />}
            />
          </div>
          <div className="SmptDetailsContainer">
            {currentDomain?.records.map((record, index) => (
              <RecordComponent
                key={record.key}
                record={record}
                validationResult={
                  verifyResultsState && !verifyResultsState[index].valid
                    ? verifyResultsState[index]
                    : undefined
                }
                sendError={sendError}
              />
            ))}
          </div>
          <Button
            variant="primary"
            title="Back to My Domains"
            className="BackToDominsBtn"
            onClick={onBackToMyDomains}
          />
        </div>
      ),
    },
  ];
  return (
    <FormProvider {...methods}>
      <div className="CreateDomainForm">
        <div className="flex flex-col gap-2.5">
          {CREATE_DOMAIN_TUTORIAL_STEPS.map((step, index) => (
            <div style={{ marginBottom: 12 }} key={step.title}>
              <Accordion
                title={`${step.title}`}
                content={step.content}
                isOpen={index === activeStep}
              />
            </div>
          ))}
        </div>
      </div>
    </FormProvider>
  );
};

export default CreateDomainForm;
