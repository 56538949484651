import { ENDPOINTS } from '@constants/api';

import { wrappedAxiosRequest } from '../../api';

export interface ShopifyIntegrationData {
  shopifyAccessToken: string;
  customAppApiKey?: string;
  storeName: string;
}

const templateApi = {
  async createShopifyConnection({
    shopifyAccessToken,
    storeName,
  }: ShopifyIntegrationData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.shopify.connect,
      data: {
        accessToken: shopifyAccessToken,
        shop: storeName,
        apiKey: 'string',
        apiSecret: 'string',
      },
    });
  },

  async deleteShopifyConnection() {
    return wrappedAxiosRequest({
      method: 'delete',
      url: ENDPOINTS.shopify.disconnect,
    });
  },

  async verifyConnection() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.shopify.product,
    });
  },

  async getShopifyStore() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.shopify.storeName,
    });
  },
};

export default templateApi;
