import { useEffect } from 'react';
import { toColor, useColor } from 'react-color-palette';
import grapesjs from 'grapesjs';

import { ColorPickerInput } from '@molecules';
import { EditorBlockSettingsComponent } from '@devTypes';

const DEFAULT_BACKGROUND_COLOR = '#ffffff';

const getCurrentBackgroundColor = (component: grapesjs.Component) => {
  const { backgroundColor } = component.getAttributes();

  return backgroundColor || DEFAULT_BACKGROUND_COLOR;
};

interface Props extends EditorBlockSettingsComponent {
  titleVariant?: 'bold' | 'regular';
}

const EditorBlockBackgroundSettings = ({
  selectedComponent,
  titleVariant = 'bold',
}: Props) => {
  const [color, setColor] = useColor(
    'hex',
    getCurrentBackgroundColor(selectedComponent)
  );

  useEffect(() => {
    setColor(toColor('hex', getCurrentBackgroundColor(selectedComponent)));
  }, [selectedComponent]);

  const handleColorChange = (colorValue: string) => {
    selectedComponent.addStyle({ 'background-color': colorValue });
    selectedComponent.addAttributes({
      backgroundColor: colorValue,
    });
  };

  if (!selectedComponent) return null;

  return (
    <ColorPickerInput
      color={color}
      setColor={setColor}
      onChange={handleColorChange}
      title="Background color"
      titleVariant={titleVariant}
    />
  );
};

export default EditorBlockBackgroundSettings;
