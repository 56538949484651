import { CreateNewPassword } from '@organisms';
import { CreateNewPasswordTemplate } from '@templates';

interface ConfirmNewPasswordViewProps {
  onSubmit: () => void;
}

const CreateNewPasswordView = ({ onSubmit }: ConfirmNewPasswordViewProps) => (
  <CreateNewPasswordTemplate onSubmit={onSubmit} type="resetPassword">
    <CreateNewPassword />
  </CreateNewPasswordTemplate>
);

export default CreateNewPasswordView;
