import './styles.css';

interface Props {
  title: string;
  price: string;
  image: string;
}

const EcommerceLayout2FallbackItem = ({ title, price, image }: Props) => (
  <div className="EcommerceLayout2FallbackItem flexbox-column flexbox-ai-center flexbox-jc-center flexbox-gap-32px">
    <div className="EcommerceLayout2FallbackItemInfo flexbox-column flexbox-ai-center flexbox-gap-10px">
      <div className="EcommerceLayout2FallbackItemInfoTitle">{title}</div>
      <div className="EcommerceLayout2FallbackItemInfoPrice">${price}</div>
    </div>
    <img
      className="EcommerceLayout2FallbackItemImage"
      src={image}
      alt={title}
    />
    <div className="EcommerceButton flex justify-center items-center border rounded py-2.5 px-10 font-Roboto">
      Shop now
    </div>
  </div>
);

export default EcommerceLayout2FallbackItem;
