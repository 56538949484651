import { ChangeEventHandler } from 'react';

import { Checkbox, Typography } from '@atoms';
import { CheckboxComponent } from '@devTypes';

import './styles.css';

export interface Props extends CheckboxComponent {
  title: string;
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const CheckboxWithTitle = ({ title, isChecked, onChange }: Props) => (
  <div className="CheckboxWithTitle">
    <Checkbox isChecked={isChecked} onChange={onChange} />
    <Typography variant="subtitle4">{title}</Typography>
  </div>
);

export default CheckboxWithTitle;
