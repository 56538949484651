import { useEffect, useState } from 'react';

import { Button, Loader, Typography } from '@atoms';
import { Modal, SearchInput } from '@molecules';
import { MAX_SHOPIFY_PRODUCTS_AMOUNT } from '@constants/common';
import { ModalComponent, ProductSelect, ShopifyProduct } from '@devTypes';
import ProductsSelect from '@organisms/ProductsSelect/ProductsSelect';
import { shopifySelectors } from '@store/features/shopify';
import { useAppSelector } from '@store/hooks';
import {
  filterDataBySearchValue,
  transformShopifyProductsToSelectOptions,
} from '@utils/helpers';

import './styles.css';

interface Props extends ModalComponent {
  onProductsAdd: (products: ShopifyProduct[]) => void;
  initialState: ProductSelect[];
}

const EcommerceSelectProductsModal = ({
  isOpen,
  onClose,
  onProductsAdd,
  initialState,
}: Props) => {
  const products = useAppSelector(shopifySelectors.shopifyProducts);
  const isShopifyProductsLoading = useAppSelector(
    shopifySelectors.shopifyProductsLoading
  );
  const [selectedProducts, setSelectedProducts] = useState<ProductSelect[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSelectedProducts(initialState);
  }, [initialState]);

  const handleAddProducts = () => {
    const selectedIds = selectedProducts.map(({ id }) => id);
    const productsToAdd = products.filter((product) =>
      selectedIds.includes(product.id)
    );

    onProductsAdd(productsToAdd);
  };

  const handleSelectedProductsChange = (newProducts: ProductSelect[]) => {
    setSelectedProducts(newProducts);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="EcommerceSelectProductsModal flexbox-column flexbox-gap-32px">
        <Typography variant="subtitle1" weight="medium">
          Select products
        </Typography>
        <div className="flexbox-column flexbox-gap-24px">
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {isShopifyProductsLoading ? (
            <Loader />
          ) : (
            <ProductsSelect
              options={filterDataBySearchValue(
                transformShopifyProductsToSelectOptions(products),
                searchValue
              )}
              value={selectedProducts}
              onChange={handleSelectedProductsChange}
              max={MAX_SHOPIFY_PRODUCTS_AMOUNT}
            />
          )}
        </div>
        <div className="flexbox-row flexbox-jc-flex-end flexbox-gap-16px">
          <Button
            title="Cancel"
            variant="secondary"
            size="md"
            onClick={onClose}
          />
          <Button
            title="Add"
            variant="primary"
            size="md"
            disabled={!selectedProducts.length}
            onClick={handleAddProducts}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EcommerceSelectProductsModal;
