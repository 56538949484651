/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import grapesjs from 'grapesjs';

import { Typography } from '@atoms';
import { InputWithTitle } from '@molecules';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getComponentText } from '@utils/editor/components/text/controllers/general';
import { schemas } from '@utils/validation';

interface Props {
  buttonComponent: grapesjs.Component;
}

const EditorButtonBlockSettingsContent = ({ buttonComponent }: Props) => {
  const { control, setValue } = useForm({
    defaultValues: {
      text: getComponentText(buttonComponent) || 'Button',
      link: buttonComponent.getAttributes().link,
    },
    mode: 'onBlur',
    resolver: yupResolver(schemas.urlInputSchema),
  });

  const buttonLink = useWatch({ control, name: 'link' }) as string;
  const buttonText = useWatch({ control, name: 'text' }) as string;

  useEffect(() => {
    if (!buttonLink) {
      return;
    }

    if (/^https?:\/\/.+/.test(buttonLink)) {
      const formattedLink = buttonLink.replace(/^https?:\/\//, '');
      buttonComponent.addAttributes({
        link: formattedLink,
        href: buttonLink,
      });
      setValue('link', formattedLink);
      return;
    }

    buttonComponent.addAttributes({
      link: buttonLink,
      href: `https://${buttonLink}`,
    });
  }, [buttonLink]);

  useEffect(() => {
    const buttonEl = buttonComponent.getEl();

    if (!buttonEl) {
      return;
    }

    buttonEl.innerHTML = buttonText;
    buttonComponent.addAttributes({
      buttonText,
    });
    if (buttonComponent.attributes.components) {
      buttonComponent.attributes.components.models[0].attributes.content =
        buttonText;
    }
  }, [buttonText]);

  useEffect(() => {
    setValue('link', buttonComponent.getAttributes().link || '');
    setValue('text', getComponentText(buttonComponent) || 'Button');
  }, [buttonComponent]);

  return (
    <div>
      <InputWithTitle
        name="text"
        title="Button text"
        placeholder="Button"
        control={control}
      />
      <InputWithTitle
        name="link"
        title="Link to URL"
        placeholder="www.mysite.com"
        className="[&_.Input-with-start-icon]:pl-[85px] [&>div>div>p]:!left-0"
        control={control}
        startIcon={
          <Typography
            className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
            variant="subtitle4"
          >
            https://
          </Typography>
        }
      />
    </div>
  );
};

export default EditorButtonBlockSettingsContent;
