import { useFormContext } from 'react-hook-form';

import { Button } from '@atoms';
import { InputWithTitle } from '@molecules';

import './styles.css';

interface ForgotPasswordProps {
  onSubmit: () => void;
}

const ForgotPassword = ({ onSubmit }: ForgotPasswordProps) => {
  const { control, handleSubmit } = useFormContext();

  return (
    <div className="ForgotPassword">
      <InputWithTitle
        name="email"
        title="Email"
        type="email"
        control={control}
      />
      <Button
        variant="primary"
        size="lg"
        title="Reset password"
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default ForgotPassword;
