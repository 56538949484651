import { Typography } from '@atoms';
import { CarouselPreview, UploadImageContainer } from '@molecules';
import { GalleryItemProps } from '@devTypes';
import { useEditorSelected } from '@hooks';

interface Props {
  onImageUploadModalOpen: () => void;
  onChange: (images: GalleryItemProps[]) => void;
  maxImages: number;
}

const EditorCarouselBlockSettingsContent = ({
  onImageUploadModalOpen,
  maxImages,
  onChange,
}: Props) => {
  const selectedComponent = useEditorSelected();

  const selectedImages = selectedComponent?.getAttributes().images;

  const handleImagesChange = (images: GalleryItemProps[]) => {
    onChange(images);
    selectedComponent?.addAttributes({ images });
  };

  return (
    <div className="flexbox-column flexbox-gap-24px">
      <div className="flexbox-column flexbox-gap-10px">
        <div className="flexbox-row flexbox-jc-space-between">
          <Typography variant="subtitle4">Upload images</Typography>
          <Typography variant="subtitle4">
            {selectedImages?.length} of {maxImages}
          </Typography>
        </div>
        <UploadImageContainer onClick={onImageUploadModalOpen} />
      </div>
      <CarouselPreview images={selectedImages} onChange={handleImagesChange} />
    </div>
  );
};

export default EditorCarouselBlockSettingsContent;
