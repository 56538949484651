import TagManager from 'react-gtm-module';
import { format, parseISO } from 'date-fns';
import grapesjs from 'grapesjs';

import {
  EDITOR_CONTAINERS,
  EDITOR_WIDTH,
  EditorComponentTypes,
} from '@constants/editor';
import { ProductSelect, ShopifyProduct, User } from '@devTypes';

export const withCssClassPrefix = (className: string) => `.${className}`;

export const withCssIdPrefix = (id: string) => `#${id}`;

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
};

export const deleteAllCookies = () => {
  // retrieve all cookies
  const Cookies = document.cookie.split(';');
  // set past expiry to all cookies
  for (let i = 0; i < Cookies.length; i += 1) {
    document.cookie = `${Cookies[i]}=; expires=${new Date(0).toUTCString()}`;
  }
};

export const createEditorBlock = (
  title: string,
  icon: string,
  blockId: string
) => `
  <div id=${blockId} class="EditorBlock">
      ${icon}
      <p>${title}</p>
  </div>
`;

export const getChildComponentAt = (
  component: grapesjs.Component,
  position: number
) => component.components().models[position];

export const generateRandomNumberBetween = (min: number, max: number) =>
  Math.random() * (max - min) + min;

export const convertBytesToMegabytes = (bytes: number) =>
  (bytes / (1024 * 1024)).toFixed(3);

export const hasParentOfType = (
  component: grapesjs.Component,
  type: string
) => {
  let currentComponent = component;

  while (currentComponent.attributes.type !== type) {
    if (currentComponent.parent() !== null) {
      // @ts-ignore
      currentComponent = currentComponent.parent();
    } else {
      return false;
    }
  }

  return true;
};

export const findComponentToBeSelected = (
  component: grapesjs.Component,
  id: string
): grapesjs.Component | undefined => {
  if (component.getAttributes().customId === id) {
    return component;
  }

  for (let i = 0; i < component.components().length; i += 1) {
    const item = findComponentToBeSelected(
      component.components().models[i],
      id
    );

    if (item) {
      return item;
    }
  }

  return undefined;
};

type CalculateEditorImageProps = {
  url: string;
  isColumn: boolean;
  callback: ({ height, width }: { height: number; width: number }) => void;
};

export const calculateEditorImage = ({
  url,
  isColumn,
  callback,
}: CalculateEditorImageProps) => {
  const img = document.createElement('img');

  img.onload = () => {
    const columnWidth = EDITOR_WIDTH / 2;
    const containerWidth =
      isColumn && img.height > columnWidth ? columnWidth : EDITOR_WIDTH;

    let imgHeight = img.height;
    let imgWidth = img.width;

    while (imgWidth > containerWidth) {
      const imageWidthToContainerWidthQuotient = imgWidth / containerWidth;

      imgHeight /= imageWidthToContainerWidthQuotient;
      imgWidth /= imageWidthToContainerWidthQuotient;
    }

    if (callback) {
      callback({ height: imgHeight, width: imgWidth });
    }
  };

  img.src = url;
};

export const swapArrayElements = (
  arr: any[],
  firstIndex: number,
  secondIndex: number
) => {
  const newArr = Array.from(arr);
  const temp = arr[firstIndex];

  newArr[firstIndex] = newArr[secondIndex];
  newArr[secondIndex] = temp;

  return newArr;
};

export const generateId = (length: number = 6) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  let counter = 0;

  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
    counter += 1;
  }

  return result;
};

export const transformShopifyProductsToSelectOptions = (
  products: ShopifyProduct[]
): ProductSelect[] =>
  products.map((product) => ({
    id: product.id,
    title: product.title,
    url: product?.image?.src,
  }));

export const filterDataBySearchValue = <T extends { title: string }>(
  data: T[],
  searchValue: string
) =>
  searchValue
    ? data.filter(
        (x) =>
          x.title.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) >= 0
      )
    : data;

export const buildCampaingName = (date: string) =>
  `Email Campaign ${format(parseISO(date), 'yyyy-dd-MM')} (${format(
    parseISO(date),
    'HH:mm:ss'
  )})`;

export const secondCellColumnStyle = (cell: string | undefined) => {
  switch (cell) {
    case 'Draft':
      return 'Gray';
    case 'Published':
      return 'Green';
    case 'Scheduled':
      return 'Yellow';
    default:
      return '';
  }
};

export const convertRGBStringToObject = (rgb: string) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [r, g, b, a] = rgb.match(/\d+/g)?.map(Number)!;

  return {
    r,
    g,
    b,
    a,
  };
};

export const getEditorIframe: () => HTMLIFrameElement | null = () => {
  const iframe = document.querySelector(
    EDITOR_CONTAINERS.iframe
  ) as HTMLIFrameElement;

  return iframe;
};

export const getEditorIframeWindow: () => Window | null = () => {
  const iframe = document.querySelector(
    EDITOR_CONTAINERS.iframe
  ) as HTMLIFrameElement;

  const window = iframe?.contentWindow;

  return window;
};

export const getEditorIframeDocument: () => Document | null = () => {
  const iframe = document.querySelector(
    EDITOR_CONTAINERS.iframe
  ) as HTMLIFrameElement;

  const iframeDocument = iframe?.contentDocument;

  return iframeDocument;
};

export const getEditorHeight = (editor: grapesjs.Editor) => {
  const componentsLength = editor.getWrapper()?.components()?.length;

  const lastComponentEl = editor
    .getWrapper()
    ?.components()
    .models[componentsLength - 1].getEl();

  return lastComponentEl?.getBoundingClientRect().bottom;
};

export const setEditorHeight = (height: number | string) => {
  const gjs = document.getElementById('gjs');

  if (!gjs) {
    return;
  }
  if (parseFloat(gjs.style.height) !== parseFloat(height.toString())) {
    gjs.style.height = `${height}px`;
  }
};

export const resizeHeightAfterRemove = (
  editor: grapesjs.Editor,
  isBackspace?: boolean
) => {
  const gjs = document.getElementById('gjs');

  if (!gjs) {
    return;
  }

  const componentsLength = editor.getWrapper()?.components().length;
  const lastComponentEl = editor
    .getWrapper()
    ?.components()
    .models[componentsLength - 1].getEl();
  let boundingHeight = lastComponentEl?.getBoundingClientRect().bottom;

  if (typeof isBackspace === 'boolean') {
    if (isBackspace) {
      boundingHeight += 33;
    } else {
      boundingHeight -= 33;
    }
  }
  if (gjs.style.height !== boundingHeight?.toString()) {
    gjs.style.height = `${boundingHeight}px`;
  }
};

export const resizeCanvas = (editor: grapesjs.Editor) => {
  const gjs = document.getElementById('gjs');

  if (!gjs) {
    return;
  }

  const clientHeight = editor?.getWrapper()?.getEl()?.clientHeight;
  const componentsLength = editor.getWrapper()?.components().length;
  const lastComponentEl = editor
    .getWrapper()
    ?.components()
    .models[componentsLength - 1].getEl();
  const boundingHeight = lastComponentEl?.getBoundingClientRect().bottom;

  if (gjs.style.height !== (clientHeight || boundingHeight)?.toString()) {
    gjs.style.height = `${clientHeight || boundingHeight}px`;
  }
  editor.refresh();
};

export const handleEmailTypeChange =
  (editor: grapesjs.Editor) => (type: { id: 'amp' | 'fallback' | 'html' }) => {
    if (!editor) {
      return;
    }

    editor
      .getWrapper()
      .components()
      .filter(
        (item) =>
          item.get('type') === EditorComponentTypes.Ecommerce ||
          item.get('type') === EditorComponentTypes.Carousel
      )
      .forEach((c) => c.addAttributes({ emailType: type.id }));
  };

export const handleComponentDropBelowFooter = (
  editor: grapesjs.Editor,
  component: grapesjs.Component
) => {
  const footer = editor
    .getWrapper()
    .components()
    .find((item) => item.get('type') === EditorComponentTypes.Footer)!;
  const componentIndex = component.index();
  const footerIndex = footer.index();

  if (componentIndex > footerIndex) {
    component.move(component.parent()!, {
      at: footerIndex,
    });
  }
};

export const removeAddBlockPlaceholder = (editor: grapesjs.Editor) => {
  const addBlockPlaceholder = editor
    .getWrapper()
    .components()
    .find((item) => item.get('type') === EditorComponentTypes.AddBlock);

  addBlockPlaceholder?.remove();
};

export const reduceCarouselBlocksAmount = (
  editor: grapesjs.Editor,
  component: grapesjs.Component
) => {
  const carouselComponents = editor
    .getWrapper()
    .components()
    .filter((item) => item.get('type') === EditorComponentTypes.Carousel);

  if (
    component.get('type') === EditorComponentTypes.Carousel &&
    carouselComponents.length > 1
  ) {
    component.remove();
  }
};

export const removeEditorDocumentSelection = (editor: grapesjs.Editor) => {
  const selection = editor?.Canvas?.getDocument()?.getSelection();

  if (selection) {
    selection.empty();
  }
};

export const transformStylesObjectToString = (styles: object) => {
  let formattedStyles = '';

  const stylesValue = Object.entries(styles);

  stylesValue.forEach(([key, value]) => {
    formattedStyles += `${key}: ${value}; `;
  });

  return formattedStyles;
};

export const applyForEachComponent = (
  currentComponent: grapesjs.Component,
  callback: (component: grapesjs.Component) => void
) => {
  const components = currentComponent.components().models;

  if (components.length === 0) {
    return;
  }

  components.forEach((component) => {
    callback(component);
    applyForEachComponent(component, callback);
  });
};

export const getUserName = (user: User) => {
  const { firstName, lastName } = user;

  return `${firstName} ${lastName}`;
};

export const getUserInitials = (user: User) => {
  const { firstName, lastName } = user;

  return `${firstName[0]}${lastName[0]}`;
};

export const removeSpacesInString = (str: string) => str.replace(/\s+/g, '');

export const findBlockOnCanvas = (
  editor: grapesjs.Editor,
  type: EditorComponentTypes
) =>
  editor
    ?.getWrapper()
    .components()
    .find((item) => item.get('type') === type);

export const trackToGTM = (params: any) => {
  const tagManagerArgs = {
    dataLayer: {
      ...params,
    },
  };

  // Send the form data to GTM
  TagManager.dataLayer(tagManagerArgs);
};

const zappierUrl = 'https://hooks.zapier.com/hooks/catch/16125827/39i27en/';

export const trackToZappier = async (params: any) => {
  // Form the request for sending data to the server.
  const options = {
    method: 'POST',
    body: JSON.stringify(params),
  };

  // Send the form data to zappier
  await fetch(zappierUrl, options);
};
