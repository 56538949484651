import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, Loader, Typography } from '@atoms';
import { InputWithTitle, Link } from '@molecules';
import { ROUTES } from '@constants/routes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { authActions, authSelectors } from '@store/features/auth';
import { changePasswordThunk } from '@store/features/auth/asyncThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { changePasswordSchema } from '@utils/validation';

const defaultValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

type FormValues = typeof defaultValues;

const ChangePasswordForm = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(authSelectors.isLoading);

  const { control, reset, formState, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(changePasswordSchema),
    defaultValues,
  });

  const handleCancelClick = () => reset();

  const handleValid: SubmitHandler<FormValues> = (values) => {
    const { currentPassword, newPassword } = values;
    dispatch(
      changePasswordThunk({
        oldPassword: currentPassword,
        newPassword,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Password successfully updated');
        reset();
      })
      .catch((error) => {
        toast.error(error.message.message);
      });
  };

  const handleForgotPasswordClick = async () => {
    await dispatch(authActions.logOut());
  };

  const isDisabled = !formState.isValid;

  if (isLoading) return <Loader />;

  return (
    <>
      <Typography variant="subtitle2" className="CardTitle">
        Change password
      </Typography>
      <InputWithTitle
        name="currentPassword"
        control={control}
        title="Current password"
        type="password"
      />
      <div className="ConfirmPasswordInputs">
        <InputWithTitle
          name="newPassword"
          control={control}
          title="New password"
          type="password"
        />
        <InputWithTitle
          name="confirmPassword"
          control={control}
          title="Confirm password"
          type="password"
        />
      </div>
      <div className="ButtonGroup">
        <Link
          to={ROUTES.forgotPassword}
          text="Forgot password"
          onClick={handleForgotPasswordClick}
          className="ForgotPasswordLink"
        />
        <Button
          variant="secondary"
          title="Cancel"
          onClick={handleCancelClick}
          disabled={!formState.isDirty}
        />
        <Button
          variant="primary"
          title="Save"
          onClick={handleSubmit(handleValid)}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default ChangePasswordForm;
