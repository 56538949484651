import { Button, Typography } from '@atoms';
import { Modal } from '@molecules';
import { ModalComponent } from '@devTypes';

interface Props extends ModalComponent {
  onSubmit: () => void;
}

const NoShopifyConnectedModal = ({ isOpen, onClose, onSubmit }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} className="NoShopifyConnectedModal">
    <Typography variant="subtitle1" className="text-error-main">
      There is no Shopify store connected
    </Typography>
    <Typography variant="subtitle3" className="text-gray-main">
      To use this template, you will need to connect your Shopify store.
    </Typography>
    <div className="NoShopifyConnectedModal-ButtonGroup">
      <Button title="Cancel" variant="secondary" onClick={onClose} />
      <Button title="Connect" variant="primary" onClick={onSubmit} />
    </div>
  </Modal>
);

export default NoShopifyConnectedModal;
