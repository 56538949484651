import { Button, Typography } from '@atoms';
import { Modal } from '@molecules';
import { ModalComponent } from '@devTypes';

interface Props extends ModalComponent {
  title: string;
  subtitle: string | React.ReactNode;
  onConfirm: () => void;
  withoutCloseButton?: boolean;
  confirmButtonText?: string;
}

const ConfirmModal = ({
  isOpen,
  subtitle,
  title,
  className,
  onClose,
  onConfirm,
  confirmButtonText = 'Confirm delete',
  withoutCloseButton,
}: Props) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col gap-8">
        <Typography
          variant="subtitle1"
          weight="medium"
          style={{ color: 'red' }}
        >
          {title}
        </Typography>
        {typeof subtitle === 'string' ? (
          <Typography variant="subtitle4" className="text-gray-main">
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
        <div className="flex justify-end gap-4">
          {!withoutCloseButton && (
            <Button variant="secondary" title="Cancel" onClick={onClose} />
          )}
          <Button
            variant="primary"
            title={confirmButtonText}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
