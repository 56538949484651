import type { RootState } from '../../store';

const isLoading = (state: RootState) => state.auth?.isLoading;

const isSignInLoading = (state: RootState) => state.auth.signIn.isLoading;

const isSignUpLoading = (state: RootState) => state.auth.signUp.isLoading;

const isForgotPasswordLoading = (state: RootState) =>
  state.auth.forgotPassword.isLoading;

const isCreateNewPasswordLoading = (state: RootState) =>
  state.auth.createNewPassword.isLoading;

export default {
  isLoading,
  isSignInLoading,
  isSignUpLoading,
  isForgotPasswordLoading,
  isCreateNewPasswordLoading,
};
