import {
  DEFAULT_BACKGROUND_VALUE,
  EditorComponentTypes,
} from '@constants/editor';

export const FOOTER_CONTAINER_CLASS = 'footer-container';

const FBBlack = 'https://appmail-images.s3.amazonaws.com/FB-black.png';
const IGBlack = 'https://appmail-images.s3.amazonaws.com/IG-black.png';
const TIKTOKBlack = 'https://appmail-images.s3.amazonaws.com/TikTok-black.png';
const TWITTERBlack =
  'https://appmail-images.s3.amazonaws.com/Twitter-black.png';
const YOUTUBEBlack =
  'https://appmail-images.s3.amazonaws.com/YouTube-black.png';

export const generateGrapesjsFooterComponent = () => ({
  tagName: 'div',
  attributes: {
    class: FOOTER_CONTAINER_CLASS,
    emailText: 'Your email',
    phoneNumber: 'Your phone number',
    websiteText: 'Visit our website',
  },
  type: EditorComponentTypes.Footer,
  droppable: false,
  removable: false,
  components: [
    {
      tagName: 'div',
      selectable: false,
      hoverable: false,
      droppable: false,
      draggable: false,
      attributes: {
        class: 'footer-top-section-container',
      },
      components: [
        {
          tagName: 'table',
          attributes: {
            class: 'footer-top-section',
          },
          selectable: false,
          hoverable: false,
          droppable: false,
          draggable: false,
          components: [
            {
              tagName: 'tr',
              attributes: {
                class: 'footer-user-information',
              },
              selectable: false,
              hoverable: false,
              droppable: false,
              draggable: false,
              components: [
                {
                  tagName: 'td',
                  attributes: {
                    class: 'footer-email-information',
                  },
                  content: 'Your email',
                  selectable: false,
                  hoverable: false,
                  droppable: false,
                  draggable: false,
                },
                {
                  tagName: 'td',
                  attributes: {
                    class: 'visit-website',
                  },
                  components: [
                    [
                      {
                        tagName: 'a',
                        content: 'Visit our website',
                        selectable: false,
                        hoverable: false,
                        droppable: false,
                        draggable: false,
                      },
                    ],
                  ],
                  selectable: false,
                  hoverable: false,
                  droppable: false,
                  draggable: false,
                },
              ],
            },
            {
              tagName: 'tr',
              attributes: {
                class: 'footer-company-information',
              },
              selectable: false,
              hoverable: false,
              droppable: false,
              draggable: false,
              components: [
                {
                  tagName: 'td',
                  attributes: {
                    class: 'footer-phone-information',
                  },
                  content: 'Your Phone Number',
                  selectable: false,
                  hoverable: false,
                  droppable: false,
                  draggable: false,
                },
                {
                  tagName: 'td',
                  attributes: {
                    class: 'social-media-information',
                    id: 'socials',
                  },
                  selectable: false,
                  hoverable: false,
                  droppable: false,
                  draggable: false,
                  components: [
                    [
                      {
                        tagName: 'a',
                        selectable: false,
                        hoverable: false,
                        droppable: false,
                        draggable: false,
                        components: [
                          {
                            tagName: 'img',
                            attributes: {
                              src: FBBlack,
                              class: 'social-media-link',
                            },
                            selectable: false,
                            hoverable: false,
                            droppable: false,
                            draggable: false,
                          },
                        ],
                      },
                    ],
                    [
                      {
                        tagName: 'a',
                        selectable: false,
                        hoverable: false,
                        droppable: false,
                        draggable: false,
                        components: [
                          {
                            tagName: 'img',
                            attributes: {
                              src: IGBlack,
                              class: 'social-media-link',
                            },
                            selectable: false,
                            hoverable: false,
                            droppable: false,
                            draggable: false,
                          },
                        ],
                      },
                    ],
                    [
                      {
                        tagName: 'a',
                        selectable: false,
                        hoverable: false,
                        droppable: false,
                        draggable: false,
                        components: [
                          {
                            tagName: 'img',
                            attributes: {
                              src: TWITTERBlack,
                              class: 'social-media-link',
                            },
                            selectable: false,
                            hoverable: false,
                            droppable: false,
                            draggable: false,
                          },
                        ],
                      },
                    ],
                    [
                      {
                        tagName: 'a',
                        selectable: false,
                        hoverable: false,
                        droppable: false,
                        draggable: false,
                        components: [
                          {
                            tagName: 'img',
                            attributes: {
                              src: YOUTUBEBlack,
                              class: 'social-media-link',
                            },
                            selectable: false,
                            hoverable: false,
                            droppable: false,
                            draggable: false,
                          },
                        ],
                      },
                    ],
                    [
                      {
                        tagName: 'a',
                        selectable: false,
                        hoverable: false,
                        droppable: false,
                        draggable: false,
                        components: [
                          {
                            tagName: 'img',
                            attributes: {
                              src: TIKTOKBlack,
                              class: 'social-media-link',
                            },
                            selectable: false,
                            hoverable: false,
                            droppable: false,
                            draggable: false,
                          },
                        ],
                      },
                    ],
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      tagName: 'div',
      attributes: {
        class: 'footer-unsubscribe-section',
      },
      content:
        // eslint-disable-next-line quotes
        "Click here to <a href='{unsubscribe-url-placeholder}'> unsubscribe </a>",
      selectable: false,
      hoverable: false,
      droppable: false,
      draggable: false,
    },
    {
      tagName: 'div',
      attributes: {
        class: 'footer-appmail-section',
      },
      content: 'This email was created with <u> AppMail </u>',
      selectable: false,
      hoverable: false,
      droppable: false,
      draggable: false,
    },
  ],
  styles: `
  .footer-container {
    padding-top: 30px;
    font-size: 12px;
    font-family: 'Helvetica', sans-seirf;
    font-weight: 500;
    min-width: 595px;
    width: 100%;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
  }
  .footer-top-section {
    width: 100%;
    vertical-align: middle;
    border-collapse: collapse;
    min-width: 595px;
  }
  .footer-user-information {
    width: 50%;
    padding: 0px 30px 0px 30px;
  }
  .footer-email-information {
    display: table-cell;
    width: 50%;
    text-align: center;
    line-height: 20px;
    border-right: 2px solid;
    border-right-color: initial;
  }
  .footer-phone-information {
    display: table-cell;
    width: 50%;
    text-align: center;
    line-height: 20px;
    border-right: 2px solid;
    border-right-color: initial;
  }
  .footer-company-information {
    padding: 0px 30px 0px 30px;
    line-height: 20px;
  }
  .visit-website {
    display: table-cell;
    width: 50%;
    text-align: center;
    line-height: 20px;
  }
  .social-media-information {
    display: table-cell;
    text-align: center;
    width: 50%;
  }
  .social-media-link {
    height: 25px;
    width: 25px;
    display: inline-block;
    margin: 0px 10px 0px 10px;
  }
  .footer-unsubscribe-section {
    background-color: #D9D9D9;
    color: black;
    width: 100%;
    height: 36px;
    margin-top: 30px;
    text-align: center;
    line-height: 22px;
    padding: 7px;
  }
  .footer-appmail-section {
    background-color: #000;
    color: #FFFFFF;
    width: 100%;
    height: 36px;
    text-align: center;
    line-height: 22px;
    padding: 7px;
  }
  @media(max-width: 525px){
    .footer-container{
      min-width: unset;
      font-size: 12px;
      font-family: 'Helvetica', sans-serif;
      font-weight: 500;
    }
    .footer-top-section{
      min-width: unset;
    }
    .footer-company-information {
      padding: 0px;
    }
    .footer-appmail-section, .footer-user-information, .footer-unsubscribe-section {
      width: 100%;
    }
    .footer-unsubscribe-section {
      margin-top: 16px;
    }
    .footer-email-information {
      display: block;
      width: 100%;
      text-align: center;
      line-height: 20px;
      border-right: 0px solid;
      border-right-color: transparent;
      padding: 8px;
    }
    .footer-phone-information {
      padding: 8px;
      display: block;
      width: 100%;
      text-align: center;
      line-height: 20px;
      border-right: 0px solid;
      border-right-color: transparent;
    }
    .visit-website {
      display: block;
      text-align: center;
      padding: 8px;
      width: 100%;
    }
    .social-media-information {
      padding: 8px;
      display: block;
      text-align: center;
      width: 100%;
    }
    tr {
      display: block;
      text-align: center;
    }
    td {
      display: block;
      text-align: center;
    }
  }
  `,
});
