import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DesktopPreview, MobilePreview, SendTestEmailModal } from '@organisms';
import { ReactComponent as DesktopIcon } from '@assets/icons/monitor.svg';
import { ReactComponent as MobileIcon } from '@assets/icons/smartphone.svg';
import { EDITOR_EMAIL_TYPES } from '@constants/editor';
import DevicePreview from '@templates/PreviewTemplate/DevicePreview';
import EmailVersionsToolbar, {
  EmailVersion,
} from '@templates/PreviewTemplate/EmailVersionsToolbar';
import Header from '@templates/PreviewTemplate/Header';

import { Tab } from './DeviceViewTab';

import './styles.css';

interface Props {
  templateId: number;
  htmlContent?: string;
  ampContent?: string;
  isSendTestEmailDisabled: boolean;
}

export const tabs = [
  {
    id: 1,
    icon: DesktopIcon,
    title: 'Desktop',
    renderDevicePreview: (content: string) => (
      <DesktopPreview content={content} />
    ),
  },
  {
    id: 2,
    icon: MobileIcon,
    title: 'Mobile',
    renderDevicePreview: (content: string) => (
      <MobilePreview content={content} />
    ),
  },
];

const PreviewTemplate = ({
  htmlContent,
  ampContent,
  templateId,
  isSendTestEmailDisabled,
}: Props) => {
  const navigate = useNavigate();
  const [selectedDeviceTab, setSelectedDeviceTab] = useState<Tab>(tabs[0]);
  const [selectedEmailVersion, setSelectedEmailVersion] = useState(
    EDITOR_EMAIL_TYPES[0]
  );
  const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] =
    useState(false);

  const handleDeviceViewChange = (tab: Tab) => setSelectedDeviceTab(tab);

  const navigateBackToEditor = () => navigate(-1);

  const handleSendTestEmailModalToggle = () =>
    setIsSendTestEmailModalOpen((prevState) => !prevState);

  const handleEmailVersionChange = (version: EmailVersion) => {
    setSelectedEmailVersion(version);
  };

  const isAMPVisible = selectedEmailVersion.id === 'amp';

  return (
    <>
      <Header
        tabs={tabs}
        selectedTab={selectedDeviceTab}
        onDeviceTabChange={handleDeviceViewChange}
        onGoBackClick={navigateBackToEditor}
        onSendTestEmail={handleSendTestEmailModalToggle}
        isSendTestEmailDisabled={isSendTestEmailDisabled}
      />
      <EmailVersionsToolbar
        emailVersion={selectedEmailVersion}
        onEmailVersionChange={handleEmailVersionChange}
      />
      <div className="PreviewTemplate-Content">
        {isAMPVisible ? (
          <DevicePreview
            title={`AMP Preview (${selectedDeviceTab.title})`}
            devicePreview={selectedDeviceTab.renderDevicePreview(ampContent!)}
          />
        ) : (
          <DevicePreview
            title={`Fallback Preview (${selectedDeviceTab.title})`}
            devicePreview={selectedDeviceTab.renderDevicePreview(htmlContent!)}
          />
        )}
      </div>
      <SendTestEmailModal
        isOpen={isSendTestEmailModalOpen}
        onClose={handleSendTestEmailModalToggle}
        templateId={templateId}
      />
    </>
  );
};

export default PreviewTemplate;
