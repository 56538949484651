import { useState } from 'react';

import { Divider, Typography } from '@atoms';
import { ColorSelect, Select } from '@molecules';
import { MAX_CAROUSEL_PADDING, MIN_CAROUSEL_PADDING } from '@constants/editor';
import { EditorBlockSettingsComponent } from '@devTypes';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';
import { carouselComponentController } from '@utils/editor/components/carousel';

const navigationIconColorOptions = [
  { title: 'White', color: '#ffffff' },
  { title: 'Black', color: '#000000' },
];

const imageFittingOptions = [
  { title: 'Contain', value: 'contain' },
  { title: 'Fill', value: 'cover' },
];

const EditorCarouselBlockSettingsDesign = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => {
  const [imageFit, setImageFit] = useState(
    imageFittingOptions.find(
      (option) => option.value === selectedComponent.getAttributes().imageFit
    )!
  );
  const [selectedNavigationIconColor, setSelectedNavigationIconColor] =
    useState(
      navigationIconColorOptions.find(
        (option) =>
          option.title === selectedComponent.getAttributes().navigationColor
      ) || navigationIconColorOptions[1]
    );

  const handleImageFitChange = (option: typeof imageFittingOptions[0]) => {
    setImageFit(option);
    carouselComponentController.setImageFit(selectedComponent, option.value);
  };

  const handleNavigationIconColorChange = (
    color: typeof navigationIconColorOptions[0]
  ) => {
    setSelectedNavigationIconColor(color);
    carouselComponentController.setNavigationColor(
      selectedComponent,
      color.color
    );
  };

  return (
    <div className="flexbox-column">
      <div className="flexbox-column flexbox-gap-10px">
        <Typography
          className="Typography-bold Typography-gray-main"
          variant="subtitle5"
        >
          Image fitting
        </Typography>
        <Select
          value={imageFit}
          options={imageFittingOptions}
          onChange={handleImageFitChange}
        />
      </div>
      <Divider />
      <div className="flexbox-column flexbox-gap-10px">
        <Typography
          className="Typography-bold Typography-gray-main"
          variant="subtitle5"
        >
          Navigation icon color
        </Typography>
        <ColorSelect
          value={selectedNavigationIconColor}
          options={navigationIconColorOptions}
          onChange={handleNavigationIconColorChange}
          withIcon={false}
        />
      </div>
      <Divider />
      <EditorBlockBackgroundSettings selectedComponent={selectedComponent} />
      <Divider />
      <EditorBlockPaddingSettings
        selectedComponent={selectedComponent}
        min={MIN_CAROUSEL_PADDING}
        max={MAX_CAROUSEL_PADDING}
      />
    </div>
  );
};

export default EditorCarouselBlockSettingsDesign;
