import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Typography } from '@atoms';
import { Modal } from '@molecules';
import { ModalComponent, Recipient } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AddContactForm from '@organisms/AddContactForm/AddContactForm';
import { EditRecipientData } from '@store/features/allcontacts/api';
import { addContactSchema } from '@utils/validation';

interface Props extends ModalComponent {
  selectedContact: Recipient;
  onSubmit: (recipient: EditRecipientData) => void;
}

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  streetAddress: string;
  streetLine2: string;
  city: string;
  zipCode: string;
  country: string;
};

const EditContactModal = ({
  isOpen,
  onClose,
  selectedContact,
  onSubmit,
}: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      firstName: selectedContact.firstName || '',
      lastName: selectedContact.lastName || '',
      email: selectedContact.email || '',
      phoneNumber: selectedContact.phoneNumber?.substring(1) || '',
      streetAddress: selectedContact.streetAddress || '',
      streetLine2: selectedContact.streetLine2 || '',
      city: selectedContact.city || '',
      country: selectedContact.country || '',
      zipCode: selectedContact.zipCode || '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(addContactSchema),
  });

  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = methods;

  const handleValid: SubmitHandler<FormValues> = (values) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      country,
      city,
      streetAddress,
      streetLine2,
      zipCode,
    } = values;

    const updatedFields = {
      ...(dirtyFields.firstName && { firstName }),
      ...(dirtyFields.lastName && { lastName }),
      ...(dirtyFields.email && { email }),
      ...(dirtyFields.phoneNumber && { phoneNumber: `+${phoneNumber}` }),
      ...(dirtyFields.country && { country }),
      ...(dirtyFields.city && { city }),
      ...(dirtyFields.streetAddress && { streetAddress }),
      ...(dirtyFields.streetLine2 && { streetLine2 }),
      ...(dirtyFields.zipCode && { zipCode }),
    };

    if (!isDirty) {
      onClose();
      return;
    }

    onSubmit({
      id: selectedContact.id,
      ...updatedFields,
    });
  };

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isOpen} onClose={onClose} type="fullScreen">
        <Typography variant="subtitle2">Edit contact</Typography>
        <AddContactForm
          onClose={onClose}
          handleSave={handleSubmit(handleValid)}
        />
      </Modal>
    </FormProvider>
  );
};

export default EditContactModal;
