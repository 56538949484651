import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import shopifyApi, { ShopifyIntegrationData } from './api';

export const createShopifyConnectionThunk =
  createCommonAsyncThunk<ShopifyIntegrationData>(
    'createShopifyConnection',
    shopifyApi.createShopifyConnection
  );

export const deleteShopifyConnectionThunk = createCommonAsyncThunk(
  'deleteShopifyConnection',
  shopifyApi.deleteShopifyConnection
);

export const verifyShopifyConnectionThunk = createCommonAsyncThunk(
  'verifyShopifyConnection',
  shopifyApi.verifyConnection
);

export const storeNameThunk = createCommonAsyncThunk(
  'storeName',
  shopifyApi.getShopifyStore
);

export default {
  createShopifyConnectionThunk,
  verifyShopifyConnectionThunk,
  deleteShopifyConnectionThunk,
  storeNameThunk,
};
