import clsx from 'clsx';

import { Button, Icon } from '@atoms';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';

type Item = {
  id: string;
  title: string;
};

interface Props {
  items: Item[];
  onDelete: (id: string) => void;
  className?: string;
}

const ChipButtonsList = ({ items, onDelete, className }: Props) => (
  <div className={clsx('w-full flex flex-row gap-[10px] flex-wrap', className)}>
    {items.map(({ id, title }) => (
      <Button
        key={id}
        title={title}
        variant="secondary"
        endIcon={
          <Icon
            SVG={CrossIcon}
            height={20}
            width={20}
            onClick={() => onDelete(id)}
          />
        }
      />
    ))}
  </div>
);

export default ChipButtonsList;
