import { useNavigate } from 'react-router-dom';

import { Button, HeaderContainer, Loader, Typography } from '@atoms';
import { TemplateObject } from '@devTypes';
import TemplatePreviewCards from '@organisms/TemplatePreviewCards/TemplatePreviewCards';

import NoShopifyConnectedModal from './NoShopifyConnectedModal';

import './styles.css';

interface SelectTemplateViewProps {
  templates: TemplateObject[] | null;
  onPreviewClick: (item: TemplateObject) => void;
  onUseTemplateClick: (item: TemplateObject) => void;
  isNoShopifyModalOpen: boolean;
  hasBackButton: boolean;
  onShopifyConnect: () => void;
  onNoShopifyModalClose: () => void;
  isLoading: boolean;
}

const SelectTemplateView = ({
  templates,
  onPreviewClick,
  onUseTemplateClick,
  hasBackButton,
  isNoShopifyModalOpen,
  onShopifyConnect,
  onNoShopifyModalClose,
  isLoading,
}: SelectTemplateViewProps) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <>
      <HeaderContainer>
        <Typography variant="subtitle1">Select a template</Typography>
        {hasBackButton && (
          <Button title="Back" variant="secondary" size="lg" onClick={goBack} />
        )}
      </HeaderContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <TemplatePreviewCards
          templates={templates}
          onClickTemplate={onPreviewClick}
          onUseTemplate={onUseTemplateClick}
        />
      )}
      <NoShopifyConnectedModal
        onSubmit={onShopifyConnect}
        isOpen={isNoShopifyModalOpen}
        onClose={onNoShopifyModalClose}
      />
    </>
  );
};

export default SelectTemplateView;
