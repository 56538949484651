import { ReactNode, useRef } from 'react';
import clsx from 'clsx';

import { useOutsideClick } from '@utils/hooks';

import './styles.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  anchorEl: ReactNode;
}

const Popup = ({ isOpen, onClose, children, className, anchorEl }: Props) => {
  const ref = useRef(null);
  useOutsideClick(ref, onClose);

  return (
    <div className="PopupWrapper" ref={ref}>
      {anchorEl}
      <div className={clsx('Popup', isOpen && 'Popup-open', className)}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
