import { useFormContext, useWatch } from 'react-hook-form';

import UploadImageFromUrl from '@organisms/UploadImageFromUrl/UploadImageFromUrl';

const EditorImageBlockUploadModalFromUrl = () => {
  const methods = useFormContext();

  const { control, getValues, setValue } = methods;

  const imageFile = useWatch({ control, name: 'image' });

  const handleImageByUrlChange = (file: File | null) => {
    if (!file) {
      return;
    }

    const { imageUrl } = getValues();
    const splitedImageUrl = imageUrl.split('/');
    const url = splitedImageUrl[splitedImageUrl.length - 1].split('?')[0];

    const image = new File([file], url, { type: file.type });

    setValue('image', image);
  };

  return (
    <UploadImageFromUrl image={imageFile} onChange={handleImageByUrlChange} />
  );
};

export default EditorImageBlockUploadModalFromUrl;
