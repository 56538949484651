import { useCallback, useEffect, useMemo, useState } from 'react';
import update from 'immutability-helper';

import { Button, Divider, Icon, Typography } from '@atoms';
import { BlockEmptyState, DefaultInputWithTitle } from '@molecules';
import { ReactComponent as ClosedEyeIcon } from '@assets/icons/closed-eye.svg';
import { ReactComponent as OpenedEyeIcon } from '@assets/icons/opened-eye.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { MAX_SHOPIFY_PRODUCTS_AMOUNT } from '@constants/common';
import {
  ECOMMERCE_DEFAULT_LAYOUT,
  EditorComponentTypes,
} from '@constants/editor';
import { ShopifyProduct } from '@devTypes';
import { useEditorSelected } from '@hooks';
import DraggableList from '@organisms/DraggableList/DraggableList';
import EcommerceSelectProductsModal from '@organisms/EcommerceSelectProductsModal/EcommerceSelectProductsModal';
import { canvasSelectors } from '@store/features/canvas';
import { useAppSelector } from '@store/hooks';
import { transformShopifyProductsToSelectOptions } from '@utils/helpers';

import EditorEcommerceBlockSettingsLayoutSelect from './EditorEcommerceBlockSettingsLayoutSelect';

interface Props {
  onDesignTabDisabledChange: (x: boolean) => void;
}

const EditorEcommerceBlockSettingsContent = ({
  onDesignTabDisabledChange,
}: Props) => {
  const [isSelectProductsModalOpen, setIsSelectProductsModalOpen] =
    useState(false);
  const [uploadedProducts, setUploadedProducts] = useState<ShopifyProduct[]>(
    []
  );

  const selectedComponent = useEditorSelected();

  const attributes = selectedComponent!.getAttributes();

  const [isProductDescription, setIsProductDescription] = useState<boolean>(
    attributes.isProductDescription
  );
  const [checkoutButtonText, setCheckoutButtonText] = useState<string>(
    attributes.checkoutButtonText
  );
  const editorEmailType = useAppSelector(canvasSelectors.editorEmailType);

  if (!selectedComponent) {
    return null;
  }

  useEffect(() => {
    const ecommerceAttributes = selectedComponent.getAttributes();

    if (!ecommerceAttributes.products) {
      return;
    }
    setIsProductDescription(ecommerceAttributes.isProductDescription);
    setUploadedProducts(ecommerceAttributes.products);
  }, [selectedComponent]);

  useEffect(() => {
    onDesignTabDisabledChange(!uploadedProducts.length);
  }, [uploadedProducts.length]);

  useEffect(() => {
    if (uploadedProducts.length) {
      selectedComponent.addAttributes({
        products: uploadedProducts,
      });
    }
  }, [uploadedProducts]);

  const handleProductsAdd = (products: ShopifyProduct[]) => {
    const { layout } = selectedComponent.getAttributes();

    selectedComponent.addAttributes({
      products,
      selectedProduct: products[0],
      currentHeroImage: products[0].image,
      onChange: (product: any) => {
        if (selectedComponent.get('type') !== EditorComponentTypes.Ecommerce) {
          return;
        }

        selectedComponent?.addAttributes({
          selectedProduct: product,
          currentHeroImage: product.image,
        });
      },
      emailType: editorEmailType.id,
      layout: layout ?? ECOMMERCE_DEFAULT_LAYOUT,
      isCartOpen: false,
      onCartChange: (x: boolean) => {
        selectedComponent?.addAttributes({ isCartOpen: x });
      },
    });

    setUploadedProducts(products);
    setIsSelectProductsModalOpen(false);
  };

  const handleProductDelete = (id: number | string) => {
    const newProducts = uploadedProducts.filter((product) => product.id !== id);

    if (!newProducts.length) {
      // eslint-disable-next-line no-param-reassign
      selectedComponent.attributes.component = BlockEmptyState;
    }

    const selectedProduct = newProducts[0];

    selectedComponent.addAttributes({
      products: newProducts,
      selectedProduct,
      currentHeroImage: selectedProduct?.image,
    });

    setUploadedProducts(newProducts);
  };

  const handleDraggableListChange = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setUploadedProducts((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    },
    []
  );

  const handleIsProductDescriptionChange = () => {
    selectedComponent.addAttributes({
      isProductDescription: !isProductDescription,
    });
    setIsProductDescription((prevState) => !prevState);
  };

  const handleCheckoutButtonTextChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    selectedComponent.addAttributes({ checkoutButtonText: e.target.value });
    setCheckoutButtonText(e.target.value);
  };

  const handleSelectProductsModalClose = () => {
    setIsSelectProductsModalOpen(false);
  };

  const handleSelectProductsModalOpen = () => {
    setIsSelectProductsModalOpen(true);
  };

  const productsInitialState = useMemo(
    () => transformShopifyProductsToSelectOptions(uploadedProducts),
    [uploadedProducts]
  );

  return (
    <div>
      <EcommerceSelectProductsModal
        isOpen={isSelectProductsModalOpen}
        onClose={handleSelectProductsModalClose}
        onProductsAdd={handleProductsAdd}
        initialState={productsInitialState}
      />
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <Typography variant="subtitle5" weight="bold">
            Product
          </Typography>
          <Typography variant="subtitle4">
            {uploadedProducts.length} of {MAX_SHOPIFY_PRODUCTS_AMOUNT}
          </Typography>
        </div>
        <DraggableList
          items={uploadedProducts}
          onChange={handleDraggableListChange}
          onDelete={handleProductDelete}
        />
        <Button
          title="Add product"
          variant="primary"
          size="md"
          className="[&_svg]:stroke-white"
          startIcon={<PlusIcon />}
          onClick={handleSelectProductsModalOpen}
        />
      </div>
      {!!uploadedProducts.length && (
        <div>
          <Divider />
          <div className="flex flex-col gap-5">
            <Typography variant="subtitle5" weight="bold">
              Content
            </Typography>
            <div className="flex justify-between">
              <Typography variant="subtitle4">Product description</Typography>
              <Icon
                className="stroke-gray-500 cursor-pointer"
                SVG={isProductDescription ? OpenedEyeIcon : ClosedEyeIcon}
                width={20}
                height={20}
                onClick={handleIsProductDescriptionChange}
              />
            </div>
          </div>
          <Divider />
          <div className="flex flex-col gap-5">
            <Typography variant="subtitle5" weight="bold">
              Button
            </Typography>
            <DefaultInputWithTitle
              title="Checkout button text"
              value={checkoutButtonText}
              onChange={handleCheckoutButtonTextChange}
            />
          </div>
          <Divider />
          <div className="flex flex-col gap-5">
            <Typography variant="subtitle5" weight="bold">
              E-commerce layout
            </Typography>
            <EditorEcommerceBlockSettingsLayoutSelect />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditorEcommerceBlockSettingsContent;
