const BASE_URL = process.env.REACT_APP_BASE_URL;

const ENDPOINTS = {
  auth: {
    signIn: '/auth/sign-in',
    signUp: 'auth/sign-up',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    changePassword: '/auth/change-password',
  },
  domains: {
    create: '/domains',
    get: '/domains',
    deleteDomain: (id: number) => `/domains/${id}`,
    deleteSender: (id: number, senderId: number) =>
      `/domains/${id}/senders/${senderId}`,
    validate: (id: number) => `/domains/${id}/validate`,
    availableSenders: '/domains/available-senders',
  },
  reporting: {
    sendError: '/reporting/send-error',
  },
  templates: {
    create: '/templates',
    update: '/templates',
    get: '/templates',
    fetch: '/templates/me',
    presets: '/preset-templates',
    duplicate: '/templates/duplicate',
    autoSave: (id: number) => `/templates/${id}/auto-save`,
    presignedUrl: '/templates/presigned-url',
  },
  shopify: {
    connect: '/shopify/connect',
    disconnect: '/shopify/disconnect',
    product: '/shopify/products',
    connectionStatus: '/shopify/connection',
    storeName: '/shopify/store',
  },
  galleryGetPresignedUrl: '/gallery/presigned-url',
  gallery: '/gallery',
  recipients: {
    getSpamReports: '/campaigns/spam',
    getInvalidEmails: '/campaigns/invalid-emails',
    getBouncedEmails: '/campaigns/bounces',
    getRecipients: '/recipients',
    createRecipient: '/recipients',
    importCsvFile: '/recipients/import-csv',
    status: '/recipients/status',
    delete: '/recipients/delete-batch',
    update: (id: number) => `/recipients/${id}`,
  },
  campaigns: {
    campaignsRoute: '/campaigns',
    campaignReport: (id: number) => `/campaign-reports/${id}`,
    unsubscribe: '/campaigns-public/unsubscribe',
  },
  organisations: {
    getMeOrganizationData: '/organizations/me',
    addOrganizationData: '/organizations',
  },
  users: {
    me: '/users/me',
  },
  generativeAI: {
    generate: 'generative-ai/generate',
  },
};

export { BASE_URL, ENDPOINTS };
