import { ReactNode } from 'react';
import clsx from 'clsx';

import { CardVariant } from '@devTypes';

import './styles.css';

interface Props {
  children: ReactNode;
  className?: string;
  variant?: CardVariant;
  onClick?: (param?: any) => void;
}

const Card = ({ children, className, variant = 'primary', onClick }: Props) => (
  <div
    className={clsx(
      'Card',
      variant === 'secondary' && 'Card-secondary',
      className
    )}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    {children}
  </div>
);

export default Card;
