import { useRef } from 'react';
import grapesjs from 'grapesjs';

import EditorTextBlockContentSettings from './EditorTextBlockContentSettings';
import EditorTextBlockDesignSettings from './EditorTextBlockDesignSettings';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorTextBlockSettings = ({ selectedComponent, type }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  // const removeSelection = (event: MouseEvent) => {
  //   if (!editor) return;

  //   // @ts-ignore
  //   const isColorPickerOpen = event.target?.className.includes(
  //     COLOR_PICKER_PALETTE_CLASSNAME
  //   );

  //   if (isColorPickerOpen) return;

  //   removeEditorDocumentSelection(editor);
  // };

  // useOutsideClick(ref, removeSelection);

  const renderSettings = () => {
    if (type === 'content') {
      return (
        <EditorTextBlockContentSettings selectedComponent={selectedComponent} />
      );
    }

    if (type === 'design') {
      return (
        <EditorTextBlockDesignSettings selectedComponent={selectedComponent} />
      );
    }

    return null;
  };

  return (
    <div className="EditorTextBlockSettings" ref={ref}>
      {renderSettings()}
    </div>
  );
};

export default EditorTextBlockSettings;
