import { Typography } from '@atoms';
import { EDITOR_CONTAINERS } from '@constants/editor';

const BlockManagerPanel = () => (
  <div className="flexbox-column flexbox-gap-24px">
    <Typography variant="subtitle3">Add block</Typography>
    <div className={EDITOR_CONTAINERS.blockManager} />
  </div>
);

export default BlockManagerPanel;
