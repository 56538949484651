import { ShopifyProduct } from '@devTypes';

import EcommerceLayout2FallbackItem from './EcommerceLayout2FallbackItem';

import './styles.css';

interface Props {
  products: ShopifyProduct[];
  dropdownAddToCartTextColor: string;
  dropdownAddToCartStrokeColor: string;
  headerFont: string;
  headerTextColor: string;
  isHeaderBold: boolean;
  isHeaderItalic: boolean;
}

const EcommerceLayout2Fallback = ({
  products,
  dropdownAddToCartTextColor,
  dropdownAddToCartStrokeColor,
  headerFont,
  headerTextColor,
  isHeaderBold,
  isHeaderItalic,
}: Props) => {
  const styles = `
    .EcommerceButton, .EcommerceSelect .DropdownHeader {
      border-color: ${dropdownAddToCartStrokeColor};
    }

    .EcommerceButton {
      color: ${dropdownAddToCartTextColor};
    }

    .EcommerceLayout2FallbackItemInfoTitle {
      font-family: ${headerFont};
      color: ${headerTextColor};
      font-weight: ${isHeaderBold ? 700 : 400};
      font-style: ${isHeaderItalic ? 'italic' : 'normal'};
    }

    .EcommerceLayout2FallbackItemInfoPrice {
      color: ${headerTextColor};
    }
  `;
  return (
    <div className="EcommerceLayout2Fallback flexbox-column flexbox-gap-32px">
      <style>{styles}</style>
      {products.map((p) => (
        <EcommerceLayout2FallbackItem
          key={p.id}
          title={p.title}
          price={p.variants[0].price}
          image={p.image.src}
        />
      ))}
    </div>
  );
};

export default EcommerceLayout2Fallback;
