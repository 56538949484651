import { useState } from 'react';

import { Typography } from '@atoms';
import { GalleryItem } from '@molecules';
import { GalleryItemProps } from '@devTypes';

import './styles.css';

interface Props {
  images: GalleryItemProps[];
  value: GalleryItemProps | GalleryItemProps[] | null;
  onChange: (image: GalleryItemProps | GalleryItemProps[]) => void;
  onDelete?: (id: number) => void;
  max?: number;
}

const Gallery = ({ images, value, onChange, max, onDelete }: Props) => {
  const [idOfHoveredItem, setIdOfHoveredItem] = useState(-1);

  const handleMouseEnter = (id: number) => () => {
    setIdOfHoveredItem(id);
  };

  const handleMouseLeave = () => {
    setIdOfHoveredItem(-1);
  };

  if (Array.isArray(value)) {
    return (
      <div className="GalleryWrapper">
        <div className="GalleryHeader">
          <Typography variant="subtitle4">
            {`Select up to ${max} images for the carousel`}
          </Typography>
          <Typography variant="subtitle4">
            {`${value.length} of ${max}`}
          </Typography>
        </div>
        <div className="Gallery">
          {images.map((item) => {
            const isSelected = !!value.find((v) => v.id === item.id);
            const isHovered = item.id === idOfHoveredItem;
            const itemOrderNumber =
              value.findIndex((v) => v.id === item.id) + 1;

            const handleImageSelect = () => {
              if (isSelected) {
                onChange(value.filter((v) => v.id !== item.id));
              } else {
                if (value.length === max) {
                  return;
                }
                onChange(value.concat(item));
              }
            };

            return (
              <GalleryItem
                id={item.id}
                key={item.id}
                url={item.url}
                isSelected={isSelected}
                onClick={handleImageSelect}
                onMouseEnter={handleMouseEnter(item.id)}
                onMouseLeave={handleMouseLeave}
                onDelete={onDelete}
                closeIcon={idOfHoveredItem === item.id}
                orderNumber={itemOrderNumber}
                isHovered={isHovered}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="Gallery">
      {images.map((item) => {
        const isSelected = !!value && value.id === item.id;
        const isHovered = item.id === idOfHoveredItem;

        const handleImageSelect = () => {
          onChange(item);
        };

        return (
          <GalleryItem
            id={item.id}
            key={item.id}
            url={item.url}
            isSelected={isSelected}
            onClick={handleImageSelect}
            onMouseEnter={handleMouseEnter(item.id)}
            onMouseLeave={handleMouseLeave}
            onDelete={onDelete}
            closeIcon={idOfHoveredItem === item.id}
            isHovered={isHovered}
          />
        );
      })}
    </div>
  );
};

export default Gallery;
