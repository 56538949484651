import { CONTACTS_TABLE_ROWS_PER_PAGE } from '@constants/common';
import { createSlice } from '@reduxjs/toolkit';
import { buildCampaingName } from '@utils/helpers';

import { CampaignData, CampaignsResponseData } from './api';
import {
  createCampaignThunk,
  getCampaignByIdThunk,
  getCampaignReport,
  getCampaignsThunk,
  scheduleCampaignThunk,
  sendCampaignThunk,
  sendTestEmailThunk,
  updateCampaignThunk,
} from './asyncThunks';

type LoadingState = {
  isLoading: boolean;
};

interface CampaignsListState extends LoadingState {
  campaigns: CampaignsResponseData;
}

interface CampaignState extends LoadingState {
  campaignObject: CampaignData;
}

interface CampaignReportState extends LoadingState {
  campaignData: {
    title: string;
    previewUrl: string;
    campaignId: number;
    audience: 'all';
    subject: string;
    deliveredAt: string;
    recipients: number;
  };
  sendgridData: {
    sent: number;
    delivered: {
      value: number;
      percentage: string;
    };
    unsubscribed: {
      value: number;
      percentage: string;
    };
    bounced: {
      value: number;
      percentage: string;
    };
    opens: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
    clicks: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
    uniqueOpens: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
    uniqueClicks: {
      total: {
        value: number;
        percentage: string;
      };
      amp: {
        value: number;
        percentage: string;
      };
      html: {
        value: number;
        percentage: string;
      };
    };
  };
  shopifyData: {
    totalCartsCreated: number;
    paidCarts: number;
    totalBookedCartValue: string;
    totalItemsAddedToCart: number;
    revenue: string;
    averageOrderValue: string;
  };
}

const CampaignsInitialState: CampaignsListState = {
  isLoading: false,
  campaigns: {
    data: [],
    limit: CONTACTS_TABLE_ROWS_PER_PAGE,
    offset: 0,
    total: 0,
    metadata: {
      draftCampaignsCount: 0,
      publishedCampaignsCount: 0,
    },
  },
};

const CampaignInitialState: CampaignState = {
  isLoading: false,
  campaignObject: {
    id: 0,
    title: '',
    templateId: undefined,
    userId: 0,
    senderEmail: '',
    senderName: '',
    subject: '',
    recipientType: '',
    recipientsMetadata: {
      totalCount: 0,
      unsubscribedCount: 0,
      subscribedCount: 0,
    },
  },
};

const CampaignReportInitialState: CampaignReportState = {
  isLoading: false,
  campaignData: {
    title: '',
    previewUrl: '',
    campaignId: 0,
    audience: 'all',
    recipients: 0,
    subject: '',
    deliveredAt: '',
  },
  sendgridData: {
    sent: 0,
    delivered: {
      value: 0,
      percentage: '',
    },
    unsubscribed: {
      value: 0,
      percentage: '',
    },
    bounced: {
      value: 0,
      percentage: '',
    },
    opens: {
      total: {
        value: 0,
        percentage: '',
      },
      amp: {
        value: 0,
        percentage: '',
      },
      html: {
        value: 0,
        percentage: '',
      },
    },
    clicks: {
      total: {
        value: 0,
        percentage: '',
      },
      amp: {
        value: 0,
        percentage: '',
      },
      html: {
        value: 0,
        percentage: '',
      },
    },
    uniqueOpens: {
      total: {
        value: 0,
        percentage: '',
      },
      amp: {
        value: 0,
        percentage: '',
      },
      html: {
        value: 0,
        percentage: '',
      },
    },
    uniqueClicks: {
      total: {
        value: 0,
        percentage: '',
      },
      amp: {
        value: 0,
        percentage: '',
      },
      html: {
        value: 0,
        percentage: '',
      },
    },
  },
  shopifyData: {
    totalCartsCreated: 0,
    paidCarts: 0,
    totalBookedCartValue: '',
    totalItemsAddedToCart: 0,
    revenue: '',
    averageOrderValue: '',
  },
};

interface InitialState extends LoadingState {
  campaigns: any;
  campaignObject: any;
  campaignReport: CampaignReportState;
}

const initialState: InitialState = {
  isLoading: false,
  campaigns: CampaignsInitialState,
  campaignObject: CampaignInitialState,
  campaignReport: CampaignReportInitialState,
};

const campaingsSlice = createSlice({
  name: 'campaings',
  initialState,
  reducers: {
    updateCampaignPreviewUrl: (state, { payload }) => ({
      ...state,
      campaignObject: {
        ...state.campaignObject,
        previewUrl: payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignReport.pending, (state) => ({
      ...state,
      campaignReport: {
        ...state.campaignReport,
        isLoading: false,
      },
    }));
    builder.addCase(getCampaignReport.fulfilled, (state, { payload }) => ({
      ...state,
      campaignReport: {
        ...payload,
        isLoading: false,
      },
    }));
    builder.addCase(getCampaignReport.rejected, (state) => ({
      ...state,
      campaignReport: {
        ...state.campaignReport,
        isLoading: false,
      },
    }));
    builder.addCase(getCampaignsThunk.fulfilled, (state, { payload }) => ({
      ...state,
      campaigns: {
        isLoading: false,
        campaigns: {
          ...payload,
          data: payload.data.map((item: any) => ({
            ...item,
            ...{
              title: item.title || buildCampaingName(item.createdAt),
              openRate: item.report?.openRate ?? '-',
              clickRate: item.report?.clickRate ?? '-',
              status:
                item.status.charAt(0).toUpperCase() + item.status.slice(1),
            },
          })),
        },
      },
    }));
    builder.addCase(getCampaignsThunk.pending, (state) => ({
      ...state,
      campaigns: {
        ...state.campaigns,
        isLoading: true,
      },
    }));
    builder.addCase(createCampaignThunk.fulfilled, (state, { payload }) => ({
      ...state,
      campaignObject: {
        recipientsMetadata: payload.recipientsMetadata,
        campaign: {
          ...payload.campaign,
          title:
            payload.campaign.title ??
            buildCampaingName(payload.campaign.createdAt),
        },
      },
    }));
    builder.addCase(createCampaignThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getCampaignByIdThunk.fulfilled, (state, { payload }) => ({
      ...state,
      campaignObject: payload,
    }));
    builder.addCase(getCampaignByIdThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(updateCampaignThunk.fulfilled, (state, { payload }) => ({
      ...state,
      campaignObject: {
        ...state.campaignObject,
        campaign: {
          ...state.campaignObject.campaign,
          ...payload.campaign,
        },
      },
    }));
    builder.addCase(updateCampaignThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(scheduleCampaignThunk.fulfilled, (state, { payload }) => ({
      ...state,
      isLoading: false,
      campaignObject: payload,
    }));
    builder.addCase(scheduleCampaignThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(sendCampaignThunk.fulfilled, (state, { payload }) => ({
      ...state,
      isLoading: false,
      campaignObject: payload,
    }));
    builder.addCase(sendCampaignThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(sendTestEmailThunk.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(sendTestEmailThunk.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
  },
});

export default campaingsSlice.reducer;

export const campaignsActions = campaingsSlice.actions;
