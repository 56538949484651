import { Icon, Tooltip } from '@atoms';
import { ReactComponent as EditorDuplicateIcon } from '@assets/icons/editor-duplicate.svg';
import { ReactComponent as EditorMoveDownIcon } from '@assets/icons/editor-move-down.svg';
import { ReactComponent as EditorMoveUpIcon } from '@assets/icons/editor-move-up.svg';
import { ReactComponent as EditorTrashIcon } from '@assets/icons/editor-trash.svg';
import { EDITOR_SIDEBAR_TABS } from '@constants/common';
import { EditorComponentTypes } from '@constants/editor';
import {
  useEditor,
  useEditorSelected,
  useForceUpdate,
  useTimeoutAutoSave,
} from '@hooks';
import { canvasActions } from '@store/features/canvas';
import { useAppDispatch } from '@store/hooks';
import { resizeCanvas } from '@utils/helpers';

interface Props {
  autoSave: () => void;
  onPositionChange: (position: { x: number; y: number }) => void;
  position: { x: number; y: number };
}

const EditorToolbar = ({ autoSave, onPositionChange, position }: Props) => {
  const force = useForceUpdate();

  const selectedComponent = useEditorSelected()!;
  const editor = useEditor()!;
  const autoSaveTimer = useTimeoutAutoSave();

  const dispatch = useAppDispatch();

  if (!selectedComponent) {
    return null;
  }

  const isTypeAllowed = () => {
    const exceptions = [EditorComponentTypes.Footer];
    const types = Object.values(EditorComponentTypes);

    const filtredTypes = types.filter((type) => !exceptions.includes(type));

    return filtredTypes.includes(
      selectedComponent?.get('type') as EditorComponentTypes
    );
  };

  const isMoveUpDisabled = () => {
    const index = selectedComponent.index();

    if (index === 0) {
      return true;
    }

    return false;
  };

  const isMoveDownDisabled = () => {
    const index = selectedComponent.index();

    const parent = selectedComponent.parent();

    if (!parent) {
      return false;
    }

    if (index === parent.components().length - 1) {
      return true;
    }

    return false;
  };

  const isDuplicateDisabled = () => {
    const selectedComponentType = selectedComponent?.get('type');
    switch (selectedComponentType) {
      case EditorComponentTypes.Carousel:
        return true;
      case EditorComponentTypes.Ecommerce:
        return true;
      default:
        return false;
    }
  };

  const handleMoveUp = () => {
    
    const index = selectedComponent.index();

    selectedComponent.move(selectedComponent.parent()!, {
      at: index - 1,
    });

    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 0);

    const selectedEl = editor.getSelected()?.getEl();

    if (selectedEl) {
      onPositionChange({
        x: position.x,
        y: selectedEl.getBoundingClientRect().y,
      });
    }
    resizeCanvas(editor);

    // force();
  };

  const handleMoveDown = () => {
    const index = selectedComponent.index();

    selectedComponent.move(selectedComponent.parent()!, {
      at: index + 2,
    });

    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 0);

    const selectedEl = editor.getSelected()?.getEl();

    if (selectedEl) {
      onPositionChange({
        x: position.x,
        y: selectedEl.getBoundingClientRect().y,
      });
    }
    resizeCanvas(editor);

    // force();
  };

  const handleDuplicate = () => {
    editor.runCommand('core:copy');
    editor.runCommand('core:paste');

    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 2000);

    force();
  };

  const handleDelete = () => {
    selectedComponent.remove();

    dispatch(canvasActions.setEditorSelectedTab(EDITOR_SIDEBAR_TABS.addBlock));
    editor.select(undefined);

    autoSaveTimer.triggerTimeout(() => {
      autoSave();
    }, 2000);

    force();
  };

  if (!isTypeAllowed()) {
    return null;
  }

  return (
    <div className="flex flex-col items-center px-3 gap-8 py-5 w-[48px] bg-white border border-gray-300 rounded-lg z-20">
      {isMoveUpDisabled() ? (
        <Icon
          className="opacity-50"
          SVG={EditorMoveUpIcon}
          width={22}
          height={22}
        />
      ) : (
        <Tooltip label="Move up" position="left" withArrow={false}>
          <Icon
            SVG={EditorMoveUpIcon}
            width={22}
            height={22}
            onClick={handleMoveUp}
          />
        </Tooltip>
      )}
      {isMoveDownDisabled() ? (
        <Icon
          className="opacity-50"
          SVG={EditorMoveDownIcon}
          width={22}
          height={22}
        />
      ) : (
        <Tooltip label="Move down" position="left" withArrow={false}>
          <Icon
            SVG={EditorMoveDownIcon}
            width={22}
            height={22}
            onClick={handleMoveDown}
          />
        </Tooltip>
      )}
      <div className="w-full h-[1px] bg-gray-300 " />
      {isDuplicateDisabled() ? (
        <Icon
          className="opacity-50"
          SVG={EditorDuplicateIcon}
          width={22}
          height={22}
        />
      ) : (
        <Tooltip label="Duplicate" position="left" withArrow={false}>
          <Icon
            SVG={EditorDuplicateIcon}
            width={22}
            height={22}
            onClick={handleDuplicate}
          />
        </Tooltip>
      )}
      <Tooltip label="Delete" position="left" withArrow={false}>
        <Icon
          SVG={EditorTrashIcon}
          width={22}
          height={22}
          onClick={handleDelete}
        />
      </Tooltip>
    </div>
  );
};

export default EditorToolbar;
