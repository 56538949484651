import { EDITOR_EMAIL_TYPES } from '@constants/editor';
import EditorCanvasHeaderItem from '@organisms/EditorCanvasHeader/EditorCanvasHeaderItem';
import Menu from '@organisms/Menu/Menu';

export type EmailVersion = typeof EDITOR_EMAIL_TYPES[0];

interface Props {
  emailVersion: EmailVersion;
  onEmailVersionChange: (version: EmailVersion) => void;
}

const EmailVersionsToolbar = ({
  emailVersion,
  onEmailVersionChange,
}: Props) => (
  <div className="EmailVersionsToolbar">
    <Menu
      value={emailVersion}
      items={EDITOR_EMAIL_TYPES}
      ItemComponent={EditorCanvasHeaderItem}
      onChange={onEmailVersionChange}
    />
  </div>
);

export default EmailVersionsToolbar;
