import type { RootState } from '../../store';

const isLoading = (state: RootState) => state.users.isLoading;

const user = (state: RootState) => state.users.user.data.user;

const userIntegrations = (state: RootState) =>
  state.users.user.data.integrations;

const isGetUserLoading = (state: RootState) => state.users.user.isLoading;

export default {
  userIntegrations,
  user,
  isGetUserLoading,
  isLoading,
};
