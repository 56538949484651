import { useState } from 'react';
import { useColor } from 'react-color-palette';

import { Divider, Typography } from '@atoms';
import { ColorPickerInput, SelectWithTitle, SliderWithInput } from '@molecules';
import { ReactComponent as BoldIcon } from '@assets/icons/bold.svg';
import { ReactComponent as ItalicIcon } from '@assets/icons/italic.svg';
import {
  DEFAULT_BACKGROUND_VALUE,
  DEFAULT_TEXT_COLOR_VALUE,
  MAX_ECOMMERCE_CHECKOUT_BUTTON_BORDER_RADIUS,
  MAX_ECOMMERCE_CHECKOUT_BUTTON_BORDER_WIDTH,
  TEXT_FONT_FAMILY_OPTIONS,
} from '@constants/editor';
import { Font, Fonts } from '@devTypes';
import { useEditorSelected } from '@hooks';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorTextBlockStylesSettingsOption from '@organisms/EditorTextBlockStylesSettings/EditorTextBlockStylesSettingsOption';

const fontSelectOptions = Object.keys(TEXT_FONT_FAMILY_OPTIONS) as Fonts;

const EditorEcommerceBlockSettingsDesign = () => {
  const selectedComponent = useEditorSelected();
  const attributes = selectedComponent!.getAttributes();

  const [textColor, setTextColor] = useColor(
    'hex',
    attributes.textColor || DEFAULT_TEXT_COLOR_VALUE
  );
  const [headerFont, setHeaderFont] = useState<Font>(attributes.headerFont);
  const [headerTextColor, setHeaderTextColor] = useColor(
    'hex',
    attributes.headerTextColor || DEFAULT_TEXT_COLOR_VALUE
  );
  const [isHeaderBold, setIsHeaderBold] = useState(attributes.isHeaderBold);
  const [isHeaderItalic, setIsHeaderItalic] = useState(
    attributes.isHeaderItalic
  );
  const [productTabTextColor, setProductTabTextColor] = useColor(
    'hex',
    attributes.productTabTextColor || DEFAULT_TEXT_COLOR_VALUE
  );
  const [productTabFillColor, setProductTabFillColor] = useColor(
    'hex',
    attributes.productTabFillColor || DEFAULT_BACKGROUND_VALUE
  );
  const [productTabLineColor, setProductTabLineColor] = useColor(
    'hex',
    attributes.productTabLineColor || DEFAULT_TEXT_COLOR_VALUE
  );
  const [
    dropdownGeneralButtonsStrokeColor,
    setDropdownGeneralButtonsStrokeColor,
  ] = useColor(
    'hex',
    attributes.dropdownGeneralButtonsStrokeColor || DEFAULT_TEXT_COLOR_VALUE
  );
  const [checkoutButtonTextColor, setCheckoutButtonTextColor] = useColor(
    'hex',
    attributes.checkoutButtonTextColor || DEFAULT_TEXT_COLOR_VALUE
  );
  const [checkoutButtonFillColor, setCheckoutButtonFillColor] = useColor(
    'hex',
    attributes.checkoutButtonFillColor || DEFAULT_BACKGROUND_VALUE
  );
  const [checkoutButtonBorderWidth, setCheckoutButtonBorderWidth] = useState(
    attributes.checkoutButtonBorderWidth
  );
  const [checkoutButtonBorderRadius, setCheckoutButtonBorderRadius] = useState(
    attributes.checkoutButtonBorderRadius
  );
  const [checkoutButtonBorderColor, setCheckoutButtonBorderColor] = useColor(
    'hex',
    attributes.checkoutButtonBorderColor || DEFAULT_TEXT_COLOR_VALUE
  );

  const handleDropdownAddToCartStrokeColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      dropdownGeneralButtonsStrokeColor: colorValue,
    });
  };

  const handleProductTabTextColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      productTabTextColor: colorValue,
    });
  };

  const handleProductTabFillColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      productTabFillColor: colorValue,
    });
  };

  const handleProductTabLineColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      productTabLineColor: colorValue,
    });
  };

  const handleHeaderFontChange = (option: Font) => {
    setHeaderFont(option);
    selectedComponent?.addAttributes({
      headerFont: option,
    });
  };

  const handleTextColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      textColor: colorValue,
    });
  };

  const handleHeaderTextColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      headerTextColor: colorValue,
    });
  };

  const handleHeaderBoldChange = () => {
    selectedComponent?.addAttributes({
      isHeaderBold: !isHeaderBold,
    });
    setIsHeaderBold(!isHeaderBold);
  };

  const handleHeaderItalicChange = () => {
    selectedComponent?.addAttributes({
      isHeaderItalic: !isHeaderItalic,
    });
    setIsHeaderItalic(!isHeaderItalic);
  };

  const handleCheckoutButtonTextColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      checkoutButtonTextColor: colorValue,
    });
  };

  const handleCheckoutButtonFillColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      checkoutButtonFillColor: colorValue,
    });
  };

  const handleCheckoutButtonBorderColorChange = (colorValue: string) => {
    selectedComponent?.addAttributes({
      checkoutButtonBorderColor: colorValue,
    });
  };

  const handleCheckoutButtonBorderWidthChange = (value: number | number[]) => {
    selectedComponent?.addAttributes({
      checkoutButtonBorderWidth: value,
    });
    setCheckoutButtonBorderWidth(value);
  };

  const handleCheckoutButtonBorderRadiusChange = (value: number | number[]) => {
    selectedComponent?.addAttributes({
      checkoutButtonBorderRadius: value,
    });
    setCheckoutButtonBorderRadius(value);
  };

  return (
    <div className="flex-col">
      <div className="flex flex-col gap-5">
        <ColorPickerInput
          color={textColor}
          setColor={setTextColor}
          onChange={handleTextColorChange}
          title="General text color"
        />
        <div className="EditorEcommerceBlockSetiingsDesignHeaderFont flexbox-row flexbox-jc-center flexbox-ai-flex-end flexbox-gap-24px">
          <SelectWithTitle
            title="Header font"
            value={headerFont}
            options={fontSelectOptions}
            onChange={handleHeaderFontChange}
            selectedCheckMark
          />
          <div className="flexbox-row flexbox-gap-12px">
            <EditorTextBlockStylesSettingsOption
              name="Bold"
              onClick={handleHeaderBoldChange}
              svgIcon={BoldIcon}
              isActive={isHeaderBold}
            />
            <EditorTextBlockStylesSettingsOption
              name="Italic"
              onClick={handleHeaderItalicChange}
              svgIcon={ItalicIcon}
              isActive={isHeaderItalic}
            />
          </div>
        </div>
        <ColorPickerInput
          color={headerTextColor}
          setColor={setHeaderTextColor}
          onChange={handleHeaderTextColorChange}
          title="Header text color"
        />
      </div>
      <Divider />
      <EditorBlockBackgroundSettings selectedComponent={selectedComponent!} />
      <Divider />
      <div className="flexbox-column flexbox-gap-12px">
        <Typography variant="subtitle5" weight="bold">
          Product tab (for 2+ products)
        </Typography>
        <ColorPickerInput
          color={productTabTextColor}
          setColor={setProductTabTextColor}
          onChange={handleProductTabTextColorChange}
          title="Text color"
        />
        <ColorPickerInput
          color={productTabFillColor}
          setColor={setProductTabFillColor}
          onChange={handleProductTabFillColorChange}
          title="Fill color"
        />
        <ColorPickerInput
          color={productTabLineColor}
          setColor={setProductTabLineColor}
          onChange={handleProductTabLineColorChange}
          title="Line color"
        />
      </div>
      <Divider />
      <div className="flexbox-column flexbox-gap-12px">
        <Typography variant="subtitle5" weight="bold">
          Drop down / General buttons
        </Typography>
        <ColorPickerInput
          color={dropdownGeneralButtonsStrokeColor}
          setColor={setDropdownGeneralButtonsStrokeColor}
          onChange={handleDropdownAddToCartStrokeColorChange}
          title="Line color"
        />
      </div>
      <Divider />
      <div className="flexbox-column flexbox-gap-24px">
        <Typography variant="subtitle5" weight="bold">
          Checkout button
        </Typography>
        <ColorPickerInput
          color={checkoutButtonTextColor}
          setColor={setCheckoutButtonTextColor}
          onChange={handleCheckoutButtonTextColorChange}
          title="Text color"
        />
        <ColorPickerInput
          color={checkoutButtonFillColor}
          setColor={setCheckoutButtonFillColor}
          onChange={handleCheckoutButtonFillColorChange}
          title="Fill color"
        />
        <SliderWithInput
          title="Border width"
          value={checkoutButtonBorderWidth}
          onChange={handleCheckoutButtonBorderWidthChange}
          max={MAX_ECOMMERCE_CHECKOUT_BUTTON_BORDER_WIDTH}
        />
        <SliderWithInput
          title="Border roundness"
          value={checkoutButtonBorderRadius}
          onChange={handleCheckoutButtonBorderRadiusChange}
          max={MAX_ECOMMERCE_CHECKOUT_BUTTON_BORDER_RADIUS}
        />
        <ColorPickerInput
          color={checkoutButtonBorderColor}
          setColor={setCheckoutButtonBorderColor}
          onChange={handleCheckoutButtonBorderColorChange}
          title="Border color"
        />
      </div>
    </div>
  );
};

export default EditorEcommerceBlockSettingsDesign;
