import { ReactNode } from 'react';
import clsx from 'clsx';

import './styles.css';

interface Props {
  children: ReactNode;
  className?: string;
}
const HeaderContainer = ({ children, className }: Props) => (
  <div className={clsx('HeaderContainer', className)}>{children}</div>
);

export default HeaderContainer;
