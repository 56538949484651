import grapesjs from 'grapesjs';

import { generateGrapesjsDividerComponent } from '@utils/editor/components/divider';
import { createEditorBlock } from '@utils/helpers';

const icon = `
<svg width="22" height="45" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.66602 12H20.3327M13.666 4.00001C13.666 5.47277 12.4721 6.66668 10.9993 6.66668C9.52659 6.66668 8.33268 5.47277 8.33268 4.00001C8.33268 2.52725 9.52659 1.33334 10.9993 1.33334C12.4721 1.33334 13.666 2.52725 13.666 4.00001ZM13.666 20C13.666 21.4728 12.4721 22.6667 10.9993 22.6667C9.52659 22.6667 8.33268 21.4728 8.33268 20C8.33268 18.5272 9.52659 17.3333 10.9993 17.3333C12.4721 17.3333 13.666 18.5272 13.666 20Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const label = createEditorBlock('Divider', icon, 'divider');
const content = generateGrapesjsDividerComponent();

export const DividerBlock: grapesjs.BlockOptions = {
  label,
  content,
  select: true,
};
