import { ENDPOINTS } from '@constants/api';
import { wrappedAxiosRequest } from '@store/api';

export interface PresignedUrlData {
  fileName: string;
}

export interface SaveUrlData {
  url: string;
}

export interface DeleteImageData {
  id: number;
}

const galleryApi = {
  async getPresignedUrl({ fileName }: PresignedUrlData) {
    return wrappedAxiosRequest({
      url: ENDPOINTS.galleryGetPresignedUrl,
      params: {
        fileName,
      },
    });
  },

  async saveUrl({ url }: SaveUrlData) {
    return wrappedAxiosRequest({
      url: ENDPOINTS.gallery,
      method: 'post',
      data: {
        url,
      },
    });
  },

  async getAllUrls() {
    return wrappedAxiosRequest({
      url: ENDPOINTS.gallery,
    });
  },

  async deleteImage({ id }: DeleteImageData) {
    return wrappedAxiosRequest({
      url: `${ENDPOINTS.gallery}/${id}`,
      method: 'delete',
    });
  },
};

export default galleryApi;
