import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, Loader, Typography } from '@atoms';
import { InputWithTitle } from '@molecules';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { usersSelectors } from '@store/features/users';
import { updateUserThunk } from '@store/features/users/asyncThunks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { profileSchema } from '@utils/validation';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const ProfileForm = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(usersSelectors.user);
  const isLoading = useAppSelector(usersSelectors.isLoading);

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: yupResolver(profileSchema),
    mode: 'all',
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    },
  });

  const handleCancelClick = () => reset();

  const handleValid: SubmitHandler<FormValues> = (values) => {
    const { firstName, lastName } = values;

    dispatch(
      updateUserThunk({
        firstName,
        lastName,
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Profile info successfully updated');
        reset(values, { keepDirty: false });
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const isDisabled = !formState.isValid || !formState.isDirty;

  if (isLoading) return <Loader />;

  return (
    <>
      <Typography variant="subtitle2" className="CardTitle">
        Profile
      </Typography>
      <InputWithTitle
        name="firstName"
        control={control}
        title="First name"
        placeholder="First name"
      />
      <InputWithTitle
        name="lastName"
        control={control}
        title="Last name"
        placeholder="Last name"
      />
      <InputWithTitle
        name="email"
        control={control}
        isDisabled
        title="Email"
        placeholder="Email"
      />
      <div className="ButtonGroup">
        <Button
          variant="secondary"
          title="Cancel"
          onClick={handleCancelClick}
          disabled={!formState.isDirty}
        />
        <Button
          variant="primary"
          title="Save"
          onClick={handleSubmit(handleValid)}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default ProfileForm;
