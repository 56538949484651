import {
  DEFAULT_BACKGROUND_VALUE,
  DEFAULT_COLUMNS_AMOUNT,
  DEFAULT_COLUMNS_HORIZONTAL_SPACE,
  DEFAULT_COLUMNS_LAYOUT,
  DEFAULT_COLUMNS_PADDING,
  DEFAULT_COLUMNS_VERTICAL_SPACE,
  EditorComponentTypes,
} from '@constants/editor';

export const generateSingleColumnComponent = (components: any[]) => ({
  tagName: 'div',
  classes: 'column',
  selectable: false,
  components,
});

const allowedComponentsToBeDropped = [
  EditorComponentTypes.Text,
  EditorComponentTypes.Image,
  EditorComponentTypes.Button,
  EditorComponentTypes.Divider,
  EditorComponentTypes.Spacer,
]
  .map((component) => `[data-gjs-type="${component}"]`)
  .join(',');

export const generateGrapesjsColumnsComponent = (
  columns: [any, any?],
  styles?: string
) => {
  const components =
    columns.length === 2
      ? [
          {
            tagName: 'div',
            classes: ['column', 'first-column'],
            selectable: false,
            hoverable: false,
            draggable: false,
            components: columns[0],
            droppable: allowedComponentsToBeDropped,
          },
          {
            tagName: 'div',
            classes: ['column', 'second-column'],
            selectable: false,
            hoverable: false,
            components: columns[1],
            droppable: allowedComponentsToBeDropped,
          },
        ]
      : [
          {
            tagName: 'div',
            classes: 'column',
            selectable: false,
            highlightable: false,
            draggable: false,
            components: columns[0],
          },
        ];

  return {
    tagName: 'div',
    components,
    type: EditorComponentTypes.Columns,
    droppable: false,
    classes: 'row',
    attributes: {
      layout: DEFAULT_COLUMNS_LAYOUT,
      amount: DEFAULT_COLUMNS_AMOUNT,
      paddingtop: DEFAULT_COLUMNS_PADDING,
      paddingbottom: DEFAULT_COLUMNS_PADDING,
      paddingright: DEFAULT_COLUMNS_PADDING,
      paddingleft: DEFAULT_COLUMNS_PADDING,
      horizontalSpace: DEFAULT_COLUMNS_HORIZONTAL_SPACE,
      verticalSpace: DEFAULT_COLUMNS_VERTICAL_SPACE,
    },

    styles: `
    .column {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
    }

    .first-column {
      padding-right: ${DEFAULT_COLUMNS_VERTICAL_SPACE / 2}px;
    }

    .second-column {
      padding-left: ${DEFAULT_COLUMNS_VERTICAL_SPACE / 2}px;
    }

    .column > div:not(:last-child){
      margin-bottom: ${DEFAULT_COLUMNS_HORIZONTAL_SPACE}px;
    }

    .row {
      width: 100%;
      min-width: 595px;
      padding: ${DEFAULT_COLUMNS_PADDING}px;
      background-color: ${DEFAULT_BACKGROUND_VALUE};
    }

    @media (max-width: 525px) {
      .row {
        display: block;
        min-width: unset;
      }

      .column {
        display: inline-block;
        width: 100%;
      }

      .column:first-child {
        padding-right: 0;
      }
  
      .column:last-child {
        padding-left: 0;
      }

      .second-column {
        padding-top: ${DEFAULT_COLUMNS_VERTICAL_SPACE}px;
        padding-left: unset;
        padding-right: unset;
      }

      .first-column {
        padding-left: unset;
        padding-right: unset;
      }

      .gjs-button-wrapper-columns{
        padding: unset;
        margin: auto;
      }
    }

    ${styles}
  `,
  };
};
