// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Typography } from '@atoms';
import { Modal } from '@molecules';
import { SendTestEmailModal } from '@organisms';
import { ROUTES } from '@constants/routes';
import { TemplateObject } from '@devTypes';
import {
  campaignsAsyncThunks,
  campaignsSelectors,
} from '@store/features/campaigns';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import ChangeCampaignNameForm from './ChangeCampaignNameForm';
import CreateCampaignForm from './CreateCampaignForm';
import Header from './Header';

import './styles.css';

interface CreateCampaignDetailsProps {
  selectedTemplate: TemplateObject;
  onEditClick: (item: TemplateObject) => void;
  onSaveCampaign: () => void;
}

const CreateCampaignDetails = ({
  selectedTemplate,
  onEditClick,
  onSaveCampaign,
}: CreateCampaignDetailsProps) => {
  const { resetField } = useFormContext();
  const dispatch = useAppDispatch();
  const [changeNameModalIsOpen, setChangeNameModalIsOpen] = useState(false);
  const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] =
    useState(false);
  const { getValues } = useFormContext();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { campaign } = useAppSelector(campaignsSelectors.campaignObject);

  useEffect(() => {
    if (!selectedTemplate?.backgroundStyles) {
      return;
    }

    ref?.current?.setAttribute('style', selectedTemplate.backgroundStyles);
  }, [selectedTemplate?.backgroundStyles]);

  const selectFromTemplate = () => {
    onSaveCampaign();
    navigate(ROUTES.dashboard.selectTemplate, {
      state: {
        campaignId: campaign?.id,
      },
    });
  };

  const startFromScratch = async () => {
    const response = await dispatch(
      templateAsyncThunks.createTemplateThunk({
        title: Date.now().toString(),
        gjs: '',
      })
    ).unwrap();
    sessionStorage.clear();
    localStorage.setItem('gjsProject', '');

    onSaveCampaign();
    navigate(`${ROUTES.template.editor}/${response.id}`);
  };

  const handleSendTestEmailModalToggle = () => {
    onSaveCampaign();
    setIsSendTestEmailModalOpen((prevState) => !prevState);
  };

  const navigateToPreviewPage = async () => {
    onSaveCampaign();
    navigate(`${ROUTES.template.preview}/${selectedTemplate.id}`);
  };

  const handleRename = async () => {
    const { id, title } = getValues();

    await dispatch(
      campaignsAsyncThunks.updateCampaignThunk({
        id,
        title,
      })
    );

    setChangeNameModalIsOpen(false);
  };

  const handleCancelRename = () => {
    setChangeNameModalIsOpen(false);
    resetField('title');
  };

  return (
    <Card className="CreateCampaignCard">
      <Header
        campaignTitle={getValues().title}
        isEditDisabled={!selectedTemplate}
        onEditClick={() => onEditClick(selectedTemplate)}
        onEditCampaignName={() => setChangeNameModalIsOpen(true)}
        onSendTestEmailClick={handleSendTestEmailModalToggle}
        onPreviewEmailClick={navigateToPreviewPage}
      />
      <Modal isOpen={changeNameModalIsOpen} onClose={handleCancelRename}>
        <Typography variant="subtitle2">Change campaign name</Typography>
        <ChangeCampaignNameForm
          onSave={handleRename}
          onCancel={handleCancelRename}
        />
      </Modal>
      <div className="CreateCampaignCard-Content">
        <CreateCampaignForm />
        {selectedTemplate ? (
          <div className="EmailPreview" ref={ref}>
            <img src={selectedTemplate.previewUrl} alt="template preview" />
          </div>
        ) : (
          <div className="HowToCreateEmail-Section">
            <Typography variant="subtitle3">
              How would you like to start your email design?
            </Typography>
            <Button
              title="Start from a template"
              variant="primary"
              onClick={selectFromTemplate}
            />
            <Button
              title="Start from scratch"
              variant="primary"
              onClick={startFromScratch}
            />
          </div>
        )}
      </div>
      <SendTestEmailModal
        isOpen={isSendTestEmailModalOpen}
        onClose={handleSendTestEmailModalToggle}
        templateId={selectedTemplate?.id}
      />
    </Card>
  );
};

export default CreateCampaignDetails;
