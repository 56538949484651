import clsx from 'clsx';

import ValidationStatusLabel, {
  ValidationStatusLabelProps,
} from '../ValidationStatusLabel/ValidationStatusLabel';

import './styles.css';

interface Props {
  items: ValidationStatusLabelProps[];
  className?: string;
}

const ValidationErrorsList = ({ items, className }: Props) => (
  <div className={clsx('ValidationErrorsList', className)}>
    {items.map(({ status, title }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ValidationStatusLabel status={status} title={title} key={index} />
    ))}
  </div>
);

export default ValidationErrorsList;
