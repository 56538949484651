import grapesjs from 'grapesjs';
import grapesjsckeditor from 'grapesjs-plugin-ckeditor';

import { EDITOR_CONTAINERS, EDITOR_WIDTH } from '@constants/editor';
import { blockManager } from '@utils/editor/blockManager/blockManager';
import { customComponents } from '@utils/editor/plugins';
import { withCssIdPrefix } from '@utils/helpers';

import { generateGrapesjsFooterComponent } from './components/footer';
import addBlockPlugin from './plugins/add-block';
import baseReactComponents from './plugins/base-react-components';
import carouselPlugin from './plugins/carousel';
import columnsPlugin from './plugins/columns';
import ecommercePlugin from './plugins/e-commerce';

const scripts: string[] = [];
const styles: string[] = [];

if (process.env.NODE_ENV === 'development') {
  scripts.push('http://localhost:3000/static/js/bundle.js');
  scripts.push('https://appmail-images.s3.amazonaws.com/cke/ckeditor.js');
} else {
  styles.push('https://dev.appmail.co/static/css/main.min.css');
  scripts.push('https://appmail-images.s3.amazonaws.com/cke/ckeditor.js');
}

export const editorConfig: grapesjs.EditorConfig = {
  container: withCssIdPrefix(EDITOR_CONTAINERS.canvas),
  height: '540px',
  width: `${EDITOR_WIDTH}px`,
  panels: { defaults: [] },
  showToolbar: false,
  multipleSelection: false,
  // @ts-ignore
  components: [
    '<div data-gjs-type="add-block" />',
    // @ts-ignore
    generateGrapesjsFooterComponent(),
  ],
  plugins: [
    baseReactComponents,
    ecommercePlugin,
    columnsPlugin,
    carouselPlugin,
    columnsPlugin,
    customComponents,
    addBlockPlugin,
    grapesjsckeditor,
  ],
  pluginsOpts: {
    'gjs-plugin-ckeditor': {
      options: {
        language: 'en',
        startupFocus: true,
        extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
        allowedContent: true, // Disable auto-formatting, class removing, etc.
        uiColor: '#0000001a', // Inline editor color
        removePlugins: 'magicline',
        toolbarGroups: [
          '/',
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          {
            name: 'editing',
            groups: ['find', 'selection', 'spellchecker', 'editing'],
          },
          { name: 'forms', groups: ['forms'] },
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
          },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'tools', groups: ['tools'] },
          { name: 'others', groups: ['others'] },
          { name: 'about', groups: ['about'] },
        ],
      },
      position: 'left',
    },
  },
  blockManager,
  storageManager: false,
  canvas: {
    scripts,
    styles,
  },
  canvasCss: `
    body {
      overflow: hidden;
    } 
  `,
};
