import { Icon } from '@atoms';
import { ReactComponent as CornerUpLeftIcon } from '@assets/icons/corner-up-left.svg';
import { ReactComponent as CornerUpRightIcon } from '@assets/icons/corner-up-right.svg';

import './styles.css';

interface Props {
  type: 'undo' | 'redo';
  isActive: boolean;
  onClick?: () => void;
}

const UndoRedoButton = ({ type, isActive, onClick }: Props) => (
  <Icon
    className={isActive ? 'UndoRedoButton-active' : 'UndoRedoButton-inactive'}
    SVG={type === 'undo' ? CornerUpLeftIcon : CornerUpRightIcon}
    width={16}
    height={16}
    onClick={onClick}
  />
);

export default UndoRedoButton;
