import { MouseEventHandler } from 'react';

import { Button, Typography } from '@atoms';

interface Props {
  campaignTitle: string;
  onEdit: MouseEventHandler;
}

const HeaderTitle = ({ campaignTitle, onEdit }: Props) => (
  <div className="HeaderTitle">
    <Typography variant="subtitle2">{campaignTitle}</Typography>
    <Button variant="text-primary" title="Edit" onClick={onEdit} />
  </div>
);

export default HeaderTitle;
