import { ENDPOINTS } from '@constants/api';

import { wrappedAxiosRequest } from '../../api';

export interface PresignedUrlData {
  fileName: string;
}

interface BlockData {
  elementId: string | undefined;
  value: {
    type: string;
    payload: {
      text?: string;
      nested?: string[];
      styles: string;
      styleClasses?: string[];
    };
  };
}

export interface TemplateData {
  id: number | undefined;
  title: string;
  status: string;
  blocks: BlockData[];
  blockOrder: string[] | undefined[];
  globalStyles?: string | grapesjs.default.CssRule[] | undefined;
  gjs: string;
  backgroundStyles?: string;
}

export interface InitialTemplateData {
  title: string;
  gjs: string;
  status?: string;
  ampContent?: string;
  htmlContent?: string;
  plaintextContent?: string;
  globalStyles?: string;
  backgroundStyles?: string;
  previewUrl?: string;
}

export interface AutoSaveTemplateData {
  id: number;
  gjs: string;
}

export interface TemplateByIdData {
  id: number;
}

export interface UpdateTemplateImageURLData {
  id: number;
  url: string;
}

export interface DuplicateCampaignData {
  id: number;
}

const templateApi = {
  async createTemplate(data: InitialTemplateData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.templates.create,
      data,
    });
  },

  async updateTemplate({
    id,
    title,
    status,
    blocks,
    blockOrder,
    globalStyles,
    gjs,
    backgroundStyles,
  }: TemplateData) {
    return wrappedAxiosRequest({
      method: 'put',
      url: `${ENDPOINTS.templates.update}/${id}`,
      data: {
        title,
        status,
        blocks,
        blockOrder,
        globalStyles,
        gjs,
        backgroundStyles,
      },
    });
  },

  async fetchTemplates() {
    return wrappedAxiosRequest({
      method: 'get',
      url: `${ENDPOINTS.templates.fetch}`,
    });
  },

  async fetchPresetTemplates() {
    return wrappedAxiosRequest({
      method: 'get',
      url: `${ENDPOINTS.templates.presets}`,
    });
  },

  async autoSaveTemplate({ id, gjs }: AutoSaveTemplateData) {
    return wrappedAxiosRequest({
      method: 'put',
      data: {
        gjs,
      },
      url: `${ENDPOINTS.templates.autoSave(id)}`,
    });
  },

  async fetchTemplateById({ id }: TemplateByIdData) {
    return wrappedAxiosRequest({
      method: 'get',
      url: `${ENDPOINTS.templates.update}/${id}`,
    });
  },

  async getTemplateById({ id }: { id: number }) {
    return wrappedAxiosRequest({
      method: 'get',
      url: `${ENDPOINTS.templates.get}/${id}`,
    });
  },

  async getPresignedUrl({ fileName }: PresignedUrlData) {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.templates.presignedUrl,
      params: {
        fileName,
      },
    });
  },

  async updateTemplateImageURL({ id, url }: UpdateTemplateImageURLData) {
    return wrappedAxiosRequest({
      method: 'patch',
      url: `${ENDPOINTS.templates.get}/${id}/preview`,
      data: {
        url,
      },
    });
  },

  async duplicateTemplate({ id }: DuplicateCampaignData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: `${ENDPOINTS.templates.duplicate}`,
      data: {
        id,
      },
    });
  },
};

export default templateApi;
