import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { MenuItemComponent } from '@devTypes';

import './styles.css';

const TabLikeMenuItem = ({
  title,
  onClick,
  icon,
  isSelected,
}: MenuItemComponent) => (
  <div
    className={clsx(
      'TabLikeMenuItem',
      isSelected && 'TabLikeMenuItem-selected'
    )}
    role="button"
    tabIndex={0}
    onClick={onClick}
  >
    <Icon SVG={icon} width={24} height={24} />
    <Typography variant="subtitle4">{title}</Typography>
  </div>
);

export default TabLikeMenuItem;
