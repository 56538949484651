import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import grapesjs from 'grapesjs';

import { Divider, Toggle, Typography } from '@atoms';
import { Input } from '@molecules';
import { EditorBlockSettingsComponent } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { schemas } from '@utils/validation';

import './styles.css';

const IconColorFb = 'https://appmail-images.s3.amazonaws.com/FB-color.png';
const IconColorIg = 'https://appmail-images.s3.amazonaws.com/IG-color.png';
const IconColorTt = 'https://appmail-images.s3.amazonaws.com/TikTok-color.png';
const IconColorTw = 'https://appmail-images.s3.amazonaws.com/Twitter-color.png';
const IconColorYt = 'https://appmail-images.s3.amazonaws.com/YouTube-color.png';

const EditorFooterBlockContentSettings = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => {
  const topSection = selectedComponent.components().models[0].components()
    .models[0];
  const emailInfoAndVisitSite = topSection.components().models[0];
  const emailComponent = emailInfoAndVisitSite.components().models[0];

  const visitSiteWrapper = emailInfoAndVisitSite.components().models[1];
  const visitSite =
    emailInfoAndVisitSite.components().models[1].attributes.components!
      .models[0];

  const phoneAndSocials = topSection.components().models[1];
  const phoneComponent = phoneAndSocials.components().models[0];
  const socialMediaComponent = phoneAndSocials.components().models[1];

  const fbComponent = socialMediaComponent.components().models[0];
  const instaComponent = socialMediaComponent.components().models[1];
  const twitterComponent = socialMediaComponent.components().models[2];
  const ytComponent = socialMediaComponent.components().models[3];
  const ttComponent = socialMediaComponent.components().models[4];

  const attributes = selectedComponent.getAttributes();

  const { control } = useForm({
    defaultValues: {
      email: attributes.emailText ?? '',
      phone: attributes.phoneNumber ?? '',
      website: attributes.websiteText ?? '',
      fbLink: attributes.facebookLink ?? '',
      ytLink: attributes.youtubeLink ?? '',
      instaLink: attributes.instagramLink ?? '',
      ttLink: attributes.tiktokLink ?? '',
      twitterLink: attributes.twitterLink ?? '',
      link: attributes.link ?? '',
    },
    mode: 'onBlur',
    resolver: yupResolver(schemas.urlInputSchema),
  });

  const [appMailBrandingToggle, setAppMailBrandingToggle] = useState(false);
  const [emailToggle, setEmailToggle] = useState(true);
  const [phoneToggle, setPhoneToggle] = useState(true);
  const [websiteToggle, setWebsiteToggle] = useState(true);
  const [facebookToggle, setFacebookToggle] = useState(true);
  const [instagramToggle, setInstagramToggle] = useState(true);
  const [twitterToggle, setTwitterToggle] = useState(true);
  const [youtubeToggle, setYoutubeToggle] = useState(true);
  const [tiktokToggle, setTiktokToggle] = useState(true);
  const [socialMediaToggle, setSocialMediaToggle] = useState(true);

  const handleAppmailBrandingChange = () =>
    setAppMailBrandingToggle((prevState) => !prevState);

  const adjustUi = () => {
    const isSocialMediaVisible = !socialMediaComponent.getStyle()?.visibility;
    const isVisitWebsiteVisible = !visitSite.getStyle()?.visibility;
    const isEmailVisible = !emailComponent.getStyle()?.visibility;
    const isPhoneNumberVisible = !phoneComponent.getStyle()?.visibility;

    // show/hide container
    if (!isEmailVisible && !isVisitWebsiteVisible) {
      emailInfoAndVisitSite.addStyle({ display: 'none' });
    } else {
      emailInfoAndVisitSite.removeStyle('display');
    }

    if (!isPhoneNumberVisible && !isSocialMediaVisible) {
      phoneAndSocials.addStyle({ display: 'none' });
    } else {
      phoneAndSocials.removeStyle('display');
    }

    if (isEmailVisible) {
      phoneComponent.addStyle({ bottom: 'unset' });
    } else {
      phoneComponent.addStyle({ bottom: '10px' });
      phoneComponent.addStyle({ position: 'relative' });
    }

    if (isPhoneNumberVisible) {
      emailComponent.addStyle({ top: 'unset' });
    } else {
      emailComponent.addStyle({ position: 'relative' });
      emailComponent.addStyle({ top: '10px' });
    }

    if (isSocialMediaVisible) {
      visitSite.addStyle({ top: 'unset' });
    } else {
      visitSite.addStyle({ top: '10px' });
      visitSite.addStyle({ position: 'relative' });
    }

    if (isVisitWebsiteVisible) {
      socialMediaComponent.addStyle({ bottom: 'unset' });
    } else {
      socialMediaComponent.addStyle({ position: 'relative' });
      socialMediaComponent.addStyle({ bottom: '10px' });
    }

    if (!isVisitWebsiteVisible && !isEmailVisible && !isPhoneNumberVisible) {
      emailInfoAndVisitSite.addStyle({ display: 'none' });
      phoneComponent.addStyle({ display: 'none' });
    } else {
      emailInfoAndVisitSite.removeStyle('display');
      phoneComponent.removeStyle('display');
    }

    if (!isEmailVisible && !isPhoneNumberVisible) {
      emailComponent.addStyle({ display: 'none' });
      phoneComponent.addStyle({ display: 'none' });
    } else {
      emailComponent.removeStyle('display');
      phoneComponent.removeStyle('display');
    }

    // add/remove divider
    if (!isVisitWebsiteVisible && !isSocialMediaVisible) {
      emailComponent.addStyle({ border: 'none' });
      phoneComponent.addStyle({ border: 'none' });
      socialMediaComponent.addStyle({ display: 'none' });
      visitSiteWrapper.addStyle({ display: 'none' });
    } else {
      emailComponent.removeStyle('border');
      phoneComponent.removeStyle('border');
      socialMediaComponent.removeStyle('display');
      visitSiteWrapper.removeStyle('display');
      emailComponent.addStyle({ 'border-right': '2px solid initial' });
      phoneComponent.addStyle({ 'border-right': '2px solid initial' });
    }

    if (
      isEmailVisible &&
      isVisitWebsiteVisible &&
      !isSocialMediaVisible &&
      !isPhoneNumberVisible
    ) {
      emailComponent.addStyle({ top: 'unset' });
      visitSite.addStyle({ top: 'unset' });
    }
  };

  const showHideAllIcons = (show: boolean) => {
    if (!show) {
      fbComponent.addStyle({ display: 'none' });
      instaComponent.addStyle({ display: 'none' });
      twitterComponent.addStyle({ display: 'none' });
      ytComponent.addStyle({ display: 'none' });
      ttComponent.addStyle({ display: 'none' });
    } else {
      fbComponent.removeStyle('display');
      instaComponent.removeStyle('display');
      twitterComponent.removeStyle('display');
      ytComponent.removeStyle('display');
      ttComponent.removeStyle('display');
    }

    setFacebookToggle(show);
    setInstagramToggle(show);
    setTwitterToggle(show);
    setYoutubeToggle(show);
    setTiktokToggle(show);
  };

  const isSocialMediaIconsVisible = () => {
    const isFbVisible = !fbComponent.getStyle()?.display;
    const isInstaVisible = !instaComponent.getStyle()?.display;
    const isTwitterVisible = !twitterComponent.getStyle()?.display;
    const isYtVisible = !ytComponent.getStyle()?.display;
    const isTikTokVisible = !ttComponent.getStyle()?.display;

    return (
      isFbVisible ||
      isInstaVisible ||
      isTwitterVisible ||
      isYtVisible ||
      isTikTokVisible
    );
  };

  const toggleChange = (
    cb: React.Dispatch<React.SetStateAction<boolean>>,
    component: grapesjs.Component
  ) => {
    cb((prevState) => {
      if (prevState) {
        component.addStyle({ visibility: 'hidden' });
      } else {
        component.removeStyle('visibility');
      }
      adjustUi();
      return !prevState;
    });
  };

  const toggleSocialMediaChange = () => {
    setSocialMediaToggle((prevState) => {
      if (prevState) {
        socialMediaComponent.addStyle({ visibility: 'hidden' });
        showHideAllIcons(false);
      } else {
        socialMediaComponent.removeStyle('visibility');
        showHideAllIcons(true);
      }

      adjustUi();
      return !prevState;
    });
  };

  const toggleSocialMediaIconChange = (
    cb: React.Dispatch<React.SetStateAction<boolean>>,
    component: grapesjs.Component
  ) => {
    cb((prevState) => {
      if (prevState) {
        component.addStyle({ display: 'none' });
      } else {
        component.removeStyle('display');
      }

      if (!isSocialMediaIconsVisible()) {
        socialMediaComponent.addStyle({ display: 'none' });
        setSocialMediaToggle(false);
      } else {
        socialMediaComponent.removeStyle('display');
        setSocialMediaToggle(true);
      }

      adjustUi();

      return !prevState;
    });
  };

  const emailText = useWatch({ control, name: 'email' });
  const phoneNumber = useWatch({ control, name: 'phone' });
  const websiteText = useWatch({ control, name: 'website' });
  const link = useWatch({ control, name: 'link' });

  useEffect(() => {
    if (!selectedComponent) {
      return;
    }

    const emailToggleState =
      !emailComponent.getStyle()?.display &&
      emailComponent.getStyle()?.visibility !== 'hidden';
    setEmailToggle(emailToggleState);
    const phoneToggleState =
      !phoneComponent.getStyle()?.display &&
      phoneComponent.getStyle()?.visibility !== 'hidden';
    setPhoneToggle(phoneToggleState);
    const websiteToggleState =
      !visitSite.getStyle()?.display &&
      visitSite.getStyle()?.visibility !== 'hidden';
    setWebsiteToggle(websiteToggleState);
    const facebookToggleState = !fbComponent.getStyle()?.display;
    setFacebookToggle(facebookToggleState);
    const instagramToggleState = !instaComponent.getStyle()?.display;
    setInstagramToggle(instagramToggleState);
    const twitterToggleState = !twitterComponent.getStyle()?.display;
    setTwitterToggle(twitterToggleState);
    const youtubeToggleState = !ytComponent.getStyle()?.display;
    setYoutubeToggle(youtubeToggleState);
    const tiktokToggleState = !ttComponent.getStyle()?.display;
    setTiktokToggle(tiktokToggleState);
    const socialMediaToggleState =
      !socialMediaComponent.getStyle()?.display &&
      socialMediaComponent.getStyle()?.visibility !== 'hidden' &&
      (facebookToggleState ||
        instagramToggleState ||
        twitterToggleState ||
        youtubeToggleState ||
        tiktokToggleState);
    setSocialMediaToggle(socialMediaToggleState);

    // eslint-disable-next-line no-param-reassign
    if (emailComponent) {
      if (emailComponent.getEl()) {
        emailComponent.getEl().innerHTML = emailText;
      }
    }

    if (phoneComponent?.getEl()) {
      phoneComponent.getEl().innerHTML = phoneNumber;
    }

    if (visitSite?.getEl()) {
      visitSite.getEl().innerHTML = websiteText;
    }
  }, [emailText, phoneNumber, emailText, websiteText, selectedComponent]);

  const facebookLink = useWatch({ control, name: 'fbLink' });
  const instagramLink = useWatch({ control, name: 'instaLink' });
  const twitterLink = useWatch({ control, name: 'twitterLink' });
  const youtubeLink = useWatch({ control, name: 'ytLink' });
  const tiktokLink = useWatch({ control, name: 'ttLink' });

  useEffect(() => {
    if (!selectedComponent) {
      return;
    }

    selectedComponent.addAttributes({
      link,
      websiteText,
      phoneNumber,
      emailText,
      facebookLink,
      instagramLink,
      twitterLink,
      youtubeLink,
      tiktokLink,
    });

    const linkComponents = socialMediaComponent.components().models;
    const facebookLinkComponent = linkComponents[0];
    const instagramLinkComponent = linkComponents[1];
    const twitterLinkComponent = linkComponents[2];
    const youtubeLinkComponent = linkComponents[3];
    const tiktokLinkComponent = linkComponents[4];

    if (facebookLink.length) {
      facebookLinkComponent.addAttributes({ href: facebookLink });
    } else {
      facebookLinkComponent.removeAttributes('href');
    }
    if (instagramLink.length) {
      instagramLinkComponent.addAttributes({ href: instagramLink });
    } else {
      instagramLinkComponent.removeAttributes('href');
    }
    if (twitterLink.length) {
      twitterLinkComponent.addAttributes({ href: twitterLink });
    } else {
      twitterLinkComponent.removeAttributes('href');
    }
    if (youtubeLink.length) {
      youtubeLinkComponent.addAttributes({ href: youtubeLink });
    } else {
      youtubeLinkComponent.removeAttributes('href');
    }
    if (tiktokLink.length) {
      tiktokLinkComponent.addAttributes({ href: tiktokLink });
    } else {
      tiktokLinkComponent.removeAttributes('href');
    }
    if (link.length) {
      visitSite.addAttributes({ href: link });
    } else {
      visitSite.removeAttributes('href');
    }
  }, [
    attributes,
    link,
    websiteText,
    phoneNumber,
    emailText,
    facebookLink,
    instagramLink,
    twitterLink,
    youtubeLink,
    tiktokLink,
  ]);

  return (
    <div className="EditorFooterBlockContentSettings">
      <div className="AppmailBranding-Block">
        <div className="flexbox-row flexbox-jc-space-between">
          <Typography variant="subtitle3" className="branding-typography">
            Show AppMail branding
          </Typography>
          <Toggle
            isChecked={appMailBrandingToggle}
            isDisabled
            onChange={handleAppmailBrandingChange}
          />
        </div>
        <Typography variant="subtitle6" className="appmail-branding-typography">
          Upgrade to remove AppMail branding
        </Typography>
      </div>
      <Divider />
      <div>
        <div className="input-textfield-toggle">
          <Typography variant="subtitle4" className="InputWithTitle-Title">
            <div className="flex justify-between">
              <span>Email</span>
              <span className="input-span-email">
                <Toggle
                  isChecked={emailToggle}
                  onChange={() => toggleChange(setEmailToggle, emailComponent)}
                />
              </span>
            </div>
          </Typography>
          <Input
            name="email"
            placeholder="Your email"
            isDisabled={!emailToggle}
            control={control}
          />
        </div>
      </div>
      <div className="input-textfield-toggle">
        <Typography variant="subtitle4" className="InputWithTitle-Title">
          <div className="flex justify-between">
            <span>Phone</span>
            <span className="input-span">
              <Toggle
                isChecked={phoneToggle}
                onChange={() => toggleChange(setPhoneToggle, phoneComponent)}
              />
            </span>
          </div>
        </Typography>
        <Input
          name="phone"
          placeholder="Your phone number"
          isDisabled={!phoneToggle}
          control={control}
        />
      </div>
      <div className="input-textfield-toggle">
        <Typography variant="subtitle4" className="InputWithTitle-Title">
          <div className="flex justify-between">
            <span>Website</span>
            <span className="input-span-website">
              <Toggle
                isChecked={websiteToggle}
                onChange={() => toggleChange(setWebsiteToggle, visitSite)}
              />
            </span>
          </div>
        </Typography>
        <Input
          name="website"
          placeholder="Visit our website"
          isDisabled={!websiteToggle}
          control={control}
        />
        <Input
          control={control}
          className="[&_.Input-with-start-icon]:pl-[85px] [&>div>p]:!left-0"
          name="link"
          isDisabled={!websiteToggle}
          placeholder="Enter your website URL"
          startIcon={
            <Typography
              className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
              variant="subtitle4"
            >
              https://
            </Typography>
          }
        />
      </div>
      <Divider />
      <div className="social-medial-text-field">
        <div className="social-media-text-fields-toggle">
          <div>
            <Typography
              className="social-media-text-toggle "
              weight="bold"
              variant="subtitle4"
            >
              <div className="flex justify-between w-full">
                <span>SOCIAL MEDIA &nbsp;</span>
                <span>
                  <span className="social-media-span">Filled in Fields</span>
                  <span className="social-media-toggle">
                    <Toggle
                      isChecked={socialMediaToggle}
                      onChange={toggleSocialMediaChange}
                    />
                  </span>
                </span>
              </div>
            </Typography>
          </div>
        </div>
        <div className="social-media-input-blocks">
          <div className="social-media-textfield-toggle">
            <Typography variant="subtitle4" className="InputWithTitle-Title">
              <span className="input-span-facebook flex justify-between">
                <div className="flex">
                  <img className="facebook-image" src={IconColorFb} alt="" />
                  <h2 className="social-media-text">Facebook</h2>
                </div>
                <span className="input-span-facebook-toggle">
                  <Toggle
                    isChecked={facebookToggle}
                    onChange={() =>
                      toggleSocialMediaIconChange(
                        setFacebookToggle,
                        fbComponent
                      )
                    }
                  />
                </span>
              </span>
            </Typography>
            <Input
              className="[&_.Input-with-start-icon]:pl-[140px] [&>div>p]:!left-0"
              name="fbLink"
              placeholder=""
              isDisabled={!facebookToggle}
              control={control}
              startIcon={
                <Typography
                  className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
                  variant="subtitle4"
                  style={{ width: 135 }}
                >
                  facebook.com/
                </Typography>
              }
            />
          </div>
          <div className="social-media-textfield-toggle">
            <Typography variant="subtitle4" className="InputWithTitle-Title">
              <span className="input-span-instagram flex justify-between">
                <div className="flex">
                  <img className="facebook-image" src={IconColorIg} alt="" />
                  <h2 className="social-media-text">Instagram</h2>
                </div>
                <span className="input-span-instagram-toggle">
                  <Toggle
                    isChecked={instagramToggle}
                    onChange={() =>
                      toggleSocialMediaIconChange(
                        setInstagramToggle,
                        instaComponent
                      )
                    }
                  />
                </span>
              </span>
            </Typography>
            <Input
              className="[&_.Input-with-start-icon]:pl-[140px] [&>div>p]:!left-0"
              name="instaLink"
              placeholder=""
              isDisabled={!instagramToggle}
              control={control}
              startIcon={
                <Typography
                  className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
                  variant="subtitle4"
                  style={{ width: 135 }}
                >
                  instagram.com/
                </Typography>
              }
            />
          </div>
          <div className="social-media-textfield-toggle">
            <Typography variant="subtitle4" className="InputWithTitle-Title">
              <span className="input-span-twitter flex justify-between">
                <div className="flex">
                  <img className="facebook-image" src={IconColorTw} alt="" />
                  <h2 className="social-media-text">Twitter</h2>
                </div>
                <span className="input-span-twitter-toggle">
                  <Toggle
                    isChecked={twitterToggle}
                    onChange={() =>
                      toggleSocialMediaIconChange(
                        setTwitterToggle,
                        twitterComponent
                      )
                    }
                  />
                </span>
              </span>
            </Typography>
            <Input
              className="[&_.Input-with-start-icon]:pl-[140px] [&>div>p]:!left-0"
              name="twitterLink"
              placeholder=""
              isDisabled={!twitterToggle}
              control={control}
              startIcon={
                <Typography
                  className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
                  variant="subtitle4"
                  style={{ width: 135 }}
                >
                  twitter.com/
                </Typography>
              }
            />
          </div>
          <div className="social-media-textfield-toggle">
            <Typography variant="subtitle4" className="InputWithTitle-Title">
              <span className="input-span-youtube flex justify-between">
                <div className="flex">
                  <img className="facebook-image" src={IconColorYt} alt="" />
                  <h2 className="social-media-text">Youtube</h2>
                </div>
                <span className="input-span-youtube-toggle">
                  <Toggle
                    isChecked={youtubeToggle}
                    onChange={() =>
                      toggleSocialMediaIconChange(setYoutubeToggle, ytComponent)
                    }
                  />
                </span>
              </span>
            </Typography>
            <Input
              className="[&_.Input-with-start-icon]:pl-[140px] [&>div>p]:!left-0"
              name="ytLink"
              placeholder=""
              isDisabled={!youtubeToggle}
              control={control}
              startIcon={
                <Typography
                  className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
                  variant="subtitle4"
                  style={{ width: 135 }}
                >
                  youtube.com/
                </Typography>
              }
            />
          </div>
          <div className="social-media-textfield-toggle">
            <Typography variant="subtitle4" className="InputWithTitle-Title">
              <span className="input-span-tiktok flex justify-between">
                <div className="flex">
                  <img className="facebook-image" src={IconColorTt} alt="" />
                  <h2 className="social-media-text">TikTok</h2>
                </div>
                <span className="input-span-tiktok-toggle">
                  <Toggle
                    isChecked={tiktokToggle}
                    onChange={() =>
                      toggleSocialMediaIconChange(setTiktokToggle, ttComponent)
                    }
                  />
                </span>
              </span>
            </Typography>
            <Input
              className="[&_.Input-with-start-icon]:pl-[140px] [&>div>p]:!left-0"
              name="ttLink"
              placeholder=""
              isDisabled={!tiktokToggle}
              control={control}
              startIcon={
                <Typography
                  className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg"
                  variant="subtitle4"
                  style={{ width: 135 }}
                >
                  tiktok.com/
                </Typography>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorFooterBlockContentSettings;
