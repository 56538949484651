import clsx from 'clsx';

import { CheckboxComponent } from '@devTypes';

import './styles.css';

// todo: add deselect all checkboxes view

const Checkbox = ({ isChecked, onChange, type }: CheckboxComponent) => (
  <input
    className={clsx(
      isChecked && 'Checkbox-checked',
      type === 'multiple' && 'Checkbox-Multiple'
    )}
    checked={isChecked}
    onChange={onChange}
    type="checkbox"
  />
);

export default Checkbox;
