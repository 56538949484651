import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { SwitchRender } from '@atoms';
import { GalleryItemProps } from '@devTypes';
import EditorImageBlockUploadModal from '@organisms/EditorImageBlockUploadModal/EditorImageBlockUploadModal';
import { organizationsSelectors } from '@store/features/organizations';
import { useAppSelector } from '@store/hooks';
import { logoComponentController } from '@utils/editor/components/logo';

import EditorLogoBlockSettingsContent from './EditorLogoBlockSettingsContent';
import EditorLogoBlockSettingsDesign from './EditorLogoBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
  type: string;
}

const EditorLogoBlockSettings = ({ selectedComponent, type }: Props) => {
  const [selectedImage, setSelectedImage] = useState<GalleryItemProps | null>({
    id: selectedComponent.getAttributes().imageId as number,
    url: selectedComponent.getAttributes().imageUrl as string,
  });

  const meOrganizationData = useAppSelector(
    organizationsSelectors.getMeOrganizationData
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const addOrganizationLogo = () => {
    const { imageUrl } = meOrganizationData;
    const imageId = Math.random();

    logoComponentController.setLogoImage(selectedComponent, {
      imageUrl,
      imageId,
    });
    setSelectedImage({
      url: imageUrl,
      id: imageId,
    });
  };

  useEffect(() => {
    if (!selectedComponent) return;
    const attributes = selectedComponent.getAttributes();

    if (!meOrganizationData?.imageUrl) {
      setIsModalOpen(attributes.isModalOpen);
      selectedComponent.addAttributes({ loaded: true });

      return;
    }

    if (attributes.loaded || attributes.imageUrl) return;

    addOrganizationLogo();
    selectedComponent.addAttributes({ isModalOpen: false });
  }, [selectedComponent]);

  const handleSelectedImageChange = (
    image: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (Array.isArray(image)) {
      return;
    }

    setSelectedImage(image);
  };

  const handleOpen = () => {
    const { imageId, imageUrl } = selectedComponent.getAttributes();

    if (imageId !== selectedImage?.id) {
      setSelectedImage({ id: imageId, url: imageUrl });
    }

    setIsModalOpen(true);
    selectedComponent.addAttributes({ isModalOpen: true });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    selectedComponent.addAttributes({ isModalOpen: false });
  };

  const handleSelectedImageUpload = (
    image: GalleryItemProps | GalleryItemProps[]
  ) => {
    if (!selectedImage || Array.isArray(image)) {
      return;
    }

    logoComponentController.setLogoImage(selectedComponent, {
      imageUrl: image.url,
      imageId: image.id,
    });
    setSelectedImage(image);
    handleClose();
  };

  if (!selectedComponent) return null;

  return (
    <div className="EditorColumnsBlockSettings">
      <SwitchRender comparator={type} toCompare={['content', 'design']}>
        <EditorLogoBlockSettingsContent
          selectedComponent={selectedComponent}
          onUploadImageOpen={handleOpen}
        />
        <EditorLogoBlockSettingsDesign selectedComponent={selectedComponent} />
      </SwitchRender>
      <EditorImageBlockUploadModal
        galleryValue={selectedImage}
        isOpen={isModalOpen}
        onClose={handleClose}
        onGalleryValueChange={handleSelectedImageChange}
        onGallerySelectedImageUpload={handleSelectedImageUpload}
      />
    </div>
  );
};

export default EditorLogoBlockSettings;
