/* eslint-disable no-param-reassign */
import { EDITOR_SIDEBAR_TABS } from '@constants/common';
import { EDITOR_EMAIL_TYPES } from '@constants/editor';
import { EditorSidebarTab } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  selectedTab: EditorSidebarTab | null;
  emailType: typeof EDITOR_EMAIL_TYPES[0];
}

const initialState: InitialState = {
  selectedTab: EDITOR_SIDEBAR_TABS.addBlock,
  emailType: EDITOR_EMAIL_TYPES[0],
};

const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setEditorSelectedTab(state, { payload }) {
      state.selectedTab = payload;
    },
    setEditorEmailType(state, { payload }) {
      state.emailType = payload;
    },
  },
});

export default canvasSlice.reducer;

export const canvasActions = { ...canvasSlice.actions };
