import { MouseEventHandler } from 'react';

import { ExitButton, MenuItem } from '@molecules';
import { MenuItemProps } from '@devTypes';

import Menu from '../Menu/Menu';

import './styles.css';

interface Props {
  items: MenuItemProps[];
  value: MenuItemProps | null;
  onChange: (tab: any) => void;
  onGoBackClick: MouseEventHandler;
}

const Sidebar = ({ items, value, onChange, onGoBackClick }: Props) => (
  <div className="Sidebar">
    <ExitButton onGoBackClick={onGoBackClick} />
    <Menu
      items={items}
      value={value}
      onChange={onChange}
      ItemComponent={MenuItem}
    />
  </div>
);

export default Sidebar;
