import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Loader } from '@atoms';
import { PreviewView } from '@views';
import { TemplateObject } from '@devTypes';
import { storeNameThunk } from '@store/features/shopify/asyncThunks';
import { templateSelectors } from '@store/features/templates';
import { getTemplateByIdThunk } from '@store/features/templates/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const PreviewPage = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateObject>();
  const [shopDomain, setShopDomain] = useState<string>();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useSelector(templateSelectors.template);
  const { state } = useLocation();

  useEffect(() => {
    if (!id) return;

    if (state?.selectedTemplateItem) {
      setSelectedTemplate(state.selectedTemplateItem);
      return;
    }

    dispatch(getTemplateByIdThunk({ id: +id }))
      .unwrap()
      .then((response: TemplateObject) => {
        setSelectedTemplate(response);
      });

    dispatch(storeNameThunk({}))
      .unwrap()
      .then((response: any) => {
        setShopDomain(response.domain);
      });
  }, [state]);

  if (!selectedTemplate?.id) return <Loader fullScreen />;

  return (
    <PreviewView
      templateId={selectedTemplate.id}
      htmlContent={selectedTemplate.htmlContent}
      ampContent={
        shopDomain
          ? selectedTemplate.ampContent
              .replace('{shopify-store-name}', shopDomain)
              .replaceAll('{{checkoutLink}}', '')
              .replace('target="_blank"', 'style="pointer-events: none;"')
          : selectedTemplate.ampContent
      }
      isSendTestEmailDisabled={state?.isSendTestEmailDisabled}
    />
  );
};

export default PreviewPage;
