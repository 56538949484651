import clsx from 'clsx';

import { Card, Typography } from '@atoms';
import { organizationsSelectors } from '@store/features/organizations';
import { useAppSelector } from '@store/hooks';
import ChangePasswordForm from '@templates/ProfileTemplate/ChangePasswordForm';
import ProfileForm from '@templates/ProfileTemplate/ProfileForm';

import './styles.css';

const ProfileTemplate = () => {
  const { imageUrl } = useAppSelector(
    organizationsSelectors.getMeOrganizationData
  );

  return (
    <div className="ProfileTemplate">
      {imageUrl && (
        <Card className={clsx('ProfileTemplate-Card', 'OrganizationCard')}>
          <Typography variant="subtitle2" className="CardTitle">
            Organization
          </Typography>
          <img
            src={imageUrl}
            alt="Organization logo"
            className="OrganizationLogo"
          />
        </Card>
      )}

      <div className="ProfileTemplate-Forms">
        <Card className="ProfileTemplate-Card">
          <ProfileForm />
        </Card>
        <Card className="ProfileTemplate-Card">
          <ChangePasswordForm />
        </Card>
      </div>
    </div>
  );
};

export default ProfileTemplate;
