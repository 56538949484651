import { EDITOR_EMAIL_TYPES } from '@constants/editor';
import { useEditor } from '@hooks';
import Menu from '@organisms/Menu/Menu';
import { canvasActions, canvasSelectors } from '@store/features/canvas';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { resizeCanvas } from '@utils/helpers';

import EditorCanvasHeaderItem from './EditorCanvasHeaderItem';

import './styles.css';

interface Props {
  onChange: (type: any) => void;
}

const EditorCanvasHeader = ({ onChange }: Props) => {
  const editor = useEditor();
  const emailType = useAppSelector(canvasSelectors.editorEmailType);

  const dispatch = useAppDispatch();

  const handleEmailTypeChange = (type: typeof EDITOR_EMAIL_TYPES[0]) => {
    dispatch(canvasActions.setEditorEmailType(type));
    onChange(type);

    if (editor) {
      resizeCanvas(editor);
    }
  };

  return (
    <div className="EditorCanvasHeader">
      <Menu
        value={emailType}
        items={EDITOR_EMAIL_TYPES}
        ItemComponent={EditorCanvasHeaderItem}
        onChange={handleEmailTypeChange}
      />
    </div>
  );
};

export default EditorCanvasHeader;
