import { DashboardTabsHeader } from '@molecules';
import { IntegrationsTemplate } from '@templates';
import { Platform } from '@devTypes';
import ConnectShopifyModal from '@views/IntegrationsView/ConnectShopifyModal';
import DisconnectShopifyModal from '@views/IntegrationsView/DisconnectShopifyModal';

import './styles.css';

interface IntegrationsViewProps {
  availableConnections: Platform[];
  connected: Platform[];
  setIsConnectShopifyModalOpen: (state: boolean) => void;
  isConnectShopifyModalOpen: boolean;
  setIsDisconnectShopifyModalOpen: (state: boolean) => void;
  isDisconnectShopifyModalOpen: boolean;
  deactivateShopify: () => void;
}

const IntegrationsView = ({
  connected,
  availableConnections,
  setIsConnectShopifyModalOpen,
  isConnectShopifyModalOpen,
  setIsDisconnectShopifyModalOpen,
  isDisconnectShopifyModalOpen,
  deactivateShopify,
}: IntegrationsViewProps) => {
  const handleConnectShopifyModalClose = () => {
    setIsConnectShopifyModalOpen(false);
  };

  const handleDisconnectShopifyModalClose = () => {
    setIsDisconnectShopifyModalOpen(false);
  };

  return (
    <>
      <DashboardTabsHeader title="Integrations" />
      <IntegrationsTemplate
        connected={connected}
        availableConnections={availableConnections}
        onShopifyActivateClick={() => setIsConnectShopifyModalOpen(true)}
        onShopifyDeactivateClick={() => setIsDisconnectShopifyModalOpen(true)}
      />
      <ConnectShopifyModal
        isOpen={isConnectShopifyModalOpen}
        onClose={handleConnectShopifyModalClose}
      />
      <DisconnectShopifyModal
        isOpen={isDisconnectShopifyModalOpen}
        onClose={handleDisconnectShopifyModalClose}
        onSubmit={deactivateShopify}
      />
    </>
  );
};

export default IntegrationsView;
