import { PopupListItemComponent } from '@devTypes';

import PopupListItem from './PopupListItem';

export type PopupListClickHandler = (
  title: string,
  callback?: Function
) => () => void;

interface Props {
  items: Array<PopupListItemComponent>;
  selectedItem?: string;
  onClick: PopupListClickHandler;
}

const PopupList = ({ items, selectedItem, onClick }: Props) => (
  <div className="PopupList">
    {items.map(({ title, icon, onClick: callback }) => (
      <PopupListItem
        key={title}
        title={title}
        icon={icon}
        selectedItem={selectedItem}
        onClick={onClick(title, callback)}
      />
    ))}
  </div>
);
export default PopupList;
