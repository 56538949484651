import { useState } from 'react';

const useCounter = (initialState: number) => {
  const [count, setCount] = useState(initialState);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  return { count, incrementCount, decrementCount };
};

export default useCounter;
