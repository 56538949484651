import React, { ReactNode } from 'react';

import { Typography } from '@atoms';

interface Props {
  title: string;
  devicePreview: ReactNode;
}

const DevicePreview = ({ title, devicePreview }: Props) => (
  <div className="DevicePreview">
    <Typography variant="subtitle2">{title}</Typography>
    {devicePreview}
  </div>
);

export default DevicePreview;
