import { Control } from 'react-hook-form';

import { Icon, Select, Typography } from '@atoms';
import { Input } from '@molecules';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';

import FiltersDropdown from './FiltersDropdown';

export type SortOption = {
  title: string;
  fieldToSortBy: 'createdAt' | 'firstName';
};

const alphabeticSortOption: SortOption = {
  title: 'Name A to Z',
  fieldToSortBy: 'firstName',
};
const createdDateSortOption: SortOption = {
  title: 'Date created',
  fieldToSortBy: 'createdAt',
};

export const sortBySelectOptions = [
  alphabeticSortOption,
  createdDateSortOption,
];

export const searchDefaultValues = {
  search: '',
};

export type SearchFormValues = typeof searchDefaultValues;

interface Props {
  control: Control<SearchFormValues, any>;
  sortByValue: SortOption | null;
  onSortBySelect: (value: SortOption) => void;
  isUnsubscribedContactsChecked: boolean;
  isSubscribedContactsChecked: boolean;
  onUnsubscribedContactsChange: () => void;
  onSubscribedContactsChange: () => void;
  onApplyClick: () => void;
}

const ContactsTableSearchAndFilters = ({
  control,
  sortByValue,
  onSortBySelect,
  isUnsubscribedContactsChecked,
  isSubscribedContactsChecked,
  onSubscribedContactsChange,
  onUnsubscribedContactsChange,
  onApplyClick,
}: Props) => (
  <div className="ContactsTableSearchAndFilters">
    <FiltersDropdown
      isSubscribedContactsChecked={isSubscribedContactsChecked}
      isUnsubscribedContactsChecked={isUnsubscribedContactsChecked}
      onUnsubscribedContactsChange={onUnsubscribedContactsChange}
      onSubscribedContactsChange={onSubscribedContactsChange}
      onApplyClick={onApplyClick}
    />
    <Input
      name="search"
      control={control}
      startIcon={<Icon SVG={SearchIcon} />}
      placeholder="Search"
    />

    <Typography variant="subtitle4" className="SortByLabel">
      Sort by
    </Typography>
    <Select
      value={sortByValue}
      onChange={onSortBySelect}
      options={sortBySelectOptions}
      placeholder="Sort by"
      className="SortBySelect"
    />
  </div>
);
export default ContactsTableSearchAndFilters;
