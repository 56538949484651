import type { RootState } from '../../store';

const uploads = (state: RootState) => state.gallery?.uploads ?? [];

const isGetAllUrlsLoading = (state: RootState) =>
  state.gallery.loadingState.isLoading;

export default {
  isGetAllUrlsLoading,
  uploads,
};
