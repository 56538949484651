import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { SelectWithTitle } from '@molecules';
import { DEFAULT_TEXT_FORMAT, TEXT_FORMAT_OPTIONS } from '@constants/editor';
import { handleTextFormatFontWeightChange } from '@utils/editor/components/text/controllers/general';

import './styles.css';

interface Props {
  selectedComponent: grapesjs.Component;
}

const formatSelectOptions = TEXT_FORMAT_OPTIONS.map(({ title }) => title);

const FONT_WEIGHT_NORMAL_NUMERIC_VALUE = 400;

const EditorTextBlockFormatSettings = ({ selectedComponent }: Props) => {
  const [format, setFormat] = useState<string>(DEFAULT_TEXT_FORMAT);

  useEffect(() => {
    if (!selectedComponent) return;

    const formatStyle =
      selectedComponent.getAttributes().format || DEFAULT_TEXT_FORMAT;

    setFormat(formatStyle);
  }, [selectedComponent]);

  const handleFormatChange = (optionValue: string) => {
    const style = TEXT_FORMAT_OPTIONS.find(
      ({ title }) => title === optionValue
    )?.style;

    selectedComponent.addAttributes({ format: optionValue });
    setFormat(optionValue);

    if (!style) return;

    const isBold = +style['font-weight'] > FONT_WEIGHT_NORMAL_NUMERIC_VALUE;

    selectedComponent.addStyle(style);

    if (isBold) {
      handleTextFormatFontWeightChange(selectedComponent);
    }
  };

  return (
    <div className="EditorTextBlockFormatSettings">
      <SelectWithTitle
        title="Format"
        onChange={handleFormatChange}
        options={formatSelectOptions}
        value={format}
      />
    </div>
  );
};

export default EditorTextBlockFormatSettings;
