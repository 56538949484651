import { SubmitHandler, useForm } from 'react-hook-form';

import { Button, Typography } from '@atoms';
import { InputWithTitle, Modal } from '@molecules';
import { ModalComponent } from '@devTypes';

interface Props extends ModalComponent {
  onConfirm: (name: string) => void;
  campaignName: string;
}

type FormValues = {
  name: string;
};

const ConfirmModal = ({
  isOpen,
  className,
  onClose,
  onConfirm,
  campaignName,
}: Props) => {
  const { control, handleSubmit } = useForm({ values: { name: campaignName } });

  const handleValidSubmit: SubmitHandler<FormValues> = ({ name }) => {
    onConfirm(name);
    onClose();
  };

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose}>
      <div className="w-[564px] flex flex-col gap-8">
        <Typography variant="subtitle1" weight="medium">
          Change campaign name
        </Typography>
        <InputWithTitle name="name" title="Campaign name" control={control} />
        <div className="flex justify-end gap-4">
          <Button variant="secondary" title="Cancel" onClick={onClose} />
          <Button
            variant="primary"
            title="Save"
            onClick={handleSubmit(handleValidSubmit)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
