import { SvgFunctionComponent } from '@devTypes';

interface Props {
  className?: string;
  SVG: SvgFunctionComponent;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const Icon = ({
  className,
  SVG,
  width = 16,
  height = 16,
  onClick,
  ...props
}: Props) => (
  <SVG
    className={className}
    width={width}
    height={height}
    onClick={onClick}
    {...props}
  />
);

export default Icon;
