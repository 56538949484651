import ReactFbq from 'react-fbq';
import TagManager from 'react-gtm-module';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SignUp } from '@organisms';
import { SignInSignUpTemplate } from '@templates';
import { ROUTES } from '@constants/routes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SignUpData } from '@store/features/auth/api';
import { signUpThunk } from '@store/features/auth/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { EMAIL_IN_USE_ERROR_MESSAGE, schemas } from '@utils/validation';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

type FormValues = typeof defaultValues;

const zappierUrl = 'https://hooks.zapier.com/hooks/catch/16125827/39i27en/';

const SignUpView = () => {
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schemas.signUpFormSchema),
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleSubmit, setError } = methods;

  const trackToZappier = async ({ firstName, lastName, email }: SignUpData) => {
    // Form the request for sending data to the server.
    const JSONdata = { event: 'Sign Up', firstName, lastName, email };
    const options = {
      method: 'POST',
      body: JSON.stringify(JSONdata),
    };

    // Send the form data to zappier
    await fetch(zappierUrl, options);
  };

  const trackToGTM = ({ firstName, lastName, email }: SignUpData) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'sign_up',
        firstName,
        lastName,
        email,
        value: '0.00',
        currency: 'USD',
        predicted_ltv: '0.00',
      },
    };

    // Send the form data to GTM
    TagManager.dataLayer(tagManagerArgs);
  };

  const handleValid: SubmitHandler<FormValues> = ({
    firstName,
    lastName,
    email,
    password,
  }) => {
    dispatch(signUpThunk({ firstName, lastName, email, password }))
      .unwrap()
      .then(() => {
        trackToZappier({ firstName, lastName, email, password });
        trackToGTM({ firstName, lastName, email, password });
        // @ts-ignore
        ReactFbq.track({
          title: 'StartTrial',
          data: {
            value: '0.00',
            currency: 'USD',
            predicted_ltv: '0.00',
            firstName,
            lastName,
            email,
          },
        });

        navigate(ROUTES.signIn);
      })
      .catch((error) => {
        if (error.message.message === 'This email is already in use.') {
          setError('email', {
            message: EMAIL_IN_USE_ERROR_MESSAGE,
          });
          return;
        }

        toast(error.message.message);
      });
  };

  return (
    <FormProvider {...methods}>
      <SignInSignUpTemplate
        authType="signUp"
        onSubmit={handleSubmit(handleValid)}
        // onGoogleAuthSubmit={() => {}}
        // onFacebookAuthSubmit={() => {}}
      >
        <SignUp />
      </SignInSignUpTemplate>
    </FormProvider>
  );
};

export default SignUpView;
