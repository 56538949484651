import { useEffect, useState } from 'react';

import { Typography } from '@atoms';
import { LayoutMenuItem } from '@molecules';
import { ReactComponent as EcommerceLayout1Icon } from '@assets/icons/e-commerce-layout-1.svg';
import { ReactComponent as EcommerceLayout2Icon } from '@assets/icons/e-commerce-layout-2.svg';
import { useEditorSelected } from '@hooks';
import Menu from '@organisms/Menu/Menu';

const items = [
  { id: 'layout1', icon: EcommerceLayout1Icon },
  { id: 'layout2', icon: EcommerceLayout2Icon, isDisabled: true },
];

const EditorEcommerceBlockSettingsLayoutSelect = () => {
  const [selectedLayout, setSelectedLayout] = useState<typeof items[0]>();
  const selectedComponent = useEditorSelected();

  useEffect(() => {
    const attributes = selectedComponent?.getAttributes();

    const layout = items.find((i) => i.id === attributes?.layout);

    setSelectedLayout(layout);
  }, []);

  const handleLayoutChange = (layout: typeof items[0]) => {
    selectedComponent?.addAttributes({ layout: layout.id });
    setSelectedLayout(layout);
  };

  return (
    <div className="flex flex-col gap-4">
      <Typography variant="subtitle4">Select layout</Typography>
      <Menu
        className="[&>div>div]:h-[225px] [&>div]:p-1 [&>div>div>svg:not(.LayoutMenuItem-CheckIcon)]:h-full [&>div>div>svg:not(.LayoutMenuItem-CheckIcon)]:w-full"
        items={items}
        value={selectedLayout!}
        onChange={handleLayoutChange}
        ItemComponent={LayoutMenuItem}
        spacing={24}
      />
    </div>
  );
};

export default EditorEcommerceBlockSettingsLayoutSelect;
