import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Icon, Typography } from '@atoms';
import { ReactComponent as AppMailLogo } from '@assets/icons/AppMailLogo.svg';
import { generalAsyncThunks } from '@store/features/general';
import { useAppDispatch } from '@store/hooks';

const UnsubscribePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      navigate('/');
      return;
    }

    dispatch(generalAsyncThunks.unsubscribeRecipient({ token }));
  }, [searchParams]);

  return (
    <div className="w-full h-screen bg-gray-50 flex justify-center items-end">
      <div className="h-4/5 w-[711px] bg-white flex flex-col items-center gap-10 pt-10">
        <Icon SVG={AppMailLogo} width={115} height={25} />
        <Typography variant="subtitle4" weight="bold">
          We are sorry to see you go
        </Typography>
        <Typography variant="subtitle4">
          Your email address has successfully been removed from our list
        </Typography>
      </div>
    </div>
  );
};

export default UnsubscribePage;
