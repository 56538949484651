import { Button, Icon, Select } from '@atoms';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/chevron-down.svg';

interface Props {
  onSendTestEmailClick: () => void;
  onPreviewEmailClick: () => void;
  isDisabled: boolean;
}

type ActionOption = {
  title: string;
  onClick: () => void;
};

const TemplateActionsDropdown = ({
  onSendTestEmailClick,
  onPreviewEmailClick,
  isDisabled,
}: Props) => {
  const actionOptions: ActionOption[] = [
    {
      title: 'Preview email',
      onClick: onPreviewEmailClick,
    },
    {
      title: 'Send test email',
      onClick: onSendTestEmailClick,
    },
  ];

  return (
    <Select
      className="TemplateActionsDropdown"
      value={null}
      options={actionOptions}
      renderDropdownHeader={({ onClick }) => (
        <Button
          variant="secondary"
          onClick={onClick}
          title=""
          disabled={isDisabled}
          startIcon={<Icon SVG={ArrowDownIcon} height={24} width={20} />}
        />
      )}
    />
  );
};

export default TemplateActionsDropdown;
