import { Progress, Typography } from '@atoms';

import './styles.css';

interface Props {
  value: number;
}

const FileUploadBar = ({ value }: Props) => (
  <div className="FileUploadBar">
    <Typography variant="subtitle6">Uploading...</Typography>
    <Progress value={value} />
  </div>
);

export default FileUploadBar;
