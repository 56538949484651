import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as LeftArrowIcon } from '@assets/icons/chevron-left.svg';
import { ReactComponent as RightArrowIcon } from '@assets/icons/chevron-right.svg';

import './styles.css';

interface Props {
  onLeftArrowClick: () => void;
  onRightArrowClick: () => void;
  currentPage: number;
  totalPagesAmount: number;
  className?: string;
}

const PaginationButtons = ({
  onRightArrowClick,
  onLeftArrowClick,
  currentPage,
  totalPagesAmount,
  className,
}: Props) => {
  const isLeftArrowDisabled = currentPage === 1;
  const isRightArrowDisabled = currentPage >= totalPagesAmount;

  return (
    <div className={clsx('PaginationButtons', className)}>
      <Icon
        SVG={LeftArrowIcon}
        height={24}
        width={24}
        className={clsx(isLeftArrowDisabled && 'ArrowButton-disabled')}
        onClick={onLeftArrowClick}
      />
      <Typography variant="subtitle4" className="PaginationButtons-Pages">
        {currentPage} of {totalPagesAmount}
      </Typography>
      <Icon
        SVG={RightArrowIcon}
        height={24}
        width={24}
        className={clsx(isRightArrowDisabled && 'ArrowButton-disabled')}
        onClick={onRightArrowClick}
      />
    </div>
  );
};

export default PaginationButtons;
