import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Card, Icon, Typography } from '@atoms';
import { Accordion } from '@molecules';
import { ReactComponent as CheckInCircle } from '@assets/icons/CheckInCircle.svg';
import { ReactComponent as CheckInCircleGreen } from '@assets/icons/CheckInCircleGreen.svg';
import exampleImageSrc from '@assets/images/CsvExample.png';
import { ROUTES } from '@constants/routes';
import { allContactsSelectors } from '@store/features/allcontacts';
import { campaignsSelectors } from '@store/features/campaigns';
import { useAppSelector } from '@store/hooks';
import ConnectShopifyModal from '@views/IntegrationsView/ConnectShopifyModal';

import './styles.css';

interface HomeRouteLayoutProps {
  isShopifyConnected: boolean;
}

const HomeRouteLayout = ({ isShopifyConnected }: HomeRouteLayoutProps) => {
  const navigate = useNavigate();
  const [isConnectShopifyModalOpen, setIsConnectShopifyModalOpen] =
    useState(false);
  const { metadata: { totalCount = 0 } = {} } = useSelector(
    allContactsSelectors.recipients
  );
  const {
    campaigns: { data },
  } = useAppSelector(campaignsSelectors.campaigns);

  const [shopifyCompleted, setShopifyCompleted] = useState(false);
  const [audienceCompleted, setAudienceCompleted] = useState(false);
  const [campaignCompleted, setCampaignCompleted] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completedStepsState, setCompletedState] = useState(0);

  let completedSteps = 0;

  const isCampaignPublished = data.find(
    (item: any) => item.status === 'Published'
  );

  useEffect(() => {
    if (isShopifyConnected && !shopifyCompleted) {
      setShopifyCompleted(true);
    }
    if (totalCount > 0) {
      setAudienceCompleted(true);
    }
    if (isCampaignPublished) {
      setCampaignCompleted(true);
    }
  }, []);

  useEffect(() => {
    if (isShopifyConnected && !shopifyCompleted) {
      setShopifyCompleted(true);
    }
  }, [isShopifyConnected]);

  useEffect(() => {
    if (totalCount === 0 && !audienceCompleted) {
      setAudienceCompleted(false);
    } else if (totalCount > 0 && !audienceCompleted) {
      setAudienceCompleted(true);
    }
  }, [totalCount]);

  useEffect(() => {
    if (isCampaignPublished && !campaignCompleted) {
      setCampaignCompleted(true);
    } else if (!isCampaignPublished) {
      setCampaignCompleted(false);
    }
  }, [isCampaignPublished]);

  useEffect(() => {
    if (shopifyCompleted) {
      completedSteps += 1;
      setCompletedState(completedSteps);
    }
  }, [shopifyCompleted]);

  useEffect(() => {
    if (audienceCompleted) {
      completedSteps += 1;
      setCompletedState(completedSteps);
    }
  }, [audienceCompleted]);

  useEffect(() => {
    if (campaignCompleted) {
      completedSteps += 1;
      setCompletedState(completedSteps);
    }
  }, [campaignCompleted]);

  const ONBOARDING_STEPS = [
    {
      title: 'Connect your Shopify store',
      titleContent: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flex: 1,
            marginRight: 25,
          }}
        >
          {isShopifyConnected ? (
            <Icon SVG={CheckInCircleGreen} height={40} width={40} />
          ) : (
            <Icon SVG={CheckInCircle} height={40} width={40} />
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginLeft: 10,
            }}
          >
            <Typography variant="subtitle4" weight="bold">
              Connect your Shopify store
            </Typography>
            <Typography variant="subtitle4">
              Connecting your store is required to send shoppable emails
            </Typography>
          </div>
          <Button
            title={isShopifyConnected ? 'Connected' : 'Connect'}
            variant={isShopifyConnected ? 'secondary' : 'primary'}
            disabled={isShopifyConnected}
            onClick={() =>
              !isShopifyConnected && setIsConnectShopifyModalOpen(true)
            }
            className="OnboardingButton"
          />
        </div>
      ),
      // content: (
      //   <div
      //     style={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       gap: '4px',
      //       marginLeft: 10,
      //     }}
      //   >
      //     <Typography variant="subtitle4">
      //       1. Go to the <b>Integration</b> page
      //     </Typography>

      //     <Typography variant="subtitle4">
      //       2. Click <b>Activate</b> in the Shopify module
      //     </Typography>
      //     <Typography variant="subtitle4">
      //       3. Follow the instructions{' '}
      //       <a
      //         style={{ color: 'blue' }}
      //         target="_blank"
      //         rel="noreferrer"
      //         href="https://appmailsupport.notion.site/Shopify-integration-cd3a344e35694e40a40219eed9a66d97"
      //       >
      //         here
      //       </a>
      //     </Typography>
      //     <Typography variant="subtitle4">
      //       If you need assistance connecting your store, schedule a session
      //       with our team using this link:{' '}
      //       <a
      //         style={{ color: 'blue' }}
      //         href="https://calendly.com/appmail"
      //         target="_blank"
      //         rel="noreferrer"
      //       >
      //         https://calendly.com/appmail
      //       </a>
      //     </Typography>
      //   </div>
      // ),
    },
    {
      title: 'Add contacts',
      titleContent: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flex: 1,
            marginRight: 25,
          }}
        >
          {totalCount > 0 ? (
            <Icon SVG={CheckInCircleGreen} height={40} width={40} />
          ) : (
            <Icon SVG={CheckInCircle} height={40} width={40} />
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginLeft: 10,
            }}
          >
            <Typography variant="subtitle4" weight="bold">
              Add contacts
            </Typography>
            <Typography variant="subtitle4">
              Upload your contact list
            </Typography>
          </div>
          <Button
            title={totalCount > 0 ? 'Done' : 'Audience'}
            variant={totalCount > 0 ? 'secondary' : 'primary'}
            disabled={totalCount > 0}
            onClick={() => navigate(`${ROUTES.dashboard.allcontacts}`)}
            className="OnboardingButton"
          />
        </div>
      ),
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            marginLeft: 10,
          }}
        >
          <Typography variant="subtitle4">
            1. Go to the <b>Audience</b> page
          </Typography>

          <Typography variant="subtitle4">
            2. Click <b>Add Contacts</b>
          </Typography>
          <Typography variant="subtitle4">
            3. Click <b>Import from CSV</b>
          </Typography>
          <Typography variant="subtitle4" style={{ marginLeft: 15 }}>
            Prepare your CSV file following these guidelines: <br />
            a. The column names should be exactly as the reference below <br />
            b. No extra columns beyond the reference below <br />
            c. No blank fields under the Email column (Blank fields under the
            First_name and Last_name is ok) <br />
            d. Ensure the file is saved as CSV format <br />
          </Typography>
          <Typography variant="subtitle4">
            4. <b>Upload</b> your CSV format
          </Typography>
          <img src={exampleImageSrc} height="118" width="540" alt="" />
        </div>
      ),
    },
    {
      title: 'Send your first campaign',
      titleContent: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          {isCampaignPublished ? (
            <Icon SVG={CheckInCircleGreen} height={40} width={40} />
          ) : (
            <Icon SVG={CheckInCircle} height={40} width={40} />
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginLeft: 10,
            }}
          >
            <Typography variant="subtitle4" weight="bold">
              Send your first campaign
            </Typography>
            <Typography variant="subtitle4">
              Design your very first interactive email campaign with our
              intuitive email editor
            </Typography>
          </div>
        </div>
      ),
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            marginLeft: 10,
          }}
        >
          <Typography variant="subtitle4">
            1. Go to the <b>Campaign</b> page
          </Typography>

          <Typography variant="subtitle4">
            2. Enter the campaign details (Subject line, Sender name etc)
          </Typography>
          <Typography variant="subtitle4">
            3. Click either <b>Start from a template</b> or{' '}
            <b>Start from scratch</b>
          </Typography>
          <Typography variant="subtitle4">4. Design your campaign</Typography>
          <Typography variant="subtitle4">5. Send your campaign</Typography>
        </div>
      ),
    },
    {
      title: 'Setup your own custom domain (Coming Soon)',
      titleContent: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Icon SVG={CheckInCircle} height={40} width={40} />
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginLeft: 10,
            }}
          >
            <Typography variant="subtitle4" weight="bold">
              Setup your own custom domain (Coming Soon)
            </Typography>
            <Typography variant="subtitle4">
              Get your custom email whitelisted
            </Typography>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="HomeLayoutContainerLeft">
      <div className="TopCards">
        <Card className="GuidingHand">
          <Typography variant="subtitle3" weight="semiBold">
            Need a guiding hand to get started?
          </Typography>
          <Typography variant="subtitle4">
            We&apos;re thrilled to assist you every step of the way. Schedule a
            call with our team using this link:{' '}
            <a
              href="https://calendly.com/appmail"
              style={{ textDecoration: 'underline', color: 'blue' }}
              target="_blank"
              rel="noreferrer"
            >
              https://calendly.com/appmail
            </a>
          </Typography>
        </Card>
      </div>

      <Card className="HomeOnboardingLayout">
        <div className="flex flex-col w-full gap-2.5">
          <div className="GettingStartedHeader">
            <Typography variant="subtitle1" weight="semiBold">
              Getting started
            </Typography>
            <Typography variant="subtitle3">
              {[shopifyCompleted, audienceCompleted, campaignCompleted].reduce(
                (sum, item) => (item ? sum + 1 : sum),
                0
              )}{' '}
              of 4 completed
            </Typography>
          </div>
          {ONBOARDING_STEPS.map((step) => (
            <Accordion
              key={step.title}
              title={`${step.title}`}
              content={step.content}
              titleContent={step.titleContent}
            />
          ))}
        </div>
      </Card>
      <div className="SupportSection">
        <Typography variant="subtitle3" weight="semiBold">
          Support
        </Typography>
        <Typography variant="subtitle6">
          If you encounter any issues or have any general feedback please
          don&apos;t hesitate to contact us at{' '}
          <a style={{ color: 'blue' }} href="mailto:support@appmail.co">
            support@appmail.co
          </a>
        </Typography>
      </div>
      <ConnectShopifyModal
        isOpen={isConnectShopifyModalOpen}
        onClose={() => setIsConnectShopifyModalOpen(false)}
      />
    </div>
  );
};

export default HomeRouteLayout;
