import { useState } from 'react';

import { SelectComponent, SelectOptionType } from '@devTypes';

import Dropdown from '../Dropdown/Dropdown';

import SelectMenu from './SelectMenu';

import './styles.css';

// todo: update for all select states

const Select = <T extends SelectOptionType>({
  value,
  onChange,
  options,
  placeholder,
  className,
  renderDropdownHeader,
  selectedCheckMark = true,
}: SelectComponent<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectClose = () => setIsOpen(false);

  const handleSelectedValueChange = (option: T) => () => {
    if (onChange) {
      onChange(option);
    }
    handleSelectClose();
  };

  const handleSelectMenuToggle = () => setIsOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={isOpen}
      value={value}
      onOpen={handleSelectMenuToggle}
      onClose={handleSelectClose}
      dropdownContent={
        <SelectMenu
          options={options}
          onClick={handleSelectedValueChange}
          selectedValue={value}
          selectedCheckMark={selectedCheckMark}
        />
      }
      renderDropdownHeader={renderDropdownHeader}
      className={className}
      placeholder={placeholder}
    />
  );
};

export default Select;
