import grapesjs from 'grapesjs';

import { generateGrapesjsTextComponent } from '@utils/editor/components/text';
import { createEditorBlock } from '@utils/helpers';

const icon = `
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3333 17.3333V13.3333H34.6667V17.3333M20 34.6667H28M24 13.3333V34.6667" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

const label = createEditorBlock('Text', icon, 'text');

const content = generateGrapesjsTextComponent({
  content:
    'This is the text block. Write something captivating, engaging and meaningful.',
});

export const TextBlock: grapesjs.BlockOptions = {
  label,
  content,
  select: true,
};
