import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import grapesjs from 'grapesjs';

import { Button, Typography } from '@atoms';
import { MiniAppCard } from '@molecules';
import { ReactComponent as ShoppingCartIcon } from '@assets/icons/shopping-cart.svg';
import { EditorComponentTypes } from '@constants/editor';
import { ROUTES } from '@constants/routes';
import { shopifyAsyncThunks } from '@store/features/shopify';
import { usersSelectors } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { findBlockOnCanvas } from '@utils/helpers';
import { SyntheticEvent } from 'hoist-non-react-statics/node_modules/@types/react';

import './styles.css';

const EcommerceNotConnectedCardFooter = memo(() => {
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="subtitle4">Integration required</Typography>
      <Button
        variant="primary"
        title="Connect"
        onClick={() => navigate(ROUTES.dashboard.integrations)}
      />
    </>
  );
});

interface Props {
  editor: grapesjs.Editor | undefined;
}

const MiniApps = ({ editor }: Props) => {
  const userIntegrations = useAppSelector(usersSelectors.userIntegrations);

  const dispatch = useAppDispatch();

  const handleCreateEcommerceComponent = async () => {
    const isEcommerceBlockOnCanvas = !!findBlockOnCanvas(
      editor!,
      EditorComponentTypes.Ecommerce
    );

    if (isEcommerceBlockOnCanvas) {
      toast.error(
        'Only one E-commerce block is allowed to be placed on canvas'
      );
      return;
    }

    editor?.addComponents('<div data-gjs-type="e-commerce"></div>', { at: 0 });

    await dispatch(shopifyAsyncThunks.verifyShopifyConnectionThunk(null));
  };

  const handleCardClick = (e: SyntheticEvent) => {
    // @ts-ignore
    if (e.target.className.includes('Button')) {
      return;
    }

    if (!userIntegrations?.shopify) {
      toast.error('Shopify is not connected');
      return;
    }

    handleCreateEcommerceComponent();
  };

  return (
    <div className="MiniApps flexbox-column flexbox-gap-24px">
      <Typography variant="subtitle3">Add mini-app</Typography>
      <MiniAppCard
        title="E-commerce"
        icon={ShoppingCartIcon}
        isActive={!!userIntegrations?.shopify}
        footer={
          userIntegrations?.shopify ? (
            <Typography variant="subtitle4">In email, shopping cart</Typography>
          ) : (
            <EcommerceNotConnectedCardFooter />
          )
        }
        onClick={handleCardClick}
      />
    </div>
  );
};

export default MiniApps;
