/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Typography } from '@atoms';
import { Domain } from '@devTypes';
import ConfirmModal from '@organisms/ConfirmModal/ConfirmModal';
import { domainAsyncThunks, domainSelectors } from '@store/features/domains';
import { domainActions } from '@store/features/domains/slice';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import './styles.css';

interface MyDomainProps {
  domain: Domain;
  onViewDnsClick: () => void;
}

interface SenderComponentProps {
  senderId: string;
  senderStatus?: string;
  onDeleteSender: () => void;
}

const SenderComponent = ({
  senderId,
  senderStatus,
  onDeleteSender,
}: SenderComponentProps) => (
  <div className="SenderComponentContainer">
    <div className="DomainStatusesSection">
      <div className="DomainNameSection">
        <Typography className="SenderSettingLabel" variant="subtitle4">
          Sender ID
        </Typography>
        <Typography variant="subtitle4">{senderId}</Typography>
      </div>
      <div className="DomainStatus">
        <Typography className="SenderSettingLabel" variant="subtitle4">
          Status
        </Typography>

        <Typography
          variant="subtitle4"
          style={{ color: senderStatus === 'Verified' ? 'green' : 'black' }}
        >
          {senderStatus}
        </Typography>
      </div>
    </div>

    <div className="DomainButtons">
      <Button variant="secondary" onClick={() => {}} title="Verify sender" />
      <Button variant="text-error" onClick={onDeleteSender} title="Delete" />
    </div>
  </div>
);

const MyDomain = ({ domain, onViewDnsClick }: MyDomainProps) => {
  const dispatch = useAppDispatch();
  const validationResults = useAppSelector(
    domainSelectors.validationResultsSelector
  );
  const [isDeleteDomainModalOpen, setIsDeleteDomainModalOpen] = useState(false);
  const [isDeleteSenderModalOpen, setIsDeleteSenderModalOpen] = useState(false);
  const onVerifyDomainClick = async () => {
    if (domain?.id) {
      await dispatch(domainAsyncThunks.validateDomainThunk(domain?.id))
        .unwrap()
        .then((results) => {
          dispatch(
            domainActions.setValidationResults([
              ...validationResults,
              {
                domainId: domain.id,
                validationResults: results.validationResults,
              },
            ])
          );
        })
        .catch((err) => {
          toast.error(err.message.message);
        });
      await dispatch(domainAsyncThunks.getListOfDomainsThunk(null)).catch(
        (err) => {
          toast.error(err.message.message);
        }
      );
    }
  };

  const onDeleteDomain = async () => {
    if (domain?.id) {
      dispatch(domainAsyncThunks.deleteDomainThunk(domain?.id))
        .then(() => dispatch(domainActions.setValidationResults([])))
        .catch((err) => {
          toast.error(err.message.message);
        });
      await dispatch(domainAsyncThunks.getListOfDomainsThunk(null)).catch(
        (err) => {
          toast.error(err.message.message);
        }
      );
    }
  };

  const onDeleteSender = async (senderId: number) => {
    if (domain?.id) {
      await dispatch(
        domainAsyncThunks.deleteSenderThunk({ id: domain?.id, senderId })
      )
        .unwrap()
        .catch((err) => {
          toast.error(err.message.message);
        });
      await dispatch(domainAsyncThunks.getListOfDomainsThunk(null)).catch(
        (err) => {
          toast.error(err.message.message);
        }
      );
    }
  };

  useEffect(() => {
    if (domain?.status === 'Failed') {
      dispatch(domainAsyncThunks.validateDomainThunk(domain.id)).unwrap();
    }
  }, [domain]);

  return (
    <>
      <ConfirmModal
        isOpen={isDeleteDomainModalOpen}
        onClose={() => setIsDeleteDomainModalOpen(false)}
        onConfirm={onDeleteDomain}
        title="Deleting your Domain"
        subtitle={
          <span className="flex flex-col gap-2" style={{ maxWidth: 495 }}>
            <span>
              Are you sure you want to delete your Domain? Your Sender ID’s
              associated with this Domain will also be deleted.
            </span>
          </span>
        }
      />
      <div className="MyDomainCard">
        <div className="DomainContainer">
          <div className="DomainNameSection">
            <Typography className="SenderSettingLabel" variant="subtitle4">
              Domain
            </Typography>
            <Typography variant="subtitle4">{domain.domain}</Typography>
          </div>
          <div className="DomainDetailsContainer">
            <div className="DomainStatusesSection">
              <div className="DomainStatus">
                <Typography className="SenderSettingLabel" variant="subtitle4">
                  Status
                </Typography>
                <Typography
                  variant="subtitle4"
                  style={{
                    color: domain.status === 'Verified' ? 'green' : 'black',
                  }}
                >
                  {domain.status}
                </Typography>
              </div>
              <div className="DomainSmtp">
                <Typography className="SenderSettingLabel" variant="subtitle4">
                  SMTP
                </Typography>
                <Typography variant="subtitle4">{domain.smtp}</Typography>
              </div>
              <div className="DomainLastUsed">
                <Typography className="SenderSettingLabel" variant="subtitle4">
                  Last used
                </Typography>
                <Typography variant="subtitle4">
                  {domain?.lastUsedAt?.length
                    ? domain.lastUsedAt
                    : 'Not used yet'}
                </Typography>
              </div>
            </div>
            <div className="DomainButtons">
              <Button
                variant="secondary"
                onClick={onVerifyDomainClick}
                title="Verify domain"
              />
              <Button
                variant="secondary"
                onClick={onViewDnsClick}
                title="View DNS record"
              />
              <Button
                variant="text-error"
                onClick={() => setIsDeleteDomainModalOpen(true)}
                title="Delete"
              />
            </div>
          </div>
        </div>
        <div className="SendersSection">
          {domain.senders.map(({ address, status, id: senderId }) => (
            <>
              <SenderComponent
                key={address}
                senderId={address}
                senderStatus={status}
                onDeleteSender={() => setIsDeleteSenderModalOpen(true)}
              />
              <ConfirmModal
                isOpen={isDeleteSenderModalOpen}
                onClose={() => setIsDeleteSenderModalOpen(false)}
                onConfirm={() => {
                  if (senderId) {
                    onDeleteSender(senderId);
                  }
                }}
                title="Deleting your Sender ID"
                subtitle={
                  <span
                    className="flex flex-col gap-2"
                    style={{ maxWidth: 495 }}
                  >
                    <span>Are you sure you want to delete your Sender ID?</span>
                  </span>
                }
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyDomain;
