import { useFormContext } from 'react-hook-form';

import { Button, Typography } from '@atoms';
import { InputWithTitle } from '@molecules';
import { removeSpacesInString } from '@utils/helpers';

import './styles.css';

interface AddContactFormInterface {
  onClose: () => void;
  handleSave: () => void;
}

const formatPhoneNumber = (phoneNumber: string) => {
  const numbers = removeSpacesInString(phoneNumber);

  const formattedPhoneNumber = numbers.replace(
    /(\d{3})(\d{3})(\d)/,
    '$1 $2 $3'
  );

  return formattedPhoneNumber;
};

const AddContactForm = ({ onClose, handleSave }: AddContactFormInterface) => {
  const { control, setValue, getValues, trigger } = useFormContext();

  const handlePhoneNumberValidation = async () => {
    const isFieldValid = await trigger('phoneNumber');

    if (isFieldValid) {
      setValue('phoneNumber', formatPhoneNumber(getValues('phoneNumber')));
    }
  };

  return (
    <div className="AddContactDetailsForm">
      <Typography variant="subtitle4">Name</Typography>
      <div className="InputsSection">
        <InputWithTitle
          title=""
          control={control}
          name="firstName"
          placeholder="First name"
          className="AddContactDetailsForm-Input"
        />
        <InputWithTitle
          title=""
          control={control}
          name="lastName"
          placeholder="Last name"
          className="AddContactDetailsForm-Input"
        />
      </div>
      <Typography variant="subtitle4">Contact</Typography>
      <div className="InputsSection">
        <InputWithTitle
          title=""
          control={control}
          placeholder="Email"
          name="email"
          className="AddContactDetailsForm-Input"
        />
        <InputWithTitle
          title=""
          control={control}
          placeholder="Phone (include country code)"
          name="phoneNumber"
          onBlur={handlePhoneNumberValidation}
          startIcon={
            <Typography
              className="h-full bg-gray-50 left-0 py-3 px-2.5 border-r border-gray-300 rounded-l-lg text-gray-main"
              variant="subtitle4"
            >
              +
            </Typography>
          }
          className="AddContactDetailsForm-Input  [&_.Input-with-start-icon]:pl-[40px] [&>div>div>p]:!left-0"
        />
      </div>
      <Typography variant="subtitle4">Address</Typography>
      <div className="InputsAddressSection">
        <div className="InputsSection">
          <InputWithTitle
            title=""
            control={control}
            name="streetAddress"
            className="AddContactDetailsForm-Input"
            placeholder="Street Address"
          />
          <InputWithTitle
            title=""
            control={control}
            name="streetLine2"
            className="AddContactDetailsForm-Input"
            placeholder="Street Line 2(Optional)"
          />
        </div>
        <div className="InputsSection">
          <InputWithTitle
            title=""
            control={control}
            name="city"
            placeholder="City"
            className="AddContactDetailsForm-Input"
          />
          <InputWithTitle
            title=""
            control={control}
            name="zipCode"
            placeholder="Zip Code"
            className="AddContactDetailsForm-Input"
          />
        </div>
        <InputWithTitle
          title=""
          control={control}
          name="country"
          placeholder="Country"
          className="AddContactDetailsForm-Input"
        />
      </div>
      <div className="InputsSection" style={{ justifyContent: 'flex-end' }}>
        <Button onClick={onClose} title="Cancel" variant="secondary" />
        <Button onClick={handleSave} title="Save" variant="primary" />
      </div>
    </div>
  );
};

export default AddContactForm;
