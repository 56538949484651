import Select from '@molecules/Select/Select';
import SelectWithTitle from '@molecules/SelectWithTitle/SelectWithTitle';

import './styles.css';

interface Props {
  title?: string;
  value: any;
  options: any[];
  onChange?: (option: any) => void;
}

const EcommerceSelect = ({ title, value, options, onChange }: Props) =>
  typeof title === 'string' ? (
    <SelectWithTitle
      className="EcommerceSelect"
      title={title}
      value={value}
      options={options}
      onChange={onChange}
      selectedCheckMark={false}
    />
  ) : (
    <Select
      className="EcommerceSelect"
      value={value}
      options={options}
      onChange={onChange}
      selectedCheckMark={false}
    />
  );

export default EcommerceSelect;
