import type { RootState } from '../../store';

const getMeOrganizationData = (state: RootState) => ({
  title: state.organizations.getMeOrganizationData.title,
  imageUrl: state.organizations.getMeOrganizationData.imageUrl,
});

const isGetMeOrganizationDataLoading = (state: RootState) =>
  state.organizations.getMeOrganizationData.isLoading;

export default { getMeOrganizationData, isGetMeOrganizationDataLoading };
