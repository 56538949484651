/* eslint-disable no-param-reassign */
import { TemplateObject } from '@devTypes';
import { createSlice } from '@reduxjs/toolkit';

import {
  createTemplateThunk,
  fetchPresetTemplatesThunk,
  fetchTemplatesThunk,
  getTemplateByIdThunk,
  updateTemplateThunk,
} from './asyncThunks';

type LoadingState = {
  isLoading: boolean;
};

interface CreateTemplateState {
  templateObject: TemplateObject | null;
}

interface UpdateTemplateState extends LoadingState {
  templateObject: TemplateObject | null;
}

interface FetchTemplateState extends LoadingState {
  templates: TemplateObject[] | null;
}

interface FetchPresetTemplatesState extends LoadingState {
  templates: TemplateObject[] | null;
}

interface Template extends TemplateObject {
  id: number;
  backgroundStyles: string;
}

interface TemplateState extends LoadingState {
  data: Template | null;
}

interface InitialState extends LoadingState {
  createTemplate: CreateTemplateState;
  updateTemplate: UpdateTemplateState;
  listOfTemplates: FetchTemplateState;
  template: TemplateState;
  presetTemplates: FetchPresetTemplatesState;
}

const initialState: InitialState = {
  isLoading: false,
  createTemplate: { templateObject: null },
  updateTemplate: { isLoading: false, templateObject: null },
  listOfTemplates: { isLoading: false, templates: [] },
  template: {
    data: null,
    isLoading: false,
  },
  presetTemplates: { isLoading: false, templates: [] },
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    resetTemplateState(state) {
      state.template = initialState.template;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTemplateThunk.fulfilled, (state, { payload }) => ({
      ...state,
      createTemplate: {
        templateObject: payload,
      },
    }));
    builder.addCase(createTemplateThunk.pending, (state) => ({
      ...state,
      createTemplate: {
        ...state.createTemplate,
      },
    }));
    builder.addCase(updateTemplateThunk.fulfilled, (state, { payload }) => ({
      ...state,
      updateTemplate: {
        isLoading: false,
        templateObject: payload,
      },
    }));
    builder.addCase(updateTemplateThunk.pending, (state) => ({
      ...state,
      updateTemplate: {
        ...state.updateTemplate,
        isLoading: true,
      },
    }));
    builder.addCase(fetchTemplatesThunk.fulfilled, (state, { payload }) => ({
      ...state,
      listOfTemplates: {
        isLoading: false,
        templates: payload,
      },
    }));
    builder.addCase(fetchTemplatesThunk.pending, (state) => ({
      ...state,
      listOfTemplates: {
        ...state.listOfTemplates,
        isLoading: true,
      },
    }));
    builder.addCase(getTemplateByIdThunk.fulfilled, (state, { payload }) => ({
      ...state,
      template: {
        ...state.template,
        isLoading: false,
        data: payload,
      },
    }));
    builder.addCase(getTemplateByIdThunk.pending, (state) => ({
      ...state,
      template: {
        ...state.template,
        isLoading: true,
      },
    }));
    builder.addCase(
      fetchPresetTemplatesThunk.fulfilled,
      (state, { payload }) => ({
        ...state,
        presetTemplates: {
          isLoading: false,
          templates: payload,
        },
      })
    );
    builder.addCase(fetchPresetTemplatesThunk.pending, (state) => ({
      ...state,
      presetTemplates: {
        ...state.presetTemplates,
        isLoading: true,
      },
    }));
  },
});

export default templateSlice.reducer;

export const templateActions = templateSlice.actions;
