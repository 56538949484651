import { useEffect } from 'react';
import ReactFbq from 'react-fbq';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { NON_PROD_HOSTNAMES } from '@constants/common';
import { persistor, store } from '@store';

import Router from './router';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    if (!NON_PROD_HOSTNAMES.includes(window.location.hostname)) {
      // @ts-ignore
      ReactFbq.track({
        title: 'ViewContent',
      });
    }
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProSidebarProvider>
          <Router />
          <ToastContainer
            autoClose={3500}
            position="bottom-right"
            theme="dark"
          />
        </ProSidebarProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
