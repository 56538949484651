import CampaignsTable from './CampaignsTable';

import './styles.css';

const AllCampaignsTemplate = () => (
  <div className="AllCampaignsTemplate">
    <CampaignsTable />
  </div>
);

export default AllCampaignsTemplate;
