import { useEffect, useState } from 'react';
import { toColor, useColor } from 'react-color-palette';
import grapesjs from 'grapesjs';

import { Typography } from '@atoms';
import { ColorPickerInput } from '@molecules';
import {
  DEFAULT_BUTTON_HOVER_BORDER_COLOR,
  DEFAULT_BUTTON_HOVER_FILL_COLOR,
  DEFAULT_BUTTON_HOVER_TEXT_COLOR,
} from '@constants/editor';

interface Props {
  editor: grapesjs.Editor;
  buttonComponent: grapesjs.Component;
  onClose: () => void;
}

const EditorButtonBlockSettingsHoverEffect = ({
  editor,
  buttonComponent,
  onClose,
}: Props) => {
  const [color, setColor] = useColor('hex', '');
  const [backgroundColor, setBackgroundColor] = useColor('hex', '');
  const [borderColor, setBorderColor] = useColor('hex', '');
  const [currentCssSelector, setCurrentCssSelector] = useState<string>('');

  useEffect(() => {
    const state = buttonComponent.getAttributes();
    const componentId = buttonComponent.getId();

    setColor(
      toColor('hex', state?.hoverTextColor || DEFAULT_BUTTON_HOVER_TEXT_COLOR)
    );
    setBorderColor(
      toColor(
        'hex',
        state?.hoverBorderColor || DEFAULT_BUTTON_HOVER_BORDER_COLOR
      )
    );
    setBackgroundColor(
      toColor('hex', state?.hoverFillColor || DEFAULT_BUTTON_HOVER_FILL_COLOR)
    );

    setCurrentCssSelector(`#${componentId}:hover`);
  }, [buttonComponent]);

  const handleHoverColorChange =
    (attr: string, style: string) => (colorValue: string) => {
      buttonComponent.addAttributes({ [attr]: colorValue });
      editor.Css.setRule(currentCssSelector, {
        color: color.hex,
        'border-color': borderColor.hex,
        'background-color': backgroundColor.hex,
        [style]: colorValue,
      });
    };

  const handleRemoveHoverEffect = () => {
    buttonComponent.removeAttributes([
      'hoverBorderColor',
      'hoverFillColor',
      'hoverTextColor',
    ]);
    editor.Css.setRule(currentCssSelector, {});

    onClose();
  };

  return (
    <div className="EditorButtonBlockSettingsHoverEffect">
      <Typography variant="subtitle3">Hover effect</Typography>
      <ColorPickerInput
        color={color}
        setColor={setColor}
        onChange={handleHoverColorChange('hoverTextColor', 'color')}
        title="Hover text color"
      />
      <ColorPickerInput
        color={backgroundColor}
        setColor={setBackgroundColor}
        onChange={handleHoverColorChange('hoverFillColor', 'background-color')}
        title="Hover fill color"
      />
      <ColorPickerInput
        color={borderColor}
        setColor={setBorderColor}
        onChange={handleHoverColorChange('hoverBorderColor', 'border-color')}
        title="Hover border color"
      />
      <Typography
        className="EditorButtonBlockSettingsHoverEffect-RemoveHoverButton"
        variant="subtitle4"
        onClick={handleRemoveHoverEffect}
      >
        Remove hover
      </Typography>
    </div>
  );
};

export default EditorButtonBlockSettingsHoverEffect;
