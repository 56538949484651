import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import clsx from 'clsx';

import { Button, Checkbox } from '@atoms';
import { MoreOptionsPopup } from '@molecules';
import { PopupListItemComponent } from '@devTypes';
import TableCell from '@organisms/Table/TableCell';
import { secondCellColumnStyle } from '@utils/helpers';

export interface RowData {
  [key: string]: any;
  id: number;
}

export type TableRowMoreOptionsPopupProps = {
  selectOptions: Array<PopupListItemComponent>;
};

interface Props<T extends RowData> {
  withCheckbox?: boolean;
  rowItem: T;
  columnWidths?: Array<string>;
  renderRowData: (item: T) => Array<ReactNode>;
  onCheckboxClick?: (id: number) => void;
  isCheckboxChecked?: boolean;
  moreOptionsPopup?: TableRowMoreOptionsPopupProps;
  setActiveRow?: Dispatch<SetStateAction<T | null>>;
  onTableRowClick?: (item: any) => void;
  buttonProps?: {
    isVisible: boolean;
    title: string;
    onClick: (id: number) => void;
  };
  hoveredItemId: number | null;
  onTableRowHoverEnter: (id: number) => void;
}

const TableRow = <T extends RowData>({
  withCheckbox,
  rowItem,
  columnWidths,
  renderRowData,
  isCheckboxChecked,
  onCheckboxClick,
  moreOptionsPopup,
  setActiveRow = () => {},
  onTableRowClick,
  buttonProps,
  hoveredItemId,
  onTableRowHoverEnter,
}: Props<T>) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePopupClose = () => setIsPopupOpen(false);

  const handlePopupItemClick =
    (row: T) =>
    (title: string, callback: Function = () => {}) =>
    () => {
      setActiveRow(row);
      callback(row);
      handlePopupClose();
    };

  return (
    <div
      className={clsx('TableRow', isPopupOpen && 'TableRow-selected')}
      onClick={onTableRowClick}
      role="button"
      tabIndex={0}
      onMouseEnter={() => onTableRowHoverEnter(rowItem.id)}
    >
      {onCheckboxClick && withCheckbox && (
        <TableCell className="CheckboxCell">
          <Checkbox
            isChecked={!!isCheckboxChecked}
            onChange={() => onCheckboxClick(rowItem.id)}
          />
        </TableCell>
      )}
      {renderRowData(rowItem).map((cell, index) => (
        <TableCell
          key={Math.random()}
          style={
            columnWidths?.[index]
              ? { width: columnWidths[index], flexShrink: 0 }
              : undefined
          }
          className={clsx(
            'TableCell',
            secondCellColumnStyle(cell?.toString()),
            columnWidths?.[index] ? 'Text-Ellipsis' : undefined
          )}
        >
          {cell}
        </TableCell>
      ))}
      {buttonProps &&
        rowItem.report !== null &&
        hoveredItemId === rowItem.id && (
          <TableCell className="ButtonCell">
            <Button
              variant="secondary"
              title={buttonProps.title}
              onClick={() => buttonProps.onClick(rowItem.report.id)}
            />
          </TableCell>
        )}
      {moreOptionsPopup && (
        <TableCell className="OptionsCell">
          <MoreOptionsPopup
            isOpen={isPopupOpen}
            setIsOpen={setIsPopupOpen}
            items={moreOptionsPopup.selectOptions}
            onClick={handlePopupItemClick(rowItem)}
          />
        </TableCell>
      )}
    </div>
  );
};

export default TableRow;
