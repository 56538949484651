import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button, Typography } from '@atoms';
import { Dropzone, Modal } from '@molecules';
import { ReactComponent as AddManually } from '@assets/icons/add-manually.svg';
import { ReactComponent as ImportFromCsv } from '@assets/icons/upload-cloud.svg';
import { FIVE_MEGABYTES } from '@constants/common';
import { AudienceImportType } from '@devTypes';
import AddContactForm from '@organisms/AddContactForm/AddContactForm';
import CsvDropzoneContent from '@organisms/CsvDropzoneContent/CsvDropzoneContent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedImportType: AudienceImportType | string;
  onSaveForm: () => void;
  onSaveFile: (csvFile: File | undefined) => void;
}

export const FormAudienceSelectOption = {
  title: 'Add manually',
  icon: AddManually,
};

export const CsvAudienceSelectOption = {
  title: 'Import from CSV',
  icon: ImportFromCsv,
};

const csvDropZone = { 'text/*': ['.csv'] };

const AddContactsModal = ({
  isOpen,
  onClose,
  selectedImportType,
  onSaveFile,
  onSaveForm,
}: Props) => {
  const [importedFile, setImportedFile] = useState<File>();
  const { reset } = useFormContext();
  const handleDrop = (acceptedFiles: File[]) => {
    setImportedFile(acceptedFiles[0]);
  };

  const handleClose = () => {
    setImportedFile(undefined);
    reset();
    onClose();
  };

  const handleFileSaveSubmit = () => {
    onSaveFile(importedFile);
    setImportedFile(undefined);
  };

  return (
    <Modal className="max-w-[820px]" isOpen={isOpen} onClose={handleClose}>
      {selectedImportType === FormAudienceSelectOption ? (
        <>
          <Typography variant="subtitle1">Create a new contact</Typography>
          <AddContactForm onClose={handleClose} handleSave={onSaveForm} />
        </>
      ) : (
        <>
          <Typography variant="h6">Upload CSV file</Typography>
          <div className="UploadCsv-Container">
            <CsvDropzoneContent />
            <div className="Dropzone-Container">
              {importedFile ? (
                <div className="flex flex-1 self-center items-center justify-center border border-dashed border-gray-300 text-center h-full rounded-lg">
                  <Typography variant="subtitle3">
                    {importedFile.name}
                  </Typography>
                </div>
              ) : (
                <Dropzone
                  subtitle="Supported file types - CSV"
                  title="Drag and drop file"
                  maxSize={FIVE_MEGABYTES}
                  maxFiles={1}
                  onDrop={handleDrop}
                  dropZoneType={csvDropZone}
                  buttonVariant="primary"
                />
              )}
            </div>
          </div>
          <div style={{ float: 'right' }}>
            <Button
              title={importedFile ? 'Upload' : 'Confirm'}
              onClick={handleFileSaveSubmit}
              variant="primary"
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddContactsModal;
