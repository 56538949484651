import grapesjs from 'grapesjs';

import { EDITOR_CONTAINERS } from '@constants/editor';
import { withCssClassPrefix } from '@utils/helpers';

import {
  ButtonBlock,
  CarouselBlock,
  ColumnsBlock,
  ImageBlock,
  LogoBlock,
  SpacerBlock,
  TextBlock,
} from './blocks/previews';
import { DividerBlock } from './blocks/previews/dividerBlock';

export const blockManager: grapesjs.BlockManagerConfig = {
  appendTo: withCssClassPrefix(EDITOR_CONTAINERS.blockManager),
  blocks: [
    TextBlock,
    DividerBlock,
    SpacerBlock,
    ColumnsBlock,
    ButtonBlock,
    ImageBlock,
    CarouselBlock,
    LogoBlock,
  ],
};
