/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface BackgroundState {
  color: string;
  imageUrl: string;
  isImagePattern: boolean;
}

const initialState: BackgroundState = {
  color: '',
  imageUrl: '',
  isImagePattern: false,
};

const backgroundSlice = createSlice({
  name: 'background',
  initialState,
  reducers: {
    setBackgroundState(state, { payload }) {
      state.color = payload.color;
      state.imageUrl = payload.imageUrl;
      state.isImagePattern = payload.isImagePattern;
    },
    resetState() {
      return initialState;
    },
  },
});

export default backgroundSlice.reducer;

export const backgroundActions = { ...backgroundSlice.actions };
