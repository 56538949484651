import DeviceViewTab, { Tab } from './DeviceViewTab';

interface Props {
  tabs: Tab[];
  selectedTab: Tab;
  onChange: (tab: Tab) => void;
}

const DeviceViewTabs = ({ tabs, selectedTab, onChange }: Props) => (
  <div className="DeviceViewTabs">
    {tabs.map((tab, index) => {
      const firstTabClassName = index === 0 ? 'DeviceViewTab-first' : '';
      const lastTabClassName =
        index === tabs.length - 1 ? 'DeviceViewTab-last' : '';

      const isSelected = selectedTab.id === tab.id;
      return (
        <DeviceViewTab
          tab={tab}
          isSelected={isSelected}
          key={tab.id}
          onChange={onChange}
          className={firstTabClassName || lastTabClassName}
        />
      );
    })}
  </div>
);

export default DeviceViewTabs;
