import { Slider } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';
import DefaultInput from '@molecules/DefaultInput/DefaultInput';
import EditorBlockSettingContainer from '@molecules/EditorBlockSettingContainer/EditorBlockSettingContainer';

import './styles.css';

interface Props {
  title: string;
  startIcon?: SvgFunctionComponent;
  value: number;
  max?: number;
  min?: number;
  onChange: (value: number) => void;
}

const SliderWithInput = ({
  title,
  startIcon,
  value,
  onChange,
  max = 150,
  min = 0,
}: Props) => {
  const handleSliderValue = (sliderValue: number | number[]) => {
    onChange(sliderValue as number);
  };

  const handleInputValue = (e: any) => {
    let { value: newValue } = e.target;

    if (newValue > max) {
      newValue = max;
    }

    if (newValue < min) {
      newValue = min;
    }

    onChange(newValue);
  };

  return (
    <EditorBlockSettingContainer title={title} startIcon={startIcon}>
      <div className="SliderWithInput">
        <Slider
          max={max}
          min={min}
          onChange={handleSliderValue}
          value={value}
        />
        <DefaultInput
          value={value}
          onChange={handleInputValue}
          endIcon={<span className="SliderWithInput-EndIcon">px</span>}
        />
      </div>
    </EditorBlockSettingContainer>
  );
};

export default SliderWithInput;
