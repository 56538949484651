import axios, { AxiosError } from 'axios';

import { BASE_URL } from '@constants/api';
import { ROUTES } from '@constants/routes';
import { deleteAllCookies, getCookie } from '@utils/helpers';
import storage from '@utils/storage';

type MethodsType = 'get' | 'post' | 'put' | 'delete' | 'patch';

export interface AxiosRequestConfig<T> {
  url?: string;
  method?: MethodsType;
  data?: T | null;
  params?: Record<string, any>;
  headers?: any;
}

export interface IAxiosError {
  message: string;
  status: number | undefined;
  headers?: any;
}

export type IAxiosResponse<T> =
  | { error: IAxiosError; data: null }
  | { data: T; error: null };

const wrappedAxiosRequest = async <ReqType, RespType>({
  url,
  method,
  data = null,
  headers = {},
  params = {},
}: AxiosRequestConfig<ReqType>) => {
  try {
    const storageToken = storage.getToken();
    const cookieToken = getCookie('token');

    const axiosWithAccess = axios.create({
      baseURL: BASE_URL,
      headers: {
        // eslint-disable-next-line
        Authorization: 'Bearer ' + (storageToken ?? cookieToken),
        'Cache-Control': 'no-cache',
      },
    });

    const response = await axiosWithAccess({
      url,
      method,
      data,
      headers,
      params,
    });

    const responseObj: IAxiosResponse<RespType> = {
      data: response.data,
      error: null,
    };

    return responseObj;
  } catch (error) {
    // eslint-disable-next-line
    const handledError = errorHandler(error);
    const errorObj: IAxiosResponse<RespType> = {
      // @ts-ignore
      error: handledError,
      data: null,
    };

    return errorObj;
  }
};

const errorHandler = (error: any) => {
  const axiosError = error as AxiosError;
  if (typeof error === 'string') {
    return {
      message: error,
      status: 401,
    };
  }

  if (axiosError.response?.status === 401) {
    storage.clearToken();
    deleteAllCookies();
    window.location.replace(ROUTES.signIn);
  }

  return {
    message: axiosError.response?.data,
    status: axiosError.response?.status,
    headers: axiosError.response?.headers,
  };
};

export { wrappedAxiosRequest };
