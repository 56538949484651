import { Divider } from '@atoms';
import { EditorBlockSettingsComponent } from '@devTypes';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';

const EditorImageBlockSettingsDesign = ({
  selectedComponent,
}: EditorBlockSettingsComponent) => (
  <div>
    <EditorBlockBackgroundSettings
      selectedComponent={selectedComponent}
      titleVariant="regular"
    />
    <Divider />
    <EditorBlockPaddingSettings
      selectedComponent={selectedComponent}
      min={0}
      max={200}
    />
  </div>
);

export default EditorImageBlockSettingsDesign;
