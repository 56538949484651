import { ReactNode, useRef } from 'react';
import clsx from 'clsx';

import { Button, Icon, Typography } from '@atoms';
import { RedirectLabel } from '@molecules';
import { ReactComponent as AppMailLogo } from '@assets/icons/AppMailLogo.svg';
// import { ReactComponent as FacebookIcon } from '@assets/icons/facebook-icon.svg';
// import { ReactComponent as GoogleIcon } from '@assets/icons/google-icon.svg';
import { ROUTES } from '@constants/routes';

import AuthPageTemplate from '../AuthPageTemplate/AuthPageTemplate';

import './styles.css';

const authTypeOptions = {
  signUp: {
    title: 'Sign up',
    subtitle: 'Create your account',
    submit: 'Sign up',
    googleAuthSubmit: 'Sign up with Google',
    facebookAuthSubmit: 'Sign up with Facebook',
    redirectLabel: {
      text: 'Already have an account?',
      link: 'Sign in',
      to: ROUTES.signIn,
    },
  },
  signIn: {
    title: 'Sign in',
    subtitle: 'Welcome back! Please enter your details',
    submit: 'Sign in',
    googleAuthSubmit: 'Sign in with Google',
    facebookAuthSubmit: 'Sign in with Facebook',
    redirectLabel: {
      text: 'Don’t have an account?',
      link: 'Sign up',
      to: ROUTES.signUp,
    },
  },
  confirmPassword: {
    title: 'Create new password',
    subtitle: 'Your password was successfully reset',
    submit: 'Submit',
    redirectLabel: {
      text: 'Don’t have an account?',
      link: 'Sign up',
      to: ROUTES.signUp,
    },
  },
};

const ELEVATE_BUSSINESS_TIPS = [
  {
    title: 'Sell more effectively',
    subtitle:
      'Boost your shopping carts and sales by enabling your customers to purchase products directly from your marketing emails.',
  },
  {
    title: '3X Conversions & CTR',
    subtitle:
      'See your business thrive with 3X higher conversions compared to static emails.',
  },
  {
    title: 'Simplify the Customer Journey',
    subtitle:
      'Boosting sales by up to 35% through a streamlined customer journey that reduces multi-platform hopping by 40%.',
  },
  {
    title: 'Turn Browsers into Shoppers',
    subtitle:
      'Convert your customers from casual browsers into eager shoppers from the moment they open up their email.',
  },
  {
    title: 'Real-time, relevant content',
    subtitle:
      'Synchronize your email campaign with your e-commerce platform to display inventory availability, and real-time dynamic pricing.',
  },
  {
    title: 'Integrated with Shopify',
    subtitle:
      'Effortlessly integrate your Shopify store to retrieve product content and real inventory.',
  },
];

const SellMoreBlock = (title: string, subtitle: string) => (
  <div className="SellMoreBlock">
    <Typography variant="subtitle3" weight="semiBold">
      {title}
    </Typography>
    <Typography variant="subtitle4" weight="regular">
      {subtitle}
    </Typography>
  </div>
);

type AuthType = 'signIn' | 'signUp' | 'confirmPassword';

interface Props {
  children: ReactNode;
  authType: AuthType;
  onSubmit: () => void;
  // onGoogleAuthSubmit: () => void;
  // onFacebookAuthSubmit: () => void;
}

const SignInSignUpTemplate = ({
  children,
  authType,
  onSubmit,
}: // onGoogleAuthSubmit,
// onFacebookAuthSubmit,
Props) => {
  const signUpArea = useRef<null | HTMLDivElement>(null);
  return (
    <AuthPageTemplate>
      <div className="SignInSignUpTemplate">
        <div>
          <Icon
            SVG={AppMailLogo}
            width={153}
            height={28}
            className="SignInSignUpTemplate-Logo"
          />
        </div>
        {authType === 'signUp' && (
          <div className="SignUp-ProductDemo">
            <Typography variant="h6">
              Changing the Email Marketing Game with Shoppable Emails
            </Typography>
            <img
              alt="Product demo"
              src="https://appmail-images.s3.amazonaws.com/ProductDemo.png"
              className="DemoImage"
            />
          </div>
        )}
        {authType === 'signIn' && (
          <>
            <Typography
              variant="subtitle3"
              weight="semiBold"
              style={{ color: '#0074f1' }}
            >
              In Beta Phase
            </Typography>
            <Typography
              variant="subtitle6"
              style={{ paddingTop: 12, paddingBottom: 24 }}
              className="InBetaSubtitle"
            >
              While many of our features are operational, some are still a work
              in progress as we continue refining the platform. Your feedback
              during this phase will play a pivotal role in shaping and
              enhancing these features. Please don&apos;t hesitate to contact
              our team to share your insights and suggestions. Your input
              matters!
            </Typography>
          </>
        )}

        {authType === 'signUp' ? (
          <div ref={signUpArea}>
            <Typography
              variant="h6"
              weight="semiBold"
              style={{ paddingBottom: 15 }}
            >
              Get Started For Free
            </Typography>
            <Typography variant="subtitle2" weight="bold">
              {authTypeOptions[authType].title}
            </Typography>
            <Typography variant="subtitle3">No credit card required</Typography>
          </div>
        ) : (
          <Typography variant="h4">
            {authTypeOptions[authType].title}
          </Typography>
        )}

        {authType === 'signIn' && (
          <Typography
            variant="subtitle3"
            className="SignInSignUpTemplate-Subtitle-Mobile"
          >
            The AppMail platform is available on Desktop only!
          </Typography>
        )}
        {authType !== 'signIn' && authType !== 'signUp' ? (
          <Typography
            variant="subtitle3"
            className="SignInSignUpTemplate-Subtitle"
          >
            {authTypeOptions[authType].subtitle}
          </Typography>
        ) : (
          <br />
        )}
        {children}
        <Button
          title={authTypeOptions[authType].submit}
          variant="primary"
          className={clsx('SignInSignUpTemplate-Submit', authType)}
          onClick={onSubmit}
        />

        {authType === 'signUp' && (
          <div className="ElevateBusinessContainer" style={{ marginTop: 25 }}>
            <Typography
              variant="h6"
              weight="semiBold"
              style={{ paddingBottom: 15 }}
            >
              Elevate Your Business!
            </Typography>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="subtitle3" weight="semiBold">
                {ELEVATE_BUSSINESS_TIPS[0].title}
              </Typography>
              <Typography variant="subtitle4">
                {ELEVATE_BUSSINESS_TIPS[0].subtitle}
              </Typography>
            </div>
            <img
              alt="Amp Mobile Preview"
              src="https://appmail-images.s3.amazonaws.com/AmpMobilePreview.png"
            />
            <div>
              {ELEVATE_BUSSINESS_TIPS.map(
                (
                  { title, subtitle }: typeof ELEVATE_BUSSINESS_TIPS[0],
                  index: number
                ) => index > 0 && SellMoreBlock(title, subtitle)
              )}
            </div>
            <Button
              title="↑ Scroll to Sign Up"
              variant="primary"
              className={clsx('SignInSignUpTemplate-Submit', authType)}
              onClick={() =>
                signUpArea.current && signUpArea.current.scrollIntoView()
              }
            />
          </div>
        )}
        {/* <Button */}
        {/*  startIcon={<Icon SVG={GoogleIcon} height={24} width={24} />} */}
        {/*  title={authTypeOptions[authType].googleAuthSubmit} */}
        {/*  variant="secondary" */}
        {/*  onClick={onGoogleAuthSubmit} */}
        {/* /> */}
        {/* <Button */}
        {/*  startIcon={<Icon SVG={FacebookIcon} height={24} width={24} />} */}
        {/*  title={authTypeOptions[authType].facebookAuthSubmit} */}
        {/*  variant="secondary" */}
        {/*  onClick={onFacebookAuthSubmit} */}
        {/* /> */}
        <RedirectLabel {...authTypeOptions[authType].redirectLabel} />
      </div>
    </AuthPageTemplate>
  );
};

export default SignInSignUpTemplate;
