import { useState } from 'react';
import { Control } from 'react-hook-form';
import clsx from 'clsx';

import { Button, Loader } from '@atoms';
import InputBox from '@organisms/GenerateAISettings/InputBox';
import { Action } from '@store/features/generativeAI/api';

export type InputType =
  | 'prompt'
  | 'input'
  | 'outputForPrompt'
  | 'outputForRewrite';
export type TextStateDirection = 'prev' | 'next';

export type TextStateIndex = {
  input: number;
  outputForPrompt: number;
  outputForRewrite: number;
  prompt: number;
};

interface Props {
  control: Control<any, any>;
  onGenerateOutputClick: () => void;
  onCancelClick: () => void;
  onUseOutputCopyClick: () => void;
  onShowPrevOrNextTextClick: (
    inputType: InputType,
    direction: TextStateDirection
  ) => () => void;
  actionId: Action;
  currentStateNumber: TextStateIndex;
  totalStatesAmount: TextStateIndex;
  isResponseGeneratedForInput: boolean;
  isResponseGeneratedForPrompt: boolean;
  isLoadingOutput: boolean;
  handleInputCancelClick: (name: string) => void;
}

const TextAreasSection = ({
  control,
  actionId,
  handleInputCancelClick,
  onGenerateOutputClick,
  onCancelClick,
  onUseOutputCopyClick,
  onShowPrevOrNextTextClick,
  isResponseGeneratedForInput,
  isResponseGeneratedForPrompt,
  isLoadingOutput,
  currentStateNumber,
  totalStatesAmount,
}: Props) => {
  const [isInputCopyEditEnabled, setIsInputCopyEditEnabled] = useState(false);
  const [isPromptCopyEditEnabled, setIsPromptCopyEditEnabled] = useState(true);

  const handleInputCopyEditClick = () => {
    setIsInputCopyEditEnabled(true);
  };

  const onCancelClickForInput = (name: string) => {
    handleInputCancelClick(name);
    setIsInputCopyEditEnabled(false);
    setIsPromptCopyEditEnabled(false);
  };

  const handlePromptCopyEditClick = () => {
    setIsPromptCopyEditEnabled(true);
  };

  const onGenerateOutputClickHandler = () => {
    if (actionId === 'Create') {
      setIsInputCopyEditEnabled(false);
      setIsPromptCopyEditEnabled(false);
    }
    onGenerateOutputClick();
  };

  const onSaveAndSubmitClick = () => {
    setIsInputCopyEditEnabled(false);
    setIsPromptCopyEditEnabled(false);
    onGenerateOutputClickHandler();
  };

  return (
    <div>
      <InputBox
        title="Input copy"
        name="input"
        control={control}
        isTextAreaEnabled={isInputCopyEditEnabled}
        onSaveAndSubmitClick={onSaveAndSubmitClick}
        onEditClick={handleInputCopyEditClick}
        onCancelClickForInput={onCancelClickForInput}
        onShowPrevTextClick={onShowPrevOrNextTextClick('input', 'prev')}
        onShowNextTextClick={onShowPrevOrNextTextClick('input', 'next')}
        currentTextNumber={currentStateNumber.input}
        totalTextsAmount={totalStatesAmount.input}
        editButtonState="enabled"
        className={clsx(actionId !== 'Rewrite' && 'display-hidden')}
      />
      <InputBox
        title="Prompt"
        name="prompt"
        control={control}
        onEditClick={handlePromptCopyEditClick}
        isTextAreaEnabled={isPromptCopyEditEnabled}
        onSaveAndSubmitClick={onSaveAndSubmitClick}
        onCancelClickForInput={onCancelClickForInput}
        onShowPrevTextClick={onShowPrevOrNextTextClick('prompt', 'prev')}
        onShowNextTextClick={onShowPrevOrNextTextClick('prompt', 'next')}
        currentTextNumber={currentStateNumber.prompt}
        totalTextsAmount={totalStatesAmount.prompt}
        placeholder="Please provide clear and concise instructions for the desired output."
        editButtonState={
          control.getFieldState('prompt').isTouched ? 'enabled' : 'disabled'
        }
        className={clsx(actionId !== 'Create' && 'display-hidden')}
      />
      {isLoadingOutput && <Loader height={190} />}
      {((actionId === 'Rewrite' && isResponseGeneratedForInput) ||
        (actionId === 'Create' && isResponseGeneratedForPrompt)) &&
      !isLoadingOutput ? (
        <>
          {totalStatesAmount.outputForPrompt > 0 && (
            <InputBox
              title="Output copy"
              name="outputForPrompt"
              control={control}
              onShowPrevTextClick={onShowPrevOrNextTextClick(
                'outputForPrompt',
                'prev'
              )}
              onShowNextTextClick={onShowPrevOrNextTextClick(
                'outputForPrompt',
                'next'
              )}
              currentTextNumber={currentStateNumber.outputForPrompt}
              totalTextsAmount={totalStatesAmount.outputForPrompt}
              className={clsx(
                'mt-[32px] ',
                actionId === 'Rewrite' && 'display-hidden'
              )}
            />
          )}

          <InputBox
            title="Output copy"
            name="outputForRewrite"
            control={control}
            onShowPrevTextClick={onShowPrevOrNextTextClick(
              'outputForRewrite',
              'prev'
            )}
            onShowNextTextClick={onShowPrevOrNextTextClick(
              'outputForRewrite',
              'next'
            )}
            currentTextNumber={currentStateNumber.outputForRewrite}
            totalTextsAmount={totalStatesAmount.outputForRewrite}
            className={clsx(
              'mt-[32px] ',
              actionId === 'Create' && 'display-hidden'
            )}
          />
          {(actionId === 'Create' && totalStatesAmount.outputForPrompt > 0) ||
            (actionId === 'Rewrite' &&
              totalStatesAmount.outputForRewrite > 0 && (
                <Button
                  title="Regenerate output"
                  variant="text-primary"
                  className="ml-auto"
                  onClick={onGenerateOutputClick}
                />
              ))}

          <div className="flex justify-end gap-[10px] mt-[24px]">
            <Button
              title="Cancel"
              variant="secondary"
              onClick={onCancelClick}
            />
            <Button
              title="Use output copy"
              variant="primary"
              onClick={onUseOutputCopyClick}
            />
          </div>
        </>
      ) : (
        <Button
          title="Generate response"
          variant="primary"
          onClick={onGenerateOutputClickHandler}
          className="flex gap-[10px] mt-[24px] ml-auto"
        />
      )}
    </div>
  );
};

export default TextAreasSection;
