import { useState } from 'react';
import clsx from 'clsx';

import './styles.css';

interface Props {
  label: string;
  position?: 'above' | 'below' | 'left' | 'right';
  withArrow?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Tooltip = ({
  label,
  className,
  position = 'above',
  withArrow = true,
  children = null,
}: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div
      className={className}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {isTooltipVisible && (
        <div className={clsx('Tooltip', `Tooltip-${position}`)}>
          <div className="TooltipLabel">{label}</div>
          {withArrow && <div className="TooltipArrow" />}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Tooltip;
