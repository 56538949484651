import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import { Loader } from '@atoms';
import PaginationButtons, {
  PaginationButtonsProps,
} from '@organisms/Table/PaginationButtons';
import TableHeader from '@organisms/Table/TableHeader';
import TableRow, {
  RowData,
  TableRowMoreOptionsPopupProps,
} from '@organisms/Table/TableRow';

import './styles.css';

interface Props<T extends RowData> {
  withCheckbox?: boolean;
  headerCells: Array<string>;
  columnWidths?: Array<string>;
  data: Array<T>;
  renderRowData: (item: T) => Array<ReactNode>;
  selectedItemsIds?: Array<number>;
  setSelectedItemsIds?: Dispatch<SetStateAction<Array<number>>>;
  setActiveRow?: Dispatch<SetStateAction<T | null>>;
  paginationButtonsProps?: PaginationButtonsProps;
  isLoading?: boolean;
  moreOptionsPopup?: TableRowMoreOptionsPopupProps;
  onTableRowClick?: (item: any) => void;
  buttonProps?: {
    isVisible: boolean;
    title: string;
    onClick: (id: number) => void;
  };
}

const isElementThatEqualsTarget = (
  target: Element,
  parentElement: Element | null | undefined
): any => {
  if (parentElement?.className === target.className) {
    return true;
  }

  if (parentElement === document.body) {
    return false;
  }

  return isElementThatEqualsTarget(target, parentElement?.parentElement);
};

const Table = <T extends RowData>({
  withCheckbox,
  headerCells,
  columnWidths,
  data,
  renderRowData,
  selectedItemsIds,
  setSelectedItemsIds,
  setActiveRow,
  paginationButtonsProps,
  isLoading,
  moreOptionsPopup,
  onTableRowClick,
  buttonProps,
}: Props<T>) => {
  const [hoveredItemId, setHoveredItemId] = useState<number | null>(null);

  const isHeaderCheckboxChecked = selectedItemsIds?.length === data.length;

  const handleHeaderCheckboxClick = () => {
    if (!selectedItemsIds || !setSelectedItemsIds) return;

    if (isHeaderCheckboxChecked) {
      setSelectedItemsIds([]);
      return;
    }

    setSelectedItemsIds(data.map(({ id }) => id));
  };

  const isRowCheckboxChecked = (id: number, selectedIds?: Array<number>) =>
    selectedIds?.includes(id);

  const handleRowCheckboxClick = (id: number) => {
    if (!setSelectedItemsIds) return;

    setSelectedItemsIds((prevState) => {
      if (isRowCheckboxChecked(id, prevState)) {
        return [...prevState.filter((item) => item !== id)];
      }

      return [...prevState, id];
    });
  };

  const handleTableRowClick = (item: RowData) => (e: any) => {
    const optionsCell = document.querySelector('.OptionsCell');

    if (!optionsCell) {
      return;
    }

    const isOptionsCellParent = isElementThatEqualsTarget(
      optionsCell,
      e.target.parentElement
    );

    if (isOptionsCellParent || !onTableRowClick) {
      return;
    }

    onTableRowClick(item);
  };

  const handleTableRowHoverEnter = (id: number) => setHoveredItemId(id);
  const handleTableRowsHoverLeave = () => setHoveredItemId(null);

  // const tableContentHeight = `${
  //   (paginationButtonsProps?.maxVisibleRowsAmount || data.length) * 60
  // }px`;

  return (
    <div className="Table">
      <TableHeader
        withCheckbox={withCheckbox}
        cells={headerCells}
        columnWidths={columnWidths}
        isCheckboxChecked={isHeaderCheckboxChecked}
        onCheckboxClick={handleHeaderCheckboxClick}
        withMoreOptionsColumn={!!moreOptionsPopup}
      />
      <div
        style={
          {
            // height: tableContentHeight,
          }
        }
        onMouseLeave={handleTableRowsHoverLeave}
      >
        {isLoading ? (
          <Loader />
        ) : (
          data.map((item) => (
            <TableRow
              withCheckbox={withCheckbox}
              rowItem={item}
              key={item.id}
              columnWidths={columnWidths}
              renderRowData={renderRowData}
              setActiveRow={setActiveRow}
              isCheckboxChecked={isRowCheckboxChecked(
                item.id,
                selectedItemsIds
              )}
              onCheckboxClick={handleRowCheckboxClick}
              onTableRowClick={handleTableRowClick(item)}
              moreOptionsPopup={moreOptionsPopup}
              buttonProps={buttonProps}
              hoveredItemId={hoveredItemId}
              onTableRowHoverEnter={handleTableRowHoverEnter}
            />
          ))
        )}
      </div>
      {paginationButtonsProps && (
        <PaginationButtons {...paginationButtonsProps} />
      )}
    </div>
  );
};

export default Table;
