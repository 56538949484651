import clsx from 'clsx';

import { Icon, Popup, Typography } from '@atoms';
import { ReactComponent as RightArrowIcon } from '@assets/icons/chevron-right.svg';
import { SvgFunctionComponent } from '@devTypes';

import './styles.css';

interface Props {
  isOpen: boolean;
  onClick: () => Promise<void>;
  item: {
    title: string;
    icon: SvgFunctionComponent;
  };
}

const DashboardSidebarPopup = ({ isOpen, item, onClick }: Props) => {
  const handlePopupClose = () => {};

  return (
    <Popup
      isOpen={isOpen}
      onClose={handlePopupClose}
      anchorEl={
        <Icon
          SVG={RightArrowIcon}
          height={24}
          width={24}
          className={clsx('ArrowButton', isOpen && 'ArrowButton-inverted')}
        />
      }
      className="DashboardSidebarPopup"
    >
      <div className="PaddingBlock" />
      <div className="PopupItem" onClick={onClick} role="button" tabIndex={0}>
        <Icon SVG={item.icon} height={24} width={24} />
        <Typography variant="subtitle4" className="PopupItemTitle">
          {item.title}
        </Typography>
      </div>
    </Popup>
  );
};

export default DashboardSidebarPopup;
