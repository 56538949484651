import { useFormContext, useWatch } from 'react-hook-form';

import { Dropzone } from '@molecules';
import { ReactComponent as PictureIcon } from '@assets/icons/picture.svg';
import { FIVE_MEGABYTES } from '@constants/common';
import UploadedImagePreview from '@organisms/UploadedImagePreview/UploadedImagePreview';

const imageDropZoneFileTypes = { 'image/*': ['.png', '.gif', '.jpg'] };

const EditorImageBlockUploadModalUploadNew = () => {
  const methods = useFormContext();
  const { setValue, control } = methods;

  const imageFile = useWatch({ control, name: 'image' });

  const handleDrop = (acceptedFiles: File[]) => {
    setValue('image', acceptedFiles[0]);
  };

  return imageFile ? (
    <UploadedImagePreview image={imageFile} />
  ) : (
    <Dropzone
      subtitle="Supported file types - JPG, PNG and GIF Maximum image size - 5MB"
      title="Drag and drop files here"
      icon={PictureIcon}
      maxSize={FIVE_MEGABYTES}
      maxFiles={1}
      dropZoneType={imageDropZoneFileTypes}
      onDrop={handleDrop}
    />
  );
};
export default EditorImageBlockUploadModalUploadNew;
