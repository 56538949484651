import { Icon } from '@atoms';
import { SvgFunctionComponent } from '@devTypes';

interface Props {
  icon: SvgFunctionComponent;
}

const PlatformLogo = ({ icon }: Props) => (
  <div className="PlatformLogo">
    <Icon SVG={icon} height={32} width={32} />
  </div>
);

export default PlatformLogo;
