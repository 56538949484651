import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@atoms';
import { ImagePreview, InputWithTitle } from '@molecules';
import { convertBytesToMegabytes } from '@utils/helpers';

import './styles.css';

interface Props {
  image: File;
}

const UploadedImagePreview = ({ image }: Props) => {
  const { control } = useFormContext();

  const imageSize = useMemo(
    () => `${convertBytesToMegabytes(image.size)}MB`,
    [image.size]
  );

  return (
    <ImagePreview image={image}>
      <div className="UploadedImagePreviewTitle">
        <Typography variant="subtitle4">{image.name}</Typography>
        <Typography variant="subtitle6">{imageSize}</Typography>
      </div>
      <div>
        <InputWithTitle
          name="altText"
          title="Alternative image text"
          control={control}
          hintText="This text will appear when the image fails to load"
        />
      </div>
    </ImagePreview>
  );
};

export default UploadedImagePreview;
