import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/success-check.svg';

import './styles.css';

export interface ValidationStatusLabelProps {
  status: 'error' | 'success';
  title: string;
}

const ValidationStatusLabel = ({
  status,
  title,
}: ValidationStatusLabelProps) => (
  <div
    className={clsx('ValidationStatusLabel', `ValidationStatusLabel-${status}`)}
  >
    <Icon
      SVG={status === 'success' ? CheckIcon : CrossIcon}
      height={18}
      width={18}
    />
    <Typography
      variant="subtitle4"
      className={`ValidationStatusLabel-${status}`}
    >
      {title}
    </Typography>
  </div>
);

export default ValidationStatusLabel;
