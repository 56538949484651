import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { SwitchRender, Typography } from '@atoms';
import { ExitButton, Tabs } from '@molecules';
import useEditorSelected from '@hooks/useEditorSelected';
import ContentTab, {
  ActionOption,
  actions,
  DEFAULT_COPY_LENGTH,
  FormatOption,
  formats,
} from '@organisms/GenerateAISettings/ContentTab';
import ContextTab from '@organisms/GenerateAISettings/ContextTab';
import { InputType } from '@organisms/GenerateAISettings/TextAreasSection';
import {
  generativeAIAsyncThunks,
  generativeAISelectors,
} from '@store/features/generativeAI';
import { generativeAIActions } from '@store/features/generativeAI/slice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { generateId } from '@utils/helpers';

import './styles.css';

const tabs = [
  { id: 'content', label: 'Content' },
  { id: 'context', label: 'Context' },
];

interface Props {
  onClose: () => void;
}

type FormValues = {
  input: string;
  outputForRewrite: string;
  outputForPrompt: string;
  prompt: string;
};

const GenerateAISettings = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();

  const currentState = useAppSelector(generativeAISelectors.currentState);
  const states = useAppSelector(generativeAISelectors.states);

  const selectedComponent = useEditorSelected();

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [selectedAction, setSelectedAction] = useState(actions[0]);
  const [selectedFormat, setSelectedFormat] = useState(formats[0]);
  const [copyLength, setCopyLength] = useState<number>(DEFAULT_COPY_LENGTH);
  const [writingStyles, setWritingStyles] = useState<string[]>([]);
  const [isCopyLengthChecked, setIsCopyLengthChecked] =
    useState<boolean>(false);

  const [isResponseGeneratedForInput, setIsResponseGeneratedForInput] =
    useState(false);
  const [isResponseGeneratedForPrompt, setIsResponseGeneratedForPrompt] =
    useState(false);
  const [isLoadingOutput, setIsLoadingOutput] = useState(false);

  const { control, getValues, setValue, reset } = useForm<FormValues>({
    defaultValues: {
      input: '',
      prompt: '',
      outputForPrompt: '',
      outputForRewrite: '',
    },
  });
  const resetState = () => {
    reset();
    dispatch(generativeAIActions.resetState());
  };

  useEffect(() => () => resetState(), []);

  useEffect(() => {
    const textContent = selectedComponent?.getEl().textContent;

    if (!textContent) return;

    const state = {
      id: generateId(),
      text: textContent,
    };

    dispatch(
      generativeAIActions.setCurrentState({
        input: {
          index: 0,
          ...state,
        },
      })
    );
    dispatch(
      generativeAIActions.addState({
        type: 'input',
        state,
      })
    );
  }, [selectedComponent]);

  useEffect(() => {
    setValue('input', currentState.input.text);
    setValue('prompt', currentState.prompt.text);
    if (currentState.outputForRewrite?.text) {
      setValue('outputForRewrite', currentState.outputForRewrite.text);
    }
    if (currentState.outputForPrompt?.text) {
      setValue('outputForPrompt', currentState.outputForPrompt.text);
    }
  }, [currentState]);

  const generateOutput = async () => {
    const input = {
      action: selectedAction.id,
      format: selectedFormat.id,
      writingStyles,
      prompt:
        selectedAction.id === 'Rewrite'
          ? getValues().input
          : getValues().prompt,
      length: copyLength,
    };

    const res = await dispatch(generativeAIAsyncThunks.generateAIThunk(input));

    const output = res.payload.text;

    if (!output) {
      toast.error('Something went wrong');
      return;
    }

    const inputState = {
      id: generateId(),
      text: input.prompt,
    };

    if (selectedAction.id === 'Rewrite') {
      dispatch(
        generativeAIActions.setCurrentState({
          input: {
            index: states.input.length,
            ...inputState,
          },
        })
      );
      dispatch(
        generativeAIActions.addState({
          type: 'input',
          state: inputState,
        })
      );
    } else {
      dispatch(
        generativeAIActions.setCurrentState({
          prompt: {
            index: states.prompt.length,
            ...inputState,
          },
        })
      );
      dispatch(
        generativeAIActions.addState({
          type: 'prompt',
          state: inputState,
        })
      );
    }

    // eslint-disable-next-line consistent-return
    return output;
  };

  const handleInputCancelClick = (name: string) => {
    if (name === 'input') {
      setValue('input', currentState.input.text);
    }
    if (name === 'prompt') {
      setValue('prompt', currentState.prompt.text);
    }
  };

  const handleGenerateOutputClick = async () => {
    setIsLoadingOutput(true);
    const output = await generateOutput();

    const outputState = {
      id: generateId(),
      text: output,
    };

    setIsLoadingOutput(false);
    if (selectedAction.id === 'Create') {
      setIsResponseGeneratedForPrompt(true);
      setValue('outputForPrompt', output);
      dispatch(
        generativeAIActions.setCurrentState({
          outputForPrompt: {
            index: states.outputForPrompt.length,
            ...outputState,
          },
        })
      );
      dispatch(
        generativeAIActions.addState({
          type: 'outputForPrompt',
          state: outputState,
        })
      );
    } else {
      setIsResponseGeneratedForInput(true);
      setValue('outputForRewrite', output);
      dispatch(
        generativeAIActions.setCurrentState({
          outputForRewrite: {
            index: states.outputForRewrite.length,
            ...outputState,
          },
        })
      );
      dispatch(
        generativeAIActions.addState({
          type: 'outputForRewrite',
          state: outputState,
        })
      );
    }
  };

  const handleActionChange = (value: ActionOption) => setSelectedAction(value);

  const handleFormatChange = (value: FormatOption) => setSelectedFormat(value);

  const handleCopyLengthChange = (value: number[]) => {
    setCopyLength(value[0]);
  };
  // setCopyLength(value as number);

  const handleWritingStylesSubmit = (values: string[]) =>
    setWritingStyles(values);

  const handleCopyLengthToggle = () =>
    setIsCopyLengthChecked((prevState) => !prevState);

  const handleUseOutputCopyClick = () => {
    if (selectedAction.id === 'Rewrite') {
      selectedComponent?.components(getValues().outputForRewrite);
    }
    if (selectedAction.id === 'Create') {
      selectedComponent?.components(getValues().outputForPrompt);
    }
    onClose();
  };

  const handleShowPrevOrNextTextClick =
    (type: InputType, stateDirection: 'prev' | 'next') => () => {
      const prevStateIndex = currentState[type].index - 1;
      const nextStateIndex = currentState[type].index + 1;

      const index = stateDirection === 'prev' ? prevStateIndex : nextStateIndex;
      const state = states[type][index];

      dispatch(
        generativeAIActions.setCurrentState({
          [type]: {
            ...state,
            index,
          },
        })
      );
    };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const currentStateNumber = {
    input: currentState.input.index + 1,
    prompt: currentState.prompt.index + 1,
    outputForRewrite: currentState.outputForRewrite.index + 1,
    outputForPrompt: currentState.outputForPrompt
      ? currentState.outputForPrompt.index + 1
      : 0,
  };

  const totalStatesAmount = {
    input: states.input.length,
    prompt: states.prompt.length,
    outputForRewrite: states.outputForRewrite.length,
    outputForPrompt: states.outputForPrompt.length,
  };

  return (
    <div className="GenerateAISettings">
      <ExitButton
        onGoBackClick={handleClose}
        className="GenerateAISettings-ExitButton"
      />
      <div className="GenerateAISettings-Content">
        <Typography variant="subtitle1" className="font-medium">
          Your content/copywriter co-pilot
        </Typography>
        <Typography variant="subtitle3" className="pt-[10px] pb-[24px]">
          Share your requirements, ideas, and vision, and let our AI-powered
          assistant bring them to life.
        </Typography>
        <Tabs
          items={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <SwitchRender
          comparator={selectedTab.id}
          toCompare={tabs.map((item) => item.id)}
        >
          <ContentTab
            action={selectedAction}
            format={selectedFormat}
            isResponseGeneratedForInput={isResponseGeneratedForInput}
            isResponseGeneratedForPrompt={isResponseGeneratedForPrompt}
            isLoadingOutput={isLoadingOutput}
            control={control}
            // copyLength={copyLength}
            isCopyLengthChecked={isCopyLengthChecked}
            isCopyLengthSliderVisible={selectedFormat.id === 'Body'}
            handleInputCancelClick={handleInputCancelClick}
            onActionChange={handleActionChange}
            onFormatChange={handleFormatChange}
            onGenerateOutputClick={handleGenerateOutputClick}
            onCopyLengthChange={handleCopyLengthChange}
            onCopyLengthToggle={handleCopyLengthToggle}
            onCancelClick={handleClose}
            onUseOutputCopyClick={handleUseOutputCopyClick}
            onShowPrevOrNextTextClick={handleShowPrevOrNextTextClick}
            currentStateNumber={currentStateNumber}
            totalStatesAmount={totalStatesAmount}
          />
          <ContextTab
            submittedWritingStyles={writingStyles}
            onWritingStylesSubmit={handleWritingStylesSubmit}
          />
        </SwitchRender>
      </div>
    </div>
  );
};

export default GenerateAISettings;
