import { useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Typography } from '@atoms';
import { DashboardTabsHeader, FullPageLoader } from '@molecules';
import { ConfirmModal, ShopDetails } from '@organisms';
import { ShopifyAuthFormTemplate } from '@templates';
import shopifyAccessScopesErrorImage from '@assets/images/shopify-access-scopes-error.png';
import { ROUTES } from '@constants/routes';
import { SelectorOptionType } from '@devTypes';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { usePresetTemplate } from '@hooks';
import CustomAppDetails from '@organisms/ShopDetails/CustomAppDetails';
import {
  organizationsAsyncThunks,
  organizationsSelectors,
} from '@store/features/organizations';
import { shopifyAsyncThunks, shopifySelectors } from '@store/features/shopify';
import { usersAsyncThunks, usersSelectors } from '@store/features/users';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { trackToGTM, trackToZappier } from '@utils/helpers';
import { schemas } from '@utils/validation';

const selectorOptions = [
  {
    id: 1,
    text: 'Admin API',
  },
  {
    id: 2,
    text: 'Shopify details',
  },
];

const customAppDefaultValues = {
  shopifyAccessToken: '',
  customAppApiKey: '',
  storeName: '',
};

type CustomAppFormValues = typeof customAppDefaultValues;

interface OrganizationFormValues {
  organizationName: string;
  organizationLogo: string;
}

const ShopifyConnectionErrorModalSubtitle = (
  <div className="flex flex-col gap-5">
    <Typography variant="subtitle3">
      There was an error with either the{' '}
      <span className="font-bold">Custom app API Key</span>, or the
      <span className="font-bold"> Admin Access API Token</span>. Please ensure
      the keys are entered correctly.
    </Typography>
    <Typography variant="subtitle3">
      If there are still further issues, please contact us at Support
      @appmail.co
    </Typography>
  </div>
);

const ShopifyConnectionAccessScopesErrorModalSubtitle = (
  <div className="w-full flex justify-between gap-8">
    <div className="flex flex-col gap-5">
      <Typography variant="subtitle3">
        There’s an issue with your{' '}
        <span className="font-bold">Read and write</span> access settings Please
        check that you have enabled ALL the read and write access referenced in
        the image on the right.
      </Typography>
      <Typography variant="subtitle3">
        <span className="font-bold">To find these settings: </span>
        Apps {'>'} Apps and sales channels {'>'} Develop apps {'>'} (The custom
        app you created) {'>'} Configuration
      </Typography>
      <Typography variant="subtitle3">
        If you need further assistance, please contact us at Support@appmail.co
        Got it
      </Typography>
    </div>
    <img
      className="w-[509px] h-[274px]"
      src={shopifyAccessScopesErrorImage}
      alt="Access scopes error"
    />
  </div>
);

const ShopifyAuthFormView = () => {
  const meOrganizationData = useAppSelector(
    organizationsSelectors.getMeOrganizationData
  );
  const { state } = useLocation();
  const [selectedTab, setSelectedTab] = useState<SelectorOptionType>(
    selectorOptions[0]
  );
  const [
    shopifyConnectionErrorModalIsOpen,
    setShopifyConnectionErrorModalIsOpen,
  ] = useState(false);

  const [
    shopifyConnectionAccessScopesErrorModalIsOpen,
    setShopifyConnectionAccessScopesErrorModalIsOpen,
  ] = useState(false);

  const isShopifyConnectionLoading = useAppSelector(
    shopifySelectors.shopifyConnectionLoading
  );
  const isGetMeOrganizationDataLoading = useAppSelector(
    organizationsSelectors.isGetMeOrganizationDataLoading
  );

  const userIntegrations = useAppSelector(usersSelectors.userIntegrations);

  const organizationDefaultValues: OrganizationFormValues = useMemo(
    () => ({
      organizationName: meOrganizationData.title,
      organizationLogo: meOrganizationData.imageUrl,
    }),
    [meOrganizationData]
  );

  const organizationMethods = useForm<OrganizationFormValues>({
    mode: 'onChange',
    values: organizationDefaultValues,
    resolver: yupResolver(schemas.oraganiationFormSchema),
  });

  const currentOrganizationLogo = useWatch({
    control: organizationMethods.control,
    name: 'organizationLogo',
  });

  const onUsePresetTemplate = usePresetTemplate(state?.campaignId);

  const isOrganizationSaveDisabled =
    !organizationMethods.formState.isValid ||
    (!!meOrganizationData.title &&
      currentOrganizationLogo === meOrganizationData.imageUrl);

  const customAppMethods = useForm<CustomAppFormValues>({
    defaultValues: customAppDefaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schemas.shopifyAuthForm),
  });

  const isCustomAppFormEmpty =
    !customAppMethods.formState.isValid || !!userIntegrations?.shopify;

  const { handleSubmit: handleCustomAppSubmit } = customAppMethods;
  const { handleSubmit: handleOrganizationSubmit } = organizationMethods;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(organizationsAsyncThunks.getMeOrganizationDataThunk(null));
    dispatch(usersAsyncThunks.getUserThunk(null));
  }, []);

  const handleShopifyConnectionAccessScopesErrorModalClose = () => {
    setShopifyConnectionAccessScopesErrorModalIsOpen(false);
  };

  const handleShopifyConnectionAccessScopesErrorModalOpen = () => {
    setShopifyConnectionAccessScopesErrorModalIsOpen(true);
  };

  const handleShopifyConnectionErrorModalClose = () => {
    setShopifyConnectionErrorModalIsOpen(false);
  };

  const handleShopifyConnectionErrorModalOpen = () => {
    setShopifyConnectionErrorModalIsOpen(true);
  };

  const onOrganizationSave: SubmitHandler<OrganizationFormValues> = async ({
    organizationName,
    organizationLogo,
  }) => {
    const data = {
      title: meOrganizationData.title ? undefined : organizationName,
      imageUrl: organizationLogo,
    };

    try {
      await dispatch(
        organizationsAsyncThunks.addOrganizationDataThunk(data)
      ).unwrap();

      await dispatch(
        organizationsAsyncThunks.getMeOrganizationDataThunk(null)
      ).unwrap();

      navigate(ROUTES.dashboard.integrations);
    } catch (err: any) {
      toast.error(err.message.message);
    }
  };

  const onCustomAppSave: SubmitHandler<CustomAppFormValues> = async ({
    shopifyAccessToken,
    storeName,
    customAppApiKey,
  }) => {
    try {
      await dispatch(
        shopifyAsyncThunks.createShopifyConnectionThunk({
          shopifyAccessToken,
          storeName: `${storeName}.myshopify.com`,
          customAppApiKey,
        })
      )
        .unwrap()
        .then(() => {
          trackToZappier({ event: 'Shopify store connected', storeName });
          trackToGTM({ event: 'shopify_link_new', storeName });
        });

      dispatch(shopifyAsyncThunks.verifyShopifyConnectionThunk(null)).unwrap();

      if (state?.selectedPresetTemplate) {
        await onUsePresetTemplate(state.selectedPresetTemplate);

        return;
      }

      navigate(ROUTES.dashboard.integrations, {
        state: { shopifyConnected: true },
      });
    } catch (err: any) {
      if (err.message.message.includes('read_orders')) {
        handleShopifyConnectionAccessScopesErrorModalOpen();
      } else {
        handleShopifyConnectionErrorModalOpen();
      }
    }
  };

  const handleTabChange = (option: SelectorOptionType) => {
    setSelectedTab(option);
  };

  return (
    <>
      <ConfirmModal
        isOpen={shopifyConnectionErrorModalIsOpen}
        className="[&>div>p:first-child]:text-error-500 w-[564px]"
        onClose={handleShopifyConnectionErrorModalClose}
        onConfirm={handleShopifyConnectionErrorModalClose}
        title="There seems to be an issue with your Keys"
        subtitle={ShopifyConnectionErrorModalSubtitle}
        confirmButtonText="Got it"
        withoutCloseButton
      />
      <ConfirmModal
        isOpen={shopifyConnectionAccessScopesErrorModalIsOpen}
        className="[&>div>p:first-child]:text-error-500 w-[944px]"
        onClose={handleShopifyConnectionAccessScopesErrorModalClose}
        onConfirm={handleShopifyConnectionAccessScopesErrorModalClose}
        title="There’s an issue with your “Read and write” access settings"
        subtitle={ShopifyConnectionAccessScopesErrorModalSubtitle}
        confirmButtonText="Got it"
        withoutCloseButton
      />

      {isShopifyConnectionLoading && isGetMeOrganizationDataLoading && (
        <FullPageLoader />
      )}
      <DashboardTabsHeader
        title="Shopify setup"
        selectorProps={{
          options: selectorOptions,
          value: selectedTab,
          onChange: handleTabChange,
        }}
      />
      <ShopifyAuthFormTemplate>
        {selectedTab.id === 1 ? (
          <FormProvider {...customAppMethods}>
            <CustomAppDetails
              onSave={handleCustomAppSubmit(onCustomAppSave)}
              isSaveDisabled={isCustomAppFormEmpty}
            />
          </FormProvider>
        ) : (
          <FormProvider {...organizationMethods}>
            <ShopDetails
              onSave={handleOrganizationSubmit(onOrganizationSave)}
              isSaveDisabled={isOrganizationSaveDisabled}
            />
          </FormProvider>
        )}
      </ShopifyAuthFormTemplate>
    </>
  );
};

export default ShopifyAuthFormView;
