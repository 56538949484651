import { ReactNode } from 'react';
import clsx from 'clsx';

import { Typography } from '@atoms';

interface Props {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const TableCell = ({ children, className, style }: Props) => (
  <div style={style} className={clsx('TableCell', className)}>
    {typeof children === 'string' ? (
      <Typography variant="subtitle4" className="TableCell-Text">
        {children}
      </Typography>
    ) : (
      children
    )}
  </div>
);

export default TableCell;
