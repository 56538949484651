import { ENDPOINTS } from '@constants/api';
import { GENERATIVE_AI_WRITING_STYLES } from '@constants/common';

import { wrappedAxiosRequest } from '../../api';

export type Action = 'Create' | 'Rewrite' | 'Check spelling';
export type WritingStyle = typeof GENERATIVE_AI_WRITING_STYLES[number];
export type Format = 'Body' | 'Header' | 'CallToAction';

export interface GenerateAIData {
  action: Action;
  format: Format;
  prompt: string;
  length?: number;
  writingStyles: WritingStyle[];
}

const generativeAIApi = {
  async generate(data: GenerateAIData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.generativeAI.generate,
      data,
    });
  },
};

export default generativeAIApi;
