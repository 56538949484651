import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import galleryApi, {
  DeleteImageData,
  PresignedUrlData,
  SaveUrlData,
} from './api';

const getPresignedUrlThunk = createCommonAsyncThunk<PresignedUrlData>(
  'gallery/getPresignedUrl',
  galleryApi.getPresignedUrl
);

const saveUrlThunk = createCommonAsyncThunk<SaveUrlData>(
  'gallery/saveUrl',
  galleryApi.saveUrl
);

const getAllUrlsThunk = createCommonAsyncThunk(
  'gallery/getAllUrls',
  galleryApi.getAllUrls
);

const deleteImageThunk = createCommonAsyncThunk<DeleteImageData>(
  'gallery/deleteImage',
  galleryApi.deleteImage
);

export default {
  getPresignedUrlThunk,
  saveUrlThunk,
  getAllUrlsThunk,
  deleteImageThunk,
};
