import grapesjs from 'grapesjs';

import { EditorComponentTypes } from '@constants/editor';
import { ButtonBlock } from '@devTypes';
import { getChildComponentAt, resizeHeightAfterRemove } from '@utils/helpers';

import { logoComponentController } from '../components/logo';

const buttomComponentSettings = [
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'fillColor',
  'textColor',
  'borderColor',
  'borderRadius',
  'borderWidth',
  'width',
  'height',
  'font',
  'alignment',
  'hoverBorderColor',
  'hoverFillColor',
  'hoverTextColor',
  'backgroundColor',
];

const imageComponentSettings = [
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'imageUrl',
  'backgroundColor',
];

const logoComponentSettings = [
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'alignId',
  'size',
  'imageUrl',
  'backgroundColor',
  'src',
];

const textComponentSettings = [
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'backgroundColor',
  'isBold',
  'isItalic',
  'isUnderline',
  'isStrikethrough',
  'isHighlight',
  'textAlign',
  'link',
  'unlink',
  'numberedList',
  'bulletedList',
  'indentMore',
  'indentLess',
  'lineSpacing',
  'letterSpacing',
  'removeFormatting',
  'format',
];

const dividerComponentSettings = [
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'backgroundColor',
  'selectedId',
  'borderWidth',
  'borderColor',
];

const spacerComponentSettings = ['height', 'backgroundColor'];

const footerComponentSettings = [
  'facebookLink',
  'twitterLink',
  'instagramLink',
  'youtubeLink',
  'tiktokLink',
  'emailText',
  'phoneNumber',
  'websiteText',
  'link',
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'backgroundColor',
  'src',
  'color',
];

export const customComponents = (editor: grapesjs.Editor) => {
  // Footer type
  editor.DomComponents.addType(EditorComponentTypes.Footer, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Footer,
        },
      },
      init() {
        this.listenTo(
          this,
          footerComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          footerComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
        this.onAll((c) => {
          c.on('change:attributes:src', this.handleSettingsChange);
        });
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
    },
    view: {
      init() {
        const { model } = this;
        const topSection = model.components().models[0].components().models[0];
        const emailInfoAndVisitSite = topSection.components().models[0];
        const emailComponent = emailInfoAndVisitSite.components().models[0];
        const visitSite =
          emailInfoAndVisitSite.components().models[1].attributes.components!
            .models[0];

        const phoneAndSocials = topSection.components().models[1];
        const phoneComponent = phoneAndSocials.components().models[0];
        const socialMediaComponent = phoneAndSocials.components().models[1];

        const isSocialMediaVisible =
          !socialMediaComponent?.getStyle()?.visibility;
        const isVisitWebsiteVisible = !visitSite?.getStyle()?.visibility;
        const isEmailVisible = !emailComponent?.getStyle()?.visibility;
        const isPhoneNumberVisible = !phoneComponent?.getStyle()?.visibility;

        const userInfoHidden = !!isEmailVisible && !isVisitWebsiteVisible;
        const companyInfoHidden =
          !isPhoneNumberVisible && !isSocialMediaVisible;

        // show/hide container
        if (!isEmailVisible && !isVisitWebsiteVisible) {
          emailInfoAndVisitSite.addStyle({ display: 'none' });
        } else {
          emailInfoAndVisitSite.removeStyle('display');
        }

        if (!isPhoneNumberVisible && !isSocialMediaVisible) {
          phoneAndSocials.addStyle({ display: 'none' });
        } else {
          phoneAndSocials.removeStyle('display');
        }

        // if (isEmailVisible) {
        //   phoneComponent.addStyle({ 'padding-bottom': 'none' });
        // } else {
        //   phoneComponent.addStyle({ 'padding-bottom': '30px' });
        // }

        // if (isPhoneNumberVisible) {
        //   emailComponent.addStyle({ 'padding-top': 'none' });
        // } else {
        //   emailComponent.addStyle({ 'padding-top': '30px' });
        // }

        // if (isSocialMediaVisible) {
        //   visitSite?.addStyle({ 'padding-top': 'none' });
        // } else {
        //   visitSite?.addStyle({ 'padding-top': '30px' });
        // }

        // if (isVisitWebsiteVisible) {
        //   socialMediaComponent.addStyle({ 'padding-bottom': 'none' });
        // } else {
        //   socialMediaComponent.addStyle({ 'padding-bottom': '30px' });
        // }

        if (
          !isVisitWebsiteVisible &&
          !isEmailVisible &&
          !isPhoneNumberVisible
        ) {
          emailInfoAndVisitSite.addStyle({ display: 'none' });
          phoneComponent.addStyle({ display: 'none' });
        } else {
          emailInfoAndVisitSite.removeStyle('display');
          phoneComponent.removeStyle('display');
        }

        if (!isEmailVisible && !isPhoneNumberVisible) {
          emailComponent.addStyle({ display: 'none' });
          phoneComponent.addStyle({ display: 'none' });
        } else {
          emailComponent.removeStyle('display');
          phoneComponent.removeStyle('display');
        }

        // add/remove divider
        if (userInfoHidden || companyInfoHidden) {
          emailInfoAndVisitSite.addStyle({ border: 'none' });
        } else {
          emailInfoAndVisitSite.removeStyle('border');
          emailInfoAndVisitSite.addStyle({
            'border-right': '2px solid initial',
          });
        }
      },
      onRender() {
        const { model } = this;
        const topSection = model.components().models[0].components().models[0];
        const emailInfoAndVisitSite = topSection.components().models[0];
        const emailComponent = emailInfoAndVisitSite.components().models[0];
        const visitSite =
          emailInfoAndVisitSite.components().models[1].attributes.components!
            .models[0];

        const phoneAndSocials = topSection.components().models[1];
        const phoneComponent = phoneAndSocials.components().models[0];

        const { websiteText, phoneNumber, emailText } = model.getAttributes();

        if (emailComponent) {
          if (emailComponent.getEl()) {
            emailComponent.getEl().innerHTML = emailText;
          }
        }

        if (phoneComponent?.getEl()) {
          phoneComponent.getEl().innerHTML = phoneNumber;
        }

        if (visitSite?.getEl()) {
          visitSite.getEl().innerHTML = websiteText;
        }
      },
    },
  });

  // Spacer type
  editor.DomComponents.addType(EditorComponentTypes.Spacer, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Spacer,
        },
      },
      init() {
        this.listenTo(
          this,
          spacerComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          spacerComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
    },
  });

  // Divider type
  editor.DomComponents.addType(EditorComponentTypes.Divider, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Divider,
        },
      },
      init() {
        this.listenTo(
          this,
          dividerComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          dividerComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
    },
  });

  const textType = editor.DomComponents.getType('text');

  // Text type
  editor.DomComponents.addType(EditorComponentTypes.Text, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Text,
        },
      },
      init() {
        this.listenTo(
          this,
          textComponentSettings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          textComponentSettings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
    },
    view: textType.view.extend({
      events: {
        click: 'onActive',
        input: 'handleInputUpdate',
        keydown: (e: any) => {
          resizeHeightAfterRemove(editor, e.keyCode === 13);
        },
      },
      handleInputUpdate() {
        resizeHeightAfterRemove(editor);
      },
    }),
  });

  // Button type
  editor.DomComponents.addType(EditorComponentTypes.Button, {
    model: {
      defaults: {
        tagName: 'button',
        attributes: {
          type: EditorComponentTypes.Button,
        },
      },
      init(this: ButtonBlock) {
        this.listenTo(this, 'change:attributes', this.handleAttrChange);
        this.listenTo(
          this,
          buttomComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          buttomComponentSettings
            .map((s) => `change:attributes:${s}`)
            .join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
      handleAttrChange(this: ButtonBlock) {
        const { paddingtop, paddingbottom } = this.getAttributes();
        const button = getChildComponentAt(this, 0);
        const { height } = button.getAttributes();

        this.addStyle({ height: `${height + paddingtop + paddingbottom}px` });
      },
    },
  });

  // Image type
  editor.DomComponents.addType(EditorComponentTypes.Image, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Image,
        },
      },
      init() {
        this.listenTo(
          this,
          imageComponentSettings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          imageComponentSettings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
    },
  });

  // Logo type
  editor.DomComponents.addType(EditorComponentTypes.Logo, {
    model: {
      defaults: {
        tagName: 'div',
        attributes: {
          type: EditorComponentTypes.Logo,
        },
      },
      init() {
        this.listenTo(
          this,
          'change:attributes:imageUrl',
          this.handleImageChange
        );
        this.listenTo(this, 'change:attributes:size', this.handleSizeChange);
        this.listenTo(
          this,
          logoComponentSettings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          logoComponentSettings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
      handleSizeChange() {
        logoComponentController.handleLogoSizeChange(this);
      },

      handleImageChange() {
        logoComponentController.removeLogoPlaceholderComponent(this);
        logoComponentController.handleLogoSizeChange(this);
      },
    },
    view: {
      init({ model }: { model: grapesjs.Component }) {
        logoComponentController.removeLogoPlaceholderComponent(model);
        logoComponentController.handleLogoSizeChange(model);
      },
    },
  });
};
