import { ReactNode } from 'react';

import { Popup } from '@atoms';
import { PopupListItemComponent } from '@devTypes';
import PopupList, {
  PopupListClickHandler,
} from '@molecules/SelectPopup/PopupList';

import './styles.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: PopupListClickHandler;
  items: Array<PopupListItemComponent>;
  selectedItem?: string;
  anchorEl: ReactNode;
  className?: string;
}

const SelectPopup = ({
  isOpen,
  onClose,
  items,
  selectedItem,
  onClick,
  anchorEl,
  className,
}: Props) => (
  <Popup
    isOpen={isOpen}
    onClose={onClose}
    anchorEl={anchorEl}
    className={className}
  >
    <PopupList items={items} selectedItem={selectedItem} onClick={onClick} />
  </Popup>
);

export default SelectPopup;
