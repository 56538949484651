import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { Button, SwitchRender, Typography } from '@atoms';
import { Tabs } from '@molecules';
import { EditorComponentTypes } from '@constants/editor';
import { useAutoSave, useTimeoutAutoSave } from '@hooks';
import EditorFooterBlockSettings from '@organisms/EditorBlockFooterSettings/EditorFooterBlockSettings';
import EditorButtonBlockSettings from '@organisms/EditorButtonBlockSettings/EditorButtonBlockSettings';
import EditorCarouselBlockSettings from '@organisms/EditorCarouselBlockSettings/EditorCarouselBlockSettings';
import EditorColumnsBlockSettings from '@organisms/EditorColumnsBlockSettings/EditorColumnsBlockSettings';
import EditorDividerBlockSettings from '@organisms/EditorDividerBlockSettings/EditorDividerBlockSettings';
import EditorEcommerceBlockSettings from '@organisms/EditorEcommerceBlockSettings/EditorEcommerceBlockSettings';
import EditorImageBlockSettings from '@organisms/EditorImageBlockSettings/EditorImageBlockSettings';
import EditorLogoBlockSettings from '@organisms/EditorLogoBlockSettings/EditorLogoBlockSettings';
import EditorSpacerBlockSettings from '@organisms/EditorSpacerBlockSettings/EditorSpacerBlockSettings';
import EditorTextBlockSettings from '@organisms/EditorTextBlockSettings/EditorTextBlockSettings';
import { editorActions, editorSelectors } from '@store/features/editor';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { findComponentToBeSelected, getEditorHeight } from '@utils/helpers';

import './styles.css';

const items = [
  { id: 'content', label: 'Content' },
  { id: 'design', label: 'Design' },
];

const blockTitles = {
  [EditorComponentTypes.Text]: 'Text settings',
  [EditorComponentTypes.Columns]: 'Layout settings',
  [EditorComponentTypes.Button]: 'Button settings',
  [EditorComponentTypes.Image]: 'Image settings',
  [EditorComponentTypes.Carousel]: 'Image carousel settings',
  [EditorComponentTypes.Ecommerce]: 'E-commerce',
  [EditorComponentTypes.Divider]: 'Divider settings',
  [EditorComponentTypes.Spacer]: 'Spacer settings',
  [EditorComponentTypes.Logo]: 'Logo settings',
  [EditorComponentTypes.Footer]: 'Footer settings',
};

interface Props {
  selectedComponent: grapesjs.Component | null;
  editor: grapesjs.Editor | undefined;
}

const EditorBlockSettings = ({ editor, selectedComponent }: Props) => {
  const dispatch = useAppDispatch();
  const currentState = useAppSelector(editorSelectors.currentState);
  const [isApplyCancelDisabled, setIsApplyCancelDisabled] = useState(true);
  const [selectedTab, setSelectedTab] = useState(items[0]);
  const [isDesignTabDisabled, setIsDesignTabDisabled] = useState(false);
  const autoSaveTimer = useTimeoutAutoSave();
  const autoSave = useAutoSave();

  const handleIsDesignTabDisabled = (x: boolean) => {
    setIsDesignTabDisabled(x);
  };

  useEffect(() => {
    if (selectedComponent) {
      setSelectedTab(items[0]);
    }
  }, [selectedComponent]);

  useEffect(() => {
    document.addEventListener('settingsChange', (event: any) => {
      setIsApplyCancelDisabled(event.detail);

      if (!event.detail) {
        autoSaveTimer.triggerTimeout(() => {
          autoSave();
        }, 5000);
      }
    });
  }, [editor]);

  if (!selectedComponent || !editor) return null;

  const handleCancelSettings = () => {
    const selected = editor.getSelected();
    const { customId } = selected!.getAttributes();

    setIsApplyCancelDisabled(true);
    editor.loadProjectData(JSON.parse(currentState.gjs));

    const wrapper = editor.getWrapper();

    const toSelect = findComponentToBeSelected(wrapper, customId);

    if (toSelect) {
      editor.select(toSelect);
    }
  };

  const handleApplySettings = () => {
    dispatch(
      editorActions.setCurrentStateEditorHeight(getEditorHeight(editor!))
    );
    setIsApplyCancelDisabled(true);
    autoSaveTimer.clearTimeout();
    autoSave();
  };

  return (
    <div className="EditorBlockSettings">
      <div className="EditorBlockSettings-Header">
        <div className="EditorBlockSettings-Actions">
          <Button
            variant="secondary"
            title="Cancel"
            onClick={handleCancelSettings}
            disabled={isApplyCancelDisabled}
          />
          <Button
            variant="primary"
            title="Apply"
            onClick={handleApplySettings}
            disabled={isApplyCancelDisabled}
          />
        </div>
        <Typography variant="subtitle3">
          {/* @ts-ignore */}
          {blockTitles[selectedComponent.attributes.type]}
        </Typography>
      </div>
      <div className="EditorBlockSettings-SettingsPanel">
        {selectedComponent.attributes.type !== EditorComponentTypes.Spacer ? (
          <Tabs
            items={items}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            disabledTab={isDesignTabDisabled ? items[1] : undefined}
          />
        ) : (
          ''
        )}
        <SwitchRender
          comparator={selectedComponent.attributes.type!}
          toCompare={[
            EditorComponentTypes.Text,
            EditorComponentTypes.Columns,
            EditorComponentTypes.Button,
            EditorComponentTypes.Image,
            EditorComponentTypes.Carousel,
            EditorComponentTypes.Footer,
            EditorComponentTypes.Ecommerce,
            EditorComponentTypes.Divider,
            EditorComponentTypes.Spacer,
            EditorComponentTypes.Logo,
            EditorComponentTypes.Footer,
          ]}
        >
          <EditorTextBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorColumnsBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorButtonBlockSettings
            editor={editor}
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorImageBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorCarouselBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorFooterBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorEcommerceBlockSettings
            type={selectedTab.id}
            onDesignTabDisabledChange={handleIsDesignTabDisabled}
          />
          <EditorDividerBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
          <EditorSpacerBlockSettings selectedComponent={selectedComponent} />
          <EditorLogoBlockSettings
            selectedComponent={selectedComponent}
            type={selectedTab.id}
          />
        </SwitchRender>
      </div>
    </div>
  );
};

export default EditorBlockSettings;
