import axios from 'axios';

import { BASE_URL, ENDPOINTS } from '@constants/api';

export interface GetImageFileByUrlData {
  url: string;
}

const generalApi = {
  async getImageFileByUrl({ url }: GetImageFileByUrlData) {
    const file = await axios.get(url, { responseType: 'blob' });

    return file;
  },

  async unsubscribeRecipient({ token }: { token: string }) {
    const url = `${BASE_URL}${ENDPOINTS.campaigns.unsubscribe}`;

    return axios.post(url, null, { params: { token } });
  },
};

export default generalApi;
