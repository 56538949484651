import clsx from 'clsx';

import { Checkbox } from '@atoms';
import { MenuItemComponent, MenuItemProps } from '@devTypes';

import './styles.css';

interface Props<T> {
  items: T[];
  value: T | null;
  ItemComponent: React.FC<MenuItemComponent>;
  onChange: (option: T) => void;
  spacing?: number;
  className?: string;
  showCheckbox?: boolean;
}

const Menu = <T extends MenuItemProps>({
  items,
  value,
  ItemComponent,
  onChange,
  spacing = 38,
  className,
  showCheckbox = false,
}: Props<T>) => (
  <div className={clsx('Menu', className)} style={{ gap: spacing }}>
    {items.map((item) => {
      const isSelected = value ? item.id === value.id : false;

      return (
        <div
          key={item.id}
          className="menu-container"
          style={{ opacity: item.isDisabled ? 0.5 : 1 }}
        >
          <ItemComponent
            isSelected={isSelected}
            onClick={() => (!item.isDisabled ? onChange(item) : null)}
            {...item}
          />
          {showCheckbox && isSelected ? (
            <Checkbox
              isChecked={isSelected}
              onChange={() => {}}
              type="primary"
            />
          ) : null}
        </div>
      );
    })}
  </div>
);

export default Menu;
