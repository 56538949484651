import RcSlider from 'rc-slider';

import Typography from '@atoms/Typography/Typography';

import 'rc-slider/assets/index.css';
import './styles.css';

interface Props {
  title?: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number | number[]) => void;
  disabled?: boolean;
}

const Slider = ({
  value,
  min = 0,
  max = 150,
  onChange,
  title,
  disabled = false,
}: Props) =>
  title ? (
    <div className="flexbox-column flexbox-gap-8px">
      <Typography variant="subtitle4">{title}</Typography>
      <RcSlider
        disabled={disabled}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
    </div>
  ) : (
    <RcSlider
      disabled={disabled}
      value={value}
      min={min}
      max={max}
      onChange={onChange}
    />
  );

export default Slider;
