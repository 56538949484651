import { Button, HeaderContainer, Typography } from '@atoms';
import AllCampaignsTemplate from '@templates/AllCampaignsTemplate/AllCampaignsTemplate';

import './styles.css';

interface AllCampaignsViewProps {
  createNewCampaign: () => void;
}

const AllCampaignsView = ({ createNewCampaign }: AllCampaignsViewProps) => (
  <>
    <HeaderContainer>
      <Typography variant="subtitle1">Manage your campaigns</Typography>
      <Button
        title="Create campaign"
        variant="primary"
        size="lg"
        onClick={createNewCampaign}
      />
    </HeaderContainer>
    <AllCampaignsTemplate />
  </>
);

export default AllCampaignsView;
