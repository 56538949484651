import grapesjs from 'grapesjs';

import { PaddingSide } from '@devTypes';

//
// Padding controllers
//
export const setPaddingBySide = (
  component: grapesjs.Component,
  side: PaddingSide,
  value: number
) => {
  component.addAttributes({ [`padding${side}`]: value });

  component.addStyle({ [`padding-${side}`]: `${(value / 700) * 100}%` });
};

export const getPaddingBySide = (
  component: grapesjs.Component,
  side: PaddingSide
) => {
  const state = component.getAttributes();

  return state[`padding${side}`];
};

export const setPaddings = (component: grapesjs.Component, value: number) => {
  setPaddingBySide(component, 'top', value);
  setPaddingBySide(component, 'bottom', value);
  setPaddingBySide(component, 'right', value);
  setPaddingBySide(component, 'left', value);
};

export const setIsPaddingConstrain = (
  component: grapesjs.Component,
  value: boolean
) => {
  component.addAttributes({ isPaddingConstrain: value });
};

export const getIsPaddingConstrain = (component: grapesjs.Component) => {
  const { isPaddingConstrain } = component.getAttributes();

  return isPaddingConstrain;
};
