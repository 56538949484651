/* eslint-disable react/jsx-no-bind */
import { useFormContext } from 'react-hook-form';

import { Button } from '@atoms';
import { InputWithTitle } from '@molecules';

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

const ChangeCampaignNameForm = ({ onSave, onCancel }: Props) => {
  const { control, getValues } = useFormContext();
  return (
    <div className="ChangeCampaignNameForm">
      <InputWithTitle
        title="Campaign name"
        control={control}
        name="title"
        className="ChangeCampaignNameForm-Input"
      />
      <div className="flexbox-row flexbox-gap-16px ChangeCampaignButtons">
        <Button title="Cancel" variant="secondary" onClick={onCancel} />
        <Button
          title="Save"
          variant="primary"
          disabled={getValues('title').length === 0}
          onClick={onSave}
        />
      </div>
    </div>
  );
};

export default ChangeCampaignNameForm;
