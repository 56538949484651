import {
  DEFAULT_BUTTON_WIDTH,
  DEFAULT_COLUMNS_TEXT_ATTRIBUTES,
  DEFAULT_COLUMNS_TEXT_STYLE,
  DEFAULT_LAYOUT_IMAGE,
} from '@constants/editor';
import { transformStylesObjectToString } from '@utils/helpers';

import { generateGrapesjsButtonComponent } from '../../button/definitions/component';
import { generateImageComponent } from '../../image/definitions/component';
import { generateGrapesjsTextComponent } from '../../text/definitions/component';

import { generateGrapesjsColumnsComponent } from './component';

const layoutImage = generateImageComponent({
  attributes: { imageUrl: DEFAULT_LAYOUT_IMAGE, isModalOpen: false },
  styles: {
    actualImage: '',
  },
  image: DEFAULT_LAYOUT_IMAGE,
  className: 'layout-image',
});

const COLUMNS_TEXT_BLOCK_CLASSNAME = 'columns-text-block';

export const imageTextButtonSingleColumn = [
  layoutImage,
  generateGrapesjsTextComponent({
    content: 'Write something that is captivating, engaging and meaningful.',
    attributes: DEFAULT_COLUMNS_TEXT_ATTRIBUTES,
    classes: [COLUMNS_TEXT_BLOCK_CLASSNAME],
    styles: `
    .${COLUMNS_TEXT_BLOCK_CLASSNAME} {
       ${transformStylesObjectToString(DEFAULT_COLUMNS_TEXT_STYLE)}
   `,
  }),
  generateGrapesjsButtonComponent({
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    width: DEFAULT_BUTTON_WIDTH,
    buttonCustomWrapperClass: 'gjs-button-wrapper-columns',
    buttonCustomClass: 'gjs-button-columns',
    buttonCustomWrapperStyles: `
        background: transparent;
    `,
  }),
];

export const layoutA = generateGrapesjsColumnsComponent([
  imageTextButtonSingleColumn,
  imageTextButtonSingleColumn,
]);
