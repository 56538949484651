import grapesjs from 'grapesjs';

import { generateLogoComponent } from '@utils/editor/components/logo';
import { createEditorBlock } from '@utils/helpers';

const icon = `
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4173_5356)">
    <path d="M24 36C30.6274 36 36 30.6274 36 24C36 17.3726 30.6274 12 24 12C17.3726 12 12 17.3726 12 24C12 30.6274 17.3726 36 24 36Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1333 17.3333H33.8667" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 24H36" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1333 30.6667H33.8667" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_4173_5356">
    <rect width="32" height="32" fill="white" transform="translate(8 8)"/>
    </clipPath>
    </defs>
  </svg>
`;

const label = createEditorBlock('Logo', icon, 'logo');
const content = generateLogoComponent({ attributes: { isModalOpen: true } });

export const LogoBlock: grapesjs.BlockOptions = {
  label,
  content,
  select: true,
};
