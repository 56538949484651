import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@constants/routes';
import { TemplateObject } from '@devTypes';
import {
  createCampaignThunk,
  updateCampaignThunk,
} from '@store/features/campaigns/asyncThunks';
import { templateAsyncThunks } from '@store/features/templates';
import { useAppDispatch } from '@store/hooks';
import { buildCampaingName } from '@utils/helpers';

const usePresetTemplate = (campaignId: number | null) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createTemplateCopy = async (item: TemplateObject) => {
    const templateCopy = await dispatch(
      templateAsyncThunks.createTemplateThunk({
        title: item.title,
        gjs: item.gjs,
        backgroundStyles: item.backgroundStyles,
        previewUrl: item.previewUrl!,
        htmlContent: item.htmlContent,
        ampContent: item.ampContent,
        globalStyles: item.globalStyles,
      })
    ).unwrap();

    return templateCopy;
  };

  const createCampaign = async (templateCopy: TemplateObject) => {
    if (!templateCopy.id) return;

    await dispatch(
      createCampaignThunk({
        templateId: templateCopy.id,
        title: buildCampaingName(new Date().toISOString()),
      })
    );
  };

  const updateCampaign = async (templateCopy: TemplateObject) => {
    if (!campaignId) return;

    await dispatch(
      updateCampaignThunk({
        id: campaignId,
        templateId: templateCopy.id,
      })
    );
  };

  const onUseTemplate = async (item: TemplateObject) => {
    if (!item.id) return;

    const templateCopy = await createTemplateCopy(item);

    if (campaignId) {
      await updateCampaign(templateCopy);

      navigate(`${ROUTES.template.editor}/${templateCopy.id}`);
      return;
    }

    await createCampaign(templateCopy);

    navigate(ROUTES.dashboard.createCampaign, {
      state: {
        selectedTemplateItem: templateCopy,
      },
    });
  };

  return onUseTemplate;
};

export default usePresetTemplate;
