import { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';

import { EditorBlockSettingContainer, LayoutMenuItem } from '@molecules';
import { DIVIDER_LAYOUTS } from '@constants/editor';
import { LayoutMenuOption } from '@devTypes';
import Menu from '@organisms/Menu/Menu';

interface Props {
  dividerComponent: grapesjs.Component;
}

const EditorDividerBlockSettingsContent = ({ dividerComponent }: Props) => {
  const [selectedDividerLayoutOption, setSelectedDividerLayoutOption] =
    useState<LayoutMenuOption | null>(null);

  useEffect(() => {
    const { selectedId } = dividerComponent.getAttributes();
    const selectedLayout = DIVIDER_LAYOUTS.find(
      (item) => item.id === (selectedId || 0)
    );

    if (!selectedLayout) {
      return;
    }

    setSelectedDividerLayoutOption(selectedLayout);
  }, []);

  const handleLayoutOptionChange =
    (setter: React.Dispatch<React.SetStateAction<LayoutMenuOption | null>>) =>
    (option: LayoutMenuOption) => {
      dividerComponent.addAttributes({
        divider: option.type,
        selectedId: option.id,
      });

      dividerComponent.addStyle({
        'border-bottom-style': option.type,
      });

      setter(option);
    };

  return (
    <EditorBlockSettingContainer title="">
      <Menu
        items={DIVIDER_LAYOUTS}
        value={selectedDividerLayoutOption}
        onChange={handleLayoutOptionChange(setSelectedDividerLayoutOption)}
        ItemComponent={LayoutMenuItem}
        spacing={24}
      />
    </EditorBlockSettingContainer>
  );
};

export default EditorDividerBlockSettingsContent;
