import grapesjs from 'grapesjs';

import { Divider } from '@atoms';
import {
  MAX_COLUMNS_PADDING_IN_PX,
  MIN_COLUMNS_PADDING_IN_PX,
} from '@constants/editor';
import EditorBlockBackgroundSettings from '@organisms/EditorBlockBackgroundSettings/EditorBlockBackgroundSettings';
import EditorTextBlockPaddingSettings from '@organisms/EditorBlockPaddingSettings/EditorBlockPaddingSettings';
import EditorColumnsBlockSpacingSettings from '@organisms/EditorColumnsBlockSpacingSettings/EditorColumnsBlockSpacingSettings';

import './styles.css';

interface Props {
  selectedComponent: grapesjs.Component;
}

const EditorColumnsBlockSettingsDesign = ({ selectedComponent }: Props) => (
  <div className="EditorColumnsBlockSettingsDesign">
    <EditorBlockBackgroundSettings selectedComponent={selectedComponent} />
    <Divider />
    <div className="EditorColumnsBlockSettingsDesign-PaddingSection">
      <EditorTextBlockPaddingSettings
        selectedComponent={selectedComponent}
        min={MIN_COLUMNS_PADDING_IN_PX}
        max={MAX_COLUMNS_PADDING_IN_PX}
      />
    </div>
    <Divider />
    <div className="EditorColumnsBlockSettingsDesign-ColumnSpacingSection">
      <EditorColumnsBlockSpacingSettings
        selectedComponent={selectedComponent}
      />
    </div>
  </div>
);

export default EditorColumnsBlockSettingsDesign;
