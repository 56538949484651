import clsx from 'clsx';

import { Icon, Typography } from '@atoms';
import { MenuItemComponent } from '@devTypes';

const EditorCanvasHeaderItem = ({
  isSelected,
  onClick,
  icon,
  title,
  disabled,
}: MenuItemComponent) => (
  <div
    className={clsx(
      disabled && 'EditorCanvasHeaderItem-disabled',
      isSelected && 'EditorCanvasHeaderItem-selected',
      !disabled && 'EditorCanvasHeaderItem',
      'flexbox-row',
      'flexbox-gap-10px',
      'flexbox-jc-center',
      'flexbox-ai-center'
    )}
    role="button"
    tabIndex={0}
    onClick={disabled ? () => {} : onClick}
  >
    <Icon SVG={icon} width={20} height={20} />
    <Typography variant="subtitle4">{title}</Typography>
  </div>
);

export default EditorCanvasHeaderItem;
