import { Typography } from '@atoms';
import { DefaultInputProps } from '@devTypes';
import DefaultInput from '@molecules/DefaultInput/DefaultInput';

interface Props extends DefaultInputProps {
  title: string;
}

const DefaultInputWithTitle = ({ title, ...rest }: Props) => (
  <div className="flexbox-column flexbox-gap-6px">
    <Typography variant="subtitle4">{title}</Typography>
    <DefaultInput {...rest} />
  </div>
);

export default DefaultInputWithTitle;
