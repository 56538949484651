import { useState } from 'react';

import ContactsTable from '@templates/AllContactsTemplate/ContactsTable';
import ContactsTotalAmounts from '@templates/AllContactsTemplate/ContactsTotalAmounts';

import './styles.css';

const AllContactsTemplate = () => {
  const [selectedTab, setSelectedTab] = useState('');

  return (
    <div className="AllContactsTemplate">
      <ContactsTotalAmounts
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <ContactsTable selectedTab={selectedTab} />
    </div>
  );
};

export default AllContactsTemplate;
