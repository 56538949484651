import grapesjs from 'grapesjs';

import { EditorCarouselFallback, EditorCarouselLayout } from '@organisms';
import {
  DEFAULT_CAROUSEL_IMAGE_FIT,
  DEFAULT_CAROUSEL_NAVIGATION_COLOR,
  DEFAULT_IMAGE_BLOCK,
  EditorComponentTypes,
} from '@constants/editor';
import { getChildComponentAt } from '@utils/helpers';

const settings = [
  'navigationColor',
  'imageFit',
  'images',
  'paddingtop',
  'paddingbottom',
  'paddingright',
  'paddingleft',
  'backgroundColor',
];

const carouselPlugin = (editor: grapesjs.Editor) => {
  const carouselBlocks = {
    fallback: EditorCarouselFallback,
    amp: EditorCarouselLayout,
  };

  editor.DomComponents.addType(EditorComponentTypes.Carousel, {
    extend: 'react-component',
    model: {
      defaults: {
        component: EditorCarouselLayout,
        tagName: 'div',
        classes: ['carousel-root'],
        styles: '.carousel-root { background-color: #ffffff; }',
        attributes: {
          navigationColor: DEFAULT_CAROUSEL_NAVIGATION_COLOR,
          imageFit: DEFAULT_CAROUSEL_IMAGE_FIT,
          backgroundColor: '#ffffff',
          images: [],
          placeholders: [
            {
              id: 0,
              url: DEFAULT_IMAGE_BLOCK,
            },
            {
              id: 1,
              url: DEFAULT_IMAGE_BLOCK,
            },
            {
              id: 2,
              url: DEFAULT_IMAGE_BLOCK,
            },
          ],
        },
      },
      init() {
        this.listenTo(
          this,
          settings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          getChildComponentAt(this, 0),
          settings.map((s) => `change:attributes:${s}`).join(' '),
          this.handleSettingsChange
        );
        this.listenTo(
          this,
          'change:attributes:emailType',
          this.handleEmailTypeChange
        );
      },
      handleEmailTypeChange() {
        const { emailType } = this.getAttributes() as {
          emailType: 'fallback' | 'amp';
        };

        this.attributes.component = carouselBlocks[emailType];
      },
      handleSettingsChange() {
        document.dispatchEvent(
          new CustomEvent('settingsChange', { detail: false })
        );
        this.addAttributes({ settingsChanged: true });
      },
    },
  });
};

export default carouselPlugin;
