import { createAsyncThunk } from '@reduxjs/toolkit';

const createCommonAsyncThunk = <T>(
  name: string,
  request: (body: T) => Promise<any>
) =>
  createAsyncThunk(name, async (body: T, { rejectWithValue }) => {
    const { data, error } = await request(body);

    if (error) {
      // eslint-disable-next-line
      console.error(error);
      return rejectWithValue(error);
    }

    return data;
  });

export { createCommonAsyncThunk };
