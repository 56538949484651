import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Icon, Loader } from '@atoms';
import { CheckboxWithTitle, InputWithTitle, Link } from '@molecules';
import { ReactComponent as CheckIcon } from '@assets/icons/validation-check.svg';
import { ROUTES } from '@constants/routes';
import { authSelectors } from '@store/features/auth';
import { useAppSelector } from '@store/hooks';

import './styles.css';

const SignIn = () => {
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);

  const { control, setValue, formState } = useFormContext();
  const isLoading = useAppSelector(authSelectors.isSignInLoading);

  const handleRememberMeChange = () => {
    setIsRememberMeChecked((prevState) => {
      const value = !prevState;
      setValue('rememberMe', value);

      return value;
    });
  };

  const isValidationCheckShown =
    !formState.errors.email && formState.touchedFields.email;

  if (isLoading) return <Loader height={228} />;

  return (
    <>
      <InputWithTitle
        name="email"
        title="Email"
        control={control}
        endIcon={
          isValidationCheckShown && (
            <Icon
              SVG={CheckIcon}
              height={24}
              width={24}
              className="ValidationCheckIcon"
            />
          )
        }
        isRequired
      />
      <InputWithTitle
        name="password"
        title="Password"
        type="password"
        control={control}
        isRequired
      />
      <div className="RememberMeBlock">
        <CheckboxWithTitle
          title="Remember me"
          isChecked={isRememberMeChecked}
          onChange={handleRememberMeChange}
          {...control.register}
        />
        <Link
          to={ROUTES.forgotPassword}
          text="Forgot password?"
          typographyVariant="subtitle4"
        />
      </div>
    </>
  );
};

export default SignIn;
