import { useMemo } from 'react';
import grapesjs from 'grapesjs';

import { getChildComponentAt } from '@utils/helpers';

import EditorButtonBlockSettingsContent from './EditorButtonBlockSettingsContent';
import EditorButtonBlockSettingsDesign from './EditorButtonBlockSettingsDesign';

interface Props {
  selectedComponent: grapesjs.Component;
  editor: grapesjs.Editor;
  type: string;
}

const EditorButtonBlockSettings = ({
  editor,
  selectedComponent,
  type,
}: Props) => {
  const buttonComponent = useMemo(
    () => getChildComponentAt(selectedComponent, 0),
    [selectedComponent]
  );

  const renderSettings = () => {
    if (type === 'content') {
      return (
        <EditorButtonBlockSettingsContent buttonComponent={buttonComponent} />
      );
    }

    if (type === 'design') {
      return (
        <EditorButtonBlockSettingsDesign
          editor={editor}
          buttonContainerComponent={selectedComponent}
          buttonComponent={buttonComponent}
        />
      );
    }

    return null;
  };

  return <div className="EditorColumnsBlockSettings">{renderSettings()}</div>;
};

export default EditorButtonBlockSettings;
