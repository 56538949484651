import { Button, Divider, Typography } from '@atoms';
import { CheckboxWithTitle } from '@molecules';

export interface FiltersDropdownContentProps {
  isUnsubscribedContactsChecked: boolean;
  isResubscribedContactsChecked: boolean;
  onUnsubscribedContactsChange: () => void;
  onSubscribedContactsChange: () => void;
  onCancelClick: () => void;
  onApplyClick: () => void;
}

const FiltersDropdownContent = ({
  isUnsubscribedContactsChecked,
  isResubscribedContactsChecked,
  onUnsubscribedContactsChange,
  onSubscribedContactsChange,
  onCancelClick,
  onApplyClick,
}: FiltersDropdownContentProps) => (
  <div className="FiltersDropdownContent">
    <Typography variant="subtitle3">Filter by</Typography>
    <Divider />
    <div className="CheckboxContainer">
      <CheckboxWithTitle
        title="Unsubscribed contacts"
        isChecked={isUnsubscribedContactsChecked}
        onChange={onUnsubscribedContactsChange}
      />
      <CheckboxWithTitle
        title="Subscribed contacts"
        isChecked={isResubscribedContactsChecked}
        onChange={onSubscribedContactsChange}
      />
    </div>
    <Divider />
    <div className="FiltersDropdownContent-Buttons">
      <Button title="Cancel" variant="secondary" onClick={onCancelClick} />
      <Button title="Apply" variant="primary" onClick={onApplyClick} />
    </div>
  </div>
);

export default FiltersDropdownContent;
