import { ENDPOINTS } from '@constants/api';

import { wrappedAxiosRequest } from '../../api';

export interface CreateDomainData {
  customDomainEmail: string;
}

export interface DeleteSenderData {
  id: number;
  senderId: number;
}

const domainsApi = {
  async registerDomain({ customDomainEmail }: CreateDomainData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.domains.create,
      data: {
        email: customDomainEmail,
      },
    });
  },

  async getListOfDomains() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.domains.get,
    });
  },

  async validateDomain(id: number) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.domains.validate(id),
    });
  },

  async getAvailableSenders() {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.domains.availableSenders,
    });
  },

  async deleteDomain(id: number) {
    return wrappedAxiosRequest({
      method: 'delete',
      url: ENDPOINTS.domains.deleteDomain(id),
    });
  },

  async deleteSender({ id, senderId }: DeleteSenderData) {
    return wrappedAxiosRequest({
      method: 'delete',
      url: ENDPOINTS.domains.deleteSender(id, senderId),
    });
  },

  async sendError(error: string) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.reporting.sendError,
      data: {
        error,
      },
    });
  },
};

export default domainsApi;
