import { useState } from 'react';

import { Button, Typography } from '@atoms';
import { Modal, SelectWithTitle } from '@molecules';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const selectOptions = [
  'Does not meet my needs',
  'App is not performing well',
  'Not needed anymore',
  'I found a better app',
  'App is not worth the cost',
  'Other',
];

const DisconnectShopifyModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const [selectValue, setSelectValue] = useState<string | null>('');

  const handleChange = (value: string) => setSelectValue(value);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="ShopifyModal-Content">
        <Typography variant="subtitle1">
          Are you sure you want to deactivate Shopify?
        </Typography>
        <div className="DisconnectShopifyModal-Content">
          <Typography variant="subtitle4">
            By deactivating Shopify, you will no longer be able to view it in
            your Shopify admin or access its features.
          </Typography>
          <ul>
            <li>
              <Typography variant="subtitle6">
                Any app sections or app snippets added to your online store will
                also be automatically removed.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle6">
                Discounts that depend on this app will be permanently deleted.
              </Typography>
            </li>
          </ul>
          <div className="DisconnectShopifyModal-Note">
            <Typography variant="subtitle4">
              We’ll send a request to the app developer to delete all personal
              customer information unless you add the app again within 48 hours.
              {/* todo: add data privacy link */}
              Learn more about data privacy.
            </Typography>
          </div>
          <Typography variant="subtitle3" className="FeedbackSelectLabel">
            Feedback (optional)
          </Typography>
          <SelectWithTitle
            title="Reason for deleting"
            value={selectValue}
            onChange={handleChange}
            options={selectOptions}
            placeholder="Select"
          />
        </div>
        <div className="ShopifyModal-ButtonGroup">
          <Button variant="secondary" onClick={onClose} title="Cancel" />
          <Button variant="error" onClick={onSubmit} title="Delete" />
        </div>
      </div>
    </Modal>
  );
};

export default DisconnectShopifyModal;
