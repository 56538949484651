import { Icon } from '@atoms';
import { ReactComponent as MinusIcon } from '@assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import { ShopifyProductOption } from '@devTypes';
import { useCounter } from '@hooks';

interface Props {
  title: string;
  options: ShopifyProductOption[];
}

const EcommerceLayout2CartItem = ({ title, options }: Props) => {
  const { count, decrementCount, incrementCount } = useCounter(1);
  return (
    <div className="pb-5 flex flex-col gap-1.5 border-b border-solid border-gray-300 last:border-b-0 last:pb-4">
      <div className="flex items-center justify-between">
        <div>{title}</div>
        <div className="flex items-center gap-2.5">
          <div className="border border-ecommerce-300 px-0.5 py-0.5 rounded">
            <Icon
              className="stroke-black cursor-pointer"
              SVG={MinusIcon}
              width={20}
              height={20}
              onClick={decrementCount}
            />
          </div>
          <div className="px-2 py-0.5 rounded-sm">{count}</div>
          <div className=" border border-ecommerce-300 px-0.5 py-0.5 rounded">
            <Icon
              className="stroke-black cursor-pointer"
              SVG={PlusIcon}
              width={20}
              height={20}
              onClick={incrementCount}
            />
          </div>
        </div>
      </div>
      <div className="text-ecommerce-600  divide-x divide-solid divide-ecommerce-600">
        {options.map((option) => (
          <span key={option.id} className="first:pl-0 last:pr-0 pr-1.5 pl-1.5">
            {option.name}
          </span>
        ))}
      </div>
    </div>
  );
};
export default EcommerceLayout2CartItem;
