import { useState } from 'react';
import clsx from 'clsx';

import { Icon } from '@atoms';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/chevron-down.svg';

import './styles.css';

interface Props {
  title: string;
  content: string | React.ReactNode;
}

const EcommerceDropdown = ({ title, content }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={clsx(
        'EcommerceDropdown',
        'flexbox-column',
        'flexbox-gap-10px',
        isOpen && 'EcommerceDropdown-open'
      )}
    >
      <div className="flexbox-row flexbox-ai-center flexbox-jc-space-between">
        <div className="EcommerceDropdownTitle">{title}</div>
        <Icon
          SVG={ArrowDownIcon}
          width={24}
          height={24}
          onClick={() => setIsOpen((prevState) => !prevState)}
        />
      </div>
      {isOpen && <div className="EcommerceDropdownContent">{content}</div>}
    </div>
  );
};

export default EcommerceDropdown;
