/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-regex-literals */
// @ts-nocheck
import CssFilterConverter from 'css-filter-converter';
import sanitizeHtml from 'sanitize-html';

import {
  BlockType,
  DIVIDER_LAYOUTS,
  LOGO_ALIGNMENT_OPTIONS,
} from '@constants/editor';

// import { BUTTON_CLASS } from '../components/button';
// import { DIVIDER_CLASS, DIVIDER_WRAPPER_CLASS } from '../components/divider';
// import { SPACER_WRAPPER_CLASS } from '../components/spacer';
import { BLOCK_TYPES } from './types';

const DEFAULT_DIVIDER_TYPE = 0;
interface ConvertedTypeInterface {
  type: string;
  class: string;
}

const buildBlockStyle = (id: string | undefined, css: string | undefined) => {
  const blockCss = css ? css.substring(css.indexOf(`#${id}{`)) : '';
  const formattedCss = blockCss.substring(
    blockCss.indexOf('{') + 1,
    blockCss.indexOf('}')
  );
  return formattedCss;
};

const buildPayload = (
  convertedType: ConvertedTypeInterface,
  item: grapesjs.default.Component,
  styles: string
) => {
  const classes = item.attributes.classes.length
    ? item.attributes.classes.map((classModel) => classModel.id)
    : [];
  switch (convertedType.type) {
    case BLOCK_TYPES.TEXT_BLOCK_TYPE:
      const dirtyTextComponentHtml = item.view?.el.outerHTML;
      const cleanTextComponentHtml = sanitizeHtml(dirtyTextComponentHtml, {
        allowedAttributes: {
          div: ['id', 'href', 'alt', 'color', 'class', 'style'],
          '*': ['style', 'href'],
        },
        parseStyleAttributes: false,
      });

      return {
        html: cleanTextComponentHtml,
        styleClasses: [...classes],
        styles,
      };
    case BLOCK_TYPES.GJSBUTTON_TYPE:
      return {
        href: item.attributes?.attributes?.href || '',
        text: item.view?.el.textContent.replace(/\s{2,3}/g, ' &#xA0;'),
        styleClasses: [...classes],
        styles,
      };
    case BLOCK_TYPES.DIVIDER_TYPE:
      return {
        styleClasses: [...classes],
        styles,
      };
    case BLOCK_TYPES.GJSDIVIDER_TYPE:
      return {
        divider:
          DIVIDER_LAYOUTS[
            item.attributes?.attributes?.selectedId || DEFAULT_DIVIDER_TYPE
          ].type,
        width: item.view?.el.offsetWidth.toString(),
        height: item.view?.el.offsetHeight.toString(),
        styleClasses: [...classes],
        styles,
      };
    case BLOCK_TYPES.SPACER:
      return {
        styleClasses: [...classes],
        styles,
      };
    case BLOCK_TYPES.LOGO:
      return {
        alt: 'alt',
        size: item.attributes?.attributes?.size,
        alignment: LOGO_ALIGNMENT_OPTIONS.find(
          (alignmentOption) =>
            alignmentOption.id === item.attributes?.attributes?.alignId
        )?.value.align,
        url: item.attributes?.attributes?.imageUrl,
        width: (
          item.view?.el.offsetWidth -
          (item.attributes?.attributes?.paddingleft || 0) -
          (item.attributes?.attributes?.paddingright || 0)
        ).toString(),
        height: (
          item.view?.el.offsetHeight -
          (item.attributes?.attributes?.paddingtop || 0) -
          (item.attributes?.attributes?.paddingbottom || 0)
        ).toString(),
        href:
          item.attributes?.components?.models[1]?.attributes?.attributes
            ?.href || '',
        styleClasses: [...classes],
        styles,
      };
    case BLOCK_TYPES.CUSTOM_IMAGE_TYPE:
      return {
        alt: 'alt',
        url: item.attributes?.attributes?.imageUrl,
        width: (item.attributes?.components.models[0].view?.el.width)
          // -
          // (item.attributes?.attributes?.paddingleft || 0) -
          // (item.attributes?.attributes?.paddingright || 0)
          .toString(),
        height: (item.attributes?.components.models[0].view?.el.height)
          // -
          // (item.attributes?.attributes?.paddingtop || 0) -
          // (item.attributes?.attributes?.paddingbottom || 0)
          .toString(),
        href:
          item.attributes?.components?.models[1]?.attributes?.attributes
            ?.href || '',
        styleClasses: ['actual-image', ...classes],
        styles,
      };
    case BLOCK_TYPES.CAROUSEL:
      return {
        images: item.attributes?.attributes?.images.map(
          (image: GalleryItemProps) => image.url
        ),
        width: item.view?.el.offsetWidth.toString(),
        height: item.view?.el.offsetHeight.toString(),
        imageFit: item.attributes?.attributes?.imageFit,
        navigationColor: item.attributes?.attributes?.navigationColor,
        styleClasses: ['actual-image', ...classes],
        styles,
      };
    case BLOCK_TYPES.FOOTER:
      const dirtyFooterComponentHtml = item.view?.el.outerHTML;
      const cleanFooterComponentHtml = sanitizeHtml(dirtyFooterComponentHtml, {
        allowedAttributes: {
          div: ['id', 'href', 'alt', 'class', 'src'],
          img: ['href', 'alt', 'class', 'src'],
          a: ['href', 'id'],
          table: ['id', 'class'],
          tr: ['id', 'class'],
          td: ['id', 'class'],
          u: ['id', 'class'],
        },
        allowedTags: ['div', 'img', 'table', 'tr', 'td', 'u', 'a'],
      });

      let filledHtmlWithRef = cleanFooterComponentHtml;

      if (
        item.attributes.attributes.link &&
        item.attributes.attributes.link !== '' &&
        item.attributes.attributes.link.indexOf('https://') === -1
      ) {
        const visitWebsiteRegExp = new RegExp(
          `href="${item.attributes.attributes.link}"`,
          'g'
        );
        filledHtmlWithRef = filledHtmlWithRef.replace(
          visitWebsiteRegExp,
          `href="https://${item.attributes.attributes.link}"`
        );
      }

      if (
        item.attributes.attributes.facebookLink &&
        item.attributes.attributes.facebookLink !== '' &&
        item.attributes.attributes.facebookLink.indexOf('https://') === -1
      ) {
        const facebookRegExp = new RegExp(
          `href="${item.attributes.attributes.facebookLink}"><img src="https://appmail-images.s3.amazonaws.com/FB`,
          'g'
        );
        filledHtmlWithRef = filledHtmlWithRef.replace(
          facebookRegExp,
          `href="https://facebook.com/${item.attributes.attributes.facebookLink}"><img id="${item.attributes.attributes.ccid}" src="https://appmail-images.s3.amazonaws.com/FB`
        );
      }

      if (
        item.attributes.attributes.instagramLink &&
        item.attributes.attributes.instagramLink !== '' &&
        item.attributes.attributes.instagramLink.indexOf('https://') === -1
      ) {
        const instagramLinkRegExp = new RegExp(
          `href="${item.attributes.attributes.instagramLink}"><img src="https://appmail-images.s3.amazonaws.com/IG`,
          'g'
        );
        filledHtmlWithRef = filledHtmlWithRef.replace(
          instagramLinkRegExp,
          `href="https://instagram.com/${item.attributes.attributes.instagramLink}"><img src="https://appmail-images.s3.amazonaws.com/IG`
        );
      }
      if (
        item.attributes.attributes.youtubeLink &&
        item.attributes.attributes.youtubeLink !== '' &&
        item.attributes.attributes.youtubeLink.indexOf('https://') === -1
      ) {
        const youtubeLinkRegExp = new RegExp(
          `href="${item.attributes.attributes.youtubeLink}"><img src="https://appmail-images.s3.amazonaws.com/YouTube`,
          'g'
        );
        filledHtmlWithRef = filledHtmlWithRef.replace(
          youtubeLinkRegExp,
          `href="https://youtube.com/${item.attributes.attributes.youtubeLink}"><img src="https://appmail-images.s3.amazonaws.com/YouTube`
        );
      }

      if (
        item.attributes.attributes.twitterLink &&
        item.attributes.attributes.twitterLink !== '' &&
        item.attributes.attributes.twitterLink.indexOf('https://') === -1
      ) {
        const twitterLinkRegExp = new RegExp(
          `href="${item.attributes.attributes.twitterLink}"><img src="https://appmail-images.s3.amazonaws.com/Twitter`,
          'g'
        );
        filledHtmlWithRef = filledHtmlWithRef.replace(
          twitterLinkRegExp,
          `href="https://twitter.com/${item.attributes.attributes.twitterLink}"><img src="https://appmail-images.s3.amazonaws.com/Twitter`
        );
      }

      if (
        item.attributes.attributes.tiktokLink &&
        item.attributes.attributes.tiktokLink !== '' &&
        item.attributes.attributes.tiktokLink.indexOf('https://') === -1
      ) {
        const tiktokLinkRegExp = new RegExp(
          `href="${item.attributes.attributes.tiktokLink}"><img src="https://appmail-images.s3.amazonaws.com/TikTok`,
          'g'
        );
        filledHtmlWithRef = filledHtmlWithRef.replace(
          tiktokLinkRegExp,
          `href="https://tiktok.com/${item.attributes.attributes.tiktokLink}"><img src="https://appmail-images.s3.amazonaws.com/TikTok`
        );
      }

      return {
        html: filledHtmlWithRef,
        styleClasses: ['footer-container', ...classes],
        styles,
      };
    case BLOCK_TYPES.ECOMMERCE_BLOCK_TYPE:
      const {
        checkoutButtonBorderColor,
        checkoutButtonBorderRadius,
        checkoutButtonFillColor,
        checkoutButtonText,
        checkoutButtonTextColor,
        checkoutButtonBorderWidth,
        dropdownGeneralButtonsStrokeColor,
        font,
        headerFont,
        headerTextColor,
        isHeaderBold,
        isHeaderItalic,
        isProductDescription,
        layout,
        productTabFillColor,
        productTabLineColor,
        productTabTextColor,
        products,
        textColor,
      } = item.attributes.attributes;

      const productIds = products
        ? products.map((product) => product.id.toString())
        : [];

      return {
        checkoutButtonBorderColor,
        checkoutButtonBorderRadius,
        checkoutButtonFillColor,
        checkoutButtonText: checkoutButtonText.replace(/\s{2,3}/g, ' &#xA0;'),
        checkoutButtonTextColor,
        dropdownGeneralButtonsStrokeColor,
        dropdownGeneralButtonsStrokeFilterValue: CssFilterConverter.hexToFilter(
          dropdownGeneralButtonsStrokeColor
        ).color,
        checkoutButtonBorderWidth,
        font,
        headerFont,
        headerTextColor,
        isHeaderBold,
        isHeaderItalic,
        isProductDescription,
        type: layout === 'layout2' ? 'vertical' : 'horizontal',
        productTabFillColor,
        productTabLineColor,
        productTabTextColor,
        productTabTextFilterValue:
          CssFilterConverter.hexToFilter(productTabTextColor).color,
        productIds,
        textColor,
        styleClasses: [...classes],
        styles,
      };
    default:
      return {
        styles,
        styleClasses: [convertedType.class, ...classes],
      };
  }
};

const typeConvertor = (type: string) => {
  let convertedType: ConvertedTypeInterface = { type: '', class: '' };
  switch (type) {
    case 'columns':
      convertedType = { type: 'column', class: 'row' };
      break;
    case 'column':
      convertedType = { type: 'cell', class: 'column' };
      break;
    case 'button':
      convertedType = { type, class: 'gjs-button-wrapper' };
      break;
    default:
      convertedType = { type, class: type };
      break;
  }
  return convertedType;
};

const blockOrderBuilder = (components: grapesjs.default.Components) => {
  const blockOrderArray: string[] = [];
  components.forEach((item: grapesjs.default.Component) =>
    blockOrderArray.push(item.ccid || '')
  );
  return blockOrderArray;
};

export const buildJsonTemplate = (
  editor: grapesjs.default.Editor | undefined,
  backgroundStyles: string
) => {
  const components = editor?.DomComponents.getComponents().models;
  const css = editor?.getCss();

  const blocks: BlockType[] = [];
  const blockOrder = blockOrderBuilder(components);

  const deepForEach = (models: grapesjs.default.Component[] | undefined) => {
    models?.forEach((item: grapesjs.default.Component) => {
      const styles = buildBlockStyle(item.ccid, css?.toString());
      const convertedType = typeConvertor(
        item.attributes.type !== ''
          ? item.attributes.type
          : item.attributes?.attributes?.class ||
              item.attributes.classes.models[0].attributes.name
      );
      const isNestedType =
        convertedType.type === 'cell' ||
        convertedType.type === 'column' ||
        convertedType.type === 'button' ||
        convertedType.type === 'divider';
      const payload = buildPayload(convertedType, item, styles);
      if (isNestedType) {
        const nestedIds: string[] = [];
        const nestedBlocks = item.attributes?.components?.models;
        nestedBlocks.forEach((nestedComp: grapesjs.default.Components) =>
          nestedIds.push(nestedComp.ccid)
        );
        blocks.push({
          elementId: item.ccid,
          value: {
            type: convertedType.type,
            payload: { ...payload, nested: nestedIds },
          },
        });
        deepForEach(nestedBlocks);
      } else {
        blocks.push({
          elementId: item.ccid,
          value: {
            type: convertedType.type,
            payload,
          },
        });
      }
    });
  };
  deepForEach(components);
  const generalCss = `.layout-image {
    width: 305px;
  }p{margin: 0;}
    `;
  const newJson = {
    title: 'new',
    status: 'new',
    blocks,
    blockOrder,
    globalStyles: css?.concat(generalCss),
    backgroundStyles,
    gjs: JSON.stringify(editor?.getProjectData()),
  };
  return newJson;
};

export default buildJsonTemplate;
