/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState } from 'react';
import clsx from 'clsx';

import { Tooltip, Typography } from '@atoms';

import './styles.css';

interface Props {
  isActive?: boolean;
  tooltipText?: string;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

const SelectorOption = ({
  isActive = false,
  tooltipText = '',
  text,
  disabled = false,
  onClick,
}: Props) => {
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>();

  const handleClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  };

  return (
    <span
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      className={clsx(
        'SelectorOption',
        disabled && 'SelectorOption-disabled',
        isActive && 'SelectorOption-active'
      )}
      onClick={handleClick}
    >
      {tooltipText && isTooltipVisible && <Tooltip label={tooltipText} />}
      <Typography variant="subtitle3">{text}</Typography>
    </span>
  );
};

export default SelectorOption;
