import grapesjs from 'grapesjs';

import { EditorAddBlockContainer } from '@organisms';

const addBlockPlugin = (editor: grapesjs.Editor) => {
  editor.DomComponents.addType('add-block', {
    extend: 'react-component',
    model: {
      defaults: {
        component: EditorAddBlockContainer,
        droppable: false,
        draggable: false,
        selectable: false,
        hoverable: false,
        copyable: false,
      },
    },
  });
};

export default addBlockPlugin;
