import {
  DEFAULT_BACKGROUND_VALUE,
  EditorComponentTypes,
} from '@constants/editor';

export const generateImageComponent = ({
  attributes,
  styles,
  image,
  className,
}: {
  attributes: Record<string, any>;
  styles?: { actualImage: string; imageWrapper?: string };
  image: string;
  className?: string;
}) => ({
  tagName: 'div',
  type: EditorComponentTypes.Image,
  classes: className,
  droppable: false,
  attributes: {
    ...attributes,
  },
  components: [
    {
      tagName: 'img',
      classes: 'actual-image',
      selectable: false,
      droppable: false,
      draggable: false,
      hoverable: false,
      src: image,
      attributes: {
        src: image,
      },
    },
    {
      tagName: 'a',
      classes: 'image-link',
      selectable: false,
      droppable: false,
      draggable: false,
      hoverable: false,
      layerable: false,
    },
  ],

  styles: `
  .${className} {
    width: 100%;
    position: relative;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
    ${styles?.imageWrapper}
  }

  .actual-image {
    width: 100%;
    ${styles?.actualImage}
  }

  .image-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
  }

  .column .image-wrapper {
    width: 100%;
    position: relative;
    background-color: ${DEFAULT_BACKGROUND_VALUE};
    padding: unset;
  }
  `,
});
