import { ENDPOINTS } from '@constants/api';
import { wrappedAxiosRequest } from '@store/api';

export interface AddOrganizationData {
  title?: string;
  imageUrl: string;
}

const organizationsApi = {
  async getMeOrganizationData() {
    return wrappedAxiosRequest({
      url: ENDPOINTS.organisations.getMeOrganizationData,
      method: 'get',
    });
  },
  async addOrganizationData({ title, imageUrl }: AddOrganizationData) {
    return wrappedAxiosRequest({
      method: 'put',
      url: ENDPOINTS.organisations.addOrganizationData,
      data: {
        title,
        imageUrl,
      },
    });
  },
};

export default organizationsApi;
