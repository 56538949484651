import { ElementParams } from '@devTypes';

export type ElementAttributes = { [key: string]: string | object };

export const coloredTextDefinition: ElementParams = {
  tagName: 'span',
  type: 'color',
};

export const getColoredTextDefinition: (color: string) => ElementParams = (
  color
) => ({
  ...coloredTextDefinition,
  attributes: {
    style: {
      color,
    },
  },
});

export const boldTextDefinition: ElementParams = {
  tagName: 'b',
  type: 'bold',
};

export const italicTextDefinition: ElementParams = {
  tagName: 'i',
  type: 'italic',
};

export const underlinedTextDefinition: ElementParams = {
  tagName: 'u',
  type: 'underline',
};

export const strikethroughTextDefinition: ElementParams = {
  tagName: 's',
  type: 'strikethrough',
};

export const highlightTextDefinition: ElementParams = {
  tagName: 'span',
  type: 'highlight',
};

export const getHighlightTextWithColorDefinition: (
  color: string
) => ElementParams = (color) => ({
  ...highlightTextDefinition,
  attributes: {
    style: {
      'background-color': color,
    },
  },
});

export const linkDefinition: ElementParams = {
  tagName: 'a',
  type: 'link',
};

export const getLinkWithAttributesDefinition: (
  linkURL: string
) => ElementParams = (linkURL) => ({
  ...linkDefinition,
  attributes: {
    href: linkURL,
    style: {
      'text-decoration': 'none',
    },
  },
  content: underlinedTextDefinition,
});

export const removeLinkDefinition: ElementParams = {
  ...linkDefinition,
  removeLink: true,
};

export const letterSpacingDefinition: ElementParams = {
  tagName: 'span',
  type: 'letter-spacing',
};

export const getLetterSpacingDefinition: (
  letterSpacing: string
) => ElementParams = (letterSpacing) => ({
  ...letterSpacingDefinition,
  attributes: {
    style: {
      'letter-spacing': letterSpacing,
    },
  },
});
