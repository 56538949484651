import { Control } from 'react-hook-form';

import { Divider, Toggle, Typography } from '@atoms';
import { SelectWithTitle } from '@molecules';
import { TooltipSlider } from '@atoms/Slider/TooltipSlider';
import TextAreasSection, {
  InputType,
  TextStateDirection,
  TextStateIndex,
} from '@organisms/GenerateAISettings/TextAreasSection';
import { Action, Format } from '@store/features/generativeAI/api';

export type ActionOption = {
  id: Action;
  title: string;
};

export const actions: ActionOption[] = [
  { id: 'Rewrite', title: 'Rewrite' },
  { id: 'Create', title: 'Write with prompt' },
];

export type FormatOption = {
  id: Format;
  title: string;
};

export const formats: FormatOption[] = [
  { id: 'Body', title: 'Body' },
  { id: 'Header', title: 'Header' },
  { id: 'CallToAction', title: 'Call to action' },
];

// const MAX_COPY_LENGTH = 500;
// const MIN_COPY_LENGTH = 0;
export const DEFAULT_COPY_LENGTH = 10;

interface Props {
  action: ActionOption;
  format: FormatOption;
  // copyLength: number;
  isCopyLengthChecked: boolean;
  control: Control<any, any>;
  isLoadingOutput: boolean;
  isResponseGeneratedForInput: boolean;
  isResponseGeneratedForPrompt: boolean;
  isCopyLengthSliderVisible: boolean;
  handleInputCancelClick: (name: string) => void;
  onActionChange: (action: ActionOption) => void;
  onFormatChange: (format: FormatOption) => void;
  onGenerateOutputClick: () => void;
  onCopyLengthChange: (value: number[]) => void;
  onCopyLengthToggle: () => void;
  onCancelClick: () => void;
  onUseOutputCopyClick: () => void;
  onShowPrevOrNextTextClick: (
    inputType: InputType,
    direction: TextStateDirection
  ) => () => void;
  currentStateNumber: TextStateIndex;
  totalStatesAmount: TextStateIndex;
}

const ContentTab = ({
  action,
  format,
  // copyLength,
  isCopyLengthChecked,
  control,
  isLoadingOutput,
  isResponseGeneratedForInput,
  isResponseGeneratedForPrompt,
  isCopyLengthSliderVisible,
  onActionChange,
  onFormatChange,
  onGenerateOutputClick,
  onCopyLengthChange,
  onCopyLengthToggle,
  onCancelClick,
  onUseOutputCopyClick,
  onShowPrevOrNextTextClick,
  currentStateNumber,
  totalStatesAmount,
  handleInputCancelClick,
}: Props) => (
  <div className="ContentTab">
    <SelectWithTitle
      title="Action"
      value={action}
      onChange={onActionChange}
      options={actions}
      className="ContentTab-Select"
    />
    <SelectWithTitle
      title="Format"
      value={format}
      onChange={onFormatChange}
      options={formats}
      className="ContentTab-Select"
    />
    {isCopyLengthSliderVisible && (
      <div className="ContentTab-SliderBlock">
        <div className="ContentTab-SliderBlock-Title">
          <Typography variant="subtitle4">Approx. word count</Typography>
          <Toggle
            isChecked={isCopyLengthChecked}
            onChange={onCopyLengthToggle}
          />
        </div>
        <TooltipSlider
          disabled={!isCopyLengthChecked}
          // value={copyLength}
          onChange={onCopyLengthChange}
          // max={MAX_COPY_LENGTH}
          //  min={MIN_COPY_LENGTH}
        />
      </div>
    )}
    <Divider className="ContentTab-Divider" />
    <TextAreasSection
      control={control}
      actionId={action.id}
      isResponseGeneratedForInput={isResponseGeneratedForInput}
      isResponseGeneratedForPrompt={isResponseGeneratedForPrompt}
      isLoadingOutput={isLoadingOutput}
      onGenerateOutputClick={onGenerateOutputClick}
      onCancelClick={onCancelClick}
      onUseOutputCopyClick={onUseOutputCopyClick}
      onShowPrevOrNextTextClick={onShowPrevOrNextTextClick}
      handleInputCancelClick={handleInputCancelClick}
      currentStateNumber={currentStateNumber}
      totalStatesAmount={totalStatesAmount}
    />
  </div>
);

export default ContentTab;
