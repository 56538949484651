import { MouseEventHandler } from 'react';

import { Button } from '@atoms';

import HeaderTitle from './HeaderTitle';
import TemplateActionsDropdown from './TemplateActionsDropdown';

interface HeaderProps {
  campaignTitle: string;
  onEditClick: MouseEventHandler;
  onEditCampaignName: () => void;
  isEditDisabled: boolean;
  onSendTestEmailClick: () => void;
  onPreviewEmailClick: () => void;
}

const Header = ({
  campaignTitle,
  onEditClick,
  onEditCampaignName,
  isEditDisabled,
  onSendTestEmailClick,
  onPreviewEmailClick,
}: HeaderProps) => (
  <div className="CreateCampaignCard-Header">
    <HeaderTitle campaignTitle={campaignTitle} onEdit={onEditCampaignName} />
    <div className="CreateCampaignCard-Header-ButtonGroup">
      <Button
        variant="secondary"
        title="Edit template"
        onClick={onEditClick}
        size="md"
        disabled={isEditDisabled}
      />
      <TemplateActionsDropdown
        onSendTestEmailClick={onSendTestEmailClick}
        onPreviewEmailClick={onPreviewEmailClick}
        isDisabled={isEditDisabled}
      />
    </div>
  </div>
);

export default Header;
