/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';

import { Card, Divider, Icon, Typography } from '@atoms';
import { Accordion } from '@molecules';
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';

import CustomAppDetailsForm from './CustomAppDetailsForm';
import Header from './Header';

interface CustomAppDetailsProps {
  onSave: () => void;
  isSaveDisabled: boolean;
}

const images: ImagesListType = [
  {
    src: 'https://appmail-images.s3.amazonaws.com/CreateCustomAppStep.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/CreateCustomAppStep2.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/CreateCustomAppStep3.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep2.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep3.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep4.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep5.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep6.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiAccessStep7.png',
    loading: 'lazy',
    alt: 'Create custom app',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiCreds.png',
    loading: 'lazy',
    alt: 'ApiAccess',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiCreds2.png',
    loading: 'lazy',
    alt: 'ApiAccess',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ApiCreds3.png',
    loading: 'lazy',
    alt: 'ApiAccess',
  },
  {
    src: 'https://appmail-images.s3.amazonaws.com/ShopifySetup.png',
    loading: 'lazy',
    alt: 'ShopifySetup',
  },
];

const CustomAppDetails = ({
  onSave,
  isSaveDisabled,
}: CustomAppDetailsProps) => {
  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const SHOPIFY_TUTORIAL_STEPS = [
    {
      title: 'Log in',
      content: (
        <Typography variant="subtitle4">
          Log into <b>Shopify admin panel</b>
        </Typography>
      ),
    },
    {
      title: 'Create a custom app',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ul style={{ listStyle: 'disc', marginLeft: 20, gap: '4px' }}>
            <li>
              <Typography variant="subtitle4">
                Log into <b>Shopify admin panel</b>
              </Typography>
            </li>

            <li>
              <Typography variant="subtitle4" weight="semiBold">
                Navigate to Apps and sales channels {'>'} Click on Develop apps
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/CreateCustomAppStep.png"
                width={500}
                height={230}
                alt="Create custom app"
                onClick={() => {
                  setActiveIndex(0);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4" weight="semiBold">
                Click on Create an app
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/CreateCustomAppStep2.png"
                width={500}
                height={230}
                alt="Create custom app"
                onClick={() => {
                  setActiveIndex(1);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4" weight="semiBold">
                Click on Create an app
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/CreateCustomAppStep3.png"
                width={500}
                height={230}
                alt="Create custom app"
                onClick={() => {
                  setActiveIndex(2);
                  setLightboxVisible(true);
                }}
              />
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Configure admin API access',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ul style={{ listStyle: 'disc', marginLeft: 20, gap: '4px' }}>
            <li>
              <Typography variant="subtitle4">
                Navigate to the <b>Configuration tab</b>
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(3);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4">
                Enable the following permissions:
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep2.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(4);
                  setLightboxVisible(true);
                }}
              />
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep3.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(5);
                  setLightboxVisible(true);
                }}
              />
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep4.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(6);
                  setLightboxVisible(true);
                }}
              />
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep5.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(7);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4">
                Click <b>Save</b> {'>'} Click<b> Install app</b>
              </Typography>

              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep6.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(8);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4">
                Click <b>Install</b>
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiAccessStep7.png"
                width={500}
                height={230}
                alt="ApiAccessStep"
                onClick={() => {
                  setActiveIndex(9);
                  setLightboxVisible(true);
                }}
              />
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: 'API credentials',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <ul
            style={{
              listStyle: 'disc',
              marginLeft: 20,
              gap: '8px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <li>
              <Typography variant="subtitle4">
                Navigate to the <b>API credentials</b> tab
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiCreds.png"
                width={500}
                height={230}
                alt="API credentials"
                onClick={() => {
                  setActiveIndex(10);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4">
                Click <b>Reveal token once</b> (As stated in the yellow
                notification message, copy and save your Access token in a
                secure place)
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiCreds2.png"
                width={500}
                height={230}
                alt="API credentials"
                onClick={() => {
                  setActiveIndex(11);
                  setLightboxVisible(true);
                }}
              />
            </li>
            <li>
              <Typography variant="subtitle4">
                Copy and save your <b>API key</b> in a secure place
              </Typography>
              <img
                src="https://appmail-images.s3.amazonaws.com/ApiCreds3.png"
                width={500}
                height={230}
                alt="API credentials"
                onClick={() => {
                  setActiveIndex(12);
                  setLightboxVisible(true);
                }}
              />
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Shopify setup',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography variant="subtitle4">
            Navigate back to AppMail’s <b>Shopify setup</b> page (You should be
            automatically directed to the page)
          </Typography>

          <Typography variant="subtitle4">
            1. Enter your <b>Shopify store’s URL</b> (Typically this is your
            Store’s name)
          </Typography>
          <Typography variant="subtitle4">
            2. Enter the <b>API key</b> (The key that you copied and pasted into
            a secure place)
          </Typography>
          <Typography variant="subtitle4">
            3. Enter the <b>Admin access API token</b> (This is the token that
            you revealed once, and then copied and pasted into a secure place)
          </Typography>
          <Typography variant="subtitle4">
            4. Click <b>Save and Continue</b>
          </Typography>
          <img
            src="https://appmail-images.s3.amazonaws.com/ShopifySetup.png"
            width={500}
            height={230}
            alt="API credentials"
            onClick={() => {
              setActiveIndex(13);
              setLightboxVisible(true);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Card className="ShopDetails">
      <Header
        buttonTitle="Save and Continue"
        title="API integration"
        subtitle="Fill in the fields below to connect your Shopify store"
        onSave={onSave}
        isSaveDisabled={isSaveDisabled}
      />
      <Divider />
      <div className="ShopDetails-Content">
        <CustomAppDetailsForm />
        <div className="HowToSetupStore-Section flex flex-col items-center gap-6">
          <Typography variant="subtitle2" weight="semiBold">
            Shopify integration
          </Typography>
          <video controls width="529" height="292">
            <source
              src="https://appmail-images.s3.amazonaws.com/Shopify+Integration+Tutorial.mp4"
              type="video/mp4"
            />
          </video>
          <Typography variant="subtitle3">
            Here is a guide to assist you in setting up your Shopify integration
            with AppMail. This guide will walk you through the process of
            granting permission for AppMail to synchronize your product and
            inventory data seamlessly into our platform.
            <br />
            <br />
            Please follow the following steps:
          </Typography>
          <div className="flex flex-col w-full gap-2.5">
            {SHOPIFY_TUTORIAL_STEPS.map((step, index) => (
              <Accordion
                key={step.title}
                title={`${index + 1}. ${step.title}`}
                content={step.content}
              />
            ))}
          </div>
        </div>
      </div>
      <Lightbox
        images={images}
        isOpen={lightboxVisible}
        onPrev={() => {}}
        onNext={() => {}}
        onClose={() => setLightboxVisible(false)}
        currentIndex={activeIndex}
        style={{ backgroundColor: 'rgba(128,128,128, .9)' }}
        renderHeader={() => (
          <div className="Lightbox-Header">
            <Icon
              SVG={CrossIcon}
              onClick={() => setLightboxVisible(false)}
              width={50}
              height={50}
            />
          </div>
        )}
      />
    </Card>
  );
};

export default CustomAppDetails;
