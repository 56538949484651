import { Button, HeaderContainer, Loader, Typography } from '@atoms';
import { CampaignsTemplate } from '@templates';
import { campaignsSelectors } from '@store/features/campaigns';
import { useAppSelector } from '@store/hooks';

interface ICampaignsViewProps {
  createNewCampaign: () => void;
}

const CampaignsView = ({ createNewCampaign }: ICampaignsViewProps) => {
  const {
    campaigns: { data },
    isLoading,
  } = useAppSelector(campaignsSelectors.campaigns);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {/* TODO: this header component is copy of AllCampaignsView header. */}
      {/* Try to make it reusable (probably configure DashboardTabsHeader component) */}
      <HeaderContainer>
        <Typography variant="subtitle1">Manage your campaigns</Typography>
        <Button
          title="Create campaign"
          variant="primary"
          size="lg"
          onClick={createNewCampaign}
        />
      </HeaderContainer>
      <CampaignsTemplate campaigns={data} isCampaignsLoading={isLoading} />
    </>
  );
};

export default CampaignsView;
