import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';

import domainApi, { CreateDomainData, DeleteSenderData } from './api';

export const registerCustomDomainThunk =
  createCommonAsyncThunk<CreateDomainData>(
    'registerCustomDomain',
    domainApi.registerDomain
  );

export const getListOfDomainsThunk = createCommonAsyncThunk(
  'getListOfDomains',
  domainApi.getListOfDomains
);

export const validateDomainThunk = createCommonAsyncThunk(
  'validateDomain',
  domainApi.validateDomain
);

export const getAvailableSendersThunk = createCommonAsyncThunk(
  'getAvailableSenders',
  domainApi.getAvailableSenders
);

export const sendErrorThunk = createCommonAsyncThunk(
  'sendError',
  domainApi.sendError
);

export const deleteDomainThunk = createCommonAsyncThunk(
  'deleteDomain',
  domainApi.deleteDomain
);

export const deleteSenderThunk = createCommonAsyncThunk<DeleteSenderData>(
  'deleteSender',
  domainApi.deleteSender
);

export default {
  registerCustomDomainThunk,
  getListOfDomainsThunk,
  validateDomainThunk,
  getAvailableSendersThunk,
  sendErrorThunk,
  deleteDomainThunk,
  deleteSenderThunk,
};
