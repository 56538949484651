import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { DashboardView, SignInView, SignUpView } from '@views';
import { ROUTES } from '@constants/routes';
import { TemplatesPageFrom } from '@constants/template';
import AllContactsPage from '@pages/Dashboard/AllContactsPage/AllContactsPage';
import AllCampaignsPage from '@pages/Dashboard/Campaigns/AllCampaignsPage';
import CampaignsPage from '@pages/Dashboard/Campaigns/CampaignsPage';
import CreateCampaignPage from '@pages/Dashboard/Campaigns/CreateCampaignPage';
import ReportPage from '@pages/Dashboard/Campaigns/ReportPage';
import ProfilePage from '@pages/Dashboard/ProfilePage/ProfilePage';
import TemplatesPage from '@pages/Dashboard/TemplatesPage/TemplatesPage';
import EditorPage from '@pages/EditorPage/EditorPage';
import IntegrationsPage from '@pages/IntegrationsPage/IntegrationsPage';
import PreviewPage from '@pages/PreviewPage/PreviewPage';
import ShopifyAuthFormPage from '@pages/ShopifyAuthFormPage/ShopifyAuthFormPage';
import CreateNewPasswordPage from '@pages/SignIn/CreateNewPasswordPage/CreateNewPasswordPage';
import PasswordUpdatedPage from '@pages/SignIn/CreateNewPasswordPage/PasswordUpdatedPage';
import ForgotPasswordCheckEmailPage from '@pages/SignIn/ForgotPasswordPage/ForgotPasswordCheckEmailPage';
import ForgotPasswordPage from '@pages/SignIn/ForgotPasswordPage/ForgotPasswordPage';
import UnsubscribePage from '@pages/UnsubscribedPage/UnsubscribedPage';
import DashboardHomeView from '@views/DashboardHomeView/DashboardHomeView';

import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<UnsubscribePage />} path={ROUTES.unsubscribe} />
      <Route element={<AuthorizedRoute />}>
        <Route
          path={`${ROUTES.template.editor}/:id`}
          element={<EditorPage />}
        />
        <Route
          path={`${ROUTES.template.preview}/:id`}
          element={<PreviewPage />}
        />
        <Route
          path={ROUTES.dashboard.campaignReport}
          element={<ReportPage />}
        />
        <Route path={ROUTES.root} element={<DashboardView />}>
          <Route
            element={<Navigate to={ROUTES.dashboard.home} replace />}
            index
          />
          <Route path={ROUTES.dashboard.home} element={<DashboardHomeView />} />
          <Route
            path={ROUTES.dashboard.campaigns}
            element={<CampaignsPage />}
          />
          <Route
            path={ROUTES.dashboard.templates}
            element={<TemplatesPage from={TemplatesPageFrom.Dashboard} />}
          />
          <Route
            path={ROUTES.dashboard.allcampaigns}
            element={<AllCampaignsPage />}
          />
          <Route
            path={ROUTES.dashboard.createCampaign}
            element={<CreateCampaignPage />}
          />
          <Route
            path={ROUTES.dashboard.selectTemplate}
            element={<TemplatesPage from={TemplatesPageFrom.Editor} />}
          />
          <Route
            path={ROUTES.dashboard.shopifyAuth}
            element={<ShopifyAuthFormPage />}
          />
          <Route
            path={ROUTES.dashboard.allcontacts}
            element={<AllContactsPage />}
          />
          <Route
            path={ROUTES.dashboard.integrations}
            element={<IntegrationsPage />}
          />
          <Route path={ROUTES.dashboard.profile} element={<ProfilePage />} />
        </Route>
      </Route>
      <Route element={<UnauthorizedRoute />}>
        <Route path={ROUTES.signIn} element={<SignInView />} />
        <Route path={ROUTES.signUp} element={<SignUpView />} />
        <Route path={ROUTES.forgotPassword} element={<ForgotPasswordPage />} />
        <Route
          path={ROUTES.createNewPassword}
          element={<CreateNewPasswordPage />}
        />
        <Route
          path={ROUTES.passwordUpdated}
          element={<PasswordUpdatedPage />}
        />
        <Route
          path={ROUTES.forgotPasswordCheckEmail}
          element={<ForgotPasswordCheckEmailPage />}
        />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
