import { ReactComponent as CodeIcon } from '@assets/icons/code.svg';
import { ReactComponent as FallbackIcon } from '@assets/icons/fallback.svg';
import { ReactComponent as PowerIcon } from '@assets/icons/power.svg';
import { ReactComponent as StraightLine } from '@assets/icons/straight-line.svg';
import { ReactComponent as SuperDashedLine } from '@assets/icons/super-dashed-line.svg';
import * as StraightLinePath from '@assets/images/straight-line.png';
import * as SuperDashedLinePath from '@assets/images/super-dashed-line.png';
import { LayoutMenuOption, SelectorOptionType } from '@devTypes';

export const EDITOR_CONTAINERS = {
  canvas: 'gjs',
  blockManager: 'Editor-Blocks',
  iframe: 'iframe.gjs-frame',
  selectedComponent: 'gjs-selected',
  canvasContainer: 'canvas-container',
};

export enum EditorComponentTypes {
  Text = 'text',
  Columns = 'columns',
  Button = 'button',
  Image = 'custom-image',
  Carousel = 'carousel',
  Footer = 'footer',
  Ecommerce = 'e-commerce',
  Divider = 'divider',
  Spacer = 'spacer',
  AddBlock = 'add-block',
  Logo = 'logo',
}

type FormatOption = {
  title: string;
  style: { [key: string]: string };
};

export const DEFAULT_TEXT_COLOR_VALUE = '#000000';
export const DEFAULT_BACKGROUND_VALUE = '#ffffff';

export const DEFAULT_LETTER_SPACING = '0';
export const DEFAULT_LINE_SPACING = '1.4';

export const TEXT_FORMAT_OPTIONS: Array<FormatOption> = [
  {
    title: 'Default',
    style: {
      'font-family': 'Helvetica, sans-serif',
      'font-size': '14px',
      'font-style': 'normal',
      'line-height': DEFAULT_LINE_SPACING,
      'text-align': 'center',
      'letter-spacing': 'normal',
      'text-decoration': 'none',
      color: '#000000',
    },
  },
  {
    title: 'Large heading',
    style: {
      'font-family': 'Helvetica, sans-serif',
      'font-size': '28px',
      'font-style': 'normal',
      'line-height': '1.5',
      'text-align': 'center',
      color: '#000000',
    },
  },
  {
    title: 'Regular heading',
    style: {
      'font-family': 'Helvetica, sans-serif',
      'font-size': '20px',
      'font-style': 'normal',
      'line-height': '1.4',
      'text-align': 'center',
      color: '#000000',
    },
  },
  {
    title: 'Large paragraph',
    style: {
      'font-family': 'Helvetica, sans-serif',
      'font-size': '16px',
      'font-style': 'normal',
      'line-height': '1.4',
      'text-align': 'center',
      color: '#000000',
    },
  },
  {
    title: 'Regular paragraph',
    style: {
      'font-family': 'Helvetica, sans-serif',
      'font-size': '14px',
      'font-style': 'normal',
      'line-height': '1.5',
      'text-align': 'center',
      color: '#000000',
    },
  },
];

export const DEFAULT_TEXT_PADDING_TOP_IN_PX = 16;
export const DEFAULT_TEXT_PADDING_BOTTOM_IN_PX = 16;
export const DEFAULT_TEXT_PADDING_LEFT_IN_PX = 32;
export const DEFAULT_TEXT_PADDING_RIGHT_IN_PX = 32;

export const DEFAULT_TEXT_FORMAT = TEXT_FORMAT_OPTIONS[0].title;
export const DEFAULT_TEXT_STYLE = {
  ...TEXT_FORMAT_OPTIONS[0].style,
  'background-color': DEFAULT_BACKGROUND_VALUE,
  'padding-top': `${DEFAULT_TEXT_PADDING_TOP_IN_PX}px`,
  'padding-left': `${DEFAULT_TEXT_PADDING_LEFT_IN_PX}px`,
  'padding-bottom': `${DEFAULT_TEXT_PADDING_BOTTOM_IN_PX}px`,
  'padding-right': `${DEFAULT_TEXT_PADDING_RIGHT_IN_PX}px`,
  'margin-block': '0',
};

export const DEFAULT_TEXT_TYPE = 'text-block';
export const COLUMNS_TEXT_TYPE = 'columns-text-block';

export const DEFAULT_TEXT_ATTRIBUTES = {
  blockType: DEFAULT_TEXT_TYPE,
  paddingleft: DEFAULT_TEXT_PADDING_LEFT_IN_PX,
  paddingright: DEFAULT_TEXT_PADDING_RIGHT_IN_PX,
  paddingtop: DEFAULT_TEXT_PADDING_TOP_IN_PX,
  paddingbottom: DEFAULT_TEXT_PADDING_BOTTOM_IN_PX,
  format: DEFAULT_TEXT_FORMAT,
  lineSpacing: DEFAULT_LINE_SPACING,
};

export const DEFAULT_COLUMNS_TEXT_STYLE = {
  ...DEFAULT_TEXT_STYLE,
  'padding-top': '0px',
  'padding-left': '0px',
  'padding-bottom': '0px',
  'padding-right': '0px',
  'overflow-wrap': 'anywhere',
  margin: '20px 0',
};

export const DEFAULT_COLUMNS_TEXT_ATTRIBUTES = {
  ...DEFAULT_TEXT_ATTRIBUTES,
  blockType: COLUMNS_TEXT_TYPE,
  paddingleft: 0,
  paddingright: 0,
  paddingtop: 0,
  paddingbottom: 0,
};

export const DEFAULT_TEXT_FONT_FAMILY =
  TEXT_FORMAT_OPTIONS[0].style['font-family'];
export const DEFAULT_TEXT_FONT_SIZE = TEXT_FORMAT_OPTIONS[0].style['font-size'];

export const DEFAULT_BUTTON_FONT_FAMILY = 'Helvetica, sans-serif';
export const DEFAULT_BUTTON_TEXT_COLOR = '#FFFFFF';
export const DEFAULT_BUTTON_FILL_COLOR = '#000000';
export const DEFAULT_BUTTON_BORDER_COLOR = '#000000';
export const DEFAULT_BUTTON_BORDER_RADIUS = 8;
export const DEFAULT_BUTTON_BORDER_WIDTH = 0;
export const DEFAULT_BUTTON_WIDTH = 'fit-content';
export const DEFAULT_BUTTON_HEIGHT = 40;
export const DEFAULT_BUTTON_ALIGNMENT = 'center';
export const DEFAULT_BUTTON_HOVER_TEXT_COLOR = '#ffffff';
export const DEFAULT_BUTTON_HOVER_FILL_COLOR = '#5c5c5c';
export const DEFAULT_BUTTON_HOVER_BORDER_COLOR = '#5c5c5c';

export const DEFAULT_DIVIDER_COLOR = '#000000';
export const DEFAULT_DIVIDER_HEIGHT = '1px';
export const DEFAULT_DIVIDER_WIDTH = '650px';

export const DEFAULT_SPACER_WIDTH = '650px';

export const COLUMNS_LAYOUTS = {
  layoutA: { id: 'layoutA', columnsAmount: 2 },
  layoutB: { id: 'layoutB', columnsAmount: 2 },
  layoutC: { id: 'layoutC', columnsAmount: 2 },
  layoutA1: { id: 'layoutA1', columnsAmount: 1 },
};

export const DEFAULT_COLUMNS_AMOUNT = COLUMNS_LAYOUTS.layoutA.columnsAmount;
export const DEFAULT_COLUMNS_LAYOUT = COLUMNS_LAYOUTS.layoutA.id;
export const DEFAULT_COLUMNS_PADDING = 30;
export const DEFAULT_COLUMNS_VERTICAL_SPACE = 30;
export const DEFAULT_COLUMNS_HORIZONTAL_SPACE = 20;

export const DEFAULT_CAROUSEL_NAVIGATION_COLOR = '#000000';
export const DEFAULT_CAROUSEL_IMAGE_FIT = 'contain';
export const DEFAULT_CAROUSEL_NAVIGATION_SIZE = 20;

export const DEFAULT_IMAGE_BLOCK =
  'https://appmail-images.s3.amazonaws.com/Default-image-(Layout-Columns).png';

export const TEXT_SIZE_OPTIONS = [
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '18',
  '20',
  '24',
  '28',
  '32',
  '36',
  '48',
  '64',
  '72',
  '96',
  '144',
  '288',
];

export const TEXT_FONT_FAMILY_OPTIONS = {
  Arial: 'Arial, Helvetica, sans-serif',
  'Arial Black': 'Arial Black, Gadget, sans-serif',
  'Brush Script MT': 'Brush Script MT, sans-serif',
  'Comic Sans MS': 'Comic Sans MS, cursive, sans-serif',
  'Courier New': 'Courier New, monospace',
  Georgia: 'Georgia, serif',
  Helvetica: 'Helvetica, sans-serif',
  Impact: 'Impact, Charcoal, sans-serif',
  'Lucida Sans Unicode': 'Lucida Sans Unicode, Lucida Grande, sans-serif',
  Poppins: 'Poppins, sans-serif',
  Tahoma: 'Tahoma, Geneva, sans-serif',
  'Times New Roman': 'Times New Roman, Times, serif',
  'Trebuchet MS': 'Trebuchet MS, Helvetica, sans-serif',
  Verdana: 'Verdana, Geneva, sans-serif',
};

export const TEXT_LETTER_SPACING_OPTIONS = [
  '-0.1',
  '-0.05',
  '0',
  '0.1',
  '0.2',
  '0.3',
  '0.4',
  '0.5',
  '0.6',
  '0.7',
  '0.8',
  '1',
  '1.2',
];

export const TEXT_LINE_SPACING_OPTIONS = [
  '0',
  '0.1',
  '0.2',
  '0.3',
  '0.4',
  '0.5',
  '0.6',
  '0.7',
  '0.8',
  '0.9',
  '1',
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '1.7',
  '1.8',
  '1.9',
  '2',
  '2.25',
  '2.5',
  '2.75',
  '3',
];

export const EDITOR_WIDTH = 700;

export const MAX_TEXT_PADDING_IN_PX = 150;
export const MIN_TEXT_PADDING_IN_PX = 0;

export const MAX_FOOTER_PADDING_IN_PX = 30;
export const MIN_FOOTER_PADDING_IN_PX = 0;

export const MAX_COLUMNS_PADDING_IN_PX = 150;
export const MIN_COLUMNS_PADDING_IN_PX = 0;

export const MAX_BUTTON_PADDING_IN_PX = 300;
export const MIN_BUTTON_PADDING_IN_PX = 0;

export const MAX_BUTTON_BORDER_WIDTH_IN_PX = 10;
export const MAX_BUTTON_BORDER_RADIUS_IN_PX = 100;

export const MIN_BUTTON_WIDTH_IN_PX = 50;
export const MIN_BUTTON_HEIGHT_IN_PX = 30;
export const MAX_BUTTON_WIDTH_IN_PX = 250;
export const MAX_BUTTON_HEIGHT_IN_PX = 50;

export const MIN_DIVIDER_HEIGHT_IN_PX = 1;
export const MAX_DIVIDER_HEIGHT_IN_PX = 10;

export const MIN_SPACER_HEIGHT_IN_PX = 1;
export const MAX_SPACER_HEIGHT_IN_PX = 50;

export const MAX_COLUMNS_HORIZONTAL_SPACE_IN_PX = 150;
export const MIN_COLUMNS_HORIZONTAL_SPACE_IN_PX = 0;

export const MAX_COLUMNS_VERTICAL_SPACE_IN_PX = 50;
export const MIN_COLUMNS_VERTICAL_SPACE_IN_PX = 0;

export const MAX_CAROUSEL_IMAGES = 5;
export const MAX_CAROUSEL_PADDING = 150;
export const MIN_CAROUSEL_PADDING = 0;

export const MAX_ECOMMERCE_CHECKOUT_BUTTON_BORDER_WIDTH = 50;
export const MAX_ECOMMERCE_CHECKOUT_BUTTON_BORDER_RADIUS = 50;

export const ECOMMERCE_DEFAULT_LAYOUT = 'layout1';

export const MIN_LOGO_SIZE_IN_PX = 50;
export const MAX_LOGO_SIZE_IN_PX = 220;

export const MIN_LOGO_PADDING_IN_PX = 20;
export const MAX_LOGO_PADDING_IN_PX = 150;

export const LOGO_ALIGNMENT_OPTIONS: SelectorOptionType[] = [
  {
    id: 1,
    text: 'Left',
    value: {
      paddingTop: 32,
      paddingBottom: 32,
      paddingLeft: 30,
      paddingRight: 0,
      align: 'left',
    },
  },
  {
    id: 2,
    text: 'Center',
    value: {
      paddingTop: 32,
      paddingBottom: 32,
      paddingRight: 0,
      paddingLeft: 0,
      align: 'center',
    },
  },
  {
    id: 3,
    text: 'Right',
    value: {
      paddingTop: 32,
      paddingBottom: 32,
      paddingRight: 30,
      paddingLeft: 0,
      align: 'right',
    },
  },
];

export const DEFAULT_LOGO_ALIGNMENT = LOGO_ALIGNMENT_OPTIONS[1];

export type BlockType = {
  elementId: string | undefined;
  value: {
    type: string;
    payload: {
      text?: string;
      nested?: string[];
      styles: string;
      styleClasses?: string[];
    };
  };
};

export type TemplateContractType = {
  title: string;
  status: string;
  blocks: BlockType[];
  blockOrder: string[] | undefined[];
  globalStyles?: string | grapesjs.default.CssRule[] | undefined;
  gjs: string;
  backgroundStyles?: string;
};

export const DEFAULT_LAYOUT_IMAGE = DEFAULT_IMAGE_BLOCK;

export const EDITOR_EMAIL_TYPES = [
  { id: 'amp', title: 'AMP', icon: PowerIcon },
  { id: 'html', title: 'HTML interactive', icon: CodeIcon, disabled: true },
  { id: 'fallback', title: 'Fallback', icon: FallbackIcon },
];

export const DIVIDER_LAYOUTS: LayoutMenuOption[] = [
  {
    id: 0,
    path: StraightLinePath.default,
    icon: StraightLine,
    url: 'https://appmail-images.s3.amazonaws.com/dividers/straight-line.png',
    type: 'solid',
  },
  {
    id: 1,
    path: SuperDashedLinePath.default,
    icon: SuperDashedLine,
    url: 'https://appmail-images.s3.amazonaws.com/dividers/super-dashed-line.png',
    type: 'dashed',
  },
];

export const DEFAULT_OUTER_BACKGROUND_COLOR = '#f2f4f7';
export const DEFAULT_SPACER_HEIGHT_IN_PX = 32;
