import { MouseEventHandler, useState } from 'react';
import grapesjs from 'grapesjs';

import { Editor } from '@organisms';
import { EditorTemplate } from '@templates';

interface EditorViewProps {
  initiateEditor: Function;
  onPreviewClick: MouseEventHandler;
  onSaveClick: MouseEventHandler;
  onGoBackClick: MouseEventHandler;
  onEditorChange: (editor: grapesjs.Editor) => void;
}

const EditorView = ({
  initiateEditor,
  onPreviewClick,
  onSaveClick,
  onGoBackClick,
  onEditorChange,
}: EditorViewProps) => {
  const [isEditorSidebarSettingsOpen, setIsEditorSidebarSettingsOpen] =
    useState(false);

  const handleEditorSidebarSettingsOpen = () =>
    setIsEditorSidebarSettingsOpen(true);
  const handleEditorSidebarSettingsClose = () =>
    setIsEditorSidebarSettingsOpen(false);

  return (
    <EditorTemplate
      onPreviewClick={onPreviewClick}
      onSaveClick={onSaveClick}
      onGoBackClick={onGoBackClick}
      isEditorSidebarSettingsOpen={isEditorSidebarSettingsOpen}
      onEditorSidebarSettingsClose={handleEditorSidebarSettingsClose}
    >
      <Editor
        onEditorChnage={onEditorChange}
        initiateEditor={initiateEditor}
        onGenerativeAISettingsOpen={handleEditorSidebarSettingsOpen}
      />
    </EditorTemplate>
  );
};

export default EditorView;
