import { toast } from 'react-toastify';

import { Loader } from '@atoms';
import { GalleryItemProps } from '@devTypes';
import Gallery from '@organisms/Gallery/Gallery';
import { galleryAsyncThunks, gallerySelectors } from '@store/features/gallery';
import { useAppDispatch, useAppSelector } from '@store/hooks';

interface Props {
  galleryValue: GalleryItemProps | GalleryItemProps[] | null;
  onGalleryValueChange: (value: GalleryItemProps[] | GalleryItemProps) => void;
}

const EditorImageBlockUploadModalMyUploads = ({
  galleryValue,
  onGalleryValueChange,
}: Props) => {
  const uploadedImages = useAppSelector(
    gallerySelectors.uploads
  ) as GalleryItemProps[];
  const isGetAllUrlsLoading = useAppSelector(
    gallerySelectors.isGetAllUrlsLoading
  );
  const disptach = useAppDispatch();

  const handleGalleryItemDelete = async (id: number) => {
    try {
      await disptach(galleryAsyncThunks.deleteImageThunk({ id }));
    } catch {
      toast.error('Error deleting image. Please try again.');
    }

    await disptach(galleryAsyncThunks.getAllUrlsThunk(null));
  };

  if (isGetAllUrlsLoading) return <Loader />;

  return (
    <Gallery
      value={galleryValue}
      images={uploadedImages}
      onChange={onGalleryValueChange}
      onDelete={handleGalleryItemDelete}
      max={5}
    />
  );
};

export default EditorImageBlockUploadModalMyUploads;
