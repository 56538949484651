import { Button, Typography } from '@atoms';
import { TemplateObject } from '@devTypes';

import './styles.css';

interface TemplatePreviewCardsProps {
  templates: TemplateObject[] | null;
  onClickTemplate: (item: TemplateObject) => void;
  onUseTemplate: (item: TemplateObject) => void;
}

const TemplatePreviewCards = ({
  templates,
  onClickTemplate,
  onUseTemplate,
}: TemplatePreviewCardsProps) => (
  <div id="wrapper">
    <section className="TemplatePreviewList">
      {templates &&
        templates.map((item) =>
          item.previewUrl ? (
            <article key={item.id} className="CardContent">
              <div className="CardContent-overlay" />
              <div className="TemplateCard">
                <img
                  src={item.previewUrl}
                  alt="template-preview"
                  className="TemplatePreviewImage"
                />
                <div className="TemplateCard-Label">
                  <Typography variant="subtitle4">{item.title}</Typography>
                </div>
              </div>
              <div className="OverlayContainer fadeIn-bottom">
                <Button
                  title="Use template"
                  variant="primary"
                  onClick={() => onUseTemplate(item)}
                />
                <Button
                  title="Preview"
                  variant="secondary"
                  onClick={() => onClickTemplate(item)}
                />
              </div>
            </article>
          ) : null
        )}
    </section>
  </div>
);

export default TemplatePreviewCards;
