import { useFormContext } from 'react-hook-form';

import { Icon, Loader } from '@atoms';
import { InputWithTitle } from '@molecules';
import { ReactComponent as CheckIcon } from '@assets/icons/validation-check.svg';
import PasswordValidationErrorsList from '@molecules/ValidationErrorsList/PasswordValidationErrorsList';
import { authSelectors } from '@store/features/auth';
import { useAppSelector } from '@store/hooks';
import { NO_VALUE_ERROR_MESSAGE } from '@utils/validation';

import './styles.css';

const renderValidatedValueIcon = (isValid: boolean) =>
  isValid && (
    <Icon
      SVG={CheckIcon}
      height={24}
      width={24}
      className="ValidationCheckIcon"
    />
  );

const SignUp = () => {
  const { control, formState, getValues } = useFormContext();

  const isLoading = useAppSelector(authSelectors.isSignUpLoading);

  const errorMessage = formState.errors.password?.message;

  const isPasswordValidationErrorsListShown =
    !!errorMessage && errorMessage !== NO_VALUE_ERROR_MESSAGE;

  if (isLoading) return <Loader height={318} />;

  return (
    <>
      <div className="SignUp-FullNameContainer">
        <InputWithTitle
          name="firstName"
          title="First name"
          control={control}
          isRequired
        />
        <InputWithTitle
          name="lastName"
          title="Last name"
          control={control}
          isRequired
        />
      </div>
      <InputWithTitle
        name="email"
        title="Email"
        control={control}
        endIcon={renderValidatedValueIcon(
          formState.dirtyFields.email && !formState.errors.email
        )}
        isRequired
      />
      <InputWithTitle
        name="password"
        title="Password"
        type="password"
        control={control}
        isRequired
        className="SignUp-PasswordInput"
        endIcon={renderValidatedValueIcon(
          formState.dirtyFields.password &&
            !formState.errors.password &&
            getValues().password >= 10
        )}
        hideErrorMessage={isPasswordValidationErrorsListShown}
      />
      <PasswordValidationErrorsList
        isVisible={isPasswordValidationErrorsListShown}
        errorMessage={errorMessage as string}
      />
    </>
  );
};

export default SignUp;
