import { useFormContext } from 'react-hook-form';

import { InputWithTitle } from '@molecules';
import PasswordValidationErrorsList from '@molecules/ValidationErrorsList/PasswordValidationErrorsList';
import { NO_VALUE_ERROR_MESSAGE } from '@utils/validation';

import './styles.css';

const CreateNewPassword = () => {
  const { control, formState } = useFormContext();

  const errorMessage = formState.errors.password?.message;

  const isPasswordValidationErrorsListShown =
    !!errorMessage && errorMessage !== NO_VALUE_ERROR_MESSAGE;

  return (
    <>
      <InputWithTitle
        name="password"
        title="New Password"
        type="password"
        control={control}
        hideErrorMessage={isPasswordValidationErrorsListShown}
        isRequired
      />
      <PasswordValidationErrorsList
        isVisible={isPasswordValidationErrorsListShown}
        errorMessage={errorMessage as string}
      />
      <InputWithTitle
        name="confirmPassword"
        title="Confirm New Password"
        type="password"
        control={control}
        isRequired
      />
    </>
  );
};

export default CreateNewPassword;
