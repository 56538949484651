import { ENDPOINTS } from '@constants/api';
import { Order, RecipientType } from '@devTypes';

import { wrappedAxiosRequest } from '../../api';

export interface CampaignData {
  id?: number;
  userId?: number;
  title?: string;
  subject?: string;
  templateId?: number;
  recipientType?: string;
  senderEmail?: string;
  senderName?: string;
  scheduledAt?: string;
  createdAt?: string;
  report?: {
    id: number;
    clickRate: string;
    openRate: string;
  };
  recipientsMetadata?: {
    totalCount: number;
    subscribedCount: number;
    unsubscribedCount: number;
  };
}

export interface ScheduleCampaignData {
  id: number;
  title: string;
  subject: string;
  previewText: string;
  senderName: string;
  senderId: number | null;
  templateId: number;
  recipientType: RecipientType;
  scheduledAt: string;
}

export interface CampaignsResponseData {
  data: CampaignData[];
  limit: number;
  offset: number;
  total: number;
  metadata: {
    draftCampaignsCount: number;
    publishedCampaignsCount: number;
  };
}

export interface NewCampaignData {
  title: string;
  subject?: string;
  previewText?: string;
  senderName?: string;
  senderId?: number | null;
  templateId: number;
  recipientType?: string;
}

type SortBy = 'title' | 'createdAt' | 'scheduledAt';

type Status = 'draft' | 'scheduled' | 'published' | 'hidden';

export interface CampaignsData {
  limit?: number;
  offset?: number;
  order?: Order;
  sortBy?: SortBy;
  title?: string;
  status?: Status;
}

export interface IdCampaignData {
  id: number | undefined;
}

export interface SendTestEmailData {
  id: number;
  emails: string[];
  templateId: number;
  isPreset: boolean;
}

export interface CampaignReportData {
  id: number;
}

const campaignsApi = {
  async getCampaigns(data: CampaignsData) {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.campaigns.campaignsRoute,
      params: data,
    });
  },

  async createCampaign(data?: NewCampaignData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: ENDPOINTS.campaigns.campaignsRoute,
      data,
    });
  },

  async updateCampaign({ id, ...campaignUpdateBody }: CampaignData) {
    return wrappedAxiosRequest({
      method: 'patch',
      url: `${ENDPOINTS.campaigns.campaignsRoute}/${id}`,
      data: campaignUpdateBody,
    });
  },

  async getCampaignById({ id }: IdCampaignData) {
    return wrappedAxiosRequest({
      method: 'get',
      url: `${ENDPOINTS.campaigns.campaignsRoute}/${id}`,
    });
  },

  async sendCampaign({ id }: IdCampaignData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: `${ENDPOINTS.campaigns.campaignsRoute}/${id}/send`,
    });
  },

  async scheduleCampaign({ id, ...data }: ScheduleCampaignData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: `${ENDPOINTS.campaigns.campaignsRoute}/${id}/schedule`,
      data,
    });
  },

  async sendTestEmail({ id, ...data }: SendTestEmailData) {
    return wrappedAxiosRequest({
      method: 'post',
      url: `${ENDPOINTS.campaigns.campaignsRoute}/${id}/send-test`,
      data,
    });
  },

  async getCampaignReport({ id }: CampaignReportData) {
    return wrappedAxiosRequest({
      method: 'get',
      url: ENDPOINTS.campaigns.campaignReport(id),
    });
  },

  async deleteCampaignById({ id }: IdCampaignData) {
    return wrappedAxiosRequest({
      method: 'delete',
      url: `${ENDPOINTS.campaigns.campaignsRoute}/${id}`,
    });
  },
};

export default campaignsApi;
