import { forwardRef } from 'react';
import { Control, useController } from 'react-hook-form';
import clsx from 'clsx';

import './styles.css';

interface Props {
  control: Control<any, any>;
  name: string;
  rows?: number;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
}

const TextArea = forwardRef(
  ({ control, name, rows, placeholder, isDisabled, className }: Props, ref) => {
    const {
      field: { value, onChange, onBlur, ref: fieldRef },
    } = useController({
      name,
      control,
    });

    return (
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={clsx('TextArea', className)}
        rows={rows}
        disabled={isDisabled}
        placeholder={placeholder}
        ref={(e) => {
          fieldRef(e);

          if (typeof ref === 'function') {
            ref(e);
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = e;
          }
        }}
      />
    );
  }
);

export default TextArea;
