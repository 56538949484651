import { useState } from 'react';

import { Loader } from '@atoms';
import { DashboardTabsHeader } from '@molecules';
import { ProfileTemplate } from '@templates';
import Tabs, { TabItem } from '@molecules/Tabs/Tabs';
import { organizationsSelectors } from '@store/features/organizations';
import { usersSelectors } from '@store/features/users';
import { useAppSelector } from '@store/hooks';
import SenderSettingsTemplate from '@templates/SenderSettingsTemplate/SenderSettingsTemplate';

import './styles.css';

const tabs: TabItem[] = [
  {
    id: 'Profile',
    label: 'Profile',
  },
  {
    id: 'SenderSettings',
    label: 'Sender settings',
  },
];

const renderTab = (tabId: string) => {
  switch (tabId) {
    case 'Profile':
      return <ProfileTemplate />;
    case 'SenderSettings':
      return <SenderSettingsTemplate />;
    default:
      return <ProfileTemplate />;
  }
};

const ProfileView = () => {
  const isGetMeOrganizationDataLoading = useAppSelector(
    organizationsSelectors.isGetMeOrganizationDataLoading
  );
  const isGetUserLoading = useAppSelector(usersSelectors.isGetUserLoading);

  const [selectedTab, setSelectedTab] = useState<TabItem>(tabs[0]);

  if (isGetMeOrganizationDataLoading || isGetUserLoading)
    return <Loader fullScreen />;

  return (
    <>
      <DashboardTabsHeader title="Settings" />
      <div className="SettingsHeader">
        <Tabs
          items={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="SettingsTabs"
        />
        {renderTab(selectedTab.id)}
      </div>
    </>
  );
};

export default ProfileView;
